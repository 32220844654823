import { ApolloError, gql, useMutation } from "@apollo/client";
import {
  ActiveBannerOptionMutation,
  ActiveBannerOptionMutationVariables,
} from "../generated/graphql";

const ACTIVE_BANNER_OPTION_QUERY = gql`
  mutation activeBannerOption($input: ActiveBannerOptionInput!) {
    activeBannerOption(input: $input) {
      ok
      err
    }
  }
`;

export const useActiveBannerOption = (
  onCompleted?: (data: ActiveBannerOptionMutation) => void,
  onError?: (error: ApolloError) => void
) => {
  return useMutation<
    ActiveBannerOptionMutation,
    ActiveBannerOptionMutationVariables
  >(ACTIVE_BANNER_OPTION_QUERY, {
    onCompleted,
    onError,
  });
};
