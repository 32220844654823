import { ApolloError, gql, useMutation } from "@apollo/client";
import {
  ActiveBannerMutation,
  ActiveBannerMutationVariables,
} from "../generated/graphql";

const ACTIVE_BANNER_QUERY = gql`
  mutation activeBanner($input: ActiveBannerInput!) {
    activeBanner(input: $input) {
      ok
      err
    }
  }
`;

export const useActiveBanner = (
  onCompleted?: (data: ActiveBannerMutation) => void,
  onError?: (error: ApolloError) => void
) => {
  return useMutation<ActiveBannerMutation, ActiveBannerMutationVariables>(
    ACTIVE_BANNER_QUERY,
    {
      onCompleted,
      onError,
    }
  );
};
