import close from "../assets/icon/exit.png";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useParams } from "react-router";
import { pageIndicator } from "../apollo";
import { FormError2 } from "../components/form-error";
import {
  CategoryFirstOption,
  CategoryFirstType,
  CategorySecondOption,
  CategorySecondType,
  EditProductProps,
  GoodStatusOption,
  GoodStatusOptionType,
  GoodTypeOption,
  GoodTypeOptionType,
  relatedProps,
  TagList,
} from "../constants/enum";
import { IEditGoodFormProps, useEditGood } from "../hooks/useEditGood";
import { useGetGood } from "../hooks/useGetGood";
import { Button } from "../components/button";
import { useListCorporationSelect } from "../hooks/useListCorprationSelect";
import { useListGoodAll } from "../hooks/useListGoodAll";
import { Autocomplete, Switch, TextField } from "@mui/material";
import del from "../assets/icon/exit.png";
import { useCrGoodOption } from "../hooks/useCrGoodOption";
import { useListGoodOption } from "../hooks/useListGoodOption";
import moment from "moment";
import { useDelGoodOption } from "../hooks/useDelGoodOption";
import { useEditGoodOption } from "../hooks/useEditGoodOption";
import {
  CrGoodOptionMutation,
  DelGoodOptionMutation,
  DetailedGoodOptionInput,
  EditGoodInput,
  EditGoodMutation,
  EditGoodOptionInput,
  EditGoodOptionMutation,
  GetGoodInput,
  GetGoodQuery,
  GoodOption,
  GoodStatus,
  ListCorporationSelectQuery,
  ListGoodAllQuery,
  ListGoodOptionQuery,
  Tags,
} from "../generated/graphql";

export interface corporationProps {
  label: string;
  value: number;
}

export const SubAdminEditProductInfo: React.FC<EditProductProps> = ({
  tabs,
  setTabHandler,
}) => {
  // 태그
  const [tags, setTags] = useState<Tags[]>([]);
  const [corporationList, setCorporationList] = useState<corporationProps[]>(
    []
  );
  const [cor, setCor] = useState<corporationProps[]>([
    { label: "불러올 거래처가 없습니다.", value: 0 },
  ]);

  const addTagHandler = (values: Tags) => {
    setTags([...tags, values]);
  };
  const removeTagHandler = (values: Tags) => {
    setTags(tags.filter((a) => a !== values));
  };
  // 글자 수
  const getByte = (str: string) => {
    var bytes = str
      .split("")
      .map((s) => s.charCodeAt(0))
      .reduce((prev, c) => prev + (c === 10 ? 2 : c >> 7 ? 2 : 1), 0);
    return bytes;
  };
  const params = useParams();

  /// 거래처 List ///
  const onListCorporation = (data: ListCorporationSelectQuery) => {
    const {
      listCorporationSelect: { ok, err, corporations },
    } = data;
    var arrays: corporationProps[] = [...cor];
    arrays.splice(0);
    console.log(corporations);
    if (corporations && corporations.length > 0)
      for (const corporation of corporations) {
        var obj = {
          label: corporation.name,
          value: corporation.id,
        };
        arrays.push(obj);
      }
    setCor(arrays);
  };
  const [listCorporationSelect, {}] = useListCorporationSelect(
    onListCorporation
  );

  /// 거래처 List ///
  const {
    register,
    handleSubmit,
    getValues,
    watch,
    setValue,
    formState: { errors, isValid },
  } = useForm<IEditGoodFormProps>({ mode: "all" });

  const onGetComplete = (data: GetGoodQuery) => {
    const {
      getGood: { ok, err, good },
    } = data;
    if (ok && good) {
      setValue("categoryFirst", good.categoryFirst);
      setValue("categorySecond", good.categorySecond);
      setValue("summary", good.summary);
      setValue("weight", good.weight);
      if (good.tags) {
        setTags(good.tags);
      }
      setValue("title", good.title);
      setValue("goodType", good.goodType);
      setValue("expirydate", `${moment(good.expirydate).format("YYYY-MM-DD")}`);
      // setValue(
      //   "corporationId",
      //   good.corporation?.id ? Number(good.corporation?.id) : 0
      // );
      setValue("status", good.status ? good.status : GoodStatus.GoodReady);
      setValue("price", good.price);
      if (good.limitNumOrders) {
        setValue("limitNumOrders", good.limitNumOrders);
        setLimitCheck(true);
      }
      setValue("discountRate", good.discountRate);
      let _array: relatedProps[] = [];
      if (
        data?.getGood?.good?.goodRelations &&
        data?.getGood?.good?.goodRelations.length > 0
      ) {
        for (let relation of data?.getGood?.good?.goodRelations) {
          if (relation.relatedGoods && relation.relatedGoods.length > 0) {
            for (let good of relation.relatedGoods) {
              var object: relatedProps = {
                label: good.title,
                value: Number(good.id),
              };
              _array.push(object);
            }
          }
        }
        setRelatedGoods(_array);
      }

      let _corArray: corporationProps[] = [];
      if (
        data.getGood.good?.corporations &&
        data.getGood.good.corporations.length > 0
      ) {
        for (let corporation of data.getGood.good.corporations) {
          var object: corporationProps = {
            label: corporation.name,
            value: Number(corporation.id),
          };
          _corArray.push(object);
        }
        setCorporationList(_corArray);
      }

      listGoodAll();
      listCorporationSelect();
      listGoodOptionHandler(good.id);
    } else {
      alert("잘못된 접근입니다");
      window.location.href = "/product";
      return;
    }
  };
  // 연관상품
  const [relations, setRelations] = useState<relatedProps[]>([
    { label: "불러올 상품이 없습니다.", value: 0 },
  ]);
  const onlistCall = (data: ListGoodAllQuery) => {
    const {
      listGoodAll: { ok, err, goods },
    } = data;
    var arrays: relatedProps[] = [...relations];
    arrays.splice(0);
    if (goods && goods.length > 0)
      for (const good of goods) {
        var obj = {
          label: good.title,
          value: good.id,
        };
        arrays.push(obj);
      }
    setRelations(arrays);
  };
  const [listGoodAll, {}] = useListGoodAll(onlistCall);
  const [relatedGoods, setRelatedGoods] = useState<relatedProps[]>([]);
  const makeRelInputData = (data: relatedProps[]) => {
    var relData: number[] = [];
    for (let item of data) {
      relData.push(item.value);
    }
    return relData;
  };
  const makeRelation = (data: relatedProps | null) => {
    if (data) {
      if (
        relatedGoods.length > 0 &&
        relatedGoods.filter((el) => el.value === data.value).length > 0
      ) {
        return;
      }
      setRelatedGoods((order) => [...order, data]);
    }
  };
  const deleteRelation = (data: relatedProps | null) => {
    if (data) {
      var newList = relatedGoods.filter((val) => val.value !== data.value);
      setRelatedGoods(newList);
    }
  };
  // 상품 정보
  const [getGood, { data, loading, called }] = useGetGood(onGetComplete);
  const getGoodHandler = (goodId: number) => {
    const getGoodInput: GetGoodInput = {
      goodId,
    };
    getGood({ variables: { input: getGoodInput } });
  };

  const onEditComplete = async (data: EditGoodMutation) => {
    const {
      editGood: { ok, err },
    } = data;
    if (ok) {
      editGoodOptionHandler();
    } else {
      alert(err);
    }
  };
  const [editGood, { loading: editGoodLoading }] = useEditGood(onEditComplete);

  const onSubmit = () => {
    if (!params.goodId) return;
    if (!editGoodLoading) {
      const {
        weight,
        categoryFirst,
        categorySecond,
        summary,
        goodType,
        expirydate,
        title,
        // corporationId,
        status,
        price,
        discountRate,
        limitNumOrders,
      } = getValues();
      const editGoodInput: EditGoodInput = {
        weight: weight ? +weight : null,
        tags: tags,
        categoryFirst,
        categorySecond,
        summary,
        goodType,
        expirydate,
        title,
        status,
        limitNumOrders: limitCheck ? Number(limitNumOrders) : 0,
        corporationIds: makeCorporationInputData(corporationList),
        price: price ? +price : 1,
        discountRate: discountRate ? +discountRate : 0,
        id: +params.goodId,
        goodRelationIds: makeRelInputData(relatedGoods),
      };
      editGood({ variables: { input: editGoodInput } });
    }
  };

  const makeCorporationInputData = (data: corporationProps[]) => {
    var corporationData: number[] = [];
    for (let item of data) {
      corporationData.push(item.value);
    }
    return corporationData;
  };

  const makeCorporation = (data: corporationProps | null) => {
    if (data) {
      if (
        corporationList.length > 0 &&
        corporationList.filter((el) => el.value === data.value).length > 0
      ) {
        return;
      }
      setCorporationList((corporation) => [...corporation, data]);
    }
  };

  const deleteCorporation = (data: corporationProps | null) => {
    if (data) {
      var newList = corporationList.filter((val) => val.value !== data.value);
      setCorporationList(newList);
    }
  };

  useEffect(() => {
    if (!params.goodId) return;
    const goodId = +params.goodId;
    // listCorporationSelect();
    getGoodHandler(goodId);
    pageIndicator("product");
  }, []);

  // 옵션
  const [goodOptionsArray, setGoodOptionArray] = useState<
    ListGoodOptionQuery["listGoodOption"]["goodOptions"]
  >([]);
  const listGoodOptionCompleted = (data: ListGoodOptionQuery) => {
    const {
      listGoodOption: { ok, err, goodOptions },
    } = data;
    if (ok && goodOptions) {
      setGoodOptionArray(goodOptions);
    } else {
      alert(err);
    }
  };
  const [
    listGoodOption,
    { data: listGoodOptions, refetch: goodOptionsRefetch },
  ] = useListGoodOption(listGoodOptionCompleted);
  const listGoodOptionHandler = (goodId: number) => {
    listGoodOption({
      variables: { input: { goodId } },
    });
  };
  const CrGoodOptionCompleted = async (data: CrGoodOptionMutation) => {
    const {
      crGoodOption: { ok, err },
    } = data;
    if (ok) {
      await goodOptionsRefetch();
    } else {
      alert(err);
    }
  };
  const [crGoodOption] = useCrGoodOption(CrGoodOptionCompleted);
  const CrGoodOptionHandelr = (goodId: number) => {
    crGoodOption({
      variables: { input: { goodId } },
    });
  };
  const delGoodOptionCompleted = async (data: DelGoodOptionMutation) => {
    const {
      delGoodOption: { ok, err },
    } = data;
    if (ok) {
      await goodOptionsRefetch();
    } else {
      alert(err);
    }
  };
  const [delGoodOption] = useDelGoodOption(delGoodOptionCompleted);
  const delgoodOptionHandler = (goodOptionId: number) => {
    delGoodOption({
      variables: { input: { goodOptionId } },
    });
  };

  const editGoodOptionCompleted = (data: EditGoodOptionMutation) => {
    const {
      editGoodOption: { ok, err },
    } = data;
    if (ok) {
      alert("저장되었습니다");
      setTabHandler(tabs + 1);
    } else {
      alert(err);
    }
  };
  const [editGoodOption] = useEditGoodOption(editGoodOptionCompleted);
  const editGoodOptionHandler = () => {
    var goodOptions: DetailedGoodOptionInput[] = [];
    if (!goodOptionsArray) return;
    for (const goodOption of goodOptionsArray) {
      console.log(goodOption.price);
      var _goodOption: DetailedGoodOptionInput = {
        id: goodOption.id,
        name: goodOption.name,
        price: goodOption.price,
        count: goodOption.count,
        deliveryDate: goodOption.dateActive ? goodOption.deliveryDate : null,
        dateActive: goodOption.dateActive,
        isEvent: goodOption.isEvent,
        discountRate: goodOption.discountRate,
      };
      goodOptions.push(_goodOption);
    }
    const editGoodOptionInput: EditGoodOptionInput = {
      detailedGoodOptions: goodOptions,
    };
    editGoodOption({
      variables: { input: editGoodOptionInput },
    });
  };

  // 수량 제한
  const [limitCheck, setLimitCheck] = useState(
    getValues().limitNumOrders ? true : false
  );

  const optionActiveHandler = (optionId: number) => {
    if (!goodOptionsArray) return;
    const newOption = goodOptionsArray.map((option) => {
      if (optionId === option.id) {
        var changed = { ...option };
        if (option.dateActive) {
          changed = {
            ...option,
            dateActive: !option.dateActive,
            deliveryDate: null,
          };
        } else {
          changed = {
            ...option,
            dateActive: !option.dateActive,
          };
        }
        return { ...option, ...changed };
      }
      return option;
    });
    setGoodOptionArray(newOption);
  };
  const optionActiveEventHandler = (optionId: number) => {
    if (!goodOptionsArray) return;
    const newOption = goodOptionsArray.map((option) => {
      if (optionId === option.id) {
        var changed = { ...option };
        if (option.isEvent) {
          changed = {
            ...option,
            isEvent: !option.isEvent,
          };
        } else {
          changed = {
            ...option,
            isEvent: !option.isEvent,
          };
        }
        return { ...option, ...changed };
      }
      return option;
    });
    setGoodOptionArray(newOption);
  };
  return (
    <div className="shadow-lg min-w-[1160px] min-h-[800px] h-fix-content max-w-[1160px]  2xl:max-w-[1623px]  bg-white">
      <form onSubmit={handleSubmit(onSubmit)} id="update">
        <div className="pl-9 pr-11 py-8 space-y-4">
          <div className="space-y-2">
            <p className="font-bold text-[#4F4F4F] text-base">상품명</p>
            <input
              {...register("title", {
                required: `상품명을 입력해주세요`,
              })}
              type="text"
              className="border w-full h-10 border-[#F0F0F4] text-base rounded-lg py-1 px-4"
            />
            {errors.title?.message && (
              <FormError2 errorMessage={errors.title.message} />
            )}
          </div>

          <div className="grid grid-cols-2 gap-x-6">
            <div className="space-y-2">
              <p className="font-bold text-[#4F4F4F] text-base">상품설명</p>
              <input
                {...register("summary", {
                  required: "상품설명을 입력해주세요",
                })}
                type="text"
                className="border w-full h-10 border-[#F0F0F4] text-base rounded-lg py-1 px-4"
              ></input>
              {errors.summary?.message && (
                <FormError2 errorMessage={errors.summary.message} />
              )}
            </div>
            <div className="space-y-2">
              <p className="font-bold text-[#4F4F4F] text-base">무게</p>
              <div className="flex flex-wrap">
                <input
                  {...register("weight", {})}
                  type="number"
                  defaultValue={0}
                  className="border w-4/5 h-10 border-[#F0F0F4] text-base rounded-lg py-1 px-4"
                ></input>
                <p className="pl-2 my-2">g</p>
              </div>
              {errors.weight?.message && (
                <FormError2 errorMessage={errors.weight.message} />
              )}
            </div>
          </div>
          <div className="grid grid-cols-2 gap-x-6"></div>

          <div className=" grid grid-cols-3 gap-x-6">
            <div className="space-y-2">
              <div className="font-bold text-[#4F4F4F] text-base flex flex-wrap justify-between">
                <p>대 카테고리</p>
                <button className="ml-3"></button>
              </div>
              <div className="">
                <div>
                  <select
                    {...register(`categoryFirst`, {
                      required: "카테고리를 선택해주세요",
                    })}
                    className="border border-[#F0F0F4] rounded-lg py-1 px-2 w-full text-base"
                  >
                    {Object(CategoryFirstOption).map(
                      (category: CategoryFirstType, index: number) => (
                        <option
                          key={`product-category-option-${index}`}
                          value={category.value}
                          disabled={index === 0}
                        >
                          {category.label}
                        </option>
                      )
                    )}
                  </select>
                  {errors.categoryFirst?.message && (
                    <FormError2 errorMessage={errors.categoryFirst.message} />
                  )}
                </div>
              </div>
            </div>
            <div className="space-y-2">
              <div className="font-bold text-[#4F4F4F] text-base flex flex-wrap justify-between">
                <p>소 카테고리</p>
                <button className="ml-3"></button>
              </div>
              <div className="">
                <div>
                  <select
                    {...register(`categorySecond`, {
                      required: "카테고리를 선택해주세요",
                    })}
                    defaultValue={
                      data?.getGood.good?.categorySecond
                        ? data?.getGood.good?.categorySecond
                        : ""
                    }
                    className="border border-[#F0F0F4] rounded-lg py-1 px-2 w-full text-base"
                  >
                    {Object(CategorySecondOption)
                      .filter((second: any) => {
                        return watch(`categoryFirst`) === second.depth;
                      })
                      .map((category: CategorySecondType, index: number) => (
                        <option
                          key={`product-category-option-${index}`}
                          value={category.value}
                        >
                          {category.label}
                        </option>
                      ))}
                  </select>
                  {errors.categorySecond?.message && (
                    <FormError2 errorMessage={errors.categorySecond.message} />
                  )}
                </div>
              </div>
            </div>

            <div className="space-y-2">
              <div className="font-bold text-[#4F4F4F] text-base flex flex-wrap justify-between">
                <p>
                  <span className="align-middle">판매수량제한</span>
                  <input
                    type="checkbox"
                    checked={limitCheck}
                    onChange={(e) => {
                      // console.log();
                      setLimitCheck(e.target.checked);
                    }}
                    className="align-middle ml-2 w-4 h-4"
                  />
                </p>
              </div>
              <div>
                <input
                  {...register("limitNumOrders")}
                  type="number"
                  className="border border-[#F0F0F4] rounded-lg py-1 px-2 w-full text-base"
                  disabled={!limitCheck}
                />
              </div>
            </div>
          </div>

          <div className="grid grid-cols-2 gap-x-6">
            <div className="space-y-2">
              <p className="font-bold text-[#4F4F4F] text-base">판매 상태</p>
              <div className="grid grid-cols-4">
                {Object(GoodStatusOption).map(
                  (goodStatus: GoodStatusOptionType, index: number) => (
                    <div key={`product-status-${index}`} className="">
                      <input
                        {...register(`status`, {})}
                        className="border border-[#F0F0F4] rounded-lg py-1 px-2 w-full text-base"
                        type="radio"
                        name="status"
                        id={goodStatus.label}
                        value={goodStatus.value}
                      ></input>
                      <label htmlFor={goodStatus.label} className="px-2">
                        {goodStatus.label}
                      </label>
                    </div>
                  )
                )}
              </div>
            </div>
            <div className="space-y-2">
              <p className="font-bold text-[#4F4F4F] text-base">상품 상태</p>
              <select
                {...register(`goodType`, {
                  required: "상품 상태를 선택해주세요",
                })}
                className="border border-[#F0F0F4] rounded-lg py-1 px-2 w-full text-base"
              >
                {Object(GoodTypeOption).map(
                  (goodType: GoodTypeOptionType, index: number) => (
                    <option
                      key={`goodType-option-${index}`}
                      value={goodType.value}
                      disabled={index === 0}
                    >
                      {goodType.label}
                    </option>
                  )
                )}
              </select>
              {errors.goodType?.message && (
                <FormError2 errorMessage={errors.goodType.message} />
              )}
            </div>
          </div>

          <div className="grid grid-cols-2 gap-x-6">
            <div className="space-y-2">
              <p className="font-bold text-[#4F4F4F] text-base">거래처 이름</p>
              <p className="w-full">
                <Autocomplete
                  disablePortal
                  options={cor}
                  autoComplete
                  sx={{ padding: 0 }}
                  onChange={(detail, value) => {
                    makeCorporation(value);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      sx={{ width: "100%" }}
                      placeholder="등록할 거래처를 적어주세요"
                    />
                  )}
                />
              </p>
              <p>
                {corporationList &&
                  corporationList.length > 0 &&
                  corporationList.map((el, index) => (
                    <span
                      key={`relateion-width-${el.value}-${index}`}
                      className="text-sm text-darkFont bg-gray-200 rounded-full py-1 px-2  inlie-flex flex-wrap content-center"
                    >
                      {el.label}
                      <button
                        className="px-2"
                        onClick={() => {
                          deleteCorporation(el);
                        }}
                      >
                        <img
                          src={del}
                          alt="remove this relations"
                          className="inline invert w-4"
                        />
                      </button>
                    </span>
                  ))}
              </p>
              {/* <select
                {...register(`corporationId`, {
                  required: "거래처 이름을 선택해주세요",
                })}
                className="border w-full border-[#F0F0F4] rounded-lg py-1 px-2 text-base"
                defaultValue={
                  data?.getGood.good?.corporation?.id
                    ? data.getGood.good.corporation.id
                    : ""
                }
              >
                <option disabled value={0}>
                  거래처 선택
                </option>
                {corporations?.listCorporationSelect.corporations?.map(
                  (corporation) => (
                    <option
                      key={`corporation-${corporation.id}`}
                      value={corporation.id}
                    >
                      {corporation.name}
                    </option>
                  )
                )}
              </select>
              {errors.corporationId?.message && (
                <FormError2 errorMessage={errors.corporationId.message} />
              )} */}
            </div>
          </div>
          <div className="grid grid-cols-3 gap-x-6">
            <div className="space-y-2">
              <p className="font-bold text-[#4F4F4F] text-base">판매가</p>
              <div className="flex flex-wrap">
                <input
                  {...register("price", {
                    required: `가격을 입력해주세요`,
                  })}
                  type="number"
                  defaultValue={0}
                  className="border w-4/5 h-10 border-[#F0F0F4] text-base rounded-lg py-1 px-4"
                ></input>
                <p className="pl-2 my-2">원</p>
              </div>
              {errors.price?.message && (
                <FormError2 errorMessage={errors.price.message} />
              )}
            </div>
            <div className="space-y-2">
              <p className="font-bold text-[#4F4F4F] text-base">할인율</p>
              <div className="flex flex-wrap">
                <input
                  {...register("discountRate", {
                    required: `할인율을 입력해주세요`,
                  })}
                  type="number"
                  step=""
                  defaultValue={0}
                  min={0}
                  max={100}
                  className="border w-4/5 h-10 border-[#F0F0F4] text-base rounded-lg py-1 px-4"
                ></input>
                <p className="pl-2 my-2">%</p>
              </div>
              {errors.discountRate?.message && (
                <FormError2 errorMessage={errors.discountRate.message} />
              )}
            </div>
            <div className="space-y-2">
              <p className="font-bold text-[#4F4F4F] text-base">최종가격</p>
              <div className="flex flex-wrap">
                <p className="border w-4/5 h-10 border-[#F0F0F4] text-base rounded-lg py-2 px-4 ">
                  {data?.getGood.good?.price &&
                    (data.getGood.good.price *
                      (100 - Number(data.getGood.good.discountRate))) /
                      100}
                </p>
                <p className="pl-2 my-2">원</p>
              </div>
            </div>
          </div>
          <div className="space-y-2">
            <p className="font-bold text-[#4F4F4F] text-base">연관상품</p>
            <Autocomplete
              disablePortal
              options={relations}
              autoComplete
              sx={{ padding: 0 }}
              onChange={(detail, value) => {
                makeRelation(value);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  sx={{ width: "100%" }}
                  placeholder="등록할 연관상품을 적어주세요"
                />
              )}
            />
            <p className="pt-4 space-x-4 space-y-2">
              {relatedGoods &&
                relatedGoods.length > 0 &&
                relatedGoods.map((el, index) => (
                  <span
                    key={`relateion-width-${el.value}-${index}`}
                    className="text-sm text-darkFont bg-gray-200 rounded-full py-1 px-2  inlie-flex flex-wrap content-center"
                  >
                    {el.label}
                    <button
                      className="px-2"
                      onClick={() => {
                        deleteRelation(el);
                      }}
                    >
                      <img
                        src={del}
                        alt="remove this relations"
                        className="inline invert w-4"
                      />
                    </button>
                  </span>
                ))}
            </p>
          </div>
          <div className="space-y-2">
            <p className="font-bold text-[#4F4F4F] text-base">태그</p>

            <div className="pt-4 flex flex-wrap content-center gap-x-4">
              {TagList.map((tag) => (
                <label key={`tags-${tag.name}`} className="text-lg">
                  <input
                    checked={tags.includes(tag.value)}
                    onChange={() => {
                      if (tags.includes(tag.value)) {
                        removeTagHandler(tag.value);
                      } else {
                        addTagHandler(tag.value);
                      }
                    }}
                    type="checkbox"
                    value={tag.value}
                  />
                  <span className="pl-2">{tag.name}</span>
                </label>
              ))}
            </div>
          </div>
          <div className="space-y-2">
            <p className="font-bold text-[#4F4F4F] text-base inline-block">
              옵션{" "}
            </p>
            <button
              type="button"
              onClick={() => {
                CrGoodOptionHandelr(Number(data?.getGood?.good?.id));
              }}
              className="ml-4 px-3 bg-back text-white rounded-md"
            >
              추가
            </button>
            <p className="text-sm text-gray-400">
              분을 제외한 시간 단위로 저장됩니다.
            </p>
            <div className="pt-4  space-y-2 w-full">
              {goodOptionsArray &&
                goodOptionsArray.length > 0 &&
                goodOptionsArray.map((option, index) => (
                  <p
                    key={`goodOptions-${option.id}-${index}`}
                    className="text-sm text-darkFont py-1 px-2  space-x-2 inlie-flex flex-wrap content-center"
                  >
                    <div className="flex flex-wrap gap-x-2">
                      <p className="font-bold p-1 w-20 text-[#4F4F4F] text-base">
                        이벤트
                      </p>
                      <p className="font-bold p-1 w-96 text-[#4F4F4F] text-base">
                        옵션명
                      </p>
                      <p className="font-bold p-1 w-48 text-[#4F4F4F] text-base">
                        옵션가격
                      </p>
                      <p className="font-bold p-1 w-24 ml-2 text-[#4F4F4F] text-base">
                        할인율
                      </p>
                      <p className="font-bold p-1 w-24 ml-2 text-[#4F4F4F] text-base">
                        수량
                      </p>
                    </div>
                    <div className="flex flex-wrap gap-x-4 ">
                      <Switch
                        checked={option.isEvent}
                        onChange={() => {
                          optionActiveEventHandler(option.id);
                        }}
                      ></Switch>
                      <input
                        type="text"
                        defaultValue={option.name ? `${option.name}` : ""}
                        name={`goodOption${option.id}-editMode`}
                        onChange={(e) => {
                          var options = goodOptionsArray;
                          options[index].name = e.target.value;
                          setGoodOptionArray(options);
                        }}
                        className="w-96 border-2 rounded-sm p-1"
                      />
                      <input
                        type="number"
                        defaultValue={option.price ? `${option.price}` : ""}
                        name={`goodOption${option.id}-editMode`}
                        onChange={(e) => {
                          var options = goodOptionsArray;
                          options[index].price = e.target.valueAsNumber;
                          setGoodOptionArray(options);
                        }}
                        className="w-48 border-2 rounded-sm p-1"
                      />
                      <input
                        type="number"
                        defaultValue={
                          option.discountRate ? `${option.discountRate}` : ""
                        }
                        name={`goodOption${option.id}-editMode`}
                        onChange={(e) => {
                          var options = goodOptionsArray;
                          options[index].discountRate = e.target.valueAsNumber;
                          setGoodOptionArray(options);
                        }}
                        className="w-24 border-2 rounded-sm p-1"
                      />
                      <input
                        type="number"
                        min={0}
                        defaultValue={
                          option.count != null ? `${option.count}` : ""
                        }
                        name={`goodOption${option.id}-editMode`}
                        onChange={(e) => {
                          var options = goodOptionsArray;
                          options[index].count = e.target.valueAsNumber;
                          setGoodOptionArray(options);
                        }}
                        className="w-24 border-2 rounded-sm p-1"
                      />

                      <Switch
                        checked={option.dateActive}
                        onChange={() => {
                          optionActiveHandler(option.id);
                        }}
                      ></Switch>

                      <input
                        type="datetime-local"
                        value={moment(option.deliveryDate).format(
                          "YYYY-MM-DD HH:00"
                        )}
                        onChange={(e) => {
                          const newOption = goodOptionsArray.map(
                            (goodOption) => {
                              if (option.id === goodOption.id) {
                                var changed = {
                                  ...goodOption,
                                  deliveryDate: e.target.value
                                    ? e.target.value
                                    : null,
                                };
                                return { ...goodOption, ...changed };
                              }
                              return goodOption;
                            }
                          );
                          setGoodOptionArray(newOption);
                        }}
                      />

                      <button
                        type="button"
                        onClick={() => {
                          delgoodOptionHandler(option.id);
                        }}
                        className="border rounded-full bg-gray-200"
                      >
                        <img
                          src={close}
                          alt={`removeOption-${index} 번째`}
                          className="inline-block w-full"
                        />
                      </button>
                    </div>
                  </p>
                ))}
            </div>
          </div>
          <div className="flex flex-wrap justify-center pt-6">
            <Button
              className={`w-[240px] h-[40px] bg-[#3D3E49] text-white rounded-lg`}
              canClick={true}
              actionText={`등록`}
              loading={loading}
              color={"text-white"}
            />
          </div>
        </div>
      </form>
    </div>
  );
};
