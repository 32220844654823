import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { pageIndicator } from "../apollo";
import {
  ComponentTabLink,
  ITabLink,
  ITabTitle,
  PageType,
} from "../components/component-tab";
import { AsideAdminComponent } from "../layout/aside-admin";
import { FormError2 } from "../components/form-error";
import { Button } from "../components/button";

import { useParams } from "react-router";
import {
  IEditCorporationFormProps,
  useEditCorporation,
} from "../hooks/useEditCorporation";
import { useGetCorporation } from "../hooks/useGetCorporation";
import {
  EditCorporationInput,
  EditCorporationMutation,
  GetCorporationInput,
  GetCorporationQuery,
} from "../generated/graphql";
import { useDaumPostcodePopup } from "react-daum-postcode";
import { useListLinkServiceSelect } from "../hooks/useListLinkServiceSelect";
export const AdminUpCorporation = () => {
  const params = useParams();
  const [
    listLinkServiceSelect,
    { data: services },
  ] = useListLinkServiceSelect();

  useEffect(() => {
    pageIndicator("corporation");
  }, []);
  const makeStringFromKeyworkd = (keyWords?: string[] | null) => {
    var strings = "";
    if (!keyWords) return;
    for (const keyword of keyWords) {
      strings = strings + keyword + " ";
    }
    return strings;
  };

  const {
    register,
    handleSubmit,
    getValues,
    setValue,
    setFocus,
    formState: { errors, isValid },
  } = useForm<IEditCorporationFormProps>({ mode: "all" });

  const onGetComplete = (data: GetCorporationQuery) => {
    const {
      getCorporation: { ok, err, corporation },
    } = data;
    if (!ok) {
      alert("잘못된 접근입니다.");
      window.location.href = "/corporation";
      return;
    }
    setValue("address", corporation?.address);
    // setValue("service", corporation?.service);
    setValue("ceoName", corporation?.ceoName);
    setValue("managerName", corporation?.managerName);
    setValue("name", String(corporation?.name));
    setValue("phone", corporation?.phone);
    setValue(`regionKeyWord`, corporation?.regionKeyWord);
    setValue(`serviceId`, corporation?.linkService?.id);
    setFocus("name");
  };
  const [getCorporation, { data, loading }] = useGetCorporation(onGetComplete);
  const getCorporationHandler = (corporationId: number) => {
    const getCorporationInput: GetCorporationInput = {
      corporationId,
    };
    getCorporation({ variables: { input: getCorporationInput } });
  };

  const onUpComplete = (data: EditCorporationMutation) => {
    const {
      editCorporation: { ok, err },
    } = data;
    if (ok) {
      alert("저장되었습니다.");
      window.location.href = "/corporation";
    } else {
      alert(err);
    }
  };
  const [
    editCorporation,
    { loading: editCorporationLoading },
  ] = useEditCorporation(onUpComplete);

  const onSubmit = () => {
    if (!params.corporationId) return;
    if (!editCorporationLoading) {
      const {
        name,
        address,
        // ceoName,
        // service,
        managerName,
        phone,
        regionKeyWord,
        serviceId,
      } = getValues();
      const editCorporationInput: EditCorporationInput = {
        name,
        address,
        // ceoName,
        // service,
        managerName,
        phone,
        corporationId: +params.corporationId,
        serviceId: serviceId ? +serviceId : 1,
        regionKeyWord,
      };
      editCorporation({ variables: { input: editCorporationInput } });
    }
  };

  const tabList: ITabLink[] = [];

  const stitle: ITabTitle = { text: "거래처 편집" };

  const handlePostCode = (data: any) => {
    let fullAddress = data.address;
    let extraAddress = "";

    if (data.addressType === "R") {
      if (data.bname !== "") {
        extraAddress += data.bname;
      }
      if (data.buildingName !== "") {
        extraAddress +=
          extraAddress !== "" ? `, ${data.buildingName}` : data.buildingName;
      }
      fullAddress += extraAddress !== "" ? ` (${extraAddress})` : "";
    }
    setValue(`address`, fullAddress);
    setFocus(`address`);
  };
  const scriptUrl =
    "https://t1.daumcdn.net/mapjsapi/bundle/postcode/prod/postcode.v2.js";
  const open = useDaumPostcodePopup(scriptUrl);
  const handlerClick = () => {
    open({ onComplete: handlePostCode });
  };

  useEffect(() => {
    listLinkServiceSelect();
  }, []);
  useEffect(() => {
    if (!params.corporationId) return;
    const corporationId = +params.corporationId;
    getCorporationHandler(corporationId);
  }, []);
  return (
    <div className="bg-main bg-opacity-50 w-full h-full min-h-screen">
      <div className="w-full mx-auto h-full min-h-screen bg-white">
        <main id="main">
          <div className="grid grid-cols-[200px_1fr] max-w-full">
            <AsideAdminComponent />

            <div className="mypages bg-gray-100 w-full min-w-[1200px] overflow-hidden">
              <div className="orderWrap">
                <div className="bg-gray-100 h-fix-content relative">
                  <div className={`w-full h-full px-10 py-8`}>
                    <ComponentTabLink
                      tabs={tabList}
                      stitle={stitle}
                      type={PageType.Create}
                    />
                    <div className="shadow-lg h-[800px] h-fix-content w-full overflow-auto bg-white">
                      <form onSubmit={handleSubmit(onSubmit)}>
                        <div className="pl-9 pr-11 py-8 space-y-4">
                          <div className="space-y-2">
                            <p className="font-bold text-[#4F4F4F] text-base">
                              회사 이름
                            </p>
                            <input
                              {...register("name", {
                                required: `이름을 입력해주세요`,
                              })}
                              type="text"
                              className="border w-full h-10 border-[#F0F0F4] text-base rounded-lg py-1 px-4"
                            />
                            {errors.name?.message && (
                              <FormError2 errorMessage={errors.name.message} />
                            )}
                          </div>
                          <div className="space-y-2">
                            <p className="font-bold text-[#4F4F4F] text-base">
                              서비스 이름
                            </p>
                            <select
                              {...register(`serviceId`, {
                                required: "프로젝트 ID를 선택해주세요",
                              })}
                              className="border w-full border-[#F0F0F4] rounded-lg py-1 px-2"
                            >
                              {services?.listLinkServiceSelect.linkservice?.map(
                                (service) => (
                                  <option
                                    key={`service-${service.id}`}
                                    value={service.id}
                                  >
                                    {service.name}
                                  </option>
                                )
                              )}
                            </select>

                            {errors.serviceId?.message && (
                              <FormError2
                                errorMessage={errors.serviceId.message}
                              />
                            )}
                          </div>
                          {/* <div className="space-y-2">
                            <p className="font-bold text-[#4F4F4F] text-base">
                              서비스 이름
                            </p>
                            <input
                              {...register("service", {})}
                              type="text"
                              className="border w-full h-10 border-[#F0F0F4] text-base rounded-lg py-1 px-4"
                            ></input>
                          </div> */}
                          <div className="space-y-2">
                            <p className="font-bold text-[#4F4F4F] text-base">
                              담당자 이름
                            </p>
                            <input
                              {...register("managerName", {})}
                              type="text"
                              className="border w-full h-10 border-[#F0F0F4] text-base rounded-lg py-1 px-4"
                            />
                            {errors.managerName?.message && (
                              <FormError2
                                errorMessage={errors.managerName.message}
                              />
                            )}
                          </div>
                          <div className="space-y-2">
                            <p className="font-bold text-[#4F4F4F] text-base">
                              번호
                            </p>
                            <input
                              {...register("phone", {})}
                              type="text"
                              className="border w-full h-10 border-[#F0F0F4] text-base rounded-lg py-1 px-4"
                            />
                            {errors.phone?.message && (
                              <FormError2 errorMessage={errors.phone.message} />
                            )}
                          </div>
                          <div className="space-y-2">
                            <p className="font-bold text-[#4F4F4F] text-base">
                              <span className="pr-10">주소</span>
                              <button
                                className="py-2 px-4 border border-btnDarkLine rounded-lg text-btn text-grayFont"
                                type="button"
                                onClick={handlerClick}
                              >
                                우편번호찾기
                              </button>
                            </p>
                            <div className="py-1">
                              <input
                                {...register("address", {})}
                                type="text"
                                className="border w-full h-10 border-[#F0F0F4] text-base rounded-lg py-1 px-4"
                              />
                              {errors.address?.message && (
                                <FormError2
                                  errorMessage={errors.address.message}
                                />
                              )}
                            </div>
                          </div>
                          <div className="space-y-2">
                            <p className="font-bold text-[#4F4F4F] text-base">
                              배송 가능 지역
                            </p>
                            <input
                              type="text"
                              defaultValue={makeStringFromKeyworkd(
                                data?.getCorporation.corporation?.regionKeyWord
                              )}
                              onChange={(e) => {
                                var regionKeyWord = String(
                                  e.target.value
                                ).split(" ");
                                setValue("regionKeyWord", regionKeyWord);
                              }}
                              placeholder="동,리 단위로 작성 ( 예시 : 율곡동 송곡리 )"
                              className="border w-full h-10 border-[#F0F0F4] text-base rounded-lg py-1 px-4"
                            />
                            <p className="text-sm text-blue-400">
                              여러 개 작성시 띄워쓰기로 구분됩니다.
                            </p>
                          </div>
                          {/* <div className="grid grid-cols-2 gap-x-6">
                            <div className="space-y-2">
                              <p className="font-bold text-[#4F4F4F] text-base">
                                Ceo이름
                              </p>
                              <input
                                {...register("ceoName", {})}
                                type="text"
                                className="border w-full h-10 border-[#F0F0F4] text-base rounded-lg py-1 px-4"
                              ></input>
                              {errors.ceoName?.message && (
                                <FormError2
                                  errorMessage={errors.ceoName.message}
                                />
                              )}
                            </div>
                          </div> */}

                          <div className="flex flex-wrap justify-center pt-6">
                            <Button
                              className={`w-[240px] h-[40px] bg-[#3D3E49] text-white rounded-lg`}
                              canClick={true}
                              actionText={`등록`}
                              loading={loading}
                              color={"text-white"}
                            />
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
};
