import React, { useEffect, useState, useRef } from "react";
import { AsideAdminComponent } from "../layout/aside-admin";
import {
  ComponentTabLink,
  ITabLink,
  ITabTitle,
  PageType,
} from "../components/component-tab";
import { Link } from "react-router-dom";
import { pageIndicator } from "../apollo";
import { CSVLink } from "react-csv";

// import { useListPayments } from "../hooks/useListPayments";
// import { delPayments } from "../__generated__/delPayments";
// import { useDelPayments } from "../hooks/useDelPayments";

export const AdminListPayments = () => {
  const [allchk, setAllchk] = useState<boolean>(false);
  const [usechk, setUsechk] = useState<boolean>(false);

  // const onDelete = async (data: delPayments) => {
  //   const {
  //     delPayments: { ok, err },
  //   } = data;
  //   if (ok) {
  //     alert("삭제하였습니다");
  //   }
  //   if (!ok) {
  //     alert(err);
  //   }
  // };
  // const [delPayments, { loading: delPaymentsLoading }] = useDelPayments(
  //   onDelete
  // );

  const tabList: ITabLink[] = [];
  const stitle: ITabTitle = {
    text: "정산관리",
  };

  const tableRef = useRef(null);
  // const [listPayments, { data, loading, refetch }] = useListPayments();
  useEffect(() => {
    pageIndicator("payments");
  });
  const allHandler = () => {
    if (allchk) {
      setUsechk(() => {
        return false;
      });
    } else {
      setUsechk(() => {
        return true;
      });
    }
    setAllchk(() => {
      return !allchk;
    });
  };
  return (
    <div className="bg-main bg-opacity-50 w-full h-full">
      <div className="w-full mx-auto  bg-gray-100 ">
        <main id="main">
          <div className="grid grid-cols-[200px_1fr]">
            <AsideAdminComponent />
            <div className="mypages bg-gray-100">
              <div className="orderWrap">
                <div className="h-fix-content relative">
                  <div className={`w-full h-full px-10 py-8`}>
                    <ComponentTabLink
                      tabs={tabList}
                      stitle={stitle}
                      type={PageType.Payments}
                    />
                    <div className="shadow-lg max-w-[1160px]  2xl:max-w-[1623px] h-[800px] h-fix-content overflow-auto bg-white">
                      <table
                        className="align-center min-w-[1160px] 2xl:min-w-[1623px] text-[14px] "
                        ref={tableRef}
                      >
                        <colgroup>
                          <col width="36"></col>
                          <col width="40"></col>
                          <col width="88"></col>
                          <col width="168"></col>
                          <col width="48"></col>
                          <col width="88"></col>
                          <col width="88"></col>
                          <col width="112"></col>
                          <col width="112"></col>
                          <col width="88"></col>
                          <col width="88"></col>
                          <col></col>
                        </colgroup>
                        <thead>
                          <tr className="border-b border-[#F9F8F9] h-8">
                            <th className="text-[#8B8C8C] leading-3">
                              <input
                                type="checkbox"
                                name="allChk"
                                onClick={allHandler}
                                className="h-5 w-5"
                              />
                            </th>
                            <th className="text-[#8B8C8C]">no</th>
                            <th className="text-[#8B8C8C]">상품코드</th>
                            <th className="text-[#8B8C8C]">상품명</th>
                            <th className="text-[#8B8C8C]">수량</th>
                            <th className="text-[#8B8C8C]">매입가</th>
                            <th className="text-[#8B8C8C]">판매가</th>
                            <th className="text-[#8B8C8C]">결제금액</th>
                            <th className="text-[#8B8C8C]">결제방법</th>
                            <th className="text-[#8B8C8C]">월매출</th>
                            <th className="text-[#8B8C8C]">월매입</th>
                            <th className="text-[#8B8C8C]">비고</th>
                          </tr>
                        </thead>
                        {/* {paymentsData &&
                          paymentsData.map((test) => (
                            <tbody>
                              <tr className="border-b border-[#F9F8F9] h-[68px]">
                                <td className="table-text">
                                  <input type="checkbox" className="h-5 w-5" />
                                </td>
                                <td className="table-text">{test.no}</td>
                                <td className="table-text">
                                  {test.productnum}
                                </td>
                                <td className="table-text">
                                  {test.productname}
                                </td>
                                <td className="table-text">{test.count}</td>
                                <td className="table-text">{test.buyprice}</td>
                                <td className="table-text">{test.price}</td>
                                <td className="table-text">{test.payment}</td>
                                <td className="table-text">{test.method}</td>
                                <td className="table-text">{test.mrevenue}</td>
                                <td className="table-text">{test.mpurchase}</td>
                                <td className="table-text">{test.etc}</td>
                              </tr>
                            </tbody>
                          ))} */}
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
};
