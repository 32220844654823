import { ApolloError, gql, useMutation } from "@apollo/client";
import {
  DelPortMutation,
  DelPortMutationVariables,
} from "../generated/graphql";

const DEL_PORT_MUTATION = gql`
  mutation delPort($input: DelPortInput!) {
    delPort(input: $input) {
      ok
      err
    }
  }
`;

export const useDelPort = (
  onCompleted?: (data: DelPortMutation) => void,
  onError?: (Error: ApolloError) => void
) => {
  return useMutation<DelPortMutation, DelPortMutationVariables>(
    DEL_PORT_MUTATION,
    {
      onCompleted,
      onError,
    }
  );
};
