import { useEffect, useState } from "react";
import { AsideAdminComponent } from "../layout/aside-admin";

import exit from "../assets/icon/exit.png";
import {
  PageType,
  ComponentTabLink,
  ITabLink,
  ITabTitle,
} from "../components/component-tab";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { boundState, pageIndicator } from "../apollo";
import { Modal } from "@mui/material";
import { useListBound } from "../hooks/useListBound";
import moment from "moment";
import { useDelBound } from "../hooks/useDelBound";
import { TableError } from "../components/table-error";
import { PaginationComponent } from "../components/pagination";
import { useReactiveVar } from "@apollo/client";
import {
  BoundType,
  DelBoundInput,
  DelBoundMutation,
  GoodCategoryFirst,
  ListBoundInput,
  ListBoundQuery,
} from "../generated/graphql";
import { ArrElement } from "../sub-routes/sub-admin-up-product-detail";

export type ListBound = ArrElement<ListBoundQuery["listBound"]["bounds"]>;

export const AdminListBound = () => {
  const [open, setOpen] = useState(false);
  // const [change, setChange] = useState("")
  const [openSale, setOpenSale] = useState(false);
  const [page, setPage] = useState<number>(1);
  const [search, setSearch] = useState<string>("");
  const navigate = useNavigate();
  const [checkedLists, setCheckedLists] = useState<ListBound[]>([]);
  const [allChecked, setAllChecked] = useState<boolean>(false);
  const useBoundType = useReactiveVar(boundState);
  const location = useLocation();
  const [listBound, { data, loading, refetch }] = useListBound();
  const listBoundHandler = (
    page: number | null,
    search: string | null,
    type: BoundType | null,
    start?: Date | null,
    end?: Date | null
  ) => {
    const input: ListBoundInput = {
      ...(page && { page: +page }),
      ...(search && { search: decodeURI(search) }),
      ...(type && { type }),
      ...(start && { start }),
      ...(end && { end }),
    };
    listBound({ variables: { input } });
  };
  const word = "입출고관리";
  const tabList: ITabLink[] = [
    { text: "재고관리", to: "/product" },
    { text: "입출고관리", to: "/bound", selected: true },
    // { text: "반품재고관리", to: "/rproduct" },
  ];
  const stitle: ITabTitle = {
    text: "상품관리",
  };

  // --- 입출고 삭제 ---
  const onDelCompleted = async (data: DelBoundMutation) => {
    const {
      delBound: { ok, err },
    } = data;
    if (ok) {
      // alert("입/출고를 삭제");
      await refetch();
    } else {
      alert(err);
    }
  };
  const [delBound, { loading: delBoundLoading }] = useDelBound(onDelCompleted);
  const delBoundHandler = (boundId: number) => {
    if (delBoundLoading) return;
    if (
      window.confirm("재고 수량에는 변동이 없습니다. 정말로 삭제하시겠습니까?")
    ) {
      const input: DelBoundInput = {
        boundId,
      };
      delBound({ variables: { input } });
    }
  };
  // --- CheckBox ---
  const setAllCheckedHandler = () => {
    setAllChecked(!allChecked);
    if (allChecked) setCheckedLists([]);

    if (!allChecked && data?.listBound.bounds) {
      setCheckedLists([]);
      for (const bound of data.listBound.bounds) {
        setCheckedLists((checkedList) => [...checkedList, bound]);
      }
    }
  };

  const setCheckedListHandler = (boundId: number, data: any) => {
    if (Boolean(checkedLists.find((checkedId) => checkedId.id === boundId))) {
      setCheckedLists(
        checkedLists.filter((checkedId) => checkedId.id !== boundId)
      );
    } else {
      setCheckedLists((originalList) => [...originalList, data]);
    }
  };
  useEffect(() => {
    if (checkedLists.length === data?.listBound.bounds?.length)
      setAllChecked(true);
    else setAllChecked(false);
  }, [checkedLists]);

  // --- Search ---
  const setSearchHandler = (search: string) => {
    navigate({
      pathname: "/bound",
      search: `${search ? `?search=${search}` : ""}`,
    });
    const type = useBoundType;
    listBoundHandler(1, search, type);
  };
  // --- Page ---
  const pageHandler = (page: number) => {
    const search =
      location.search.split("search=").length > 1
        ? location.search.split("search=")[1].split("&")[0]
        : null;
    setPage(page);
    navigate({
      pathname: "/bound",
      search: `?page=${page}${search ? `&search=${search}` : ""}`,
    });
    const type = useBoundType;
    listBoundHandler(page, search, type);
  };
  // --- Period ---
  const periodHandler = (sDate?: Date, eDate?: Date) => {
    listBoundHandler(1, search, useBoundType, sDate, eDate);
  };
  useEffect(() => {
    pageIndicator("product");
    setPage(
      location.search.split("page=").length > 1
        ? +location.search.split("page=")[1].split("&")[0]
        : 1
    );
    setSearch(
      location.search.split("search=").length > 1
        ? location.search.split("search=")[1].split("&")[0]
        : ""
    );
    const type = useBoundType;
    listBoundHandler(page, search, type);
  }, [search, useBoundType, page]);

  return (
    <div className="bg-main bg-opacity-50 w-full h-full min-h-screen">
      <div className="w-full mx-auto h-full min-h-screen  bg-gray-100">
        <main id="main">
          <div className="grid grid-cols-[200px_1fr] max-w-full">
            <AsideAdminComponent />
            <div className="mypages bg-gray-100 w-full min-w-[1200px] overflow-hidden">
              <div className="orderWrap">
                <div className="h-fix-content relative">
                  <div className={`w-full h-full px-10 py-8`}>
                    <ComponentTabLink
                      tabs={tabList}
                      stitle={stitle}
                      type={PageType.Bound}
                      dataList={checkedLists}
                      setSearchs={setSearchHandler}
                      setDateSearch={periodHandler}
                    />
                    <div className=" shadow-lg h-[800px] h-fix-content w-full overflow-auto bg-white">
                      <table className="align-center w-[1623px] text-sm  ">
                        <thead>
                          <tr className=" border-b border-[#F9F8F9] h-8">
                            <th className="text-[#8B8C8C] leading-3 min-w-[36px]">
                              <input
                                type="checkbox"
                                name="allChk"
                                checked={allChecked}
                                onChange={setAllCheckedHandler}
                                className="w-5 h-5"
                              />
                            </th>
                            <th className="text-[#8B8C8C] min-w-[40px]">no</th>
                            {/* <th className="text-[#8B8C8C] min-w-[72px]">
                              입출고상태
                            </th> */}
                            <th className="text-[#8B8C8C] min-w-[88px]">
                              일자
                            </th>
                            <th className="text-[#8B8C8C] min-w-[72px]">
                              상태
                            </th>
                            <th className="text-[#8B8C8C] min-w-[88px]">
                              상품코드
                            </th>
                            <th className="text-[#8B8C8C] min-w-[88px]">
                              위치코드
                            </th>
                            <th className="text-[#8B8C8C] min-w-[168px]">
                              상품명
                            </th>
                            <th className="text-[#8B8C8C] min-w-[168px]">
                              상품옵션
                            </th>
                            <th className="text-[#8B8C8C] min-w-[168px]">
                              입/출고 설명
                            </th>
                            <th className="text-[#8B8C8C] min-w-[48px]">
                              수량
                            </th>
                            <th className="text-[#8B8C8C] min-w-[88px]">
                              유통기한
                            </th>
                            <th className="text-[#8B8C8C] min-w-[88px]">
                              총 금액
                            </th>
                            <th className="text-[#8B8C8C] min-w-[168px]">
                              카테고리
                            </th>
                            <th className="text-[#8B8C8C] min-w-[128px]">
                              공급사
                            </th>
                            <th className="text-[#8B8C8C] min-w-[115px]">
                              비고
                            </th>
                          </tr>
                        </thead>
                        <tbody className="">
                          {!loading &&
                            data?.listBound.bounds &&
                            data.listBound.bounds.map((bound, index) => (
                              <tr
                                key={`bound-${bound.id}`}
                                className="border-b border-[#F9F8F9] h-[68px]"
                              >
                                <td className="table-text">
                                  <input
                                    type="checkbox"
                                    className="h-5 w-5 "
                                    checked={
                                      checkedLists.find(
                                        (checkedId) => checkedId.id === bound.id
                                      ) !== undefined
                                    }
                                    onChange={(e) => {
                                      setCheckedListHandler(bound.id, bound);
                                    }}
                                  />
                                </td>
                                <td className="table-text">
                                  {(page - 1) * 20 + index + 1}
                                </td>
                                {/* <td className="table-text py-1">
                                  <p
                                    className={`rounded-lg mx-auto py-[6px] px-[3px]  w-[48px] h-7 text-white text-xs 
                                    ${
                                      bound.status === "Gathering"
                                        ? "bg-[#555B94]"
                                        : bound.status === "Done"
                                        ? "bg-[#CCCCCC]"
                                        : "text-[#B42713] border border-[#B42713]"
                                    }  `}
                                  >
                                    {bound.status === "Gathering"
                                      ? "진행중"
                                      : "완료"}
                                  </p>
                                </td> */}
                                <td className="table-text font-bold">
                                  {bound.orderedAt &&
                                    moment(bound.orderedAt).format("YYMMDD")}
                                </td>
                                <td className="table-text">
                                  <p
                                    className={`mx-auto py-[6px] px-[3px] text-xs rounded-lg w-[48px] h-7 ${
                                      bound.type === "Inbound"
                                        ? "border border-[#3D3E49] text-[#3d3e49]"
                                        : "border border-[#B42713] text-[#B42713]"
                                    }`}
                                  >
                                    {bound.type === "Inbound" ? "입고" : "출고"}
                                  </p>
                                </td>
                                <td className="table-text break-all">
                                  {bound.good?.goodUuid}
                                </td>
                                <td className="table-text font-bold"></td>
                                <td className="table-text">
                                  {bound.good?.title}
                                </td>
                                <td className="table-text">
                                  {bound.goodOption?.name}
                                </td>
                                <td className="table-text">{bound.desc}</td>
                                <td
                                  className={`table-text font-bold ${
                                    bound.type === "Inbound"
                                      ? "text-[#3D3E49]"
                                      : "text-[#B42713]"
                                  }`}
                                >
                                  {bound.type === "Inbound"
                                    ? "+" + bound.stock
                                    : "-" + bound.stock}
                                </td>
                                <td className="table-text font-bold">
                                  {bound.expiryDate &&
                                    "~" +
                                      moment(bound.expiryDate).format(
                                        "YY.MM.DD"
                                      )}
                                </td>
                                <td className="table-text font-bold">
                                  {bound.amount}
                                </td>
                                <td className="table-text">
                                  {bound.good?.categoryFirst &&
                                  bound.good?.categoryFirst ===
                                    GoodCategoryFirst.Toys
                                    ? "장난감/완구류"
                                    : bound.good?.categoryFirst ===
                                      GoodCategoryFirst.Snack
                                    ? "디저트/간식"
                                    : bound.good?.categoryFirst ===
                                      GoodCategoryFirst.Meal
                                    ? "간편식/간식/요리"
                                    : bound.good?.categoryFirst ===
                                      GoodCategoryFirst.Etc
                                    ? "기타"
                                    : ""}
                                </td>
                                <td className="table-text">
                                  {bound.corporation?.name}
                                </td>
                                <td className="table-text">
                                  {/* <Link
                                    to={`/bound/update/${bound.id}`}
                                    className="inline-block border  border-[#8B8C8C]
                                      rounded-lg py-[6px] px-[3px] w-[48px] h-7 text-xs text-[#8B8C8C]"
                                  >
                                    편집
                                  </Link> */}
                                  <button
                                    className="bg-[#CCCCCC] rounded-lg py-[6px]  w-[48px] h-7 text-white ml-3 mr-1"
                                    onClick={() => delBoundHandler(bound.id)}
                                  >
                                    삭제
                                  </button>
                                </td>
                              </tr>
                            ))}
                        </tbody>
                      </table>
                      {loading && (
                        <TableError errorMessage={`정보를 불러오는중입니다`} />
                      )}
                      {!loading &&
                        data?.listBound.bounds &&
                        data.listBound.bounds.length < 1 && (
                          <TableError
                            errorMessage={`${word} 정보가 없습니다`}
                          />
                        )}

                      {!loading &&
                        data?.listBound.bounds &&
                        data.listBound.bounds.length > 0 && (
                          <PaginationComponent
                            page={page}
                            totalPages={Number(data.listBound.totalPages)}
                            totalResults={Number(data.listBound.totalResults)}
                            pageHandler={pageHandler}
                          />
                        )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
};
