import React from "react";
import { useSubscription } from "@apollo/client";
import gql from "graphql-tag";
import {
  NewOrderListSubscription,
  NewOrderListSubscriptionVariables,
} from "../generated/graphql";

export const ORDER_SUBSCRIPTION = gql`
  subscription newOrderList {
    newOrderList {
      id
      createdAt
      goodName
      goodOptionName
      quantity
      amount
      manager {
        id
        name
      }
      receipt {
        id
        uuid
        receiver
        address
        paid_amount
        message
        phone
        receiptStatus
        user {
          username
          name
        }
      }
      goodOption {
        id
        name
        good {
          title
          uuid
        }
      }
      status
      pack {
        id
        status
      }
    }
  }
`;

export const NewOrderLists = () => {
  return useSubscription<
    NewOrderListSubscription,
    NewOrderListSubscriptionVariables
  >(ORDER_SUBSCRIPTION, {});
};
