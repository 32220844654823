import { gql, useLazyQuery } from "@apollo/client";
import { GetFlightQuery, GetFlightQueryVariables } from "../generated/graphql";

const GET_FLIGHT_QUERY = gql`
  query getFlight($input: GetFlightInput!) {
    getFlight(input: $input) {
      ok
      err
      flight {
        id
        createdAt
        orderAt
        takeOffAt
        landAt
        note
        status

        project {
          id
          name
        }
        drone {
          id
          name
        }
        portTo {
          id
          name
        }
        portFrom {
          id
          name
        }
        pilotTo {
          id
          name
        }
        pilotFrom {
          id
          name
        }
        pack {
          id
        }
      }
    }
  }
`;
export const useGetFlight = (onCompleted?: (data: GetFlightQuery) => void) => {
  return useLazyQuery<GetFlightQuery, GetFlightQueryVariables>(
    GET_FLIGHT_QUERY,
    {
      onCompleted,
      fetchPolicy: "network-only",
    }
  );
};
