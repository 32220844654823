import axios, { AxiosRequestConfig } from "axios";
import React from "react";
import { authTokenVar, UPLOAD_URI } from "../apollo";
import imageCompression from "browser-image-compression";

const headers: AxiosRequestConfig["headers"] = {
  "mfc-jwt": authTokenVar() || "",
};

export const upload = async (event: any) => {
  let data = new FormData();
  let file = event.target.files[0];
  const options = {
    maxSizeMB: 1,
    useWebWorker: true,
  };
  if (file.type === "image/gif") {
    data.append("file", file);
    const config = {
      headers,
    };
    return await axios.post(UPLOAD_URI, data, config);
  }
  try {
    const compressedFile = await imageCompression(file, options);
    data.append("file", compressedFile);
  } catch (error) {
    console.log(error);
    alert("파일 업로드에 실패하였습니다.");
  }
  // data.append("file", file);
  const config = {
    headers,
  };
  return await axios.post(UPLOAD_URI, data, config);
};

export const uploadDetailImage = async (file: any) => {
  let data = new FormData();
  console.log(file);
  if (file.type === "image/gif") {
    data.append("file", file);
    const config = {
      headers,
    };
    return await axios.post(UPLOAD_URI, data, config);
  }
  const options = {
    maxSizeMB: 1,
    useWebWorker: true,
  };
  try {
    const compressedFile = await imageCompression(file, options);
    data.append("file", compressedFile);
  } catch (error) {
    console.log(error);
    alert("파일 업로드에 실패하였습니다.");
  }
  // data.append("file", file);
  const config = {
    headers,
  };
  return await axios.post(UPLOAD_URI, data, config);
};
