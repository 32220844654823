import moment from "moment";
import React from "react";
// import { listGood_listGood_goods } from "../__generated__/listGood";
// import { listGoodOption_listGoodOption_goodOptions } from "../__generated__/listGoodOption";
import { ListGood } from "../routes/admin-list-product";
import { ListReceipt } from "../routes/admin-list-receipt";

interface OrderListProps {
  data: ListReceipt;
}

export const ComponentOrderList: React.FC<OrderListProps> = ({ data }) => {
  return (
    <table className="w-full">
      <thead>
        {" "}
        <tr className=" border-b border-[#F9F8F9] h-8">
          <th className="text-[#8B8C8C]" colSpan={5}>
            주문내용
          </th>

          <th className="text-[#8B8C8C]">상품금액</th>
          <th className="text-[#8B8C8C]">수량</th>
        </tr>
      </thead>
      <tbody>
        {/* {!data.goodStocks ||
          (data.goodStocks.length === 0 && (
            <tr className=" border-b border-[#F9F8F9] h-8 text-center">
              <td colSpan={18} className="text-[#8B8C8C]">
                해당 정보가 없습니다.
              </td>
            </tr>
          ))} */}
        {data?.orders ? (
          data?.orders.length > 0 ? (
            data?.orders?.map((order, index) => (
              <tr
                className=" border-b border-[#F9F8F9] h-8 text-center"
                key={`list-stock-by-expirydate in` + data?.receiptNum + index}
              >
                <td className="text-[#8B8C8C] min-w-[320px] " colSpan={5}>
                  {order.goodName}
                  {order.goodOptionName && "-" + order.goodOptionName}
                </td>
                <td className="text-[#8B8C8C] min-w-[88px]">
                  {order.orderReceipt?.order_price}
                </td>
                <td className="text-[#8B8C8C] min-w-[88px]">
                  {order.orderReceipt?.order_quantity}
                </td>
                <td className="text-[#8B8C8C] min-w-[88px]"></td>
                <td className="text-[#8B8C8C] min-w-[168px]"></td>
                <td className="text-[#8B8C8C] min-w-[168px]"></td>
                <td className="text-[#8B8C8C] min-w-[168px]"></td>
                <td className="text-[#8B8C8C] min-w-[128px]"></td>
              </tr>
            ))
          ) : (
            <tr className=" border-b border-[#F9F8F9] h-8 text-center">
              <td colSpan={18} className="text-[#8B8C8C]">
                해당 정보가 없습니다.
              </td>
            </tr>
          )
        ) : (
          <tr className=" border-b border-[#F9F8F9] h-8 text-center">
            <td colSpan={18} className="text-[#8B8C8C]">
              해당 정보가 없습니다.
            </td>
          </tr>
        )}
      </tbody>
    </table>
  );
};
