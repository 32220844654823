import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import { signIn } from "../../constants/token";
import { ILoginFormProps, useLogin } from "../../hooks/useLogin";
import { LoginInput, LoginMutation } from "../../generated/graphql";

export const AdminLogin = () => {
  const {
    register,
    getValues,
    handleSubmit,
    setFocus,
    setValue,
    formState: { errors, isValid },
  } = useForm<ILoginFormProps>({ mode: "onChange" });

  const onCompleted = (data: LoginMutation) => {
    const {
      login: { ok, token, err },
    } = data;
    if (ok && token) {
      signIn(token);
    } else {
      alert(err);
      setValue(`password`, ``);
    }
  };
  const [
    login,
    { data: loginData, loading: loginLoading, error: loginError },
  ] = useLogin(onCompleted);
  const loginHandler = () => {
    if (!loginLoading) {
      const { username, password } = getValues();
      if (!username || !password) return;
      const input: LoginInput = { username, password };
      login({ variables: { input } });
    }
  };

  const onSubmit = () => {
    loginHandler();
  };

  useEffect(() => {
    setFocus(`username`);
  }, []);

  return (
    <div className="bg-main bg-opacity-50 w-full h-full min-h-screen">
      <div className="max-w-screen-yl mx-auto w-full h-full min-h-screen bg-white flex flex-wrap justify-center content-center">
        <div className="w-full max-w-xl">
          <p className="text-center text-[#4f4f4f]">관리자</p>
          <form
            onSubmit={handleSubmit(onSubmit)}
            className="pt-10 mx-auto w-2/3"
          >
            <label className="block py-5">
              <span className="inline-block w-[40px] uppercase font-bold text-[#4f4f4f]">
                ID
              </span>
              <input
                {...register("username", { required: true })}
                type="text"
                className="inline-block border-b border-[#4f4f4f] pl-1 leading-normal w-10/12 focus:outline-none"
              />
            </label>

            <label className="block py-5">
              <span className="inline-block w-[40px] uppercase font-bold text-[#4f4f4f]">
                pw
              </span>
              <input
                {...register("password", {
                  required: "password is required",
                  minLength: 5,
                })}
                type="password"
                className="inline-block border-b border-[#4f4f4f] pl-1 leading-normal w-10/12 focus:outline-none"
              />
            </label>
            {loginData?.login.err && (
              <p className="text-center text-sm text-reds">
                {loginData.login.err}
              </p>
            )}

            <button
              type={`submit`}
              className="bg-[#4f4f4f] text-white w-[300px] py-2 mx-auto text-bold flex content-center justify-center mt-10"
            >
              관리자 로그인
            </button>
          </form>
          {/* <p className="pt-3 text-center text-xs">
            <Link to="/findId" className="hover:underline">
              ID 찾기
            </Link>{" "}
            /{" "}
            <Link to="/findPwd" className="hover:underline">
              비밀번호 찾기
            </Link>
          </p> */}
        </div>
      </div>
    </div>
  );
};
