import { ApolloError, gql, useMutation } from "@apollo/client";
import {
  EditGoodOptionInput,
  EditGoodOptionMutation,
  EditGoodOptionMutationVariables,
} from "../generated/graphql";

export interface IEditGoodOptionFormProps extends EditGoodOptionInput {}
const EDIT_GOOD_OPTION_QUERY = gql`
  mutation editGoodOption($input: EditGoodOptionInput!) {
    editGoodOption(input: $input) {
      ok
      err
    }
  }
`;

export const useEditGoodOption = (
  onCompleted?: (data: EditGoodOptionMutation) => void,
  onError?: (error: ApolloError) => void
) => {
  return useMutation<EditGoodOptionMutation, EditGoodOptionMutationVariables>(
    EDIT_GOOD_OPTION_QUERY,
    {
      onCompleted,
      onError,
    }
  );
};
