import { ApolloError, gql, useMutation } from "@apollo/client";
import {
  CrProjectInput,
  CrProjectMutation,
  CrProjectMutationVariables,
} from "../generated/graphql";

export interface ICrProjectFormProps extends CrProjectInput {}
const CR_PROJECT_MUTAION = gql`
  mutation crProject($input: CrProjectInput!) {
    crProject(input: $input) {
      ok
      err
    }
  }
`;

export const useCrProject = (
  onCompleted?: (data: CrProjectMutation) => void,
  onError?: (error: ApolloError) => void
) => {
  return useMutation<CrProjectMutation, CrProjectMutationVariables>(
    CR_PROJECT_MUTAION,
    {
      onCompleted,
      onError,
    }
  );
};
