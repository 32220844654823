import { ApolloError, gql, useMutation } from "@apollo/client";
import React from "react";
import {
  DelCorporationMutation,
  DelCorporationMutationVariables,
} from "../generated/graphql";

const DEL_CORPORATION_MUTATION = gql`
  mutation delCorporation($input: DelCorporationInput!) {
    delCorporation(input: $input) {
      ok
      err
    }
  }
`;

export const useDelCorporation = (
  onCompleted?: (data: DelCorporationMutation) => void,
  onError?: (ERROR: ApolloError) => void
) => {
  return useMutation<DelCorporationMutation, DelCorporationMutationVariables>(
    DEL_CORPORATION_MUTATION,
    {
      onCompleted,
      onError,
    }
  );
};
