import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { TextareaAutosize } from "@mui/material";
import { Button, ReplyButton } from "./button";
import { ICrCommentFormProps, useCrComment } from "../hooks/useCrComment";
import { CrCommentInput, CrCommentMutation } from "../generated/graphql";

interface IComponentCrCommentForm {
  inquiryId: number;
  getRefetch: () => void;
  listRefetch: () => void;
}

export const ComponentCrComment: React.FC<IComponentCrCommentForm> = ({
  inquiryId,
  getRefetch,
  listRefetch,
}) => {
  const {
    register,
    getValues,
    setValue,
    handleSubmit,
    setFocus,
    formState: { errors, isValid },
  } = useForm<ICrCommentFormProps>({ mode: "all" });

  const onCrComplete = async (data: CrCommentMutation) => {
    const {
      crComment: { ok, err },
    } = data;
    if (ok) {
      alert("답변을 완료하였습니다");
      setValue("note", "");
      getRefetch();
      listRefetch();
    }
    if (!ok) {
      alert(err);
    }
  };
  const [crComment, { loading: crCommentLoading }] = useCrComment(onCrComplete);
  const onSubmit = () => {
    if (!crCommentLoading) {
      const { note } = getValues();
      const input: CrCommentInput = {
        inquiryId,
        note,
      };
      crComment({ variables: { input } });
    }
  };

  useEffect(() => {
    setFocus("note");
  }, []);

  return (
    <div className="w-full block">
      <form onSubmit={handleSubmit(onSubmit)}>
        <textarea
          {...register(`note`, { required: "필수 정보입니다" })}
          className="w-full py-1 px-4 bg-white resize-none rounded-sm border-[#F0F0F4] border h-40"
          onKeyPress={(e) => {
            if (e.code === "Enter" && e.ctrlKey === true) {
              onSubmit();
            }
          }}
        ></textarea>
        <div className="flex flex-wrap justify-end mt-4">
          <Button
            className={`inline-block rounded-lg text-center w-[200px] py-2 bg-[#3D3E49] text-white`}
            canClick={true}
            actionText={`등록`}
            loading={crCommentLoading}
            color={"text-white"}
          />
        </div>
      </form>
    </div>
  );
};
