import { ApolloError, gql, useMutation } from "@apollo/client";
import {
  DelBannerMutation,
  DelBannerMutationVariables,
} from "../generated/graphql";

const Del_BANNER_MUTATION = gql`
  mutation delBanner($input: DelBannerInput!) {
    delBanner(input: $input) {
      ok
      err
    }
  }
`;

export const useDelBanner = (
  onCompleted?: (data: DelBannerMutation) => void,
  onError?: (Error: ApolloError) => void
) => {
  return useMutation<DelBannerMutation, DelBannerMutationVariables>(
    Del_BANNER_MUTATION,
    {
      onCompleted,
      onError,
    }
  );
};
