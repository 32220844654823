import { gql, useLazyQuery } from "@apollo/client";
import { ListUserSelectQuery } from "../generated/graphql";

const LIST_USER_SELECT = gql`
  query listUserSelect {
    listUserSelect {
      ok
      err
      users {
        name
        id
      }
    }
  }
`;

export const useListUserSelect = (
  onCompleted?: (data: ListUserSelectQuery) => void
) => {
  return useLazyQuery<ListUserSelectQuery>(LIST_USER_SELECT, {
    onCompleted,
  });
};
