import { ApolloError, gql, useMutation } from "@apollo/client";
import {
  DelCommentMutation,
  DelCommentMutationVariables,
} from "../generated/graphql";

const DEL_COMMENT_MUTATION = gql`
  mutation delComment($input: DelCommentInput!) {
    delComment(input: $input) {
      ok
      err
    }
  }
`;

export const useDelComment = (
  onCompleted?: (data: DelCommentMutation) => void,
  onError?: (error: ApolloError) => void
) => {
  return useMutation<DelCommentMutation, DelCommentMutationVariables>(
    DEL_COMMENT_MUTATION,
    {
      onCompleted,
      onError,
    }
  );
};
