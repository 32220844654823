import { gql, useLazyQuery } from "@apollo/client";
import {
  ListCorporationQuery,
  ListCorporationQueryVariables,
} from "../generated/graphql";

const LIST_CORPORATION_QUERY = gql`
  query listCorporation($input: ListCorporationInput!) {
    listCorporation(input: $input) {
      ok
      err
      totalPages
      totalResults
      row
      corporations {
        id
        name
        managerName
        address
        phone
        ceoName
        service
        regionKeyWord
        linkService {
          id
          name
        }
      }
    }
  }
`;

export const useListCorporation = (
  onCompleted?: (data: ListCorporationQuery) => void
) => {
  return useLazyQuery<ListCorporationQuery, ListCorporationQueryVariables>(
    LIST_CORPORATION_QUERY,
    {
      onCompleted,
      fetchPolicy: "network-only",
    }
  );
};
