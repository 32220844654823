import { useReactiveVar } from "@apollo/client";
import { useEffect, useRef, useState } from "react";
import { newOrder, eDate, sDate } from "../../apollo";
import { NewOrderLists } from "../../hooks/subNewOrderList";
import refresh from "../../assets/icon/refresh.png";
import { ComponentToPrint } from "../component-to-print";
import { ko } from "date-fns/esm/locale";
import searchdate from "../../assets/icon/searchdate.png";
import { CSVLink } from "react-csv";
import excel from "../../assets/icon/excel.png";
import ReactToPrint from "react-to-print";
import { useEditOrderStatus } from "../../hooks/useEditOrderStatus";
import { headers } from "../../constants/datas";
import { useEditOrdersStatusRelease } from "../../hooks/useEditOrderStatusRelease";
import moment from "moment";
import { useForm } from "react-hook-form";
import { all } from "q";
import {
  EditOrdersStatusInput,
  EditOrdersStatusReleaseMutation,
  OrderStatus,
} from "../../generated/graphql";
import { ListOrder } from "../../routes/admin-list-order";
import { ListReceipt } from "../../routes/admin-list-receipt";
import { ComponentToPrintReceipt } from "../component-to-print-receipt";

export interface ComponentTabReceipt {
  dataList?: any[];
  DataCallback?: () => void;
  setDateSearch?: (sDate?: Date, eDate?: Date) => void;
}

export interface IDateSearchFormProps {
  sDate: Date;
  eDate: Date;
}
export const ComponentTabReceipt: React.FC<ComponentTabReceipt> = ({
  dataList = [],
  DataCallback,
  setDateSearch,
}) => {
  const [orderIds, setOrderIds] = useState<number[]>([]);
  const { register, getValues, handleSubmit } = useForm<IDateSearchFormProps>({
    mode: "all",
  });
  const pageStyle =
    "@page { size: 8cm 6cm ; } @media print{footer{display:none}} @media print{header{display:none} .page-break{page-break-after:always; display:blcok;}} @media all{.page-break{display:none}} ";
  const componentRef = useRef(null);

  const datalist = dataList.map((row) => ({
    ...row,
    phone: row.receipt?.phone ? `=""${row.receipt.phone}""` : null,
    createdAt: moment(row.createdAt).format("YYYY-MM-DD HH:mm"),
  }));

  const onSubmit = () => {
    const { sDate, eDate } = getValues();
    if (setDateSearch) {
      setDateSearch(sDate, eDate);
    }
  };

  // const dateHandler = (date: Date) => {
  //   sDate(date);
  // };
  // const edateHandler = (date: Date) => {
  //   eDate(date);
  // };
  const refetchHandler = () => {
    window.location.href = "/receipt";
  };
  const IsNewOrder = useReactiveVar(newOrder);
  const { data: newOrderList } = NewOrderLists();

  // const onEditCompleted = async (data: editOrderStatus) => {
  //   const {
  //     editOrderStatus: { ok, err },
  //   } = data;
  //   if (ok) {
  //     // alert(`출고대기 상태입니다`);
  //     window.location.href = "/order";
  //   } else {
  //     alert(err);
  //   }
  // };
  // const [editOrderStatus, {}] = useEditOrderStatus(onEditCompleted);

  // const checkState = () => {
  //   if (window.confirm("운송장 출력하셨습니까?")) {
  //     var checklist: checkList[] = [...dataList];
  //     for (var i = 0; i < checklist.length; i++) {
  //       const orderId = checklist[i].id;
  //       const status = OrderStatus.Bound;
  //       const editOrderstatusInput: EditOrderStatusInput = {
  //         orderId,
  //         status,
  //       };
  //       editOrderStatus({ variables: { input: editOrderstatusInput } });
  //     }
  //   }
  // };
  const orderIdsHandler = () => {
    var _array = [];
    var checklist: ListReceipt[] = [...dataList];
    for (var i = 0; i < checklist.length; i++) {
      _array.push(checklist[i].id);
    }

    setOrderIds(_array);
  };

  useEffect(() => {
    orderIdsHandler();
    if (newOrderList && newOrderList.newOrderList.length > 0) {
      newOrder(true);
    }
  }, [newOrderList, dataList]);

  return (
    <div className="flex flex-wrap justify-between">
      <div className="flex flex-wrap">
        <button
          className="py-3 px-4 relative"
          onClick={() => {
            refetchHandler();
            newOrder(false);
          }}
        >
          {IsNewOrder && (
            <div className="absolute right-2 top-2 w-3 h-3 block rounded-full bg-red-400">
              <span className="w-full h-full block rounded-full animate-ping bg-red-400"></span>
            </div>
          )}
          <img src={refresh} alt="새로고침" className="w-[30px] h-[30px]" />
        </button>
      </div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="flex flex-wrap gap-x-4 content-center">
          <p className="text-[#8B8C8C] text-sm flex flex-wrap content-center">
            기간
          </p>
          <div className="flex flex-wrap content-center">
            <input
              {...register("sDate")}
              type="date"
              className="border w-full  border-[#F0F0F4] text-base rounded-lg py-1 px-4"
            ></input>
          </div>
          <span className="text-white flex flex-wrap content-center">~</span>
          <div className="flex flex-wrap content-center">
            <input
              {...register("eDate")}
              type="date"
              className="border w-full  border-[#F0F0F4] text-base rounded-lg py-1 px-4"
            ></input>
          </div>

          <button className="py-3">
            <img src={searchdate} alt="찾기" className="w-[32px] h-[30px]" />
          </button>
        </div>
      </form>
      <div className="flex flex-wrap justify-end pr-8">
        <div className="flex flex-wrap gap-x-3">
          <CSVLink
            data={datalist}
            headers={headers}
            filename={`주문내역`}
            className={`flex flex-wrap gap-x-1  w-[72px] h-[30px] my-[13px] bg-white text-center items-center rounded-lg`}
          >
            <p className="pl-[15px] text-xs text-[#8B8C8C]">csv</p>
            <img src={excel} className="w-[10px] h-3" />
          </CSVLink>

          <ReactToPrint
            pageStyle={pageStyle}
            // onAfterPrint={() => checkState()}
            trigger={() => (
              <button className="bg-transparent bg-[#8b8c8c] w-[104px] h-[30px] py-[7px] text-xs font-bold text-center rounded-lg my-[13px] text-white">
                운송장 출력
              </button>
            )}
            content={() => componentRef.current}
          ></ReactToPrint>
          <div className="hidden">
            <ComponentToPrintReceipt check={dataList} printRef={componentRef} />
          </div>
        </div>
      </div>
    </div>
  );
};
