import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Button } from "../components/button";
import {
  ComponentTabLink,
  ITabLink,
  ITabTitle,
  PageType,
} from "../components/component-tab";
import exit from "../assets/icon/exit.png";
import { FormError2 } from "../components/form-error";
import { ICrFlightFormProps, useCrFlight } from "../hooks/useCrFlight";
import { useListDroneSelect } from "../hooks/useListDroneSelect";
import { useListProjectSelect } from "../hooks/useListProjectSelect";
import { AsideAdminComponent } from "../layout/aside-admin";
import { useListPortSelect } from "../hooks/useListPortSelect";
import { useListUserSelect } from "../hooks/useListUserSelect";
import {
  MakeDateTime,
  MakeOrderNumber,
  usePrompt,
} from "../constants/function";
import { useListPackSelect } from "../hooks/useListPackSelect";
import { pageIndicator } from "../apollo";
import React from "react";
import { Modal } from "@mui/material";
import { CrFlightInput, CrFlightMutation } from "../generated/graphql";
export const AdminCrFlight = () => {
  const tabList: ITabLink[] = [];
  const stitle: ITabTitle = { text: "비행기록 등록" };
  const [open, setOpen] = React.useState(false);
  const [pack, setPack] = useState<number>();
  const [changePack, setChangePack] = useState<number>();
  const [listProjectSelect, { data: projects }] = useListProjectSelect();
  const [listPackSelect, { data: packs }] = useListPackSelect();
  const [listDroneSelect, { data: drones }] = useListDroneSelect();
  const [listPortSelect, { data: ports }] = useListPortSelect();
  const [listUserSelect, { data: users }] = useListUserSelect();

  const [out, setOut] = useState<boolean>(true);
  const { confirmNavigation, showPrompt, cancelNavigation } = usePrompt(out);
  useEffect(() => {
    if (showPrompt) {
      if (
        window.confirm("변경사항이 저장 되지 않았습니다. 이동하시겠습니까?")
      ) {
        confirmNavigation();
      } else {
        cancelNavigation();
      }
    }
    if (!out) {
      window.location.href = "/flight";
    }
  }, [showPrompt, out]);
  const packHandler = () => {
    setOpen(true);
  };
  // --- 비행기록 생성
  const {
    getValues,
    register,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm<ICrFlightFormProps>({
    mode: "all",
    defaultValues: { takeOffAt: MakeDateTime(new Date()) },
  });
  const onComplete = async (data: CrFlightMutation) => {
    const {
      crFlight: { ok, err },
    } = data;
    if (ok) {
      setOut(false);
      // alert("저장되었습니다");
    } else {
      alert(err);
    }
  };

  const [crFlight, { loading }] = useCrFlight(onComplete);
  const onSubmit = () => {
    if (!loading) {
      const {
        takeOffAt,
        landAt,
        orderAt,
        note,
        status,
        packId,
        projectId,
        droneId,
        portToId,
        portFromId,
        pilotToId,
        pilotFromId,
      } = getValues();

      const crFlightInput: CrFlightInput = {
        takeOffAt,
        landAt: landAt ? landAt : null,
        orderAt: orderAt ? orderAt : null,
        note,
        status,
        packId: pack ? +pack : undefined,
        projectId: projectId ? +projectId : 1,
        droneId: droneId ? +droneId : 1,
        portToId: portToId ? +portToId : 1,
        portFromId: portFromId ? +portFromId : 1,
        pilotToId: pilotToId ? +pilotToId : 1,
        pilotFromId: pilotFromId ? +pilotFromId : 1,
      };
      crFlight({ variables: { input: crFlightInput } });
    }
  };
  useEffect(() => {
    pageIndicator("flight");
  });

  useEffect(() => {
    listProjectSelect();
    listDroneSelect();
    listPortSelect();
    listUserSelect();
    listPackSelect();
  }, []);
  return (
    <div className="bg-main bg-opacity-50 w-full h-full min-h-screen">
      <div className="w-full mx-auto h-full min-h-screen bg-gray-100">
        <main id="main">
          <div className="grid grid-cols-[200px_1fr] max-w-full">
            <AsideAdminComponent />
            <div className="mypages bg-gray-100 w-full min-w-[1200px] overflow-hidden">
              <div className="orderWrap">
                <div className="h-fix-content relative">
                  <div className={`w-full h-full px-10 py-8`}>
                    <ComponentTabLink
                      tabs={tabList}
                      stitle={stitle}
                      type={PageType.Create}
                    />
                    <div className="shadow-lg w-full  bg-white">
                      <form onSubmit={handleSubmit(onSubmit)} id="create">
                        <div className="pl-9 pr-11 py-8 space-y-4">
                          <div className="space-y-2">
                            <p className="font-bold text-[#4F4F4F] text-base">
                              드론 선택
                            </p>
                            <div>
                              <select
                                {...register(`droneId`, {
                                  required: "드론 ID를 선택해주세요",
                                })}
                                className="border w-full border-[#F0F0F4] rounded-lg py-1 px-2"
                              >
                                <option selected={true} disabled value="">
                                  드론을 선택해주세요
                                </option>
                                {drones?.listDroneSelect.drones?.map(
                                  (drone) => (
                                    <option
                                      key={`drone-${drone.id}`}
                                      value={drone.id}
                                    >
                                      {drone.name}
                                    </option>
                                  )
                                )}
                              </select>

                              {errors.droneId?.message && (
                                <FormError2
                                  errorMessage={errors.droneId.message}
                                />
                              )}
                            </div>
                          </div>
                          <div className="space-y-2">
                            <p className="font-bold text-[#4F4F4F] text-base">
                              note
                            </p>
                            <input
                              {...register("note", {})}
                              type="text"
                              className="border w-full h-10
                            border-[#F0F0F4] text-base rounded-lg py-1 px-4"
                            />
                          </div>
                          <div className="space-y-2">
                            <p className="font-bold text-[#8B8C8C] text-sm">
                              프로젝트 아이디
                            </p>
                            <select
                              {...register(`projectId`, {
                                required: "프로젝트 ID를 선택해주세요",
                              })}
                              className="border w-full border-[#F0F0F4] rounded-lg py-1 px-2"
                            >
                              <option selected={true} disabled value="">
                                프로젝트를 선택해주세요
                              </option>
                              {projects?.listProjectSelect.projects?.map(
                                (project) => (
                                  <option
                                    key={`project-${project.id}`}
                                    value={project.id}
                                  >
                                    {project.name}
                                  </option>
                                )
                              )}
                            </select>

                            {errors.projectId?.message && (
                              <FormError2
                                errorMessage={errors.projectId.message}
                              />
                            )}
                          </div>
                          <div className="space-y-2">
                            <p className="font-bold text-[#8B8C8C] text-sm">
                              주문날짜
                            </p>
                            <input
                              {...register("orderAt", {
                                required: `주문날짜를 입력해주세요`,
                              })}
                              type="datetime-local"
                              className="border w-full h-10 border-[#F0F0F4] text-base rounded-lg py-1 px-4"
                            />
                          </div>
                          <div className="space-y-2">
                            <p className="font-bold text-[#8B8C8C] text-sm">
                              출발시간
                            </p>
                            <input
                              {...register("takeOffAt", {
                                required: `출발시간을 입력해주세요`,
                              })}
                              type="datetime-local"
                              className="border w-full h-10 border-[#F0F0F4] text-base rounded-lg py-1 px-4"
                            />
                          </div>

                          <div className="space-y-2">
                            <p className="font-bold text-[#8B8C8C] text-sm">
                              도착시간
                            </p>
                            <input
                              {...register("landAt", {})}
                              type="datetime-local"
                              className="border w-full h-10 border-[#F0F0F4] text-base rounded-lg py-1 px-4"
                            />
                          </div>
                          <div className="space-y-2">
                            <p className="font-bold text-[#8B8C8C] text-sm">
                              출발지 포트
                            </p>
                            <select
                              {...register(`portToId`, {
                                required: "출발지 포트를 선택해주세요",
                              })}
                              // on={onchangeHandler}
                              className="border w-full border-[#F0F0F4] rounded-lg py-1 px-2"
                            >
                              <option selected={true} disabled value="">
                                출발지 포트를 선택해주세요
                              </option>
                              {ports?.listPortSelect.ports?.map((port) => (
                                <option key={`port-${port.id}`} value={port.id}>
                                  {port.name}
                                </option>
                              ))}
                            </select>

                            {errors.portToId?.message && (
                              <FormError2
                                errorMessage={errors.portToId.message}
                              />
                            )}
                          </div>
                          <div className="space-y-2">
                            <p className="font-bold text-[#8B8C8C] text-sm">
                              도착지 포트
                            </p>
                            <select
                              {...register(`portFromId`, {
                                required: "도착지 포트를 선택해주세요",
                              })}
                              className="border w-full border-[#F0F0F4] rounded-lg py-1 px-2"
                            >
                              <option selected={true} disabled value="">
                                도착지 포트를 선택해주세요
                              </option>
                              {ports?.listPortSelect.ports?.map((port) => (
                                <option key={`port-${port.id}`} value={port.id}>
                                  {port.name}
                                </option>
                              ))}
                            </select>

                            {errors.portFromId?.message && (
                              <FormError2
                                errorMessage={errors.portFromId.message}
                              />
                            )}
                          </div>
                          <div className="space-y-2">
                            <p className="font-bold text-[#8B8C8C] text-sm">
                              출발지 조종사
                            </p>
                            <select
                              {...register(`pilotToId`, {
                                required: "출발지 조종사를 선택해주세요",
                              })}
                              className="border w-full border-[#F0F0F4] rounded-lg py-1 px-2"
                            >
                              <option selected={true} disabled value="">
                                출발지 조종사를 선택해주세요
                              </option>
                              {users?.listUserSelect.users?.map((user) => (
                                <option key={`user-${user.id}`} value={user.id}>
                                  {user.name}
                                </option>
                              ))}
                            </select>

                            {errors.pilotToId?.message && (
                              <FormError2
                                errorMessage={errors.pilotToId.message}
                              />
                            )}
                          </div>
                          <div className="space-y-2">
                            <p className="font-bold text-[#8B8C8C] text-sm">
                              도착지 조종사
                            </p>
                            <select
                              {...register(`pilotFromId`, {
                                required: "도착지 조종사를 선택해주세요",
                              })}
                              className="border w-full border-[#F0F0F4] rounded-lg py-1 px-2"
                            >
                              <option selected={true} disabled value="">
                                도착지 조종사를 선택해주세요
                              </option>
                              {users?.listUserSelect.users?.map((user) => (
                                <option key={`user-${user.id}`} value={user.id}>
                                  {user.name}
                                </option>
                              ))}
                            </select>

                            {errors.pilotFromId?.message && (
                              <FormError2
                                errorMessage={errors.pilotFromId.message}
                              />
                            )}
                          </div>
                          <div className="space-y-2">
                            <p className="font-bold text-[#8B8C8C] text-sm">
                              Pack
                            </p>
                            <div className="flex flex-wrap">
                              <p className="py-1 px-2 rounded-lg border border-b-2 mr-4">
                                {pack === undefined ? "PACK 선택" : pack}
                              </p>
                              <button
                                className="table-text rounded-lg py-[6px] w-[48px] h-7 text-white bg-[#3D3E49]"
                                type="button"
                                onClick={() => packHandler()}
                              >
                                PACK
                              </button>
                            </div>
                          </div>

                          <div className="flex flex-wrap justify-center pt-6">
                            <Button
                              className={`w-[240px] h-[40px] bg-[#3D3E49] text-white rounded-lg`}
                              canClick={true}
                              actionText={`등록`}
                              loading={loading}
                              color={"text-white"}
                            />
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
        <Modal
          open={open}
          onClose={() => {
            setOpen(false);
          }}
        >
          <div className="max-w-[720px] w-full absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2   bg-[#F9F8F9] rounded-lg ">
            <div className="text-center py-2 h-10 text-white font-bold bg-[#3D3E49] rounded-t-lg  ">
              <div className="flex flex-wrap justify-between">
                <p className="w-[480px] ml-[40px] font-bold text-base">Pack</p>
                <img
                  src={exit}
                  alt="닫기"
                  className="w-6 mr-2 cursor-pointer"
                  onClick={() => setOpen(false)}
                ></img>
              </div>
            </div>
            <div className="flex flex-wrap ">
              <div className="px-10 py-10 w-[720px] overflow-auto">
                <div className="inline-grid grid-flow-col ">
                  {packs?.listPackSelect.packs &&
                    packs.listPackSelect.packs?.map((pack) => (
                      <button
                        key={pack.id}
                        className={`packButton border border-b-2 p-2 w-[240px] h-[240px] hover:bg-[#CCCCCC] ${
                          changePack === pack.id ? "bg-[#cccccc]" : ""
                        } `}
                        onClick={() => {
                          setChangePack(pack.id);
                        }}
                      >
                        <p>팩 {pack.id}</p>
                        {pack.orders?.map((packorder) => (
                          <div
                            className="py-2 flex flex-wrap justify-between"
                            key={packorder.id}
                          >
                            <p>
                              주문번호 :{" "}
                              {packorder.orderNum &&
                                MakeOrderNumber(packorder.orderNum.toString())}
                            </p>
                            <p>상품명 : {packorder.goodOptionName}</p>
                          </div>
                        ))}
                      </button>
                    ))}
                </div>
              </div>
            </div>
            <button
              className="w-full h-14 bg-[#F0F0F4] rounded-b-lg"
              onClick={() => {
                setOpen(false);
                setPack(changePack);
              }}
            >
              확인
            </button>
          </div>
        </Modal>
      </div>
    </div>
  );
};
