import { gql, useLazyQuery } from "@apollo/client";
import { ListPackQuery, ListPackQueryVariables } from "../generated/graphql";

const LIST_PACK_QUERY = gql`
  query listPack($input: ListPackInput!) {
    listPack(input: $input) {
      ok
      err
      totalPages
      totalResults
      row
      packs {
        id
        orders {
          id
          orderNum
          good {
            id
            title
            goodOptions {
              id
              name
            }
          }
        }
      }
    }
  }
`;

export const useListPack = (onCompleted?: (data: ListPackQuery) => void) => {
  return useLazyQuery<ListPackQuery, ListPackQueryVariables>(LIST_PACK_QUERY, {
    onCompleted,
    fetchPolicy: "network-only",
  });
};
