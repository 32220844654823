import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Button } from "../components/button";
import {
  ComponentTabLink,
  ITabLink,
  ITabTitle,
  PageType,
} from "../components/component-tab";
import { FormError2 } from "../components/form-error";
import { ICrProjectFormProps, useCrProject } from "../hooks/useCrProject";
import { AsideAdminComponent } from "../layout/aside-admin";
import { usePrompt } from "../constants/function";
import { CrProjectInput, CrProjectMutation } from "../generated/graphql";

export const AdminCrProject = () => {
  const [out, setOut] = useState<boolean>(true);
  const { confirmNavigation, showPrompt, cancelNavigation } = usePrompt(out);
  useEffect(() => {
    if (showPrompt) {
      if (
        window.confirm("변경사항이 저장 되지 않았습니다. 이동하시겠습니까?")
      ) {
        confirmNavigation();
      } else {
        cancelNavigation();
      }
    }
    if (!out) {
      window.location.href = `/project`;
    }
  }, [showPrompt, out]);
  const {
    getValues,
    register,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm<ICrProjectFormProps>({ mode: "all" });

  const onComplete = async (data: CrProjectMutation) => {
    const {
      crProject: { ok, err },
    } = data;
    if (ok) {
      setOut(false);

      // alert("저장되었습니다");
    } else {
      alert(err);
    }
  };

  const [crProject, { loading }] = useCrProject(onComplete);
  const onSubmit = () => {
    if (!loading) {
      const { name, note, startAt, endAt } = getValues();
      const crProjectInput: CrProjectInput = {
        name,
        note,
        startAt,
        endAt,
      };
      crProject({ variables: { input: crProjectInput } });
    }
  };
  const tabList: ITabLink[] = [];

  const stitle: ITabTitle = { text: "프로젝트 등록" };
  return (
    <div className="bg-main bg-opacity-50 w-full h-full min-h-screen">
      <div className="w-full mx-auto h-full min-h-screen bg-gray-100">
        <main id="main">
          <div className="grid grid-cols-[200px_1fr] max-w-full">
            <AsideAdminComponent />

            <div className="mypages bg-gray-100 w-full min-w-[1200px] overflow-hidden">
              <div className="orderWrap">
                <div className="h-fix-content relative">
                  <div className={`w-full h-full px-10 py-8`}>
                    <ComponentTabLink
                      tabs={tabList}
                      stitle={stitle}
                      type={PageType.Create}
                    />
                    <div className="shadow-lg w-full h-fix-content bg-white">
                      <form onSubmit={handleSubmit(onSubmit)} id="create">
                        <div className="pl-9 pr-11 py-8 space-y-4">
                          <div className="space-y-2">
                            <p className="font-bold text-[#4F4F4F] text-base">
                              이름
                            </p>
                            <input
                              {...register("name", {
                                required: `이름을 입력해주세요`,
                              })}
                              type="text"
                              className="border w-full h-10 border-[#F0F0F4] text-base rounded-lg py-1 px-4"
                            />
                            {errors.name?.message && (
                              <FormError2 errorMessage={errors.name.message} />
                            )}
                          </div>
                          <div className="space-y-2">
                            <p className="font-bold text-[#4F4F4F] text-base">
                              설명
                            </p>
                            <input
                              {...register("note", {
                                required: `설명을 입력해주세요`,
                              })}
                              type="text"
                              className="border w-full h-10 border-[#F0F0F4] text-base rounded-lg py-1 px-4"
                            />
                            {errors.note?.message && (
                              <FormError2 errorMessage={errors.note.message} />
                            )}
                          </div>
                          <div className="space-y-2">
                            <p className="font-bold text-[#4F4F4F] text-base">
                              시작일
                            </p>
                            <input
                              {...register("startAt", {
                                required: `시작일을 입력해주세요`,
                              })}
                              type="date"
                              className="border w-full h-10 border-[#F0F0F4] text-base rounded-lg py-1 px-4"
                            />
                            {errors.startAt?.message && (
                              <FormError2
                                errorMessage={errors.startAt.message}
                              />
                            )}
                          </div>
                          <div className="space-y-2">
                            <p className="font-bold text-[#4F4F4F] text-base">
                              종료일
                            </p>
                            <input
                              {...register("endAt", {
                                required: `종료일을 입력해주세요`,
                              })}
                              type="date"
                              className="border w-full h-10 border-[#F0F0F4] text-base rounded-lg py-1 px-4"
                            />
                            {errors.endAt?.message && (
                              <FormError2 errorMessage={errors.endAt.message} />
                            )}
                          </div>

                          <div className="flex flex-wrap justify-center pt-6">
                            <Button
                              className={`w-[240px] h-[40px] bg-[#3D3E49] text-white rounded-lg`}
                              canClick={true}
                              actionText={`등록`}
                              loading={loading}
                              color={"text-white"}
                            />
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
};
