import { useEffect, useState } from "react";
import { pageIndicator } from "../apollo";
import { AsideAdminComponent } from "../layout/aside-admin";
import { SubAdminEditBannerOption } from "../sub-routes/sub-admin-up-banner-option";
import { SubAdminEditBannerMain } from "../sub-routes/sub-admin-up-banner-main";
export const AdminUpBanner = () => {
  const [tabs, setTabs] = useState(0);

  useEffect(() => {
    pageIndicator("events");
  }, []);

  return (
    <div className="bg-main bg-opacity-50 w-full h-full min-h-screen">
      <div className="w-full mx-auto h-full min-h-screen bg-white">
        <main id="main">
          <div className="grid grid-cols-[200px_1fr]">
            <AsideAdminComponent />
            <div className="mypages bg-gray-100">
              <div className="orderWrap">
                <div className="bg-gray-100 h-fix-content relative">
                  <div className={`w-full h-full px-10 py-8`}>
                    <div className="grid grid-cols-[300px_1fr_200px]">
                      <div className="flex flex-wrap">
                        <div className="border-l-4 border-[#3D3E49] my-2 ml-2 w-4 h-5"></div>
                        <h1 className="inline-block font-bold mb-2 text-[24px] text-[#3D3E49]">
                          배너 편집
                        </h1>
                      </div>
                      <div className="subtitle h-full flex flex-wrap content-end">
                        <button
                          className={`inline-block w-[104px] py-2 text-center rounded-t-xl  ${
                            tabs === 0 ? `bg-[#3D3E49]` : `bg-white`
                          }`}
                          onClick={() =>
                            setTabs(() => {
                              return 0;
                            })
                          }
                        >
                          <p
                            className={`block leading-normal font-bold hover:opacity-60 w-full  ${
                              tabs === 0 ? `text-white` : `text-[#4F4F4F]`
                            }`}
                          >
                            메인 배너
                          </p>
                        </button>
                        <button
                          className={`inline-block w-[104px] py-2 text-center rounded-t-xl  ${
                            tabs === 1 ? `bg-[#3D3E49]` : `bg-white`
                          }`}
                          onClick={() =>
                            setTabs(() => {
                              return 1;
                            })
                          }
                        >
                          <p
                            className={`block leading-normal font-bold hover:opacity-60 w-full  ${
                              tabs === 1 ? `text-white` : `text-[#4F4F4F]`
                            }`}
                          >
                            토스트 배너
                          </p>
                        </button>
                      </div>
                    </div>
                    <div className="h-14 w-full max-w-[1623px] rounded-t-2xl bg-[#3D3E49] ">
                      <div className="flex flex-wrap justify-end pr-8"></div>
                    </div>
                    {/* {tabs === 0 && (
                      <SubAdminEditBannerInfo
                        tabs={tabs}
                        setTabHandler={setTabs}
                      />
                    )} */}
                    {tabs === 0 && (
                      <SubAdminEditBannerMain
                        tabs={tabs}
                        setTabHandler={setTabs}
                      />
                    )}
                    {tabs === 1 && (
                      <SubAdminEditBannerOption
                        tabs={tabs}
                        setTabHandler={setTabs}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
};
