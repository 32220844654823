import { gql, useLazyQuery } from "@apollo/client";
import {
  ListReceiptQuery,
  ListReceiptQueryVariables,
} from "../generated/graphql";

const LIST_RECEIPT_QUERY = gql`
  query listReceipt($input: ListReceiptInput!) {
    listReceipt(input: $input) {
      ok
      err
      totalPages
      totalResults
      row
      receipts {
        id
        createdAt
        uuid
        paid_amount
        paid_at
        receiver
        phone
        address
        message
        scheduledAt
        receiptNum
        pass
        user {
          username
          name
        }
        isBilled
        bound {
          id
        }
        orders {
          id
          amount
          goodOptionName
          goodName
          quantity

          goodOption {
            id
          }
          orderReceipt {
            order_quantity
            order_price
            good_title
          }
        }
        receiptStatus
        status
      }
    }
  }
`;

export const useListReceipt = (
  onCompleted?: (data: ListReceiptQuery) => void
) => {
  return useLazyQuery<ListReceiptQuery, ListReceiptQueryVariables>(
    LIST_RECEIPT_QUERY,
    {
      onCompleted,
      fetchPolicy: "network-only",
    }
  );
};
