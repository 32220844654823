import { ApolloError, gql, useMutation } from "@apollo/client";
import {
  EditGoodStatusMutation,
  EditGoodStatusMutationVariables,
} from "../generated/graphql";

const EDIT_GOOD_STATUS_MUTATION = gql`
  mutation editGoodStatus($input: EditGoodStatusInput!) {
    editGoodStatus(input: $input) {
      ok
      err
    }
  }
`;

export const useEditGoodStatus = (
  onCompleted?: (data: EditGoodStatusMutation) => void,
  onError?: (error: ApolloError) => void
) => {
  return useMutation<EditGoodStatusMutation, EditGoodStatusMutationVariables>(
    EDIT_GOOD_STATUS_MUTATION,
    {
      onCompleted,
      onError,
    }
  );
};
