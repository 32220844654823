import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { pageIndicator } from "../apollo";
import { Button } from "../components/button";
import {
  ComponentTabLink,
  ITabLink,
  ITabTitle,
  PageType,
} from "../components/component-tab";

import { FormError2 } from "../components/form-error";
import { BoundTypeOption, BoundTypeOptionType } from "../constants/enum";
import { usePrompt } from "../constants/function";
import { ICrBoundFormProps, useCrBound } from "../hooks/useCrBound";
import { useListCorporationSelect } from "../hooks/useListCorprationSelect";
import { AsideAdminComponent } from "../layout/aside-admin";
import {
  BoundStatus,
  CrBoundInput,
  CrBoundMutation,
} from "../generated/graphql";

export const AdminCrBound = () => {
  const [out, setOut] = useState<boolean>(true);
  const { confirmNavigation, showPrompt, cancelNavigation } = usePrompt(out);
  const [checked, setChecked] = useState<boolean>(true);
  useEffect(() => {
    pageIndicator("product");
    if (showPrompt) {
      if (
        window.confirm("변경사항이 있다면 저장되지않습니다. 이동하시겠습니까?")
      ) {
        confirmNavigation();
      } else {
        cancelNavigation();
      }
    }
    if (!out) {
      window.location.href = `/bound`;
    }
  }, [showPrompt, out]);
  const [
    listCorporationSelect,
    { data: corporations },
  ] = useListCorporationSelect();
  const params = useParams();

  const {
    getValues,
    register,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm<ICrBoundFormProps>({ mode: "all" });

  const onComplete = async (data: CrBoundMutation) => {
    const {
      crBound: { ok, err },
    } = data;
    if (ok) {
      setOut(false);
      // alert("저장되었습니다");
    } else {
      alert(err);
    }
  };
  const [crBound, { loading }] = useCrBound(onComplete);
  const onSubmit = () => {
    if (!loading) {
      const {
        desc,
        stock,
        price,
        type,
        status,
        corporationName,
        expiryDate,
        orderedAt,
        deliveredAt,
      } = getValues();
      const crBoundInput: CrBoundInput = {
        desc,
        stock: +stock,
        price: +price,
        type,
        status: BoundStatus.Gathering,
        goodId: Number(params.goodId),
        corporationName,
        expiryDate: checked ? null : expiryDate,
        orderedAt,
        deliveredAt,
      };
      crBound({ variables: { input: crBoundInput } });
    }
  };

  const tabList: ITabLink[] = [];

  const stitle: ITabTitle = { text: "입/출고 등록" };

  useEffect(() => {
    listCorporationSelect();
  }, []);

  return (
    <div className="bg-main bg-opacity-50 w-full h-full min-h-screen">
      <div className="w-full mx-auto h-full min-h-screen  bg-gray-100">
        <main id="main">
          <div className="grid grid-cols-[200px_1fr] max-w-full">
            <AsideAdminComponent />

            <div className="mypages bg-gray-100 w-full min-w-[1200px] overflow-hidden">
              <div className="orderWrap">
                <div className="h-fix-content relative">
                  <div className={`w-full h-full px-10 py-8`}>
                    <ComponentTabLink
                      tabs={tabList}
                      stitle={stitle}
                      type={PageType.Create}
                    />
                    <div className="shadow-lg h-fix-content overflow-auto bg-white">
                      <form onSubmit={handleSubmit(onSubmit)}>
                        <div className="pl-9 pr-11 py-8 space-y-4">
                          <div className="space-y-2">
                            <p className="font-bold text-[#4F4F4F] text-base">
                              메모
                            </p>
                            <input
                              {...register("desc", {})}
                              type="text"
                              className="border w-full h-10 border-[#F0F0F4] text-base rounded-lg py-1 px-4"
                            />
                            {errors.desc?.message && (
                              <FormError2 errorMessage={errors.desc.message} />
                            )}
                          </div>
                          <div className="space-y-2">
                            <p className="font-bold text-[#4F4F4F] text-base">
                              수량
                            </p>
                            <input
                              {...register("stock", {
                                required: `수량을 입력해주세요`,
                              })}
                              type="number"
                              className="border w-full h-10 border-[#F0F0F4] text-base rounded-lg py-1 px-4"
                            />
                            {errors.stock?.message && (
                              <FormError2 errorMessage={errors.stock.message} />
                            )}
                          </div>
                          <div className="space-y-2">
                            <p className="font-bold text-[#4F4F4F] text-base">
                              가격
                            </p>
                            <input
                              {...register("price", {
                                required: `가격을 입력해주세요`,
                              })}
                              type="number"
                              className="border w-full h-10 border-[#F0F0F4] text-base rounded-lg py-1 px-4"
                            />
                            {errors.price?.message && (
                              <FormError2 errorMessage={errors.price.message} />
                            )}
                          </div>

                          <div className="grid grid-cols-3 gap-x-6">
                            <div className="space-y-2">
                              <div className="font-bold text-[#4F4F4F] text-base flex flex-wrap justify-between">
                                <p className="font-bold text-[#4F4F4F] text-base">
                                  입/출고
                                </p>
                              </div>
                              <div>
                                <select
                                  {...register(`type`, {
                                    required: "입고/출고를 선택해주세요",
                                  })}
                                  className="border border-[#F0F0F4] rounded-lg py-1 px-2 w-full text-base"
                                >
                                  {Object(BoundTypeOption).map(
                                    (
                                      type: BoundTypeOptionType,
                                      index: number
                                    ) => (
                                      <option
                                        key={`type-option-${index}`}
                                        value={type.value}
                                        disabled={index === 0}
                                        selected={index === 0}
                                      >
                                        {type.label}
                                      </option>
                                    )
                                  )}
                                </select>
                                {errors.type?.message && (
                                  <FormError2
                                    errorMessage={errors.type.message}
                                  />
                                )}
                              </div>
                            </div>
                            <div className="space-y-2">
                              <p className="font-bold text-[#4F4F4F] text-base">
                                <input
                                  type="checkbox"
                                  className="mr-2"
                                  onChange={() => {
                                    setChecked(!checked);
                                  }}
                                ></input>
                                유통기한
                              </p>
                              <input
                                {...register("expiryDate")}
                                type="date"
                                disabled={checked}
                                className="border w-1/2 h-10 border-[#F0F0F4] text-base rounded-lg py-1 px-4"
                              />
                            </div>
                          </div>

                          <div className="space-y-2">
                            <p className="font-bold text-[#4F4F4F] text-base">
                              거래처 이름
                            </p>
                            <select
                              {...register(`corporationName`, {
                                required: "거래처를 선택해주세요",
                              })}
                              className="border w-full border-[#F0F0F4] rounded-lg py-1 px-2"
                            >
                              <option selected={true} disabled value="">
                                거래처 선택
                              </option>
                              {corporations?.listCorporationSelect.corporations?.map(
                                (corporation) => (
                                  <option
                                    key={`corporation-${corporation.id}`}
                                    value={corporation.name}
                                  >
                                    {corporation.name}
                                  </option>
                                )
                              )}
                            </select>
                            {errors.corporationName?.message && (
                              <FormError2
                                errorMessage={errors.corporationName.message}
                              />
                            )}
                          </div>
                          <div className="flex flex-wrap justify-center pt-6">
                            <Button
                              className={`w-[240px] h-[40px] bg-[#3D3E49] text-white rounded-lg`}
                              canClick={true}
                              actionText={`등록`}
                              loading={loading}
                              color={"text-white"}
                            />
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
};
