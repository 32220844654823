import { gql, useLazyQuery } from "@apollo/client";
import { ListGoodAllQuery } from "../generated/graphql";

const LIST_GOOD_ALL_QUERY = gql`
  query listGoodAll {
    listGoodAll {
      ok
      err
      goods {
        id
        createdAt
        title
        category
        status
        goodType
        corporations {
          id
          name
        }
        bounds {
          status
        }
        goodOptions {
          deliveryDate
          expirydate
          name
          id
        }
      }
    }
  }
`;

export const useListGoodAll = (
  onCompleted?: (data: ListGoodAllQuery) => void
) => {
  return useLazyQuery<ListGoodAllQuery>(LIST_GOOD_ALL_QUERY, {
    onCompleted,
    fetchPolicy: "network-only",
  });
};
