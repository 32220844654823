import { ApolloError, gql, useMutation } from "@apollo/client";
import {
  EditInquiryStatusMutation,
  EditInquiryStatusMutationVariables,
} from "../generated/graphql";

const EDIT_INQUIRY_STATUS_MUTATION = gql`
  mutation editInquiryStatus($input: EditInquiryStatusInput!) {
    editInquiryStatus(input: $input) {
      ok
      err
    }
  }
`;

export const useEditInquiryStatus = (
  onCompleted?: (data: EditInquiryStatusMutation) => void,
  onError?: (error: ApolloError) => void
) => {
  return useMutation<
    EditInquiryStatusMutation,
    EditInquiryStatusMutationVariables
  >(EDIT_INQUIRY_STATUS_MUTATION, {
    onCompleted,
    onError,
  });
};
