import { useState } from "react";
import { eDate, sDate } from "../../apollo";
import refresh from "../../assets/icon/refresh.png";
import { ko } from "date-fns/esm/locale";
import searchdate from "../../assets/icon/searchdate.png";
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import exit from "../../assets/icon/exit.png";
import {
  ICrLinkServiceFormProps,
  useCrLinkService,
} from "../../hooks/useCrLinkService";
import {
  CrLinkServiceInput,
  CrLinkServiceMutation,
} from "../../generated/graphql";
import { Modal } from "@mui/material";
import { FormError2 } from "../form-error";

export interface ComponentTabService {
  dataList?: any[];
  DataCallback?: () => void;
}

export const ComponentTabService: React.FC<ComponentTabService> = ({
  dataList = [],
  DataCallback,
}) => {
  const [sopen, setServiceOpen] = useState(false);
  const refetchHandler = () => {
    window.location.href = "/service";
  };

  const {
    getValues,
    register,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm<ICrLinkServiceFormProps>({ mode: "all" });

  const onComplete = (data: CrLinkServiceMutation) => {
    const {
      crLinkService: { ok, err },
    } = data;
    if (ok) {
      window.location.href = `/service`;
    } else {
      alert(err);
    }
  };

  const [crLinkService, { loading }] = useCrLinkService(onComplete);
  const onSubmit = () => {
    if (!loading) {
      const { name, minAmount } = getValues();
      const crLinkServiceInput: CrLinkServiceInput = {
        name,
        minAmount: minAmount ? +minAmount : 0,
      };
      crLinkService({ variables: { input: crLinkServiceInput } });
      setServiceOpen(false);
    }
  };
  return (
    <div className="flex flex-wrap justify-between">
      <div className="flex flex-wrap">
        <button
          className="py-3 px-4 relative"
          onClick={() => {
            refetchHandler();
          }}
        >
          <img src={refresh} alt="새로고침" className="w-[30px] h-[30px]" />
        </button>
      </div>
      <div className="flex flex-wrap space-x-2"></div>
      <div className="flex flex-wrap justify-end pr-8">
        <button
          className="bg-transparent bg-[#8b8c8c] w-[104px] h-[30px] py-[7px] text-xs font-bold text-center rounded-lg my-[13px] text-white"
          onClick={() => setServiceOpen(true)}
        >
          서비스 생성
        </button>
      </div>
      <Modal
        open={sopen}
        onClose={() => {
          setServiceOpen(false);
        }}
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="max-w-[360px] w-full absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-[#F9F8F9] rounded-lg">
            <div className="text-center py-2 h-10 text-white font-bold bg-[#3D3E49] rounded-t-lg  ">
              <div className="flex flex-wrap justify-between">
                <p className="w-[240px] ml-[40px] font-bold text-base">
                  서비스 생성
                </p>
                <img
                  src={exit}
                  alt="닫기"
                  className="w-6 mr-2 cursor-pointer"
                  onClick={() => setServiceOpen(false)}
                ></img>
              </div>
            </div>
            <div className="px-10 py-10">
              <div className="space-y-5">
                <div className="space-y-3">
                  <p className="font-bold text-[#8B8C8C] text-sm">서비스명</p>
                  <input
                    {...register("name", {
                      required: `서비스이름을 입력해주세요`,
                    })}
                    type="text"
                    className="w-full border-[#F0F0F4] rounded-lg py-1 px-4 h-10"
                  ></input>
                  {errors.name?.message && (
                    <FormError2 errorMessage={errors.name.message} />
                  )}
                </div>
                <div className="space-y-3">
                  <p className="font-bold text-[#8B8C8C] text-sm">객단가</p>
                  <input
                    {...register("minAmount", {})}
                    type="number"
                    className="w-full border-[#F0F0F4] rounded-lg py-1 px-4 h-10"
                  ></input>
                  {errors.minAmount?.message && (
                    <FormError2 errorMessage={errors.minAmount.message} />
                  )}
                </div>
                {/* <div className="space-y-3">
                  <p className="font-bold text-[#8B8C8C] text-sm">링크</p>
                  <input
                    {...register("link", {})}
                    type="text"
                    className="w-full border-[#F0F0F4] rounded-lg py-1 px-4 h-10"
                  ></input>
                  {errors.link?.message && (
                    <FormError2 errorMessage={errors.link.message} />
                  )}
                </div> */}
              </div>
            </div>
            <button
              type="submit"
              className="w-full h-14 bg-[#F0F0F4] rounded-b-lg"
            >
              {loading ? "Loading..." : "확인"}
            </button>
          </div>
        </form>
      </Modal>
    </div>
  );
};
