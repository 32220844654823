import { useState } from "react";
import { eDate, sDate } from "../../apollo";
import DatePick from "react-datepicker";

import refresh from "../../assets/icon/refresh.png";
import { ko } from "date-fns/esm/locale";
import searchdate from "../../assets/icon/searchdate.png";
import excel from "../../assets/icon/excel.png";
import exit from "../../assets/icon/exit.png";
import { CSVLink } from "react-csv";
import { exchangeheaders } from "../../constants/datas";
import React from "react";
import { useEditOrdersStatus } from "../../hooks/useEditOrdersStatus";
import { Modal } from "@mui/material";
import { useForm } from "react-hook-form";
import { IDateSearchFormProps } from "./component-tab-order";
import moment from "moment";
import {
  OrderStatus,
  ExchangeStatus,
  EditOrdersStatusInput,
  EditOrdersStatusMutation,
} from "../../generated/graphql";
export interface ComponentTabExchange {
  dataList?: any[];
  DataCallback?: () => void;
  refetch?(): void;
  setDateSearch?: (sDate?: Date, eDate?: Date) => void;
}

export const ComponentTabExchange: React.FC<ComponentTabExchange> = ({
  dataList = [],
  DataCallback,
  refetch,
  setDateSearch,
}) => {
  const { register, getValues, handleSubmit } = useForm<IDateSearchFormProps>({
    mode: "all",
  });

  const [open, setOpen] = React.useState(false);
  const [change, setChange] = React.useState<OrderStatus>(OrderStatus.Refunded);
  const datalist = dataList.map((row) => ({
    ...row,
    phone: `=""${row.receipt.phone}""`,
    status: row.createdAt === OrderStatus.Exchange ? "교환" : "환불",
    exchangeStatus:
      row.exchangeStatus === ExchangeStatus.ReDelivery
        ? "재발송"
        : row.exchangeStatus === ExchangeStatus.PickUp
        ? "물품 수령"
        : row.exchangeStatus === ExchangeStatus.Pending
        ? "대기"
        : row.exchangeStatus === ExchangeStatus.Refunded
        ? "환불완료"
        : "교환 완료",
    createdAt: moment(row.createdAt).format("YYYY-MM-DD HH:mm"),
  }));
  const refetchHandler = () => {
    window.location.href = "/order/exchange";
  };
  // --- 상태 변경 한번에 ---
  const onEditCompleted = async (data: EditOrdersStatusMutation) => {
    const {
      editOrdersStatus: { ok, err },
    } = data;
    if (ok && refetch) {
      await refetch();
    } else {
      alert(err);
    }
  };
  const [editOrdersStatus, { loading }] = useEditOrdersStatus(onEditCompleted);
  const editOrdersStatusHandler = (status: OrderStatus) => {
    var orderIds: number[] = [];
    for (const data of dataList) {
      if (data.id) {
        orderIds.push(Number(data.id));
      }
    }
    if (!loading) {
      const editOrdersStatusInput: EditOrdersStatusInput = {
        orderIds,
        status,
      };
      editOrdersStatus({ variables: { input: editOrdersStatusInput } });
    }
  };
  const onSubmit = () => {
    const { sDate, eDate } = getValues();
    if (setDateSearch) {
      setDateSearch(sDate, eDate);
    }
  };
  return (
    <div className="flex flex-wrap justify-between">
      <div className="flex flex-wrap">
        <button
          className="py-3 px-4 relative"
          onClick={() => {
            refetchHandler();
          }}
        >
          <img src={refresh} alt="새로고침" className="w-[30px] h-[30px]" />
        </button>
        <button
          onClick={() => {
            setOpen(true);
          }}
          className="bg-[#555B94] w-[104px] h-[30px] py-[7px] text-xs text-center rounded-lg my-[13px] text-white"
        >
          상태변경
        </button>
      </div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="flex flex-wrap gap-x-4 content-center">
          <p className="text-[#8B8C8C] text-sm flex flex-wrap content-center">
            기간
          </p>
          <div className="flex flex-wrap content-center">
            <input
              {...register("sDate")}
              type="date"
              className="border w-full  border-[#F0F0F4] text-base rounded-lg py-1 px-4"
            ></input>
          </div>
          <span className="text-white flex flex-wrap content-center">~</span>
          <div className="flex flex-wrap content-center">
            <input
              {...register("eDate")}
              type="date"
              className="border w-full  border-[#F0F0F4] text-base rounded-lg py-1 px-4"
            ></input>
          </div>

          <button className="py-3">
            <img src={searchdate} alt="찾기" className="w-[32px] h-[30px]" />
          </button>
        </div>
      </form>
      <div className="flex flex-wrap justify-end pr-8">
        <CSVLink
          data={datalist}
          headers={exchangeheaders}
          filename={`교환/환불`}
          className={`flex flex-wrap gap-x-1  w-[72px] h-[30px] my-[13px] bg-white text-center items-center rounded-lg`}
        >
          <p className="pl-[15px] text-xs text-[#8B8C8C]">csv</p>
          <img src={excel} className="w-[10px] h-3" />
        </CSVLink>
      </div>
      <Modal
        open={open}
        onClose={() => {
          setOpen(false);
        }}
      >
        <div className="max-w-[360px] w-full absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2   bg-[#F9F8F9] rounded-lg">
          <div className="text-center py-2 h-10 text-white font-bold bg-[#3D3E49] rounded-t-lg  ">
            <div className="flex flex-wrap justify-between">
              <p className="w-[240px] ml-[40px] font-bold text-base">
                배송 상태변경
              </p>
              <img
                src={exit}
                alt="닫기"
                className="w-6 mr-2 cursor-pointer"
                onClick={() => setOpen(false)}
              ></img>
            </div>
          </div>
          <div className="flex flex-wrap content-center">
            <div className="px-10 py-10">
              <div className="grid grid-rows-6 gap-y-4">
                <p className="font-bold">배송 준비</p>
                <div>
                  <input
                    type="radio"
                    name="order"
                    id="pending"
                    onChange={(e) => {
                      if (e.target.checked) {
                        setChange(OrderStatus.Pending);
                      }
                    }}
                  ></input>
                  <span className="ml-4">배송 준비</span>
                </div>
                <p className="font-bold">배송중</p>

                <div>
                  <input
                    type="radio"
                    name="order"
                    id="Drone"
                    value="Drone"
                    onChange={(e) => {
                      if (e.target.checked) {
                        setChange(OrderStatus.Drone);
                      }
                    }}
                  ></input>
                  <span className="ml-4">드론 비행</span>
                </div>
                <div>
                  <input
                    type="radio"
                    name="order"
                    id="Port"
                    value="HandOut"
                    onChange={(e) => {
                      if (e.target.checked) {
                        setChange(OrderStatus.Port);
                      }
                    }}
                  ></input>
                  <span className="ml-4">최종 배송준비</span>
                </div>
                <div>
                  <input
                    type="radio"
                    name="order"
                    id="Stored"
                    value="Stored"
                    onChange={(e) => {
                      if (e.target.checked) {
                        setChange(OrderStatus.Stored);
                      }
                    }}
                  ></input>
                  <span className="ml-4">픽업포트</span>
                </div>
                <div>
                  <input
                    type="radio"
                    name="order"
                    id="PendingDelivered"
                    value="PendingDeliverd"
                    onChange={(e) => {
                      if (e.target.checked) {
                        setChange(OrderStatus.PendingDelivered);
                      }
                    }}
                  ></input>
                  <span className="ml-4">최종 배송</span>
                </div>
                <p className="font-bold">배송 완료</p>
                <div>
                  <input
                    type="radio"
                    name="order"
                    id="check"
                    value="Delivered"
                    onChange={(e) => {
                      if (e.target.checked) {
                        setChange(OrderStatus.Delivered);
                      }
                    }}
                  ></input>
                  <span className="ml-4">배송 완료</span>
                </div>
                <p className="font-bold">환불/교환</p>

                <div>
                  <input
                    type="radio"
                    name="order"
                    id="refunded"
                    checked={change === OrderStatus.Refunded}
                    onChange={(e) => {
                      setChange(OrderStatus.Refunded);
                    }}
                  ></input>
                  <span className="ml-4">환불</span>
                </div>
                <div>
                  <input
                    type="radio"
                    name="order"
                    id="exchange"
                    checked={change === OrderStatus.Exchange}
                    onChange={(e) => {
                      setChange(OrderStatus.Exchange);
                    }}
                  ></input>
                  <span className="ml-4">교환</span>
                </div>
              </div>
            </div>
          </div>
          <button
            className="w-full h-14 bg-[#F0F0F4] rounded-b-lg"
            onClick={() => {
              editOrdersStatusHandler(change);
              setOpen(false);
            }}
          >
            확인
          </button>
        </div>
      </Modal>
    </div>
  );
};
