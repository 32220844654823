import React, { useEffect, useState } from "react";
import { AsideAdminComponent } from "../layout/aside-admin";

import {
  ComponentTabLink,
  ITabLink,
  ITabTitle,
  PageType,
} from "../components/component-tab";
import { Modal } from "@mui/material";
import exit from "../assets/icon/exit.png";
import { pageIndicator } from "../apollo";

export const AdminListRproduct = () => {
  const [value, setValue] = React.useState("폐기");
  const [open, setOpen] = React.useState(false);
  const [change, setChange] = React.useState("");

  const tabList: ITabLink[] = [
    { text: "재고관리", to: "/product" },
    { text: "입출고관리", to: "/bound" },
    { text: "반품재고관리", to: "/rproduct", selected: true },
  ];
  const stitle: ITabTitle = {
    text: "상품관리",
  };

  return (
    <div className="bg-main bg-opacity-50 w-full h-full min-h-screen">
      <div className="w-full mx-auto h-full min-h-screen  bg-white">
        <main id="main">
          <div className="grid grid-cols-[200px_1fr]">
            <AsideAdminComponent />
            <div className="mypages bg-gray-100">
              <div className="orderWrap">
                <div className="h-fix-content relative">
                  <div className={`w-full h-full px-10 py-8`}>
                    <ComponentTabLink
                      tabs={tabList}
                      stitle={stitle}
                      type={PageType.Rproduct}
                    />
                    <div className=" shadow-lg max-w-[1160px]  2xl:max-w-[1623px] h-[800px] h-fix-content overflow-auto bg-white">
                      <table className="align-center w-[1644px] text-sm ">
                        <colgroup>
                          <col width="36"></col>
                          <col width="40"></col>
                          <col width="64"></col>
                          <col width="88"></col>
                          <col width="88"></col>
                          <col width="88"></col>
                          <col width="168"></col>
                          <col width="168"></col>
                          <col width="48"></col>
                          <col width="88"></col>
                          <col width="88"></col>
                          <col width="88"></col>
                          <col width="128"></col>
                          <col width="88"></col>
                          <col width="168"></col>
                          <col width="128"></col>
                          <col width="80"></col>
                        </colgroup>
                        <thead>
                          <tr className=" border-b border-[#F9F8F9] h-8">
                            <th className="text-[#8B8C8C] leading-3">
                              <input
                                type="checkbox"
                                name="allChk"
                                className="w-5 h-5"
                              />
                            </th>
                            <th className="text-[#8B8C8C]">no</th>
                            <th className="text-[#8B8C8C]">상태</th>
                            <th className="text-[#8B8C8C]">주문번호</th>
                            <th className="text-[#8B8C8C]">상품코드</th>
                            <th className="text-[#8B8C8C]">위치코드</th>
                            <th className="text-[#8B8C8C]">상품명</th>
                            <th className="text-[#8B8C8C]">상품명2</th>
                            <th className="text-[#8B8C8C]">수량</th>
                            <th className="text-[#8B8C8C]">결제금액</th>
                            <th className="text-[#8B8C8C]">유통기한</th>
                            <th className="text-[#8B8C8C]">주문자</th>
                            <th className="text-[#8B8C8C]">연락처</th>
                            <th className="text-[#8B8C8C]">수취인</th>
                            <th className="text-[#8B8C8C]">주소</th>
                            <th className="text-[#8B8C8C]">연락처</th>
                            <th className="text-[#8B8C8C]">비고</th>
                          </tr>
                        </thead>
                        <tbody className="">
                          {/* {rproductData &&
                            rproductData.map((test) => (
                              <tr
                                key={`test-${test.no}`}
                                className="border-b border-[#F9F8F9] h-[68px]"
                              >
                                <td className="text-xs  text-center  h-full overflow-hidden">
                                  <input type="checkbox" className="h-5 w-5" />
                                </td>
                                <td className="table-text">{test.no}</td>
                                <td className="table-text py-1">
                                  <button
                                    className={`rounded-lg py-[6px] px-[3px]  w-[48px] h-7 text-white text-xs ${
                                      test.state === "폐기"
                                        ? "bg-[#B42713]"
                                        : "bg-[#555B94]"
                                    }  `}
                                    onClick={() => {
                                      setOpen(true);
                                      setValue(test.state);
                                    }}
                                  >
                                    {test.state}
                                  </button>
                                </td>
                                <td className="table-text">{test.ordernum}</td>
                                <td className="table-text">
                                  {test.productnum}
                                </td>
                                <td className="table-text">{test.location}</td>
                                <td className="table-text">
                                  {test.productname}
                                </td>
                                <td className="table-text">
                                  {test.productname2}
                                </td>
                                <td className="table-text">{test.count}</td>
                                <td className="table-text">{test.pay}</td>
                                <td className="table-text">
                                  {test.expirydate}
                                </td>
                                <td className="table-text">{test.buyer}</td>
                                <td className="table-text">{test.tel}</td>
                                <td className="table-text">{test.receiver}</td>
                                <td className="table-text">{test.address}</td>
                                <td className="table-text">
                                  {test.receivertel}
                                </td>
                                <td className="table-text">{test.etc}</td>
                              </tr>
                            ))} */}
                        </tbody>
                      </table>
                      <Modal
                        open={open}
                        onClose={() => {
                          setOpen(false);
                        }}
                      >
                        <div className="max-w-[360px] w-full absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-[#F9F8F9] rounded-lg">
                          <div className="text-center py-2 h-10 text-white font-bold bg-[#3D3E49] rounded-t-lg  ">
                            <div className="flex flex-wrap justify-between">
                              <p className="w-[240px] ml-[40px] font-bold text-base">
                                반품재고관리
                              </p>
                              <img
                                src={exit}
                                alt="닫기"
                                className="w-6 mr-2 cursor-pointer"
                                onClick={() => setOpen(false)}
                              ></img>
                            </div>
                          </div>
                          <div className="">
                            <div className="px-10 py-10">
                              <div className="grid grid-rows-2 gap-y-4">
                                <div>
                                  <input
                                    type="radio"
                                    name="rproduct"
                                    id="Reaper"
                                    value="리퍼"
                                    onChange={(e) => {
                                      if (e.target.checked) {
                                        setChange("리퍼");
                                      }
                                    }}
                                  ></input>
                                  <span className="ml-4">리퍼</span>
                                </div>

                                <div>
                                  <input
                                    type="radio"
                                    name="rproduct"
                                    id="stop selling"
                                    value="판매중지"
                                    onChange={(e) => {
                                      if (e.target.checked) {
                                        setChange("폐기");
                                      }
                                    }}
                                  ></input>
                                  <span className="ml-4">폐기</span>
                                </div>
                              </div>
                            </div>
                          </div>
                          <button
                            className="w-full h-14 bg-[#F0F0F4] rounded-b-lg"
                            onClick={() => {
                              setValue(change);
                              setOpen(false);
                            }}
                          >
                            확인
                          </button>
                        </div>
                      </Modal>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
};
