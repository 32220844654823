import { gql, useLazyQuery } from "@apollo/client";
import {
  GetProjectQuery,
  GetProjectQueryVariables,
} from "../generated/graphql";

const GET_PROJECT_QUERY = gql`
  query getProject($input: GetProjectInput!) {
    getProject(input: $input) {
      ok
      err
      project {
        id
        name
        note
        startAt
        endAt
        status
      }
    }
  }
`;

export const useGetProject = (
  onCompleted?: (data: GetProjectQuery) => void
) => {
  return useLazyQuery<GetProjectQuery, GetProjectQueryVariables>(
    GET_PROJECT_QUERY,
    {
      onCompleted,
      fetchPolicy: "network-only",
    }
  );
};
