import { ApolloError, gql, useMutation } from "@apollo/client";
import React from "react";
import {
  CrGoodInput,
  CrGoodMutation,
  CrGoodMutationVariables,
} from "../generated/graphql";

export interface ICrGoodFormProps extends CrGoodInput {
  related: number;
}

const CR_GOOD_MUTATION = gql`
  mutation crGood($input: CrGoodInput!) {
    crGood(input: $input) {
      ok
      err
      createdId
    }
  }
`;

export const useCrGood = (
  onCompleted?: (data: CrGoodMutation) => void,
  onError?: (error: ApolloError) => void
) => {
  return useMutation<CrGoodMutation, CrGoodMutationVariables>(
    CR_GOOD_MUTATION,
    {
      onCompleted,
      onError,
    }
  );
};
