import moment from "moment";
import react from "react";
import { ProductAttrTypes, saveJson } from "./enum";

// 제품정보고시
export const kitchenUtensils: ProductAttrTypes[] = [
  { name: "품명 및 모델명", value: "" },
  { name: "재질", value: "" },
  { name: "구성품", value: "" },
  { name: "크기", value: "" },
  { name: "동일모델의 출시년월", value: "" },
  { name: "제조자", value: "" },
  { name: "제조국", value: "상세페이지 참조" },
  { name: "수입식품신고 문구", value: "상세페이지 참조" },
  { name: "품질보증기준", value: "상세페이지 참조" },
  { name: "A/S 책임자와 전화번호", value: "상세페이지 참조" },
];

export const AgriculturalProduct: ProductAttrTypes[] = [
  { name: "품목 또는 명칭", value: "" },
  { name: "용량", value: "" },
  { name: "수량", value: "" },
  { name: "크기", value: "" },
  { name: "생산자 (수입자)", value: "" },
  { name: "원산지", value: "" },
  { name: "제조연월일", value: "상세페이지 참조" },
  { name: "유통기한(소비기한)", value: "상세페이지 참조" },
  { name: "유전자변형농산물 표시", value: "상세페이지 참조" },
  { name: "지리적 표시", value: "상세페이지 참조" },
  { name: "수입식품신고 문구", value: "상세페이지 참조" },
  { name: "상품구성", value: "상세페이지 참조" },
  { name: "보관방법 , 취급방법", value: "상세페이지 참조" },
  { name: "주의사항", value: "상세페이지 참조" },
  { name: "소비자상담 전화번호", value: "상세페이지 참조" },
];

export const seafood: ProductAttrTypes[] = [
  { name: "품목 또는 명칭", value: "" },
  { name: "용량", value: "" },
  { name: "수량", value: "" },
  { name: "크기", value: "" },
  { name: "생산자 (수입자)", value: "" },
  { name: "원산지", value: "" },
  { name: "제조연월일", value: "상세페이지 참조" },
  { name: "유통기한(소비기한)", value: "상세페이지 참조" },
  { name: "유전자변형수산물 표시", value: "상세페이지 참조" },
  { name: "지리적 표시", value: "상세페이지 참조" },
  { name: "수입식품신고 문구", value: "상세페이지 참조" },
  { name: "상품구성", value: "상세페이지 참조" },
  { name: "보관방법 , 취급방법", value: "상세페이지 참조" },
  { name: "주의사항", value: "상세페이지 참조" },
  { name: "소비자상담 전화번호", value: "상세페이지 참조" },
];

export const livestock: ProductAttrTypes[] = [
  { name: "품목 또는 명칭", value: "" },
  { name: "용량", value: "" },
  { name: "수량", value: "" },
  { name: "크기", value: "" },
  { name: "생산자 (수입자)", value: "" },
  { name: "원산지", value: "" },
  { name: "제조연월일", value: "상세페이지 참조" },
  { name: "유통기한(소비기한)", value: "상세페이지 참조" },
  { name: "등급", value: "상세페이지 참조" },
  { name: "이력관리대상 축산물 유무", value: "상세페이지 참조" },
  { name: "수입식품신고 문구", value: "상세페이지 참조" },
  { name: "상품구성", value: "상세페이지 참조" },
  { name: "보관방법 , 취급방법", value: "상세페이지 참조" },
  { name: "주의사항", value: "상세페이지 참조" },
  { name: "소비자상담 전화번호", value: "상세페이지 참조" },
];

export const processedFood: ProductAttrTypes[] = [
  { name: "제품명", value: "" },
  { name: "식품의 유형", value: "" },
  { name: "제조사 (수입자)", value: "" },
  { name: "소재지 (제조국)", value: "" },
  { name: "중량", value: "" },
  { name: "수량", value: "" },
  { name: "제조연월일", value: "상세페이지 참조" },
  { name: "유통기한(소비기한)", value: "상세페이지 참조" },
  { name: "원재료명 및 함량", value: "상세페이지 참조" },
  { name: "영양성분", value: "상세페이지 참조" },
  { name: "유전자변형식품표시", value: "상세페이지 참조" },
  { name: "주의사항", value: "상세페이지 참조" },
  { name: "수입식품신고 문구", value: "상세페이지 참조" },
  { name: "소비자상담 전화번호", value: "상세페이지 참조" },
];
export const healthFunctionalFood: ProductAttrTypes[] = [
  { name: "제품명", value: "" },
  { name: "식품의 유형", value: "" },
  { name: "제조자 (수입사)", value: "" },
  { name: "소재지 (수출국)", value: "" },
  { name: "중량", value: "" },
  { name: "수량", value: "" },
  { name: "원재료명 및 함량", value: "상세페이지 참조" },
  { name: "영양성분", value: "상세페이지 참조" },
  { name: "유전자변형식품표시", value: "상세페이지 참조" },
  { name: "주의사항", value: "상세페이지 참조" },
  { name: "수입식품신고 문구", value: "상세페이지 참조" },
  { name: "소비자상담 전화번호", value: "상세페이지 참조" },
];

export const rental: ProductAttrTypes[] = [
  { name: "제품명", value: "" },
  { name: "소유권 이전 조건", value: "" },
  { name: "소비자책임", value: "" },
  { name: "환불 기준", value: "" },
  { name: "소비자상담 전화번호", value: "상세페이지 참조" },
];
export const householdChemicals: ProductAttrTypes[] = [
  { name: "제품명", value: "" },
  { name: "용도 및 제형", value: "" },
  { name: "제조자 (수입사)", value: "" },
  { name: "소재지 (수출국)", value: "" },
  { name: "중량", value: "" },
  { name: "용량", value: "" },
  { name: "개수", value: "상세페이지 참조" },
  { name: "효과 및 효능", value: "상세페이지 참조" },
  { name: "어린이 보호포장 대상 제품 유무", value: "상세페이지 참조" },
  { name: "화학물질 명칭", value: "상세페이지 참조" },
  { name: "주의사항", value: "상세페이지 참조" },
  {
    name: "안전기준 적합확인 신고번호 또는 안전확인대상 생활화학제품 승인번호",
    value: "상세페이지 참조",
  },
  { name: "소비자상담 전화번호", value: "상세페이지 참조" },
];
export const fashionAccessories: ProductAttrTypes[] = [
  { name: "종류", value: "" },
  { name: "소재", value: "" },
  { name: "치수", value: "" },
  { name: "제조자,수입자", value: "" },
  { name: "제조국", value: "" },
  { name: "취급시 주의사항", value: "상세페이지 참조" },
  { name: "퓸질보증 기준", value: "상세페이지 참조" },
  { name: "A/S 책임자와 전화번호", value: "상세페이지 참조" },
];

export const ChildrenProduct: ProductAttrTypes[] = [
  { name: "제품명", value: "" },
  { name: "KC 인증정보", value: "" },
  { name: "크기 ,중량", value: "" },
  { name: "색상", value: "" },
  { name: "재질", value: "" },
  { name: "사용연령", value: "" },
  { name: "크기·체중의 한계", value: "" },
  { name: "동일 모델의 출시년월", value: "" },
  { name: "제조자,수입자", value: "" },
  { name: "제조국", value: "" },
  { name: "취급시 주의사항", value: "상세페이지 참조" },
  { name: "품질보증기준", value: "상세페이지 참조" },
  { name: "A/s 책임자와 전화번호", value: "상세페이지 참조" },
];

export const OtherGoods: ProductAttrTypes[] = [
  { name: "모델명", value: "" },
  {
    name: "법에 의한 인증·허가",
    value: "",
  },
  { name: "제조국", value: "" },
  { name: "제조자(수입품)", value: "" },
  { name: "A/S 책임자와 전화번호", value: "상세페이지 참조" },
];

export const ProductAttrTypeLists: saveJson[] = [
  { typeName: "상품분류", attrs: [] },
  { typeName: "가공식품", attrs: processedFood },
  { typeName: "농산물", attrs: AgriculturalProduct },
  { typeName: "수산물", attrs: seafood },
  { typeName: "축산물", attrs: livestock },
  { typeName: "건강기능식품", attrs: healthFunctionalFood },
  { typeName: "주방용품", attrs: kitchenUtensils },
  { typeName: "물품대여서비스", attrs: rental },
  { typeName: "생활화학제품", attrs: householdChemicals },
  { typeName: "패션잡화", attrs: fashionAccessories },
  { typeName: "어린이제품", attrs: ChildrenProduct },
  { typeName: "기타", attrs: OtherGoods },
];
export const boundheaders = [
  { label: "상품코드", key: "good.goodUuid" },
  { label: "일자", key: "orderedAt" },
  { label: "상태", key: "type" },

  { label: "위치코드", key: "location" },
  { label: "상품명", key: "good.title" },
  { label: "상품옵션", key: "good.goodOption.name" },
  { label: "설명", key: "desc" },
  { label: "수량", key: "stock" },
  { label: "유통기한", key: "expiryDate" },
  { label: "상품금액", key: "amount" },
  { label: "카테고리", key: "good.category" },
  { label: "공급사", key: "corporation.name" },
];

export const confirmheaders = [
  { label: "주문번호", key: "receipt.receiptNum" },
  // { label: "상태", key: "status" },
  // { label: "QR코드/송장번호", key: "qrnum" },
  // { label: "배송확정", key: "confirmdate" },
  { label: "상품코드", key: "good.goodUuid" },
  { label: "상품명", key: "goodName" },
  { label: "상품옵션", key: "goodOptionName" },
  { label: "수량", key: "quantity" },
  { label: "결제금액", key: "amount" },
  { label: "주문자", key: "receipt.user.name" },
  { label: "수취인", key: "receipt.receiver" },
  { label: "주소", key: "receipt.address" },
  { label: "연락처", key: "phone" },
];

export const deliveryheaders = [
  { label: "no", key: "no" },
  { label: "상태", key: "state" },
  { label: "주문번호", key: "receipt.receiptNum" },
  { label: "QR코드/송장번호", key: "qrnum" },
  { label: "주문일시", key: "orderdate" },
  { label: "상품명", key: "goodName" },
  { label: "상품명2", key: "goodOptionName" },
  { label: "수량", key: "count" },
  { label: "구매자", key: "buyer" },
  { label: "수취인", key: "receiver" },
  { label: "주소", key: "address" },
  { label: "배송메시지", key: "message" },
  { label: "연락처", key: "tel" },
  { label: "상품코드", key: "productnum" },
  { label: "위치코드", key: "location" },
  { label: "무게", key: "weight" },
  { label: "재배송", key: "redelivery" },
];

export const doorheaders = [
  { label: "no", key: "no" },
  { label: "상태", key: "state" },
  { label: "문의유형", key: "type" },
  { label: "제목", key: "title" },
  { label: "문의일", key: "doortime" },
  { label: "고객명", key: "customer" },
  { label: "연락처", key: "tel" },
  { label: "비고", key: "etc" },
];

export const droneheaders = [
  { label: "상태", key: "status" },
  { label: "드론명", key: "name" },
  { label: "위치", key: "location" },
  { label: "배송가능무게", key: "dweight" },
  { label: "점검일", key: "checktime" },
  { label: "등록일", key: "createdAt" },
  // { label: "비행수", key: "flightcount" },
];

export const endheaders = [
  // { label: "no", key: "no" },
  // { label: "상태", key: "state" },
  { label: "주문일시", key: "createdAt" },
  // { label: "상품PLU", key: "good.plu" },
  { label: "상품명", key: "goodName" },
  { label: "옵션명", key: "goodOptionName" },
  { label: "수량", key: "quantity" },
  { label: "금액", key: "amount" },
  { label: "거래처", key: "corporation" },

  { label: "수취인", key: "receipt.receiver" },
  { label: "주소", key: "receipt.address" },
  { label: "연락처", key: "phone" },
];

export const exchangeheaders = [
  { label: "주문번호", key: "receipt.receiptNum" },
  { label: "상태", key: "status" },
  { label: "상태2", key: "exchangeStatus" },
  { label: "QR코드/송장번호", key: "qrnum" },
  { label: "주문일시", key: "createdAt" },
  { label: "상품명", key: "goodName" },
  { label: "상품옵션", key: "goodOptionName" },
  { label: "수량", key: "quantity" },
  { label: "결제금액", key: "amount" },
  { label: "구매자", key: "receipt.user.name" },
  { label: "수취인", key: "receipt.receiver" },
  { label: "주소", key: "receipt.address" },
  { label: "배송메시지", key: "receipt.message" },
  { label: "연락처", key: "phone" },
  { label: "상품코드", key: "good.goodUuid" },
  { label: "위치코드", key: "location" },
  { label: "무게", key: "good.weight" },
  { label: "비고", key: "etc" },
];

export const flightheaders = [
  // { label: "기체번호", key: "drone.serial" },
  // { label: "ID", key: "id" },
  // { label: "상태", key: "status" },
  { label: "드론명", key: "drone.name" },
  { label: "사업분류", key: "project.name" },
  // { label: "관제사", key: "controller" },
  // { label: "이송물품", key: "product" },
  { label: "주문날짜", key: "orderAt" },
  { label: "비행시간", key: "flightTime" },
  { label: "출발지", key: "portTo.name" },
  // { label: "조종사", key: "pilotTo.name" },
  { label: "출발시간", key: "takeOffAt" },
  { label: "도착지", key: "portFrom.name" },
  // { label: "조종사", key: "pilotFrom.name" },
  { label: "도착시간", key: "landAt" },
];

export const headers = [
  // { label: "주문번호", key: "receipt.receiptNum" },
  // { label: "상태", key: "status" },
  { label: "주문일시", key: "createdAt" },
  { label: "상품명", key: "goodName" },
  { label: "옵션명", key: "goodOptionName" },
  // { label: "수량", key: "quantity" },
  // { label: "결제금액", key: "amount" },
  // { label: "구매자", key: "receipt.user.name" },
  { label: "수취인", key: "receipt.receiver" },
  { label: "주소", key: "receipt.address" },
  { label: "배송메시지", key: "receipt.message" },
  { label: "공동현관비밀번호", key: "receipt.pass" },
  { label: "연락처", key: "phone" },
  // { label: "상품번호", key: "goodOption.goodOptionUuid" },
  // { label: "위치코드", key: "location" },
  // { label: "무게", key: "goodOption.weight" },
  // { label: "재배송", key: "redelivery" },
];

export const productheaders = [
  { label: "상품코드", key: "goodUuid" },
  { label: "상태", key: "status" },

  // { label: "위치코드", key: "location" },
  { label: "상품명", key: "title" },
  // { label: "상품옵션", key: "goodOptions.name" },
  { label: "수량", key: "stock" },
  { label: "상품금액", key: "price" },
  // { label: "유통기한", key: "expiryDate" },s
  { label: "무게", key: "weight" },
  { label: "카테고리", key: "category" },
  { label: "공급사", key: "corporation.name" },
];

export const releaseheaders = [
  { label: "주문번호", key: "receipt.receiptNum" },
  // { label: "QR코드/송장번호", key: "" },
  { label: "주문일시", key: "createdAt" },
  { label: "상품명", key: "goodName" },
  { label: "상품옵션", key: "goodOptionName" },
  { label: "수량", key: "quantity" },
  { label: "구매자", key: "receipt.user.name" },
  { label: "수취인", key: "receipt.receiver" },
  { label: "주소", key: "receipt.address" },
  { label: "배송메시지", key: "receipt.message" },
  { label: "연락처", key: "phone" },
  { label: "상품번호", key: "good.goodUuid" },
  { label: "위치코드", key: "location" },
  { label: "무게", key: "good.weight" },
  { label: "재배송", key: "redelivery" },
];

export const rproductheaders = [
  { label: "no", key: "no" },
  { label: "상태", key: "state" },
  { label: "주문번호", key: "ordernum" },
  { label: "상품번호", key: "productnum" },
  { label: "위치코드", key: "location" },
  { label: "상품명", key: "productname" },
  { label: "상품명2", key: "productname2" },
  { label: "수량", key: "count" },
  { label: "결제금액", key: "pay" },
  { label: "유통기한", key: "expirydate" },
  { label: "주문자", key: "buyer" },
  { label: "연락처", key: "tel" },
  { label: "수취인", key: "receiver" },
  { label: "주소", key: "address" },
  { label: "연락처", key: "receivertel" },
  { label: "비고", key: "etc" },
];
export const startheaders = [
  { label: "no", key: "no" },
  { label: "상태", key: "state" },
  { label: "주문번호", key: "ordernum" },
  { label: "QR코드/송장번호", key: "qrnum" },
  { label: "배송시작시간", key: "startdate" },
  { lable: "담당자", key: "manager" },
  { label: "상품명", key: "productname" },
  { label: "상품명2", key: "productname2" },
  { label: "수량", key: "count" },
  { label: "구매자", key: "buyer" },
  { label: "수취인", key: "receiver" },
  { label: "주소", key: "address" },
  { label: "배송메시지", key: "message" },
  { label: "연락처", key: "tel" },
  { label: "상품코드", key: "productnum" },
  { label: "무게", key: "weight" },
  { label: "재배송", key: "redelivery" },
];
