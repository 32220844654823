import { gql, useLazyQuery } from "@apollo/client";
import {
  ListInquiryQuery,
  ListInquiryQueryVariables,
} from "../generated/graphql";

const LIST_INQUIRY_QUERY = gql`
  query listInquiry($input: ListInquiryInput!) {
    listInquiry(input: $input) {
      ok
      err
      totalPages
      totalResults
      row
      inquiries {
        id
        createdAt
        title
        note
        error
        status
        type
        author {
          id
          phone
          username
          name
        }
        manager {
          id
        }
        comments {
          id
        }
        receipt {
          id
        }
      }
    }
  }
`;

export const useListInquiry = (
  onCompleted?: (data: ListInquiryQuery) => void
) => {
  return useLazyQuery<ListInquiryQuery, ListInquiryQueryVariables>(
    LIST_INQUIRY_QUERY,
    {
      onCompleted,
      fetchPolicy: "network-only",
    }
  );
};
