import { ApolloError, gql, useMutation } from "@apollo/client";
import React from "react";
import {
  CrGoodBulkMutation,
  CrGoodBulkMutationVariables,
} from "../generated/graphql";

const CR_GOOD_BULK_MUTATION = gql`
  mutation crGoodBulk($input: CrGoodBulkInput!) {
    crGoodBulk(input: $input) {
      ok
      err
    }
  }
`;

export const useCrGoodBulk = (
  onCompleted?: (data: CrGoodBulkMutation) => void,
  onError?: (error: ApolloError) => void
) => {
  return useMutation<CrGoodBulkMutation, CrGoodBulkMutationVariables>(
    CR_GOOD_BULK_MUTATION,
    {
      onCompleted,
      onError,
    }
  );
};
