import { gql, useLazyQuery } from "@apollo/client";
import { ListDroneQuery, ListDroneQueryVariables } from "../generated/graphql";

const LIST_DRONE_QUERY = gql`
  query listDrone($input: ListDroneInput!) {
    listDrone(input: $input) {
      ok
      err
      totalPages
      totalResults
      row
      drones {
        id
        createdAt
        name
        status
        model
        serial
        flights {
          id
        }
      }
    }
  }
`;

export const useListDrone = (onCompleted?: (data: ListDroneQuery) => void) => {
  return useLazyQuery<ListDroneQuery, ListDroneQueryVariables>(
    LIST_DRONE_QUERY,
    {
      onCompleted,
      fetchPolicy: "network-only",
    }
  );
};
