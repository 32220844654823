import { ApolloError, gql, useMutation } from "@apollo/client";
import React from "react";
import {
  EditCorporationInput,
  EditCorporationMutation,
  EditCorporationMutationVariables,
} from "../generated/graphql";

export interface IEditCorporationFormProps extends EditCorporationInput {}

const EDIT_CORPORATION_QUERY = gql`
  mutation editCorporation($input: EditCorporationInput!) {
    editCorporation(input: $input) {
      ok
      err
    }
  }
`;

export const useEditCorporation = (
  onCompleted?: (data: EditCorporationMutation) => void,
  onError?: (error: ApolloError) => void
) => {
  return useMutation<EditCorporationMutation, EditCorporationMutationVariables>(
    EDIT_CORPORATION_QUERY,
    {
      onCompleted,
      onError,
    }
  );
};
