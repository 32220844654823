import { authTokenVar, isLoggedInVar } from "../apollo";

export const LOCALSTORAGE_TOKEN = "mfc-admin-jwt";

export const getToken = () => localStorage.getItem(LOCALSTORAGE_TOKEN);

export const setToken = (token: string) => {
  localStorage.setItem(LOCALSTORAGE_TOKEN, token);
};

export const signIn = (token: string) => {
  setToken(token);
  authTokenVar(token);
  isLoggedInVar(Boolean(getToken()));
  window.location.href = "/";
};

export const adminSignIn = (token: string) => {
  setToken(token);
  authTokenVar(token);
  isLoggedInVar(Boolean(getToken()));
  window.location.href = "/";
};

export const removeToken = () => {
  localStorage.removeItem(LOCALSTORAGE_TOKEN);
};

export const signOut = () => {
  removeToken();
  authTokenVar("");
  isLoggedInVar(Boolean(getToken()));
  // window.location.href = "/";
};
