import { useEffect, useState } from "react";
import { pageIndicator } from "../apollo";
import { AsideAdminComponent } from "../layout/aside-admin";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {
  ComponentTabLink,
  ITabLink,
  ITabTitle,
  PageType,
} from "../components/component-tab";

import moment from "moment";
import { TableError } from "../components/table-error";
import { PaginationComponent } from "../components/pagination";
import { useListBanner } from "../hooks/useListBanner";
import { useActiveBanner } from "../hooks/useActiveBanner";
import { useDelBanner } from "../hooks/useDelBanner";
import {
  ListBannerInput,
  ActiveBannerInput,
  DelBannerInput,
  ActiveBannerMutation,
  DelBannerMutation,
} from "../generated/graphql";
export interface ISearchFormProps {
  search: string;
}
export const AdminListEvents = () => {
  const location = useLocation();

  const [page, setPage] = useState<number>(1);
  const [search, setSearch] = useState<string>("");
  const navigate = useNavigate();
  const tabList: ITabLink[] = [];
  const stitle: ITabTitle = {
    text: "배너 관리",
  };
  const word = "배너";

  // --- ListBanner --- ///
  const [listBanner, { data, loading, refetch }] = useListBanner();
  const listBannerHandler = (page: number | null, search: string | null) => {
    const input: ListBannerInput = {
      ...(page && { page: +page }),
      ...(search && { search: decodeURI(search) }),
    };
    listBanner({ variables: { input } });
  };

  const setSearchHandler = (search: string) => {
    navigate({
      pathname: "/events",
      search: `${search ? `?search=${search}` : ""}`,
    });
    listBannerHandler(1, search);
  };

  const onActiveCompleted = async (data: ActiveBannerMutation) => {
    const {
      activeBanner: { ok, err },
    } = data;
    if (ok) {
      alert("활성화 되었습니다.");
      await refetch();
    } else {
      alert(err);
    }
  };
  const [activeBanner, { loading: activeBannerLoading }] = useActiveBanner(
    onActiveCompleted
  );
  const activeBannerHandler = (bannerId: number) => {
    if (!activeBannerLoading) {
      const activeBannerInput: ActiveBannerInput = {
        bannerId,
      };
      activeBanner({ variables: { input: activeBannerInput } });
    }
  };

  // --- 배너 삭제 --- //
  const onDelCompleted = async (data: DelBannerMutation) => {
    const {
      delBanner: { ok, err },
    } = data;
    if (ok) {
      alert("배너를 삭제하였습니다");
      await refetch();
    } else {
      alert(err);
    }
  };
  const [delBanner, { loading: delBannerLoading }] = useDelBanner(
    onDelCompleted
  );
  const delBannerHandler = (bannerId: number) => {
    if (delBannerLoading) return;
    if (window.confirm("정말로 삭제하시겠습니까?")) {
      const input: DelBannerInput = {
        bannerId,
      };
      delBanner({ variables: { input } });
    }
  };

  // --- Page ---
  const pageHandler = (page: number) => {
    const search =
      location.search.split("search=").length > 1
        ? location.search.split("search=")[1].split("&")[0]
        : null;
    setPage(page);
    navigate({
      pathname: "/events",
      search: `?page=${page}${search ? `&search=${search}` : ""}`,
    });
    listBannerHandler(page, search);
  };

  useEffect(() => {
    pageIndicator("events");
    setPage(
      location.search.split("page=").length > 1
        ? +location.search.split("page=")[1].split("&")[0]
        : 1
    );
    setSearch(
      location.search.split("search=").length > 1
        ? location.search.split("search=")[1].split("&")[0]
        : ""
    );
    listBannerHandler(page, search);
  }, [search, page]);

  return (
    <div className="bg-main bg-opacity-50 w-full h-full min-h-screen">
      <div className="w-full mx-auto h-full min-h-screen  bg-gray-100">
        <main id="main">
          <div className="grid grid-cols-[200px_1fr] max-w-full">
            <AsideAdminComponent />
            <div className="mypages bg-gray-100 w-full min-w-[1200px] overflow-hidden">
              <div className="orderWrap">
                <div className=" h-fix-content relative">
                  <div className={`w-full h-full px-10 py-8`}>
                    <ComponentTabLink
                      tabs={tabList}
                      stitle={stitle}
                      setSearchs={setSearchHandler}
                      type={PageType.Event}
                    />
                    {/* 여기 */}
                    <div className="shadow-lg h-[800px] h-fix-content w-full overflow-auto bg-white">
                      <table className="align-center min-w-[1160px] 2xl:min-w-[1623px] text-[14px] ">
                        <colgroup>
                          <col width="48"></col>
                          <col></col>
                          <col width="128"></col>
                          <col width="88"></col>
                          <col width="128"></col>
                          <col></col>
                        </colgroup>
                        <thead>
                          <tr className=" border-b border-[#F9F8F9] h-8">
                            <th className="text-[#8B8C8C]">no</th>
                            <th className="text-[#8B8C8C]">제목</th>
                            <th className="text-[#8B8C8C]">날짜</th>
                            <th className="text-[#8B8C8C]">상태</th>
                            <th className="text-[#8B8C8C]">비고</th>
                          </tr>
                        </thead>
                        <tbody className="">
                          {!loading &&
                            data?.listBanner.banners &&
                            data.listBanner.banners.map((banner, index) => (
                              <tr
                                key={`banner-${banner.id}`}
                                className="border-b border-[#F9F8F9] h-[68px]"
                              >
                                <td className="table-text">{index + 1}</td>

                                <td className="text-sm text-left  h-full overflow-hidden">
                                  <Link to={`/events/update/${banner.id}`}>
                                    {banner.title}
                                  </Link>
                                </td>
                                <td className="table-text">
                                  {banner.createdAt &&
                                    moment(banner.createdAt).format("YYYYMMDD")}
                                </td>
                                <td className="table-text">
                                  <button
                                    className={`rounded-lg  w-[48px] h-7 text-white ${
                                      banner.isActive === false
                                        ? "bg-[#234873]"
                                        : "bg-[#ffc2b8]"
                                    }`}
                                    onClick={() => {
                                      if (banner.isActive === false)
                                        activeBannerHandler(banner.id);
                                    }}
                                  >
                                    {banner.isActive === false
                                      ? "비활성화"
                                      : "활성화"}
                                  </button>
                                </td>
                                <td className="table-text">
                                  <button
                                    className="bg-[#CCCCCC] rounded-lg py-[6px]  w-[48px] h-7 text-white"
                                    onClick={() => delBannerHandler(banner.id)}
                                  >
                                    삭제
                                  </button>
                                </td>
                              </tr>
                            ))}
                        </tbody>
                      </table>
                      {loading && (
                        <TableError errorMessage={`정보를 불러오는중입니다`} />
                      )}
                      {!loading &&
                        data?.listBanner.banners &&
                        data.listBanner.banners.length < 1 && (
                          <TableError
                            errorMessage={`${word} 정보가 없습니다`}
                          />
                        )}

                      {!loading &&
                        data?.listBanner.banners &&
                        data.listBanner.banners.length > 0 && (
                          <PaginationComponent
                            page={page}
                            totalPages={Number(data.listBanner.totalPages)}
                            totalResults={Number(data.listBanner.totalResults)}
                            pageHandler={pageHandler}
                          />
                        )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
};
