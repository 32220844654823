import { ApolloError, gql, useMutation } from "@apollo/client";
import {
  CrBoundInput,
  CrBoundMutation,
  CrBoundMutationVariables,
} from "../generated/graphql";
export interface ICrBoundFormProps extends CrBoundInput {}
const CR_BOUND_MUTATION = gql`
  mutation crBound($input: CrBoundInput!) {
    crBound(input: $input) {
      ok
      err
    }
  }
`;

export const useCrBound = (
  onCompleted?: (data: CrBoundMutation) => void,
  onError?: (error: ApolloError) => void
) => {
  return useMutation<CrBoundMutation, CrBoundMutationVariables>(
    CR_BOUND_MUTATION,
    {
      onCompleted,
      onError,
    }
  );
};
