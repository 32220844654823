import { ApolloError, gql, useMutation } from "@apollo/client";
import {
  EditFlightInput,
  EditFlightMutation,
  EditFlightMutationVariables,
} from "../generated/graphql";

export interface IEditFlightFormProps extends EditFlightInput {}

const EDIT_FLIGHT_MUTATION = gql`
  mutation editFlight($input: EditFlightInput!) {
    editFlight(input: $input) {
      ok
      err
    }
  }
`;

export const useEditFlight = (
  onCompleted?: (data: EditFlightMutation) => void,
  onError?: (error: ApolloError) => void
) => {
  return useMutation<EditFlightMutation, EditFlightMutationVariables>(
    EDIT_FLIGHT_MUTATION,
    {
      onCompleted,
      onError,
    }
  );
};
