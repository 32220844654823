import { ApolloError, gql, useMutation } from "@apollo/client";
import {
  DelLinkServiceMutation,
  DelLinkServiceMutationVariables,
} from "../generated/graphql";

const DEL_LINKSERVICE_MUTATION = gql`
  mutation delLinkService($input: DelLinkServiceInput!) {
    delLinkService(input: $input) {
      ok
      err
    }
  }
`;

export const useDelLinkService = (
  onCompleted?: (data: DelLinkServiceMutation) => void,
  onError?: (Error: ApolloError) => void
) => {
  return useMutation<DelLinkServiceMutation, DelLinkServiceMutationVariables>(
    DEL_LINKSERVICE_MUTATION,
    {
      onCompleted,
      onError,
    }
  );
};
