import { useEffect, useState } from "react";
import {
  ListReceiptInput,
  ListReceiptQuery,
  ReceiptStatus,
} from "../generated/graphql";
import { ArrElement } from "../sub-routes/sub-admin-up-product-detail";
import { useListReceipt } from "../hooks/useListReceipt";
import { useLocation, useNavigate } from "react-router-dom";
import { pageIndicator } from "../apollo";
import {
  ComponentTabLink,
  ITabLink,
  ITabTitle,
  PageType,
} from "../components/component-tab";
import { AsideAdminComponent } from "../layout/aside-admin";
import moment from "moment";
import { TableError } from "../components/table-error";
import { PaginationComponent } from "../components/pagination";
import React from "react";
import Collapse from "@mui/material/Collapse";
import { ComponentOrderList } from "../components/component-orderlist";

export type ListReceipt = ArrElement<
  ListReceiptQuery["listReceipt"]["receipts"]
>;

export const AdminListReceipt = () => {
  const [page, setPage] = useState<number>(1);
  const [search, setSearch] = useState<string>("");
  const [checkedLists, setCheckedLists] = useState<ListReceipt[]>([]);
  const [allChecked, setAllChecked] = useState<boolean>(false);
  const [openCollapse, setOpenCollapse] = useState<number>(-1);
  const location = useLocation();
  const navigate = useNavigate();
  const word = "주문내역";
  const tabList: ITabLink[] = [];

  const stitle: ITabTitle = { text: "주문내역" };
  const onCompleted = async (data: ListReceiptQuery) => {
    const {
      listReceipt: { ok, err },
    } = data;
  };
  const [listReceipt, { data, loading, refetch }] = useListReceipt(onCompleted);
  const listReceiptHandler = (
    page: number | null,
    search: string | null,
    status: ReceiptStatus,
    start?: Date | null,
    end?: Date | null
  ) => {
    const input: ListReceiptInput = {
      ...(page && { page: +page }),
      ...(search && { search: decodeURI(search) }),
      ...(status && { status: status }),
      ...(start && { start }),
      ...(end && { end }),
    };
    listReceipt({ variables: { input } });
  };

  // --- Search ---
  const setSearchHandler = (search: string) => {
    navigate({
      pathname: "/receipt",
      search: `${search ? `?search=${search}` : ""}`,
    });

    listReceiptHandler(1, search, ReceiptStatus.Pending);
  };

  const setAllCheckedHandler = () => {
    setAllChecked(!allChecked);
    if (allChecked) setCheckedLists([]);

    if (!allChecked && data?.listReceipt.receipts) {
      setCheckedLists([]);
      for (const receipt of data.listReceipt.receipts) {
        setCheckedLists((checkedList) => [...checkedList, receipt]);
      }
    }
  };

  const setCheckedListHandler = (receiptId: number, data: any) => {
    if (Boolean(checkedLists.find((checkedId) => checkedId.id === receiptId))) {
      setCheckedLists(
        checkedLists.filter((checkedId) => checkedId.id !== receiptId)
      );
    } else {
      setCheckedLists((originalList) => [...originalList, data]);
    }
  };

  // --- Page ---
  const pageHandler = (page: number) => {
    const search =
      location.search.split("search=").length > 1
        ? location.search.split("search=")[1].split("&")[0]
        : null;
    setPage(page);
    navigate({
      pathname: "/receipt",
      search: `?page=${page}${search ? `&search=${search}` : ""}`,
    });
    setPage(page);

    listReceiptHandler(page, search, ReceiptStatus.Pending);
  };

  const openCollapseHandler = (index: number) => {
    if (index === openCollapse) {
      setOpenCollapse(-1);
    } else {
      setOpenCollapse(index);
    }
  };
  // --- Period ---
  const periodHandler = (sDate?: Date, eDate?: Date) => {
    listReceiptHandler(1, search, ReceiptStatus.Pending, sDate, eDate);
  };

  useEffect(() => {
    pageIndicator("receipt");
    setPage(
      location.search.split("page=").length > 1
        ? +location.search.split("page=")[1].split("&")[0]
        : 1
    );
    setSearch(
      location.search.split("search=").length > 1
        ? location.search.split("search=")[1].split("&")[0]
        : ""
    );
    listReceiptHandler(page, search, ReceiptStatus.Pending);
  }, [search, page]);

  return (
    <div className="bg-main bg-opacity-50 w-full h-full min-h-screen">
      <div className="w-full mx-auto h-full min-h-screen bg-gray-100">
        <main id="main">
          <div className="grid grid-cols-[200px_1fr] max-w-full">
            <AsideAdminComponent />
            <div className="mypages bg-gray-100 w-full min-w-[1200px] overflow-hidden">
              <div className="orderWrap">
                <div className="h-fix-content relative">
                  <div className={`w-full h-full px-10 py-8`}>
                    <ComponentTabLink
                      tabs={tabList}
                      stitle={stitle}
                      type={PageType.Receipt}
                      dataList={checkedLists}
                      setSearchs={setSearchHandler}
                      setDateSearch={periodHandler}
                    />
                    {/* 목록 */}
                    <div className=" shadow-lg h-[800px] h-fix-content w-full overflow-auto bg-white">
                      <table className="align-center text-sm ">
                        <thead>
                          <tr className="border-b border-[#F9F8F9] h-8 break-all">
                            <th className="text-[#8B8C8C] leading-3 min-w-[36px]">
                              <input
                                type="checkbox"
                                name="allChk"
                                checked={allChecked}
                                onChange={setAllCheckedHandler}
                                className="h-[20px] w-[20px]"
                              />
                            </th>
                            <th className="text-[#8B8C8C] min-w-[40px]">no</th>
                            {/* <th className="text-[#8B8C8C] min-w-[64px]">
                              상태
                            </th> */}
                            <th className="text-[#8B8C8C] min-w-[88px]">
                              주문번호
                            </th>
                            <th className="text-[#8B8C8C] min-w-[72px]">
                              주문일시
                            </th>
                            <th className="text-[#8B8C8C] min-w-[168px]">
                              상품명
                            </th>

                            <th className="text-[#8B8C8C] min-w-[88px]">
                              결제금액
                            </th>
                            <th className="text-[#8B8C8C] min-w-[88px]">
                              구매자
                            </th>
                            <th className="text-[#8B8C8C] min-w-[88px]">
                              수취인
                            </th>
                            <th className="text-[#8B8C8C] min-w-[168px]">
                              주소
                            </th>
                            <th className="text-[#8B8C8C] min-w-[168px]">
                              배송메세지
                            </th>
                            <th className="text-[#8B8C8C] min-w-[168px]">
                              공동현관
                            </th>
                            <th className="text-[#8B8C8C] min-w-[128px]">
                              연락처
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {!loading &&
                            data?.listReceipt.receipts &&
                            data.listReceipt.receipts.map((receipt, index) => (
                              <React.Fragment key={`{receipt_${receipt.id}`}>
                                <tr
                                  className="border-b border-[#F9F8F9] h-[68px]"
                                  key={`receipt-list-${receipt.id}`}
                                >
                                  <td className="table-text">
                                    <input
                                      type="checkbox"
                                      name={`input ${receipt.id}`}
                                      checked={
                                        checkedLists.find(
                                          (checkedId) =>
                                            checkedId.id === receipt.id
                                        ) !== undefined
                                      }
                                      onChange={(e) => {
                                        setCheckedListHandler(
                                          receipt.id,
                                          receipt
                                        );
                                      }}
                                      className="h-[20px] w-[20px]"
                                    />
                                  </td>
                                  <td className="table-text">
                                    {(page - 1) * 20 + index + 1}
                                  </td>
                                  {/* <td className="table-text">
                                  <button
                                    className="border text-xs border-[#cccccc] rounded-lg py-[4px] px-[3px] w-[48px] h-10 text-[#cccccc] font-bold keep-all"
                                    onClick={() => {
                                      StateHandler(receipt.id);
                                    }}
                                  >
                                    {order.status === "Inspect"
                                      ? "주문 확인"
                                      : ""}
                                  </button>
                                </td> */}
                                  <td className="table-text break-all">
                                    {receipt.receiptNum}
                                  </td>
                                  <td className="table-text font-bold">
                                    {receipt.createdAt &&
                                      moment(receipt.createdAt).format(
                                        "YYMMDD"
                                      )}{" "}
                                    <br />
                                    {receipt.createdAt &&
                                      moment(receipt.createdAt).format(
                                        "H : mm"
                                      )}
                                  </td>
                                  <td className="table-text">
                                    {receipt.orders &&
                                      receipt.orders.length > 0 && (
                                        <button
                                          onClick={() =>
                                            openCollapseHandler(index)
                                          }
                                        >
                                          {receipt.orders.length > 1 &&
                                          receipt.orders[0].goodName
                                            ? receipt.orders[0]?.goodName +
                                              "외 " +
                                              Number(
                                                receipt.orders.length - 1
                                              ) +
                                              "건"
                                            : receipt.orders[0]?.goodName}
                                        </button>
                                      )}
                                  </td>

                                  <td className="table-text">
                                    {receipt.paid_amount}
                                  </td>
                                  <td className="table-text">
                                    {receipt.user?.name}
                                  </td>
                                  <td className="table-text font-bold">
                                    {receipt.receiver}
                                  </td>
                                  <td className="table-text">
                                    {receipt.address}
                                  </td>
                                  <td className="table-text">
                                    {receipt.message}
                                  </td>
                                  <td className="table-text">{receipt.pass}</td>
                                  <td className="table-text">
                                    {receipt.phone}
                                  </td>
                                </tr>
                                <tr>
                                  <td
                                    colSpan={17}
                                    className="w-full removepadding"
                                  >
                                    <Collapse in={openCollapse === index}>
                                      <ComponentOrderList data={receipt} />
                                    </Collapse>
                                  </td>
                                </tr>
                              </React.Fragment>
                            ))}
                        </tbody>
                      </table>

                      {loading && (
                        <TableError errorMessage={`정보를 불러오는중입니다`} />
                      )}
                      {!loading &&
                        data?.listReceipt.receipts &&
                        data.listReceipt.receipts.length < 1 && (
                          <TableError
                            errorMessage={`해당하는 ${word} 정보가 없습니다`}
                          />
                        )}

                      {!loading &&
                        data?.listReceipt.receipts &&
                        data.listReceipt.receipts.length > 0 && (
                          <PaginationComponent
                            page={page}
                            totalPages={Number(data.listReceipt.totalPages)}
                            totalResults={Number(data.listReceipt.totalResults)}
                            pageHandler={pageHandler}
                          />
                        )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
};
