import { ApolloError, gql, useMutation } from "@apollo/client";
import {
  EditBannerMainInput,
  EditBannerMainMutation,
  EditBannerMainMutationVariables,
} from "../generated/graphql";

export interface IEditBannerMainFormProps extends EditBannerMainInput {}
const EDIT_BANNER_MAIN_QUERY = gql`
  mutation editBannerMain($input: EditBannerMainInput!) {
    editBannerMain(input: $input) {
      ok
      err
    }
  }
`;

export const useEditBannerMain = (
  onCompleted?: (data: EditBannerMainMutation) => void,
  onError?: (error: ApolloError) => void
) => {
  return useMutation<EditBannerMainMutation, EditBannerMainMutationVariables>(
    EDIT_BANNER_MAIN_QUERY,
    {
      onCompleted,
      onError,
    }
  );
};
