import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { pageIndicator } from "../apollo";
import { AsideAdminComponent } from "../layout/aside-admin";
import { useForm } from "react-hook-form";
import {
  ComponentTabLink,
  ITabLink,
  ITabTitle,
  PageType,
} from "../components/component-tab";

import exit from "../assets/icon/exit.png";
import React from "react";

import { Modal } from "@mui/material";
import { useListDrone } from "../hooks/useListDrone";
import { useDelDrone } from "../hooks/useDelDrone";
import {
  DroneModelOption,
  DroneModelOptionType,
  DroneStatusOption,
  DroneStatusOptionType,
} from "../constants/enum";
import { FormError2 } from "../components/form-error";
import moment from "moment";
import { TableError } from "../components/table-error";
import { PaginationComponent } from "../components/pagination";
import {
  DroneStatus,
  DroneModel,
  ListDroneInput,
  DelDroneInput,
  EditDroneInput,
  ListDroneQuery,
  DelDroneMutation,
  EditDroneMutation,
} from "../generated/graphql";
import { IEditDroneFormProps, useEditDrone } from "../hooks/useEditDrone";
import { ListFlight } from "./admin-list-flight";
import { ArrElement } from "../sub-routes/sub-admin-up-product-detail";

export type ListDrone = ArrElement<ListDroneQuery["listDrone"]["drones"]>;

export const AdminListDrone = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [page, setPage] = useState<number>(1);
  const [search, setSearch] = useState<string>("");
  const [edit, setEdit] = useState(false);
  const [checkedLists, setCheckedLists] = useState<ListDrone[]>([]);
  const [allChecked, setAllChecked] = useState<boolean>(false);

  const tabList: ITabLink[] = [
    { text: "비행기록", to: "/flight" },
    { text: "드론관리", to: "/drone", selected: true },
    { text: "프로젝트관리", to: "/project" },
    { text: "포트관리", to: "/port" },
    { text: "Pack관리", to: "/pack" },
  ];
  const stitle: ITabTitle = { text: "비행/드론 관리" };
  const word = "드론";

  // --- 데이터 넘기기 ---
  const transModalDataHandler = (drone: ListDrone) => {
    setValue("name", drone.name);
    setValue("model", drone.model);
    setValue("serial", drone.serial);
    setValue("droneId", drone.id);
    setValue("status", drone.status);
  };

  const [listDrone, { data, loading, refetch }] = useListDrone();
  const listDroneHandler = (page: number | null, search: string | null) => {
    const input: ListDroneInput = {
      ...(page && { page: +page }),
      ...(search && { search: decodeURI(search) }),
    };
    listDrone({ variables: { input } });
  };

  // --- 드론 삭제 ---
  const onDelCompleted = async (data: DelDroneMutation) => {
    const {
      delDrone: { ok, err },
    } = data;
    if (ok) {
      // alert("드론삭제");
      await refetch();
    } else {
      alert(err);
    }
  };
  const [delDrone, { loading: delDroneLoading }] = useDelDrone(onDelCompleted);
  const delDroneHandler = (droneId: number) => {
    if (delDroneLoading) return;
    if (
      window.confirm(
        "삭제한 드론은 복구할 수 없습니다. 정말로 삭제하시겠습니까?"
      )
    ) {
      const input: DelDroneInput = {
        droneId,
      };
      delDrone({ variables: { input } });
    }
  };

  // --- 드론 편집 ---
  const {
    register,
    handleSubmit,
    getValues,
    setValue,
    formState: { errors, isValid },
  } = useForm<IEditDroneFormProps>({ mode: "all" });

  const onEditComplete = (data: EditDroneMutation) => {
    const {
      editDrone: { ok, err },
    } = data;
    if (ok) {
      // alert("저장되었습니다");
      window.location.href = "/drone";
    } else {
      alert(err);
    }
  };

  const [editDrone, { loading: editDroneLoading }] = useEditDrone(
    onEditComplete
  );

  const onSubmit = () => {
    if (!editDroneLoading) {
      const { name, model, serial, status, droneId } = getValues();
      const editDroneInput: EditDroneInput = {
        name,
        model,
        serial,
        status,
        droneId: droneId ? +droneId : 1,
      };
      editDrone({ variables: { input: editDroneInput } });
      setEdit(false);
    }
  };

  // --- CheckBox ---
  const setAllCheckedHandler = () => {
    setAllChecked(!allChecked);
    if (allChecked) setCheckedLists([]);

    if (!allChecked && data?.listDrone.drones) {
      setCheckedLists([]);
      for (const drone of data.listDrone.drones) {
        setCheckedLists((checkedList) => [...checkedList, drone]);
      }
    }
  };

  const setCheckedListHandler = (droneId: number, data: any) => {
    if (Boolean(checkedLists.find((checkedId) => checkedId.id === droneId))) {
      setCheckedLists(
        checkedLists.filter((checkedId) => checkedId.id !== droneId)
      );
    } else {
      setCheckedLists((originalList) => [...originalList, data]);
    }
  };
  useEffect(() => {
    if (checkedLists.length === data?.listDrone.drones?.length)
      setAllChecked(true);
    else setAllChecked(false);
  }, [checkedLists]);

  // --- Search ---
  const setSearchHandler = (search: string) => {
    navigate({
      pathname: "/drone",
      search: `${search ? `?search=${search}` : ""}`,
    });
    listDroneHandler(1, search);
  };
  // --- 페이지 ---
  const pageHandler = (page: number) => {
    const search =
      location.search.split("search=").length > 1
        ? location.search.split("search=")[1].split("&")[0]
        : null;
    setPage(page);
    navigate({
      pathname: "/drone",
      search: `?page=${page}${search ? `&search=${search}` : ""}`,
    });
    listDroneHandler(page, search);
  };

  useEffect(() => {
    pageIndicator("flight");
    setPage(
      location.search.split("page=").length > 1
        ? +location.search.split("page=")[1].split("&")[0]
        : 1
    );
    setSearch(
      location.search.split("search=").length > 1
        ? location.search.split("search=")[1].split("&")[0]
        : ""
    );
    listDroneHandler(page, search);
  }, [search, page]);

  return (
    <div className="bg-main bg-opacitiy-50 w-full h-full ">
      <div className="w-full mx-auto h-full min-h-screen bg-gray-100">
        <main id="main">
          <div className="grid grid-cols-[200px_1fr] max-w-full">
            <AsideAdminComponent />
            <div className="mypages bg-gray-100 w-full min-w-[1200px] overflow-hidden">
              <div className="orderWrap">
                <div className=" h-fix-content relative">
                  <div className={`w-full h-full px-10 py-8`}>
                    <ComponentTabLink
                      tabs={tabList}
                      stitle={stitle}
                      type={PageType.Drone}
                      dataList={checkedLists}
                      setSearchs={setSearchHandler}
                    />
                    <div className=" shadow-lg h-[800px] h-fix-content w-full overflow-auto bg-white">
                      <table className="align-center w-[1623px] text-sm ">
                        <thead>
                          <tr className="border-b border-[#F9F8F9] h-8">
                            <th className="text-[#8B8C8C] leading-3 min-w-[36px]">
                              <input
                                type="checkbox"
                                name="allChk"
                                className="h-5 w-5"
                                checked={allChecked}
                                onChange={setAllCheckedHandler}
                              />
                            </th>
                            <th className="text-[#8B8C8C] min-w-[40px]">no</th>
                            <th className="text-[#8B8C8C] min-w-[64px]">
                              상태
                            </th>
                            <th className="text-[#8B8C8C] min-w-[88px]">ID</th>
                            <th className="text-[#8B8C8C] min-w-[112px]">
                              드론명
                            </th>
                            <th className="text-[#8B8C8C] min-w-[112px]">
                              위치
                            </th>
                            <th className="text-[#8B8C8C] min-w-[88px]">
                              배송가능무게
                            </th>
                            <th className="text-[#8B8C8C] min-w-[88px]">
                              점검일
                            </th>
                            <th className="text-[#8B8C8C] min-w-[88px]">
                              등록일
                            </th>
                            {/* <th className="text-[#8B8C8C] min-w-[88px]">
                              비행수
                            </th> */}
                            <th className="text-[#8B8C8C] min-w-[88px]">
                              드론편집
                            </th>
                            <th className="text-[#8B8C8C] min-w-[819px]">
                              비고
                            </th>
                          </tr>
                        </thead>

                        <tbody>
                          {!loading &&
                            data?.listDrone.drones &&
                            data.listDrone.drones.map((drone, index) => (
                              <tr
                                key={`drone-${drone.id}`}
                                className="border-b border-[#F9F8F9] h-[68px] py-2"
                              >
                                <td className="table-text">
                                  <input
                                    type="checkbox"
                                    className="h-5 w-5"
                                    checked={
                                      checkedLists.find(
                                        (checkedId) => checkedId.id === drone.id
                                      ) !== undefined
                                    }
                                    onChange={(e) => {
                                      setCheckedListHandler(drone.id, drone);
                                    }}
                                  />
                                </td>
                                <td className="table-text">
                                  {(page - 1) * 20 + index + 1}
                                </td>
                                <td className="table-text">
                                  <p
                                    className={`border rounded-lg text-xs mx-auto py-[6px] px-[3px] w-[48px] h-7 ${
                                      drone.status === "Pending"
                                        ? "border-[#8B8C8C]  text-[#8B8C8C]"
                                        : drone.status === "Repair"
                                        ? "border-[#FFC700] text-[#FFC700]"
                                        : drone.status === "Operating"
                                        ? "border-[#3D3E49] text-[#3D3E49]"
                                        : drone.status === "Damage"
                                        ? "border-[#B42713] text-[#B42713]"
                                        : "bg-[#B42713] text-white"
                                    }`}
                                  >
                                    {drone.status === "Pending"
                                      ? "대기"
                                      : drone.status === "Damage"
                                      ? "파손"
                                      : drone.status === "Operating"
                                      ? "운용"
                                      : drone.status === "Repair"
                                      ? "수리"
                                      : "테스트"}
                                  </p>
                                </td>
                                <td className="table-text">{drone.id}</td>
                                <td className="table-text font-bold">
                                  {drone.name}
                                </td>
                                <td className="table-text"></td>
                                <td className="table-text"></td>
                                <td className="table-text font-bold"></td>
                                <td className="table-text">
                                  {drone.createdAt &&
                                    moment(drone.createdAt).format("YYMMDD")}
                                  <br />
                                  {drone.createdAt &&
                                    moment(drone.createdAt).format("h : mm")}
                                </td>
                                {/* <td className="table-text"></td> */}
                                <td className="table-text">
                                  <button
                                    className="bg-[#CCCCCC] rounded-lg py-[6px] px-[3px] w-[48px] h-7 text-white"
                                    onClick={() => {
                                      setEdit(true);
                                      transModalDataHandler(drone);
                                    }}
                                  >
                                    드론편집
                                  </button>
                                </td>
                                <td className="table-text">
                                  <button
                                    onClick={() => delDroneHandler(drone.id)}
                                    className="bg-[#CCCCCC] rounded-lg py-[6px] px-[3px] w-[48px] h-7 text-white"
                                  >
                                    드론삭제
                                  </button>
                                </td>
                              </tr>
                            ))}
                        </tbody>
                      </table>
                      {loading && (
                        <TableError errorMessage={`정보를 불러오는중입니다`} />
                      )}
                      {!loading &&
                        data?.listDrone.drones &&
                        data.listDrone.drones.length < 1 && (
                          <TableError
                            errorMessage={`${word} 정보가 없습니다`}
                          />
                        )}

                      {!loading &&
                        data?.listDrone.drones &&
                        data.listDrone.drones.length > 0 && (
                          <PaginationComponent
                            page={page}
                            totalPages={Number(data.listDrone.totalPages)}
                            totalResults={Number(data.listDrone.totalResults)}
                            pageHandler={pageHandler}
                          />
                        )}
                      {/* 드론 편집*/}
                      <Modal
                        open={edit}
                        onClose={() => {
                          setEdit(false);
                        }}
                      >
                        <form onSubmit={handleSubmit(onSubmit)}>
                          <div className="max-w-[360px] w-full absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-[#F9F8F9] rounded-lg">
                            <div className="text-center py-2 h-10 text-white font-bold bg-[#3D3E49] rounded-t-lg  ">
                              <div className="flex flex-wrap justify-between">
                                <p className="w-[240px] ml-[40px] font-bold text-base">
                                  드론 편집
                                </p>
                                <img
                                  src={exit}
                                  alt="닫기"
                                  className="w-6 mr-2 cursor-pointer"
                                  onClick={() => setEdit(false)}
                                ></img>
                              </div>
                            </div>
                            <div className="px-10 py-10">
                              <div className="space-y-5">
                                <div className="space-y-3">
                                  <p className="font-bold text-[#8B8C8C] text-sm">
                                    드론명
                                  </p>
                                  <input
                                    {...register("name", {
                                      required: `드론이름을 입력해주세요`,
                                    })}
                                    type="text"
                                    className="w-full border-[#F0F0F4] rounded-lg py-1 px-4 h-10"
                                  ></input>
                                  {errors.name?.message && (
                                    <FormError2
                                      errorMessage={errors.name.message}
                                    />
                                  )}
                                </div>
                                <div className="space-y-3">
                                  <p className="font-bold text-[#8B8C8C] text-sm">
                                    드론 모델
                                  </p>
                                  <div>
                                    <select
                                      {...register(`model`, {
                                        required: "드론모델을 선택해주세요",
                                      })}
                                      className="w-full border-[#F0F0F4] rounded-lg py-1 px-2"
                                    >
                                      {Object(DroneModelOption).map(
                                        (
                                          model: DroneModelOptionType,
                                          index: number
                                        ) => (
                                          <option
                                            key={`drone-model-option-${index}`}
                                            value={model.value}
                                          >
                                            {model.label}
                                          </option>
                                        )
                                      )}
                                    </select>
                                    {errors.model?.message && (
                                      <FormError2
                                        errorMessage={errors.model.message}
                                      />
                                    )}
                                  </div>
                                </div>
                                <div className="space-y-3">
                                  <p className="font-bold text-[#8B8C8C] text-sm">
                                    드론 상태
                                  </p>
                                  <div>
                                    <select
                                      {...register(`status`, {
                                        required: "드론상태를 선택해주세요",
                                      })}
                                      className="w-full border-[#F0F0F4] rounded-lg py-1 px-2"
                                    >
                                      {Object(DroneStatusOption).map(
                                        (
                                          status: DroneStatusOptionType,
                                          index: number
                                        ) => (
                                          <option
                                            key={`drone-status-option-${index}`}
                                            value={status.value}
                                          >
                                            {status.label}
                                          </option>
                                        )
                                      )}
                                    </select>
                                    {errors.status?.message && (
                                      <FormError2
                                        errorMessage={errors.status.message}
                                      />
                                    )}
                                  </div>
                                </div>
                                <div className="space-y-3">
                                  <p className="font-bold text-[#8B8C8C] text-sm">
                                    serial
                                  </p>
                                  <input
                                    {...register("serial", {
                                      required: `serial을 입력해주세요`,
                                    })}
                                    type="text"
                                    className="w-full border-[#F0F0F4] rounded-lg py-1 px-4 h-10"
                                  ></input>
                                  {errors.serial?.message && (
                                    <FormError2
                                      errorMessage={errors.serial.message}
                                    />
                                  )}
                                </div>
                              </div>
                            </div>
                            <button
                              type="submit"
                              className="w-full h-14 bg-[#F0F0F4] rounded-b-lg"
                            >
                              {loading ? "Loading..." : "확인"}
                            </button>
                          </div>
                        </form>
                      </Modal>
                    </div>
                  </div>
                  {/* footer 부분*/}
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
};
