import { ApolloError, gql, useMutation } from "@apollo/client";
import {
  EditExchangeStatusMutation,
  EditExchangeStatusMutationVariables,
} from "../generated/graphql";

const EDIT_EXCHANGE_STATUS_MUTATION = gql`
  mutation editExchangeStatus($input: EditExchangeStatusInput!) {
    editExchangeStatus(input: $input) {
      ok
      err
    }
  }
`;

export const useEditExchangeStatus = (
  onCompleted?: (data: EditExchangeStatusMutation) => void,
  onError?: (error: ApolloError) => void
) => {
  return useMutation<
    EditExchangeStatusMutation,
    EditExchangeStatusMutationVariables
  >(EDIT_EXCHANGE_STATUS_MUTATION, { onCompleted, onError });
};
