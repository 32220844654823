import { useState } from "react";
import { eDate, sDate } from "../../apollo";
import exit from "../../assets/icon/exit.png";
import refresh from "../../assets/icon/refresh.png";
import { ko } from "date-fns/esm/locale";
import searchdate from "../../assets/icon/searchdate.png";
import { CSVLink } from "react-csv";
import excel from "../../assets/icon/excel.png";
import { productheaders } from "../../constants/datas";
import { Link } from "react-router-dom";
import { Modal } from "@mui/material";
import { useEditGoodsStatus } from "../../hooks/useEditGoodsStatus";
import {
  GoodStatus,
  EditGoodsStatusInput,
  EditGoodsStatusMutation,
} from "../../generated/graphql";

export interface ComponentTabProduct {
  dataList?: any[];
  DataCallback?: () => void;
  refetch?(): void;
}

export const ComponentTabProduct: React.FC<ComponentTabProduct> = ({
  dataList = [],
  DataCallback,
  refetch,
}) => {
  const [startDate, setStartDate] = useState(new Date());
  const [opensale, setOpenSale] = useState(false);
  const [change, setChange] = useState<GoodStatus>(GoodStatus.GoodReady);
  const [endDate, setEndDate] = useState(new Date());
  const datalist = dataList.map((row) => ({
    ...row,
    status:
      row.status === GoodStatus.GoodSales
        ? "판매"
        : row.status === GoodStatus.GoodReady
        ? "판매중"
        : "품절",
  }));
  const dateHandler = (date: Date) => {
    sDate(date);
  };
  const edateHandler = (date: Date) => {
    eDate(date);
  };
  const refetchHandler = () => {
    window.location.href = "/product";
  };
  // --- 상태 변경 한꺼번에 ---
  const onEditCompleted = async (data: EditGoodsStatusMutation) => {
    const {
      editGoodsStatus: { ok, err },
    } = data;
    if (ok && refetch) {
      await refetch();
    } else {
      alert(err);
    }
  };
  const [editGoodsStatus, { loading }] = useEditGoodsStatus(onEditCompleted);
  const editGoodsStatusHandler = (status: GoodStatus) => {
    var goodIds: number[] = [];
    for (const data of dataList) {
      if (data.id) {
        goodIds.push(Number(data.id));
      }
    }
    if (!loading) {
      const editGoodsStatusInput: EditGoodsStatusInput = {
        goodIds,
        status,
      };
      editGoodsStatus({ variables: { input: editGoodsStatusInput } });
    }
  };
  return (
    <div className="flex flex-wrap justify-between">
      <div className="flex flex-wrap">
        <button
          className="py-3 px-4 relative"
          onClick={() => {
            refetchHandler();
          }}
        >
          <img src={refresh} alt="새로고침" className="w-[30px] h-[30px]" />
        </button>
        <button
          onClick={() => {
            setOpenSale(true);
          }}
          className="bg-[#555B94] w-[104px] h-[30px] py-[7px] text-xs text-center rounded-lg my-[13px] text-white"
        >
          상태변경
        </button>
      </div>
      <div className="flex flex-wrap justify-end pr-8">
        <div className="flex flex-wrap gap-x-3">
          <CSVLink
            data={dataList}
            headers={productheaders}
            filename={`재고관리`}
            className={`flex flex-wrap gap-x-1  w-[72px] h-[30px] my-[13px] bg-white text-center items-center rounded-lg`}
          >
            <p className="pl-[15px] text-xs text-[#8B8C8C]">csv</p>
            <img src={excel} className="w-[10px] h-3" />
          </CSVLink>
          <Link
            to={`/product/create`}
            className="bg-transparent bg-[#8b8c8c] w-[104px] h-[30px] py-[7px] text-xs font-bold text-center rounded-lg my-[13px] text-white"
          >
            새 상품 등록
          </Link>
          <Link
            to={`/product/create-bulk`}
            className="bg-transparent bg-[#8b8c8c] w-[104px] h-[30px] py-[7px] text-xs font-bold text-center rounded-lg my-[13px] text-white"
          >
            대용량 상품 등록
          </Link>
        </div>
      </div>
      <Modal
        open={opensale}
        onClose={() => {
          setOpenSale(false);
        }}
      >
        <div className="max-w-[360px] w-full absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-[#F9F8F9] rounded-lg">
          <div className="text-center py-2 h-10 text-white font-bold bg-[#3D3E49] rounded-t-lg  ">
            <div className="flex flex-wrap justify-between">
              <p className="w-[240px] ml-[40px] font-bold text-base">
                판매상태변경
              </p>
              <img
                src={exit}
                alt="닫기"
                className="w-6 mr-2 cursor-pointer"
                onClick={() => setOpenSale(false)}
              ></img>
            </div>
          </div>
          <div className="">
            <div className="px-10 py-10">
              <div className="grid grid-rows-6 gap-y-4">
                <div>
                  <input
                    type="radio"
                    checked={change === GoodStatus.Ready}
                    name="product"
                    id="ready"
                    value={GoodStatus.Ready}
                    onChange={(e) => {
                      setChange(GoodStatus.Ready);
                    }}
                  ></input>
                  <span className="ml-4">준비</span>
                </div>
                <div>
                  <input
                    type="radio"
                    checked={change === GoodStatus.GoodReady}
                    name="product"
                    id="ready"
                    value={GoodStatus.GoodReady}
                    onChange={(e) => {
                      setChange(GoodStatus.GoodReady);
                    }}
                  ></input>
                  <span className="ml-4">커밍순</span>
                </div>

                <div>
                  <input
                    type="radio"
                    checked={change === GoodStatus.GoodSales}
                    name="product"
                    id="sales"
                    value={GoodStatus.GoodSales}
                    onChange={(e) => {
                      setChange(GoodStatus.GoodSales);
                    }}
                  ></input>
                  <span className="ml-4">판매중</span>
                </div>

                <div>
                  <input
                    type="radio"
                    name="product"
                    id="soldOut"
                    checked={change === GoodStatus.SoldOut}
                    value={GoodStatus.SoldOut}
                    onChange={(e) => {
                      setChange(GoodStatus.SoldOut);
                    }}
                  ></input>
                  <span className="ml-4">품절</span>
                </div>
              </div>
            </div>
          </div>
          <button
            className="w-full h-14 bg-[#F0F0F4] rounded-b-lg"
            onClick={() => {
              editGoodsStatusHandler(change);
              setOpenSale(false);
            }}
          >
            확인
          </button>
        </div>
      </Modal>
    </div>
  );
};
