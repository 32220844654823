import { ApolloError, gql, useMutation } from "@apollo/client";
import {
  EditProjectInput,
  EditProjectMutation,
  EditProjectMutationVariables,
} from "../generated/graphql";

export interface IEditProjectFormProps extends EditProjectInput {}
const EDIT_PROJECT_QUERY = gql`
  mutation editProject($input: EditProjectInput!) {
    editProject(input: $input) {
      ok
      err
    }
  }
`;

export const useEditProject = (
  onCompleted?: (data: EditProjectMutation) => void,
  onError?: (error: ApolloError) => void
) => {
  return useMutation<EditProjectMutation, EditProjectMutationVariables>(
    EDIT_PROJECT_QUERY,
    {
      onCompleted,
      onError,
    }
  );
};
