import { gql, useLazyQuery } from "@apollo/client";
import { GetBoundQuery, GetBoundQueryVariables } from "../generated/graphql";

const GET_BOUND_QUERY = gql`
  query getBound($input: GetBoundInput!) {
    getBound(input: $input) {
      ok
      err
      bound {
        id
        createdAt
        desc
        stock
        price
        amount
        type
        status
        orderedAt
        deliveredAt
        expiryDate
        goodOption {
          id
        }
        corporation {
          id
          name
        }
      }
    }
  }
`;

export const useGetBound = (onCompleted?: (data: GetBoundQuery) => void) => {
  return useLazyQuery<GetBoundQuery, GetBoundQueryVariables>(GET_BOUND_QUERY, {
    onCompleted,
    fetchPolicy: "network-only",
  });
};
