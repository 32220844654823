import React, { useEffect, useState } from "react";
import { AsideAdminComponent } from "../layout/aside-admin";
import exit from "../assets/icon/exit.png";
import {
  ComponentTabLink,
  ITabLink,
  ITabTitle,
  PageType,
} from "../components/component-tab";
import { pageIndicator } from "../apollo";
import { Modal } from "@mui/material";
import { useListFlight } from "../hooks/useListFlight";
import { useLocation, useNavigate } from "react-router";
import moment from "moment";
import { useDelFlight } from "../hooks/useDelFlight";
import { Link } from "react-router-dom";
import { TableError } from "../components/table-error";
import { PaginationComponent } from "../components/pagination";
import { useEditFlightStatus } from "../hooks/useEditFlightStatus";
import { ArrElement } from "../sub-routes/sub-admin-up-product-detail";
import {
  DelFlightInput,
  DelFlightMutation,
  EditFlightStatusInput,
  EditFlightStatusMutation,
  FlightStatus,
  ListFlightInput,
  ListFlightQuery,
} from "../generated/graphql";

export type ListFlight = ArrElement<ListFlightQuery["listFlight"]["flights"]>;

export interface updateList {
  no: string;
  state: string;
  id: string;
  flightnum: string;
  dronename: string;
  business: string;
  controller: string;
  product: string;
  flighttime: string;
  startpoint: string;
  pilot: string;
  starttime: string;
  destination: string;
  dpilot: string;
  dtime: string;
  etc: string;
}

export const AdminListFlight = () => {
  const [openstate, setOpenState] = React.useState(false);
  const [change, setChange] = useState<FlightStatus>(FlightStatus.Ready);
  const [stateid, setStateId] = useState<number>(0);
  const onStatusChange = async (data: EditFlightStatusMutation) => {
    const {
      editFlightStatus: { ok, err },
    } = data;
    if (ok) {
      // alert("상태가 변경되었습니다.");
      await refetch();
    } else {
      alert(err);
    }
  };
  const [editFlightStatus] = useEditFlightStatus(onStatusChange);
  const upFlightStatusHandler = (flightId: number, status: FlightStatus) => {
    const input: EditFlightStatusInput = {
      flightId: flightId,
      status: status,
    };
    editFlightStatus({ variables: { input } });
  };
  const [page, setPage] = useState<number>(1);
  const [search, setSearch] = useState<string>("");
  const [checkedLists, setCheckedLists] = useState<ListFlight[]>([]);
  const [allChecked, setAllChecked] = useState<boolean>(false);
  const location = useLocation();
  const koreaTime = 9 * 60 * 60 * 1000;
  const navigate = useNavigate();
  const tabList: ITabLink[] = [
    { text: "비행기록", to: "/flight", selected: true },
    { text: "드론관리", to: "/drone" },
    { text: "프로젝트관리", to: "/project" },
    { text: "포트관리", to: "/port" },
    { text: "Pack관리", to: "/pack" },
  ];
  const stitle: ITabTitle = {
    text: "비행/드론 관리",
  };
  const word = "비행 기록";
  useEffect(() => {
    pageIndicator("flight");
  });

  // --- 리스트 ---
  const [listFlight, { data, loading, refetch }] = useListFlight();
  const listFlightHandler = (
    page: number | null,
    search: string | null,
    start?: Date | null,
    end?: Date | null
  ) => {
    const input: ListFlightInput = {
      ...(page && { page: +page }),
      ...(search && { search: decodeURI(search) }),
      ...(start && { start }),
      ...(end && { end }),
    };
    listFlight({ variables: { input } });
  };

  // --- 비행기록 삭제 ---
  const onDelCompleted = async (data: DelFlightMutation) => {
    const {
      delFlight: { ok, err },
    } = data;
    if (ok) {
      alert("비행기록을 삭제");
      await refetch();
    } else {
      alert(err);
    }
  };
  const [delFlight, { loading: delFlightLoading }] = useDelFlight(
    onDelCompleted
  );
  const delFlightHandler = (flightId: number) => {
    if (delFlightLoading) return;
    if (
      window.confirm(
        "삭제한 비행기록은 복구할 수 없습니다. 정말로 삭제하시겠습니까?"
      )
    ) {
      const input: DelFlightInput = {
        flightId,
      };
      delFlight({ variables: { input } });
    }
  };
  // --- CheckBox ---
  const setAllCheckedHandler = () => {
    setAllChecked(!allChecked);
    if (allChecked) setCheckedLists([]);

    if (!allChecked && data?.listFlight.flights) {
      setCheckedLists([]);
      for (const flight of data.listFlight.flights) {
        setCheckedLists((checkedList) => [...checkedList, flight]);
      }
    }
  };

  const setCheckedListHandler = (flightId: number, data: any) => {
    if (Boolean(checkedLists.find((checkedId) => checkedId.id === flightId))) {
      setCheckedLists(
        checkedLists.filter((checkedId) => checkedId.id !== flightId)
      );
    } else {
      setCheckedLists((originalList) => [...originalList, data]);
    }
  };

  useEffect(() => {
    if (checkedLists.length === data?.listFlight.flights?.length)
      setAllChecked(true);
    else setAllChecked(false);
  }, [checkedLists]);

  // --- Search ---
  const setSearchHandler = (search: string) => {
    navigate({
      pathname: "/flight",
      search: `${search ? `?search=${search}` : ""}`,
    });
    listFlightHandler(1, search);
  };
  // --- 페이지 ---
  const pageHandler = (page: number) => {
    const search =
      location.search.split("search=").length > 1
        ? location.search.split("search=")[1].split("&")[0]
        : null;
    setPage(page);
    navigate({
      pathname: "/flight",
      search: `?page=${page}${search ? `&search=${search}` : ""}`,
    });
    listFlightHandler(page, search);
  };
  // --- Period ---
  const periodHandler = (sDate?: Date, eDate?: Date) => {
    listFlightHandler(1, search, sDate, eDate);
  };
  useEffect(() => {
    pageIndicator("flight");
    setPage(
      location.search.split("page=").length > 1
        ? +location.search.split("page=")[1].split("&")[0]
        : 1
    );
    setSearch(
      location.search.split("search=").length > 1
        ? location.search.split("search=")[1].split("&")[0]
        : ""
    );
    listFlightHandler(page, search);
  }, [search, page]);

  return (
    <div className="bg-main bg-opacity-50 w-full h-full min-h-screen">
      <div className="w-full mx-auto h-full min-h-screen  bg-gray-100">
        <main id="main">
          <div className="grid grid-cols-[200px_1fr] max-w-full">
            <AsideAdminComponent />
            <div className="mypages bg-gray-100 w-full min-w-[1200px] overflow-hidden">
              <div className="orderWrap">
                <div className="h-fix-content relative">
                  <div className={`w-full h-full px-10 py-8`}>
                    <ComponentTabLink
                      tabs={tabList}
                      stitle={stitle}
                      type={PageType.Flight}
                      dataList={checkedLists}
                      setSearchs={setSearchHandler}
                      setDateSearch={periodHandler}
                    />
                    <div className=" shadow-lg h-[800px] h-fix-content w-full overflow-auto bg-white">
                      <table className="align-center w-[1623px] text-sm ">
                        <thead>
                          <tr className="border-b border-[#F9F8F9] h-8">
                            <th className="text-[#8B8C8C] leading-3 min-w-[36px]">
                              <input
                                type="checkbox"
                                name="allChk"
                                checked={allChecked}
                                onChange={setAllCheckedHandler}
                                className="w-5 h-5"
                              />
                            </th>
                            <th className="text-[#8B8C8C] min-w-[40px]">no</th>
                            <th className="text-[#8B8C8C] min-w-[64px]">
                              상태
                            </th>
                            <th className="text-[#8B8C8C] min-w-[88px]">ID</th>
                            <th className="text-[#8B8C8C] min-w-[88px]">
                              기체번호
                            </th>
                            <th className="text-[#8B8C8C] min-w-[112px]">
                              드론명
                            </th>
                            <th className="text-[#8B8C8C] min-w-[128px]">
                              사업분류
                            </th>
                            <th className="text-[#8B8C8C] min-w-[88px]">
                              관제사
                            </th>
                            <th className="text-[#8B8C8C] min-w-[128px]">
                              이송물품
                            </th>
                            <th className="text-[#8B8C8C] min-w-[88px]">
                              비행시간
                            </th>
                            <th className="text-[#8B8C8C] min-w-[88px]">
                              출발지
                            </th>
                            <th className="text-[#8B8C8C] min-w-[88px]">
                              조종사
                            </th>
                            <th className="text-[#8B8C8C] min-w-[88px]">
                              주문날짜
                            </th>
                            <th className="text-[#8B8C8C] min-w-[88px]">
                              출발시간
                            </th>
                            <th className="text-[#8B8C8C] min-w-[88px]">
                              도착지
                            </th>
                            <th className="text-[#8B8C8C] min-w-[88px]">
                              조종사
                            </th>
                            <th className="text-[#8B8C8C] min-w-[88px]">
                              도착시간
                            </th>
                            <th className="text-[#8B8C8C] min-w-[235px]">
                              비고
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {!loading &&
                            data?.listFlight.flights &&
                            data.listFlight.flights.map((flight, index) => (
                              <tr
                                className="border-b border-[#F9F8F9] h-[68px]"
                                key={`flight-${flight.id}`}
                              >
                                <td className="table-text">
                                  <input
                                    type="checkbox"
                                    className="w-5 h-5 "
                                    checked={
                                      checkedLists.find(
                                        (checkedId) =>
                                          checkedId.id === flight.id
                                      ) !== undefined
                                    }
                                    onChange={(e) => {
                                      setCheckedListHandler(flight.id, flight);
                                    }}
                                  />
                                </td>
                                <td className="table-text">
                                  {(page - 1) * 20 + index + 1}
                                </td>
                                <td className="table-text">
                                  <button
                                    onClick={() => {
                                      setOpenState(true);
                                      setStateId(flight.id);
                                    }}
                                    className={`rounded-lg py-[6px]  w-[48px] h-7 text-white ${
                                      flight.status === "Ready"
                                        ? "bg-[#CCCCCC]"
                                        : flight.status === "TakeOff"
                                        ? "bg-[#555B94]"
                                        : flight.status === "Land"
                                        ? "bg-[#3D3E49]"
                                        : flight.status === "Cancel"
                                        ? "bg-[#B42713]"
                                        : "bg-[#FFC700]"
                                    }`}
                                  >
                                    {flight.status === "Ready"
                                      ? "비행대기"
                                      : flight.status === "TakeOff"
                                      ? "비행중"
                                      : flight.status === "Land"
                                      ? "비행완료"
                                      : flight.status === "Cancel"
                                      ? "취소"
                                      : "정지"}
                                  </button>
                                </td>
                                <td className="table-text">{flight.id}</td>
                                <td className="table-text">
                                  {flight.drone?.serial}
                                </td>
                                <td className="table-text font-bold">
                                  {flight.drone?.name}
                                </td>
                                <td className="table-text">
                                  {flight.project?.name}
                                </td>
                                <td className="table-text"></td>
                                <td className="table-text overflow-hidden">
                                  {/* {flight.pack?.id} */}
                                  {/* {flight.pack?.orders?.map((order) => (
                                    <p>{order.goodOption?.name}</p>
                                  ))} */}
                                  {flight.pack?.orders?.map((order, index) => (
                                    <p key={`pack-${index}}`}>
                                      {order.goodOption?.name}
                                    </p>
                                  ))}
                                </td>
                                <td className="table-text">
                                  {flight.flightTime}
                                </td>
                                <td className="table-text">
                                  {flight.portTo?.name}
                                </td>
                                <td className="table-text">
                                  {flight.pilotTo?.name}
                                </td>
                                <td className="table-text font-bold">
                                  {" "}
                                  {flight.orderAt &&
                                    moment(flight.orderAt).format(
                                      "YYMMDD"
                                    )}{" "}
                                  <br />
                                  {flight.orderAt &&
                                    moment(flight.orderAt).format("HH : mm")}
                                </td>
                                <td className="table-text font-bold">
                                  {flight.takeOffAt &&
                                    moment(flight.takeOffAt).format(
                                      "YYMMDD"
                                    )}{" "}
                                  <br />
                                  {flight.takeOffAt &&
                                    moment(flight.takeOffAt).format("HH : mm")}
                                </td>
                                <td className="table-text">
                                  {flight.portFrom?.name}
                                </td>
                                <td className="table-text">
                                  {flight.pilotFrom?.name}
                                </td>
                                <td className="table-text font-bold">
                                  {flight.landAt &&
                                    moment(flight.landAt).format("YYMMDD")}{" "}
                                  <br />
                                  {flight.landAt &&
                                    moment(flight.landAt).format("HH : mm")}
                                </td>

                                <td className="table-text">
                                  <Link
                                    to={`/flight/update/${flight.id}`}
                                    className="inline-block border  border-[#8B8C8C]
                                       rounded-lg py-[6px] px-[3px] w-[48px] h-7 text-xs text-[#8B8C8C]"
                                  >
                                    편집
                                  </Link>
                                  <button
                                    onClick={() => delFlightHandler(flight.id)}
                                    className="bg-[#CCCCCC] rounded-lg py-[6px]  w-[48px] h-7 text-white ml-4"
                                  >
                                    삭제
                                  </button>
                                </td>
                              </tr>
                            ))}
                        </tbody>
                      </table>
                      {loading && (
                        <TableError errorMessage={`정보를 불러오는중입니다`} />
                      )}
                      {!loading &&
                        data?.listFlight.flights &&
                        data.listFlight.flights.length < 1 && (
                          <TableError
                            errorMessage={`${word} 정보가 없습니다`}
                          />
                        )}

                      {!loading &&
                        data?.listFlight.flights &&
                        data.listFlight.flights.length > 0 && (
                          <PaginationComponent
                            page={page}
                            totalPages={Number(data.listFlight.totalPages)}
                            totalResults={Number(data.listFlight.totalResults)}
                            pageHandler={pageHandler}
                          />
                        )}
                      <Modal
                        open={openstate}
                        onClose={() => {
                          setOpenState(false);
                        }}
                      >
                        <div className="max-w-[360px] w-full absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-[#F9F8F9] rounded-lg">
                          <div className="text-center py-2 h-10 text-white font-bold bg-[#3D3E49] rounded-t-lg  ">
                            <div className="flex flex-wrap justify-between">
                              <p className="w-[240px] ml-[40px] font-bold text-base">
                                비행상태
                              </p>
                              <img
                                src={exit}
                                alt="닫기"
                                className="w-6 mr-2 cursor-pointer"
                                onClick={() => setOpenState(false)}
                              ></img>
                            </div>
                          </div>
                          <div className="px-10 py-10">
                            <div className="grid grid-rows-3 gap-y-4">
                              <div>
                                <input
                                  type="radio"
                                  name="flight"
                                  id="ready"
                                  checked={change === FlightStatus.Ready}
                                  onChange={() => {
                                    setChange(FlightStatus.Ready);
                                  }}
                                ></input>
                                <span className="ml-4">비행대기</span>
                              </div>
                              <div>
                                <input
                                  type="radio"
                                  name="flight"
                                  id="start"
                                  checked={change === FlightStatus.TakeOff}
                                  onChange={(e) => {
                                    setChange(FlightStatus.TakeOff);
                                  }}
                                ></input>
                                <span className="ml-4">비행중</span>
                              </div>
                              <div>
                                <input
                                  type="radio"
                                  name="flight"
                                  id="end"
                                  checked={change === FlightStatus.Land}
                                  onChange={(e) => {
                                    setChange(FlightStatus.Land);
                                  }}
                                ></input>
                                <span className="ml-4">비행완료</span>
                              </div>
                            </div>
                          </div>
                          <button
                            className="w-full h-14 bg-[#F0F0F4] rounded-b-lg"
                            onClick={() => {
                              upFlightStatusHandler(stateid, change);
                              setOpenState(false);
                            }}
                          >
                            확인
                          </button>
                        </div>
                      </Modal>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
};
