import { gql, useLazyQuery } from "@apollo/client";
import React from "react";
import {
  GetCorporationQuery,
  GetCorporationQueryVariables,
} from "../generated/graphql";

const GET_CORPORATION_QUERY = gql`
  query getCorporation($input: GetCorporationInput!) {
    getCorporation(input: $input) {
      ok
      err
      corporation {
        id
        createdAt
        name
        managerName
        phone
        address
        ceoName
        amount
        service
        regionKeyWord
        linkService {
          id
          name
        }
      }
    }
  }
`;

export const useGetCorporation = (
  onCompleted?: (data: GetCorporationQuery) => void
) => {
  return useLazyQuery<GetCorporationQuery, GetCorporationQueryVariables>(
    GET_CORPORATION_QUERY,
    {
      onCompleted,
      fetchPolicy: "network-only",
    }
  );
};
