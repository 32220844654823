import { Modal } from "@mui/material";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import { pageIndicator } from "../apollo";
import exit from "../assets/icon/exit.png";
import {
  ComponentTabLink,
  ITabLink,
  ITabTitle,
  PageType,
} from "../components/component-tab";
import { FormError2 } from "../components/form-error";
import { PortStatusOption, PortStatusOptionType } from "../constants/enum";
import { useDelPort } from "../hooks/useDelPort";
import { IEditPortFormProps, useEditPort } from "../hooks/useEditPort";
import { useListPort } from "../hooks/useListPort";
import { AsideAdminComponent } from "../layout/aside-admin";
import { useListProjectSelect } from "../hooks/useListProjectSelect";
import { useGetPort } from "../hooks/useGetPort";
import { TableError } from "../components/table-error";
import { PaginationComponent } from "../components/pagination";
import { ArrElement } from "../sub-routes/sub-admin-up-product-detail";
import {
  DelPortInput,
  DelPortMutation,
  EditPortInput,
  EditPortMutation,
  GetPortQuery,
  ListPortInput,
  ListPortQuery,
} from "../generated/graphql";

export type ListPort = ArrElement<ListPortQuery["listPort"]["ports"]>;

export const AdminListPort = () => {
  const [page, setPage] = useState<number>(1);
  const [search, setSearch] = useState<string>("");
  const [edit, setEdit] = useState(false);
  const [checkedLists, setCheckedLists] = useState<ListPort[]>([]);
  const [allChecked, setAllChecked] = useState<boolean>(false);
  const location = useLocation();
  const tabList: ITabLink[] = [
    { text: "비행기록", to: "/flight" },
    { text: "드론관리", to: "/drone" },
    { text: "프로젝트관리", to: "/project" },
    { text: "포트관리", to: "/port", selected: true },
    { text: "Pack관리", to: "/pack" },
  ];

  const stitle: ITabTitle = { text: "비행/드론 관리" };
  const navigate = useNavigate();
  const word = "포트";
  const [listProjectSelect, { data: projects }] = useListProjectSelect();
  const onCompleted = (data: ListPortQuery) => {};
  const [listPort, { data, loading, refetch }] = useListPort(onCompleted);
  const listPortHandler = (page: number | null, search: string | null) => {
    const input: ListPortInput = {
      ...(page && { page: +page }),
      ...(search && { search: decodeURI(search) }),
    };
    listPort({ variables: { input } });
  };
  // --- 데이터 넘기기 ---
  const transModalDataHandler = (port: ListPort) => {
    setValue("name", port.name);
    setValue("status", port.status);
    setValue("projectId", port?.project?.id);
    setValue("portId", port.id);
  };
  // --- 포트 삭제 ---
  const onDelCompleted = async (data: DelPortMutation) => {
    const {
      delPort: { ok, err },
    } = data;
    if (ok) {
      alert("포트를 삭제");
      await refetch();
    } else {
      alert(err);
    }
  };
  const [delPort, { loading: delPortLoading }] = useDelPort(onDelCompleted);
  const delPortHandler = (portId: number) => {
    if (delPortLoading) return;
    if (
      window.confirm(
        "삭제한 포트는 복구할 수 없습니다. 정말로 삭제하시겠습니까?"
      )
    ) {
      const input: DelPortInput = {
        portId,
      };
      delPort({ variables: { input } });
    }
  };

  // --- 포트 편집 ---
  const {
    register,
    handleSubmit,
    getValues,
    setValue,
    formState: { errors, isValid },
  } = useForm<IEditPortFormProps>({ mode: "all" });

  const onGetComplete = (data: GetPortQuery) => {
    //   const {
    //     getPort: { ok, err },
    //   } = data;
    //   if (!ok) {
    //     alert("잘못된 접근");
    //     window.location.href = "/port";
    //     return;
    //   }
    //   setValue("name", `${data.getPort.port?.name}`);
    //   setValue(
    //     "status",
    //     data.getPort.port?.status ? data.getPort.port.status : PortStatus.Pending
    //   );
    //   setValue("projectId", Number(`${data.getPort.port?.project?.id}`));
  };

  const [getPort, { data: getData, loading: getLoading }] = useGetPort(
    onGetComplete
  );

  // const getPortHandler = (portId: number) => {
  //   const getPortInput: GetPortInput = {
  //     portId,
  //   };
  //   getPort({ variables: { input: getPortInput } });
  // };
  const onEditComplete = (data: EditPortMutation) => {
    const {
      editPort: { ok, err },
    } = data;
    if (ok) {
      alert("저장되었습니다");
      window.location.href = "/port";
    } else {
      alert(err);
    }
  };

  const [editPort, { loading: editPortLoading }] = useEditPort(onEditComplete);

  const onSubmit = () => {
    if (!editPortLoading) {
      const { name, status, projectId, GPS, portId } = getValues();
      const editPortInput: EditPortInput = {
        name,
        status,
        projectId: projectId ? +projectId : 1,
        GPS,
        portId: portId ? +portId : 1,
      };
      editPort({ variables: { input: editPortInput } });
      setEdit(false);
    }
  };

  // --- Search ---
  const setSearchHandler = (search: string) => {
    navigate({
      pathname: "/port",
      search: `${search ? `?search=${search}` : ""}`,
    });
    listPortHandler(1, search);
  };

  // --- CheckBox ---
  const setAllCheckedHandler = () => {
    setAllChecked(!allChecked);
    if (allChecked) setCheckedLists([]);

    if (!allChecked && data?.listPort.ports) {
      setCheckedLists([]);
      for (const port of data.listPort.ports) {
        setCheckedLists((checkedList) => [...checkedList, port]);
      }
    }
  };

  const setCheckedListHandler = (portId: number, data: any) => {
    if (Boolean(checkedLists.find((checkedId) => checkedId.id === portId))) {
      setCheckedLists(
        checkedLists.filter((checkedId) => checkedId.id !== portId)
      );
    } else {
      setCheckedLists((originalList) => [...originalList, data]);
    }
  };
  useEffect(() => {
    if (checkedLists.length === data?.listPort.ports?.length)
      setAllChecked(true);
    else setAllChecked(false);
  }, [checkedLists]);

  // --- 페이지 ---
  const pageHandler = (page: number) => {
    const search =
      location.search.split("search=").length > 1
        ? location.search.split("search=")[1].split("&")[0]
        : null;
    setPage(page);
    navigate({
      pathname: "/port",
      search: `?page=${page}${search ? `&search=${search}` : ""}`,
    });
    listPortHandler(page, search);
  };

  useEffect(() => {
    pageIndicator("flight");
    setPage(
      location.search.split("page=").length > 1
        ? +location.search.split("page=")[1].split("&")[0]
        : 1
    );
    setSearch(
      location.search.split("search=").length > 1
        ? location.search.split("search=")[1].split("&")[0]
        : ""
    );

    listPortHandler(page, search);
    listProjectSelect();
  }, [search, page]);

  return (
    <div className="bg-main bg-opacity-50 w-full h-full min-h-screen">
      <div className="w-full mx-auto h-full min-h-screen bg-gray-100">
        <main id="main">
          <div className="grid grid-cols-[200px_1fr] max-w-full">
            <AsideAdminComponent />
            <div className="mypages bg-gray-100 w-full min-w-[1200px] overflow-hidden">
              <div className="orderWrap">
                <div className="h-fix-content relative">
                  <div className={`w-full h-full px-10 py-8`}>
                    <ComponentTabLink
                      tabs={tabList}
                      stitle={stitle}
                      type={PageType.Port}
                      setSearchs={setSearchHandler}
                    />
                    {/* 목록 */}
                    <div className=" shadow-lg h-[800px] h-fix-content w-full overflow-auto bg-white">
                      <table
                        className="align-center w-[1623px] text-sm "
                        id="table"
                      >
                        <colgroup>
                          <col width="36" />
                          <col width="40" />
                          <col width="88" />
                          <col width="88" />
                          <col width="88" />
                          <col width="168" />
                          <col width="128" />
                          <col />
                          <col />
                        </colgroup>
                        <thead>
                          <tr className="border-b border-[#F9F8F9] h-8">
                            <th className="text-[#8B8C8C] leading-3 ">
                              <input
                                type="checkbox"
                                name="allChk"
                                className="h-[20px] w-[20px]"
                                checked={allChecked}
                                onChange={setAllCheckedHandler}
                              />
                            </th>
                            <th className="text-[#8B8C8C] ">no</th>
                            <th className="text-[#8B8C8C] ">이름</th>
                            <th className="text-[#8B8C8C] ">포트상태</th>
                            <th className="text-[#8B8C8C] ">프로젝트</th>
                            <th className="text-[#8B8C8C] ">주소</th>
                            <th className="text-[#8B8C8C] ">기능</th>
                            <th className="text-[#8B8C8C] ">비고</th>
                          </tr>
                        </thead>
                        <tbody>
                          {!loading &&
                            data?.listPort.ports &&
                            data.listPort.ports.map((port, index) => (
                              <tr
                                className="border-b border-[#F9F8F9] h-[68px]"
                                key={`port-${port.id}`}
                              >
                                <td className="table-text">
                                  <input
                                    type="checkbox"
                                    className="h-[20px] w-[20px]"
                                    checked={
                                      checkedLists.find(
                                        (checkedId) => checkedId.id === port.id
                                      ) !== undefined
                                    }
                                    onChange={(e) => {
                                      setCheckedListHandler(port.id, port);
                                    }}
                                  />
                                </td>
                                <td className="table-text">{index + 1}</td>
                                <td className="table-text">{port.name}</td>
                                <td className="table-text">
                                  <p
                                    className={`rounded-lg mx-auto py-[6px] px-[3px]  w-[48px] h-7 text-white text-xs ${
                                      port.status === "Operating"
                                        ? "bg-[#555B94]"
                                        : port.status === "Pending"
                                        ? "bg-[#CCCCCC]"
                                        : "text-[#B42713] border border-[#B42713]"
                                    }  `}
                                  >
                                    {port.status === "Operating"
                                      ? "운용"
                                      : port.status === "Pending"
                                      ? "대기"
                                      : "수리"}
                                  </p>
                                </td>
                                <td className="table-text">
                                  {port.project?.name}
                                </td>
                                <td className="table-text">{port.GPS?.lng}</td>
                                <td className="table-text">
                                  <button
                                    className="bg-[#CCCCCC] rounded-lg py-[6px]  w-[48px] h-7 text-white"
                                    onClick={() => {
                                      navigate(`/port/update/${port.id}`);
                                      // setEdit(true);
                                      // transModalDataHandler(port);
                                    }}
                                  >
                                    포트편집
                                  </button>
                                  <button
                                    className="bg-[#CCCCCC] rounded-lg py-[6px]  w-[48px] h-7 text-white ml-4"
                                    onClick={() => delPortHandler(port.id)}
                                  >
                                    삭제
                                  </button>
                                </td>
                                <td className="table-text">비고</td>
                              </tr>
                            ))}
                        </tbody>
                      </table>

                      {loading && (
                        <TableError errorMessage={`정보를 불러오는중입니다`} />
                      )}
                      {!loading &&
                        data?.listPort.ports &&
                        data.listPort.ports.length < 1 && (
                          <TableError
                            errorMessage={`${word} 정보가 없습니다`}
                          />
                        )}

                      {!loading &&
                        data?.listPort.ports &&
                        data.listPort.ports.length > 0 && (
                          <PaginationComponent
                            page={page}
                            totalPages={Number(data.listPort.totalPages)}
                            totalResults={Number(data.listPort.totalResults)}
                            pageHandler={pageHandler}
                          />
                        )}

                      <Modal
                        open={edit}
                        onClose={() => {
                          setEdit(false);
                        }}
                      >
                        <form onSubmit={handleSubmit(onSubmit)}>
                          <div className="max-w-[360px] w-full absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-[#F9F8F9] rounded-lg">
                            <div className="text-center py-2 h-10 text-white font-bold bg-[#3D3E49] rounded-t-lg  ">
                              <div className="flex flex-wrap justify-between">
                                <p className="w-[240px] ml-[40px] font-bold text-base">
                                  포트 편집
                                </p>
                                <img
                                  src={exit}
                                  alt="닫기"
                                  className="w-6 mr-2 cursor-pointer"
                                  onClick={() => setEdit(false)}
                                ></img>
                              </div>
                            </div>
                            <div className="px-10 py-10">
                              <div className="space-y-5">
                                <div className="space-y-3">
                                  <p className="font-bold text-[#8B8C8C] text-sm">
                                    포트이름
                                  </p>
                                  <input
                                    {...register("name", {
                                      required: `포트이름을 입력해주세요`,
                                    })}
                                    type="text"
                                    className="w-full border-[#F0F0F4] rounded-lg py-1 px-4 h-10"
                                  ></input>
                                  {errors.name?.message && (
                                    <FormError2
                                      errorMessage={errors.name.message}
                                    />
                                  )}
                                </div>
                                <div className="space-y-3">
                                  <p className="font-bold text-[#8B8C8C] text-sm">
                                    포트 상태
                                  </p>
                                  <div>
                                    <select
                                      {...register(`status`, {
                                        required: "상태를 선택해주세요",
                                      })}
                                      className="w-full border-[#F0F0F4] rounded-lg py-1 px-2"
                                    >
                                      {Object(PortStatusOption).map(
                                        (
                                          model: PortStatusOptionType,
                                          index: number
                                        ) => (
                                          <option
                                            key={`drone-model-option-${index}`}
                                            value={model.value}
                                            disabled={index === 0}
                                          >
                                            {model.label}
                                          </option>
                                        )
                                      )}
                                    </select>
                                    {errors.status?.message && (
                                      <FormError2
                                        errorMessage={errors.status.message}
                                      />
                                    )}
                                  </div>
                                </div>
                                <div className="space-y-3">
                                  <p className="font-bold text-[#8B8C8C] text-sm">
                                    프로젝트 아이디
                                  </p>
                                  <select
                                    {...register(`projectId`, {
                                      required: "프로젝트 ID를 선택해주세요",
                                    })}
                                    className="w-full border-[#F0F0F4] rounded-lg py-1 px-2"
                                  >
                                    {projects?.listProjectSelect.projects?.map(
                                      (project) => (
                                        <option
                                          key={`project-${project.id}`}
                                          value={project.id}
                                        >
                                          {project.name}
                                        </option>
                                      )
                                    )}
                                  </select>

                                  {errors.projectId?.message && (
                                    <FormError2
                                      errorMessage={errors.projectId.message}
                                    />
                                  )}
                                </div>
                              </div>
                            </div>
                            <button
                              type="submit"
                              className="w-full h-14 bg-[#F0F0F4] rounded-b-lg"
                            >
                              {getLoading ? "Loading..." : "확인"}
                            </button>
                          </div>
                        </form>
                      </Modal>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
};
