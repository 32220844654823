import QRCode from "react-qr-code";

import { ListOrder } from "../routes/admin-list-order";
import { MakeOrderNumber } from "../constants/function";

/* checklist 내용이 코드 변경 시에 변경됨*/
type propstype = {
  check: ListOrder[];
  printRef?: any;
};
export const ComponentToPrint = (props: propstype) => {
  const check = props.check;
  const { printRef } = props;
  var pattern = /[a-zA-Z]/;
  const maskingName = (strName: string) => {
    if (strName.length > 2) {
      var originName = strName.split("");
      originName.forEach(function(name, i) {
        if (i === 0 || i === originName.length - 1) return;
        originName[i] = "*";
      });
      var joinName = originName.join();
      return joinName.replace(/,/g, "");
    } else {
      var pattern = /.$/; // 정규식
      return strName.replace(pattern, "*");
    }
  };

  return (
    <div ref={printRef} className="w-full ">
      {check.map((test) => (
        <div
          className="page-break block text-[5px] w-[302px] py-[13.36px] px-[12.03px]"
          key={`print-${test.id}`}
        >
          <div className="grid grid-cols-[1fr_52.12px] gap-x-[2.67px] mb-[2.67px]  text-[16px] leading-[14.4px]">
            <div className="border border-[#000] mb-[1.34px] rounded flex flex-wrap px-1 h-[25.39px]">
              <p className="py-[4.03px] border-red-200">수령인</p>
              <p className="border-l-[1px] border-[#000] my-[2px] mx-1"></p>
              <p className="py-[4.03px]">
                {test.receipt?.receiver && test.receipt?.user?.name
                  ? pattern.test(test.receipt.receiver)
                    ? maskingName(test.receipt.user.name)
                    : maskingName(test.receipt.receiver)
                  : // test.receipt?.receiver
                    test.receipt?.user?.name &&
                    maskingName(test.receipt.user.name)}
              </p>
            </div>
            <div className="row-span-3 border border-black rounded-[5.35px] h-[52.12px] p-[6.68px]">
              <QRCode size={38.76} value={`${test.id}`} />
            </div>
            <div className="border border-[#000] mb-[2.67px] rounded flex flex-wrap px-1 h-[25.39px]">
              <p className="py-[4.03px]">연락처</p>
              <p className="border-l-[1px] border-[#000] my-[1px] mx-1"></p>
              <p className="py-[4.03px]">
                {test.receipt?.phone &&
                  test.receipt?.phone?.substring(0, 3) +
                    "****" +
                    test.receipt?.phone?.substring(
                      test.receipt.phone.length - 4,
                      test.receipt.phone.length
                    )}
              </p>
            </div>
            {/* <div className="border-[1px] border-[#000] rounded flex flex-wrap h-[25.39px]">
              <div className="w-[25.39px] px-1 text-center py-[4.03px]">
                {test.good?.goodType === "Fridge"
                  ? "동"
                  : test.good?.goodType === "Freezer"
                  ? "냉"
                  : "실"}
              </div>
              <p className="border-l-[1px] border-[#000] my-[2px]"></p>
              <div className="w-[53.49px] text-center py-[4.03px]">
                {test.orderNum && MakeOrderNumber(test.orderNum.toString())}
              </div>
              <p className="border-l-[1px] border-[#000] my-[2px]"></p>
              <div className="w-[110px] text-center py-[4.03px] ">
                {test.receipt?.receiptNum}
              </div>
            </div> */}
          </div>
          <div className="grid grid-cols-[1fr_36px] border-[1px]  border-[#000] rounded text-[16px] leading-[14.4px] h-[73px] box-border ">
            <div className="px-1 py-[5.35px] leading-[18px]">
              <p className=" h-9 ellipsis2 overflow-hidden">
                {test.goodName + "-" + test.goodOptionName}
              </p>
            </div>
            <div className="py-[26px] my-[2px] border-l-[1px] border-[#000] text-center">
              {test.quantity + "개"}
            </div>
          </div>
          <div className="grid grid-cols-[25.39px_1fr] border-[1px] border-[#000] rounded text-[16px] leading-[14.4px] mt-[1px] h-[67px] box-border">
            <div className="py-[15.5px] my-[2px] border-r-[1px] border-[#000] text-center">
              주<br />소
            </div>
            <div className="py-[5.35px] px-1 leading-[18px]">
              {test.receipt?.address}
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};
