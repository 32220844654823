import { gql, useLazyQuery } from "@apollo/client";
import {
  ListGoodDetailQuery,
  ListGoodDetailQueryVariables,
} from "../generated/graphql";

const LIST_GOOD_DETAIL_QUERY = gql`
  query listGoodDetail($input: ListGoodDetailInput!) {
    listGoodDetail(input: $input) {
      ok
      err
      goodDetails {
        id
        createdAt
        index
        note
        url
        detailType
        upload {
          id
          uuid
          filename
          url
        }
        good {
          id
          title
          summary
        }
      }
    }
  }
`;

export const useListGoodDetail = (
  onCompleted?: (data: ListGoodDetailQuery) => void
) => {
  return useLazyQuery<ListGoodDetailQuery, ListGoodDetailQueryVariables>(
    LIST_GOOD_DETAIL_QUERY,
    {
      onCompleted,
      fetchPolicy: "network-only",
    }
  );
};
