import { gql, useLazyQuery } from "@apollo/client";
import { ListGoodQuery, ListGoodQueryVariables } from "../generated/graphql";

const LIST_GOOD_QUERY = gql`
  query listGood($input: ListGoodInput!) {
    listGood(input: $input) {
      ok
      err
      totalPages
      totalResults
      row
      goods {
        id
        createdAt
        title
        stock
        price
        weight
        goodOrder
        categoryFirst
        categorySecond
        tags
        status
        goodType
        goodUuid
        goodStocks {
          price
          stock
          expirydate
        }
        corporations {
          id
          name
        }
        bounds {
          status
        }
        goodOptions {
          deliveryDate
          expirydate
          name
          id
        }
      }
    }
  }
`;

export const useListGood = (onCompleted?: (data: ListGoodQuery) => void) => {
  return useLazyQuery<ListGoodQuery, ListGoodQueryVariables>(LIST_GOOD_QUERY, {
    onCompleted,
    fetchPolicy: "network-only",
  });
};
