import { ApolloError, gql, useMutation } from "@apollo/client";
import {
  EditPortInput,
  EditPortMutation,
  EditPortMutationVariables,
} from "../generated/graphql";

export interface IEditPortFormProps extends EditPortInput {}

const EDIT_PORT_QUERY = gql`
  mutation editPort($input: EditPortInput!) {
    editPort(input: $input) {
      ok
      err
    }
  }
`;

export const useEditPort = (
  onCompleted?: (data: EditPortMutation) => void,
  onError?: (error: ApolloError) => void
) => {
  return useMutation<EditPortMutation, EditPortMutationVariables>(
    EDIT_PORT_QUERY,
    {
      onCompleted,
      onError,
    }
  );
};
