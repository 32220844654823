import {
  GoodType,
  GoodCategoryFirst,
  GoodCategorySecond,
  Tags,
  DroneStatus,
  DroneModel,
  BoundType,
  BoundStatus,
  PortStatus,
  ProjectStatus,
  GoodStatus,
} from "../generated/graphql";

export interface GoodTypeOptionType {
  value: GoodType | string;
  string: string;
  label: string;
}
export interface CategoryFirstType {
  value: GoodCategoryFirst | string;
  string: string;
  label: string;
}

export interface CategorySecondType {
  value: GoodCategorySecond | string;
  string: string;
  label: string;
  depth?: GoodCategoryFirst | string;
}
export interface TagType {
  value: Tags;
  name: string;
}
export interface DroneStatusOptionType {
  value: DroneStatus | string;
  string: string;
  label: string;
}
export interface DroneModelOptionType {
  value: DroneModel | string;
  string: string;
  label: string;
}

export interface BoundTypeOptionType {
  value: BoundType | string;
  string: string;
  label: string;
}

export interface BoundStatusOptionType {
  value: BoundStatus | string;
  string: string;
  label: string;
}
export interface PortStatusOptionType {
  value: PortStatus | string;
  string: string;
  label: string;
}
export interface ProjectStatusOptionType {
  value: ProjectStatus | string;
  string: string;
  label: string;
}
export interface GoodStatusOptionType {
  value: GoodStatus | string;
  string: string;
  label: string;
}
export const DroneStatusOption: DroneStatusOptionType[] = [
  { value: ``, string: ``, label: `드론 상태 선택` },
  { value: `Pending`, string: `Pending`, label: `대기` },
  { value: `Operating`, string: `Operating`, label: `운용` },
  { value: `Damage`, string: `Damage`, label: `파손` },
  { value: `Repair`, string: `Repair`, label: `수리` },
  { value: `Testing`, string: `Testing`, label: `테스트` },
];

export const CategoryFirstOption: CategoryFirstType[] = [
  { value: ``, string: ``, label: `카테고리 선택` },
  { value: `Fresh`, string: `Fresh`, label: `신선푸드` },
  { value: `Drink`, string: `Drink`, label: `생수/커피/음료` },
  { value: `Household`, string: `Household`, label: `생활용품` },
  { value: `Meal`, string: `Meal`, label: `간편식/요리` },
  { value: `Snack`, string: `Snack`, label: `디저트/간식` },
  { value: `Toys`, string: `Toys`, label: `장난감/완구류` },
  { value: `Etc`, string: `Etc`, label: `기타` },
];
export const CategorySecondOption: CategorySecondType[] = [
  { value: ``, string: ``, label: `카테고리 선택`, depth: "" },
  {
    value: GoodCategorySecond.Instant,
    string: `Instant`,
    label: `밀키트/즉석요리`,
    depth: "Meal",
  },
  {
    value: GoodCategorySecond.Launch,
    string: `Launch`,
    label: `도시락/김밥`,
    depth: "Meal",
  },
  {
    value: GoodCategorySecond.Sandwich,
    string: `Sandwich`,
    label: `샌드위치/햄버거`,
    depth: "Meal",
  },
  {
    value: GoodCategorySecond.CanNoodle,
    string: `CanNoodle`,
    label: `면류/통조림`,
    depth: "Meal",
  },
  {
    value: GoodCategorySecond.Diet,
    string: `Diet`,
    label: `식단관리`,
    depth: "Meal",
  },
  {
    value: GoodCategorySecond.Vegetable,
    string: `Vegetable`,
    label: `채소`,
    depth: "Fresh",
  },
  {
    value: GoodCategorySecond.Seafood,
    string: `seafood`,
    label: `수산/해산물`,
    depth: "Fresh",
  },
  {
    value: GoodCategorySecond.MeatEgg,
    string: `MeatEgg`,
    label: `정육/계란류`,
    depth: "Fresh",
  },
  {
    value: GoodCategorySecond.Fruit,
    string: `Fruit`,
    label: `과일`,
    depth: "Fresh",
  },
  {
    value: GoodCategorySecond.Water,
    string: `Water`,
    label: `생수/탄산수`,
    depth: "Drink",
  },
  {
    value: GoodCategorySecond.Coffee,
    string: `Coffee`,
    label: `커피`,
    depth: "Drink",
  },
  {
    value: GoodCategorySecond.Milk,
    string: `Milk`,
    label: `우유/두유`,
    depth: "Drink",
  },
  {
    value: GoodCategorySecond.Drink,
    string: `Drink`,
    label: `음료수`,
    depth: "Drink",
  },
  { value: GoodCategorySecond.Tea, string: `Tea`, label: `차`, depth: "Drink" },
  {
    value: GoodCategorySecond.QuasiDrugs,
    string: `QuasiDrugs`,
    label: `의약외품`,
    depth: "Household",
  },
  {
    value: GoodCategorySecond.Sanitary,
    string: `Sanitary`,
    label: `건강/위생용품`,
    depth: "Household",
  },
  {
    value: GoodCategorySecond.Clean,
    string: `Clean`,
    label: `세제/청소용품`,
    depth: "Household",
  },
  {
    value: GoodCategorySecond.Beauty,
    string: `Beauty`,
    label: `뷰티용품`,
    depth: "Household",
  },
  {
    value: GoodCategorySecond.Stuffs,
    string: `Stuffs`,
    label: `생활잡화/공구`,
    depth: "Household",
  },
  {
    value: GoodCategorySecond.Biscuits,
    string: `Biscuits`,
    label: `스낵/비스켓`,
    depth: "Snack",
  },
  {
    value: GoodCategorySecond.Bakery,
    string: `Bakery`,
    label: `베이커리`,
    depth: "Snack",
  },
  {
    value: GoodCategorySecond.Chaw,
    string: `Chaw`,
    label: `껌/초콜릿/캔디`,
    depth: "Snack",
  },
  {
    value: GoodCategorySecond.Led,
    string: `Led`,
    label: `LED 장난감`,
    depth: "Toys",
  },
  {
    value: GoodCategorySecond.WaterToy,
    string: `WaterToy`,
    label: `물놀이 장난감`,
    depth: "Toys",
  },
  {
    value: GoodCategorySecond.Camping,
    string: `Camping`,
    label: `캠핑 / 기타 완구`,
    depth: "Toys",
  },
  { value: GoodCategorySecond.Etc, string: `Etc`, label: `기타`, depth: "Etc" },
];
export const TagList: TagType[] = [
  { value: Tags.Chicken, name: "치킨" },
  { value: Tags.Cafe, name: "카페" },
  { value: Tags.Dessert, name: "디저트" },
  { value: Tags.OutdoorGear, name: "야외놀이 & 필수템" },
  { value: Tags.Safety, name: "안전용품" },
  { value: Tags.Meal, name: "식사" },
  { value: Tags.TodayMeal, name: "오늘의식사" },
  { value: Tags.EventTag, name: "이벤트" },
  { value: Tags.Suggestion, name: `Best추천` },
  // { value: Tags.Emart, name: "이마트" },
  // { value: Tags.GroupPurchase, name: `공동구매` },
  // { value: Tags.Camping, name: `캠핑이벤트` },
  // { value: Tags.LocalFood, name: `로컬푸드` },
  // { value: Tags.EcoFriendly, name: `친환경/유기농` },
  // { value: Tags.Mealkit, name: `밀키트` },
  { value: Tags.NationalFood, name: `전국맛집` },
  { value: Tags.EventProduct, name: `추천특가` },
  { value: Tags.Popularity, name: "인기품목" },
  { value: Tags.Subsidiary, name: "부자재" },

  // { value: Tags.Accessories, name: "패션잡화" },

  // { value: Tags.DeliveryDate, name: `지정일배송` },
  // { value: Tags.NiliGroupPurchase, name: `닐리공구` },
];
export const ProjectStatusOption: ProjectStatusOptionType[] = [
  { value: ``, string: ``, label: `프로젝트 상태` },
  { value: `Pending`, string: `Pending`, label: `준비` },
  { value: `On`, string: `On`, label: `진행` },
  { value: `Completed`, string: `Completed`, label: `완료` },
];
export const BoundTypeOption: BoundTypeOptionType[] = [
  { value: ``, string: ``, label: `입고/출고 선택` },
  { value: `Inbound`, string: `Outbound`, label: `입고` },
  { value: `Outbound`, string: `Outbound`, label: `출고` },
];

export const BoundStatusOption: BoundStatusOptionType[] = [
  { value: ``, string: ``, label: `입출고 상태` },
  { value: `Done`, string: `Done`, label: `완료` },
  { value: `Gathering`, string: `Gathering`, label: `진행 중` },
];
export const PortStatusOption: PortStatusOptionType[] = [
  { value: ``, string: ``, label: `포트 상태` },
  { value: `Pending`, string: `Pending`, label: `대기` },
  { value: `Repair`, string: `Repair`, label: `수리` },
  { value: `Operating`, string: `Operating`, label: `운용` },
];
export const GoodTypeOption: GoodTypeOptionType[] = [
  { value: ``, string: ``, label: `상품 상태 선택` },
  { value: GoodType.Fridge, string: `Fridge`, label: `냉동` },
  { value: GoodType.Freezer, string: `Freezer`, label: `냉장` },
  { value: GoodType.RoomTemperature, string: `RoomTemperature`, label: `실온` },
  { value: GoodType.Etc, string: `Etc`, label: `기타` },
];

export const GoodStatusOption: GoodStatusOptionType[] = [
  { value: `Ready`, string: `Ready`, label: `준비` },
  { value: `goodReady`, string: `goodReady`, label: `커밍순` },
  { value: `GoodSales`, string: `GoodSales`, label: `판매중` },
  { value: `SoldOut`, string: `SoldOut`, label: `품절` },
];
export const DroneModelOption: DroneModelOptionType[] = [
  { value: ``, string: ``, label: `드론모델 선택` },
  { value: DroneModel.Phv20, string: `PHV20`, label: `PHV20` },
  { value: DroneModel.Af200, string: `AF200`, label: `AF200` },
  { value: DroneModel.Ds30, string: `DS30`, label: `DS30` },
  { value: DroneModel.P05, string: `P05`, label: `P05` },
  { value: DroneModel.P01, string: `P01`, label: `P01` },
  { value: DroneModel.Pandion, string: `PANDION`, label: `PANDION` },
  { value: DroneModel.Gd23Ot, string: `GD230T`, label: `GD230T` },
];

export interface saveJson {
  typeName: string;
  attrs: ProductAttrTypes[];
}
export interface ProductAttrTypes {
  value: string;
  name: string;
}

export interface AttrInfoSet {
  label?: string;
  value?: string;
}

export interface EditProductProps {
  tabs: number;
  setTabHandler: (tabs: number) => void;
}
export interface EditBannerProps {
  tabs: number;
  setTabHandler: (tabs: number) => void;
}

export interface relatedProps {
  label: string;
  value: number;
}

export interface relatedProps {
  label: string;
  value: number;
}

export interface serviceProps {
  label: string;
  value: number;
}

export const getGoodCategory = (
  label: string
): [GoodCategoryFirst, GoodCategorySecond] => {
  const selected = CategorySecondOption.find(
    (option) => option.label === label
  );
  if (!selected) return [GoodCategoryFirst.Snack, GoodCategorySecond.Bakery];
  if (typeof selected.value === "string")
    return [GoodCategoryFirst.Snack, GoodCategorySecond.Bakery];

  const cS = selected.value;
  let cF: GoodCategoryFirst = GoodCategoryFirst.Snack;
  if (
    cS === GoodCategorySecond.Vegetable ||
    cS === GoodCategorySecond.Seafood ||
    cS === GoodCategorySecond.MeatEgg ||
    cS === GoodCategorySecond.Fruit
  )
    cF = GoodCategoryFirst.Fresh;
  if (
    cS === GoodCategorySecond.Water ||
    cS === GoodCategorySecond.Coffee ||
    cS === GoodCategorySecond.Milk ||
    cS === GoodCategorySecond.Drink
  )
    cF = GoodCategoryFirst.Drink;
  if (
    cS === GoodCategorySecond.QuasiDrugs ||
    cS === GoodCategorySecond.Sanitary ||
    cS === GoodCategorySecond.Clean ||
    cS === GoodCategorySecond.Beauty ||
    cS === GoodCategorySecond.Stuffs
  )
    cF = GoodCategoryFirst.Household;
  if (
    cS === GoodCategorySecond.Instant ||
    cS === GoodCategorySecond.Launch ||
    cS === GoodCategorySecond.Sandwich ||
    cS === GoodCategorySecond.CanNoodle ||
    cS === GoodCategorySecond.Diet
  )
    cF = GoodCategoryFirst.Meal;
  if (
    cS === GoodCategorySecond.Biscuits ||
    cS === GoodCategorySecond.Bakery ||
    cS === GoodCategorySecond.Chaw
  )
    cF = GoodCategoryFirst.Snack;
  if (
    cS === GoodCategorySecond.Led ||
    cS === GoodCategorySecond.WaterToy ||
    cS === GoodCategorySecond.Camping
  )
    cF = GoodCategoryFirst.Toys;
  if (cS === GoodCategorySecond.Etc) cF = GoodCategoryFirst.Etc;
  // if(cS === GoodCategorySecond.Bakery || ) {}

  return [cF, cS];
};

export const getGoodType = (label: string): GoodType => {
  const selected = GoodTypeOption.find((option) => option.label === label);
  if (!selected) return GoodType.Etc;
  if (typeof selected.value === "string") return GoodType.Etc;
  return selected.value;
};
