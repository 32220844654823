import { gql, useLazyQuery } from "@apollo/client";
import {
  ListBannerQuery,
  ListBannerQueryVariables,
} from "../generated/graphql";

const LIST_BANNER_QUERY = gql`
  query listBanner($input: ListBannerInput!) {
    listBanner(input: $input) {
      ok
      err
      totalPages
      totalResults
      row
      banners {
        id
        createdAt
        title
        isActive
        bannerOptions {
          id
          createdAt
          link
          index
        }
      }
    }
  }
`;

export const useListBanner = (
  onCompleted?: (data: ListBannerQuery) => void
) => {
  return useLazyQuery<ListBannerQuery, ListBannerQueryVariables>(
    LIST_BANNER_QUERY,
    {
      onCompleted,
      fetchPolicy: "network-only",
    }
  );
};
