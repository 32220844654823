import { ApolloError, gql, useMutation } from "@apollo/client";
import React from "react";
import {
  CrGoodPosterMutation,
  CrGoodPosterMutationVariables,
} from "../generated/graphql";

const CR_GOOD_POSTER_MUTATION = gql`
  mutation crGoodPoster($input: CrGoodPosterInput!) {
    crGoodPoster(input: $input) {
      ok
      err
    }
  }
`;

export const useCrGoodPoster = (
  onCompleted?: (data: CrGoodPosterMutation) => void,
  onError?: (error: ApolloError) => void
) => {
  return useMutation<CrGoodPosterMutation, CrGoodPosterMutationVariables>(
    CR_GOOD_POSTER_MUTATION,
    {
      onCompleted,
      onError,
    }
  );
};
