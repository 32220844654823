import { gql, useLazyQuery } from "@apollo/client";
import { GetGoodQuery, GetGoodQueryVariables } from "../generated/graphql";

const GET_GOOD_QUERY = gql`
  query getGood($input: GetGoodInput!) {
    getGood(input: $input) {
      ok
      err
      good {
        id
        categoryFirst
        categorySecond
        tags
        summary
        limitNumOrders
        title
        goodType
        status
        expirydate
        goodRelations {
          id
          index
          relatedGoods {
            title
            id
          }
        }
        createdAt
        weight
        stock
        price
        discountRate
        attributeSet
        goodAttrInfo
        corporations {
          id
          name
        }
        goodOptions {
          id
          name
          desc
          isActive
          deliveryDate
          discountRate
        }
      }
    }
  }
`;

export const useGetGood = (onCompleted?: (data: GetGoodQuery) => void) => {
  return useLazyQuery<GetGoodQuery, GetGoodQueryVariables>(GET_GOOD_QUERY, {
    onCompleted,
    fetchPolicy: "network-only",
  });
};
