import { useEffect, useState } from "react";
import { AsideAdminComponent } from "../layout/aside-admin";
import {
  PageType,
  ComponentTabLink,
  ITabTitle,
} from "../components/component-tab";
import exit from "../assets/icon/exit.png";
import { ITabLink } from "../components/component-tab";
import React from "react";
import { Modal } from "@mui/material";
import { useListOrder } from "../hooks/useListOrder";
import { useEditOrderStatus } from "../hooks/useEditOrderStatus";
import { useLocation, useNavigate } from "react-router-dom";
import { pageIndicator } from "../apollo";

import moment from "moment";
import { TableError } from "../components/table-error";
import { PaginationComponent } from "../components/pagination";
import { useListDriverSelect } from "../hooks/useDriverSelect";
import { useSetOrderManager } from "../hooks/useSetOrderManager";
import { useEditExchangeStatus } from "../hooks/useEditExchangeStatus";
import { ListOrder } from "./admin-list-order";
import {
  OrderStatus,
  ExchangeStatus,
  ListOrderInput,
  SetOrderManagerInput,
  EditExchangeStatusInput,
  EditOrderStatusInput,
  SetOrderManagerMutation,
  EditExchangeStatusMutation,
  EditOrderStatusMutation,
} from "../generated/graphql";

export const AdminListOrderExchange = () => {
  const tabList: ITabLink[] = [
    { text: "주문확인", to: "/order" },
    { text: "출고대기", to: "/release" },
    { text: "배송준비중", to: "/order/delivery" },
    { text: "배송중", to: "/order/start" },
    { text: "배송완료", to: "/order/end" },
    { text: "교환/환불", to: "/order/exchange", selected: true },
    { text: "배송확정", to: "/order/confirm" },
  ];
  const stitle: ITabTitle = { text: "배송관리" };
  const word = "교환/환불";
  const location = useLocation();
  const navigate = useNavigate();
  const [openmanager, setOpenManager] = useState(false);
  const [open, setOpen] = useState(false);
  const [openstate, setOpenState] = useState(false);
  const [orderId, setOrderId] = useState<number>(1);
  const [manager, setManager] = useState<number>(1);
  const [page, setPage] = useState<number>(1);
  const [search, setSearch] = useState<string>("");
  const [stateId, setStateId] = useState<number>(1);
  const [exchangeId, setExchangeId] = useState<number>(1);
  const [orderStatus, setOrderStatus] = React.useState<OrderStatus>(
    OrderStatus.Refunded
  );
  const [change, setChange] = React.useState<OrderStatus>(OrderStatus.Refunded);
  const [checkedLists, setCheckedLists] = useState<ListOrder[]>([]);
  const [allChecked, setAllChecked] = useState<boolean>(false);
  const [listDriverSelect, { data: drivers }] = useListDriverSelect();
  const [exchangeStatus, setExchangeStatus] = React.useState<ExchangeStatus>(
    ExchangeStatus.Pending
  );

  const modalManagerHanler = (id: number) => {
    setManager(id);
  };
  const StateHandler = (id: number) => {
    setStateId(id);
    setOpen(true);
  };
  const ExchangeHandler = (id: number, status: OrderStatus) => {
    setOrderStatus(status);
    setExchangeId(id);
    setOpenState(true);
  };
  const [listOrder, { data, loading, refetch }] = useListOrder();
  const listOrderHandler = (
    page: number | null,
    search: string | null,
    statuses: OrderStatus[],
    start?: Date | null,
    end?: Date | null
  ) => {
    const input: ListOrderInput = {
      ...(page && { page: +page }),
      ...(search && { search: decodeURI(search) }),
      ...(statuses.length > 0 && { statuses }),
      ...(start && { start }),
      ...(end && { end }),
    };
    listOrder({ variables: { input } });
  };
  const transModalDataHandler = (id: number) => {
    setOrderId(id);
  };
  const managerHandler = (e: any) => {
    setManager(e.target.value);
  };
  const onSetCompleted = async (data: SetOrderManagerMutation) => {
    const {
      setOrderManager: { ok, err },
    } = data;
    if (ok) {
      alert(`담당자가 지정되었습니다`);
      await refetch();
    } else {
      alert(err);
    }
  };

  const [
    setOrderManager,
    { loading: setOrderManagerLoading },
  ] = useSetOrderManager(onSetCompleted);

  const setOrderManagerHandler = (orderId: number, userId: number) => {
    if (!setOrderManagerLoading) {
      const setOrderManagerInput: SetOrderManagerInput = {
        orderId,
        userId: userId ? +userId : 1,
      };
      setOrderManager({ variables: { input: setOrderManagerInput } });
    }
  };
  // -- 환불/교환 상태 업데이트 ---
  const onEditExchangeCompleted = async (data: EditExchangeStatusMutation) => {
    const {
      editExchangeStatus: { ok, err },
    } = data;
    if (ok) {
      // alert(`주문상태가 수정되었습니다.`);
      await refetch();
    } else {
      alert(err);
    }
  };
  const [
    editExchangeStatus,
    { loading: editExchangeStatusLoading },
  ] = useEditExchangeStatus(onEditExchangeCompleted);

  const editExchangeStatusHandler = (
    orderId: number,
    exchangeStatus: ExchangeStatus
  ) => {
    if (!editExchangeStatusLoading) {
      const editExchangeStatusInput: EditExchangeStatusInput = {
        orderId,
        exchangeStatus,
      };
      editExchangeStatus({ variables: { input: editExchangeStatusInput } });
    }
  };

  // -- 주문 상태 업데이트 ---
  const onEditCompleted = async (data: EditOrderStatusMutation) => {
    const {
      editOrderStatus: { ok, err },
    } = data;
    if (ok) {
      // alert(`주문상태가 수정되었습니다.`);
      await refetch();
    } else {
      alert(err);
    }
  };
  const [
    editOrderStatus,
    { loading: editOrderStatusLoading },
  ] = useEditOrderStatus(onEditCompleted);

  const editOrderStatusHandler = (orderId: number, status: OrderStatus) => {
    if (!editOrderStatusLoading) {
      const editOrderStatusInput: EditOrderStatusInput = {
        orderId,
        status,
      };
      editOrderStatus({ variables: { input: editOrderStatusInput } });
    }
  };

  // --- CheckBox ---
  const setAllCheckedHandler = () => {
    setAllChecked(!allChecked);
    if (allChecked) setCheckedLists([]);

    if (!allChecked && data?.listOrder.orders) {
      setCheckedLists([]);
      for (const exchange of data?.listOrder.orders) {
        setCheckedLists((checkedList) => [...checkedList, exchange]);
      }
    }
  };

  const setCheckedListHandler = (exchangeId: number, data: any) => {
    if (
      Boolean(checkedLists.find((checkedId) => checkedId.id === exchangeId))
    ) {
      setCheckedLists(
        checkedLists.filter((checkedId) => checkedId.id !== exchangeId)
      );
    } else {
      setCheckedLists((originalList) => [...originalList, data]);
    }
  };
  useEffect(() => {
    if (checkedLists.length === data?.listOrder.orders?.length)
      setAllChecked(true);
    else setAllChecked(false);
  }, [checkedLists]);

  // --- Search ---
  const setSearchHandler = (search: string) => {
    navigate({
      pathname: "/order/exchange",
      search: `${search ? `?search=${search}` : ""}`,
    });
    const statuses: OrderStatus[] = [
      OrderStatus.Refunded,
      OrderStatus.Exchange,
    ];
    listOrderHandler(1, search, statuses);
  };

  // --- Page ---
  const pageHandler = (page: number) => {
    const search =
      location.search.split("search=").length > 1
        ? location.search.split("search=")[1].split("&")[0]
        : null;
    setPage(page);
    navigate({
      pathname: "/order/exchange",
      search: `?page=${page}${search ? `&search=${search}` : ""}`,
    });
    const statuses: OrderStatus[] = [
      OrderStatus.Refunded,
      OrderStatus.Exchange,
    ];
    listOrderHandler(page, search, statuses);
  };
  // --- Period ---
  const periodHandler = (sDate?: Date, eDate?: Date) => {
    const statuses: OrderStatus[] = [
      OrderStatus.Refunded,
      OrderStatus.Exchange,
    ];
    listOrderHandler(1, search, statuses, sDate, eDate);
  };
  useEffect(() => {
    pageIndicator("delivery");
    setPage(
      location.search.split("page=").length > 1
        ? +location.search.split("page=")[1].split("&")[0]
        : 1
    );
    setSearch(
      location.search.split("search=").length > 1
        ? location.search.split("search=")[1].split("&")[0]
        : ""
    );
    const statuses: OrderStatus[] = [
      OrderStatus.Refunded,
      OrderStatus.Exchange,
    ];
    listOrderHandler(page, search, statuses);
  }, [search, page]);

  useEffect(() => {
    listDriverSelect();
  }, [manager]);

  return (
    <div className="bg-main bg-opacity-50 w-full h-full min-h-screen">
      <div className="w-full mx-auto h-full min-h-screen  bg-gray-100">
        <main id="main">
          <div className="grid grid-cols-[200px_1fr] max-w-full">
            <AsideAdminComponent />
            <div className="mypages bg-gray-100 w-full min-w-[1200px] overflow-hidden">
              <div className="orderWrap">
                <div className="h-fix-content relative">
                  <div className={`w-full h-full px-10 py-8`}>
                    <ComponentTabLink
                      tabs={tabList}
                      stitle={stitle}
                      type={PageType.Exchange}
                      dataList={checkedLists}
                      setSearchs={setSearchHandler}
                      setDateSearch={periodHandler}
                      refetch={refetch}
                    />
                    {/* 목록 */}
                    <div className=" shadow-lg h-[800px] h-fix-content w-full overflow-auto bg-white">
                      <table className="align-center w-[1940px] text-sm border-[#F9F8F9] ">
                        <thead>
                          <tr className="border-b border-[#F9F8F9] h-8">
                            <th className="text-[#8B8C8C] leading-3 min-w-[36px]">
                              <input
                                type="checkbox"
                                className="w-5 h-5"
                                checked={allChecked}
                                onChange={setAllCheckedHandler}
                              />
                            </th>
                            <th className="text-[#8B8C8C] min-w-[40px]">no</th>
                            <th className="text-[#8B8C8C] min-w-[120px]">
                              상태
                            </th>
                            <th className="text-[#8B8C8C] min-w-[88px]">
                              주문번호
                            </th>
                            <th className="text-[#8B8C8C] min-w-[112px]">
                              QR코드/송장번호
                            </th>
                            <th className="text-[#8B8C8C] min-w-[88px]">
                              주문날짜
                            </th>
                            <th className="text-[#8B8C8C] min-w-[168px]">
                              상품명
                            </th>
                            <th className="text-[#8B8C8C] min-w-[168px]">
                              상품옵션
                            </th>
                            <th className="text-[#8B8C8C] min-w-[48px]">
                              수량
                            </th>
                            <th className="text-[#8B8C8C] min-w-[88px]">
                              결제금액
                            </th>
                            <th className="text-[#8B8C8C] min-w-[88px]">
                              구매자
                            </th>
                            <th className="text-[#8B8C8C] min-w-[88px]">
                              수취인
                            </th>
                            <th className="text-[#8B8C8C] min-w-[168px]">
                              주소
                            </th>
                            <th className="text-[#8B8C8C] min-w-[168px]">
                              배송메시지
                            </th>
                            <th className="text-[#8B8C8C] min-w-[128px]">
                              연락처
                            </th>
                            <th className="text-[#8B8C8C] min-w-[88px]">
                              상품코드
                            </th>
                            <th className="text-[#8B8C8C] min-w-[88px]">
                              위치코드
                            </th>
                            <th className="text-[#8B8C8C] min-w-[88px]">
                              무게
                            </th>
                            <th className="text-[#8B8C8C] min-w-[80px]">
                              비고
                            </th>
                          </tr>
                        </thead>

                        <tbody className="">
                          {!loading &&
                            data?.listOrder.orders &&
                            data.listOrder.orders.map((exchange, index) => (
                              <tr
                                key={`exchange-${exchange.id}`}
                                className="border-b border-[#F9F8F9] h-[68px]"
                              >
                                <td className="table-text">
                                  <input
                                    type="checkbox"
                                    className="h-5 w-5"
                                    checked={
                                      checkedLists.find(
                                        (checkedId) =>
                                          checkedId.id === exchange.id
                                      ) !== undefined
                                    }
                                    onChange={(e) => {
                                      setCheckedListHandler(
                                        exchange.id,
                                        exchange
                                      );
                                    }}
                                  />
                                </td>
                                <td className="table-text">
                                  {(page - 1) * 20 + index + 1}
                                </td>
                                <td className="table-text">
                                  <div className="grid grid-cols-2 gap-x-2">
                                    <button
                                      className={` rounded-lg py-[4px] px-[3px] w-[48px] h-10 keep-all font-bold ${
                                        exchange.status === "Refunded"
                                          ? "bg-[#B42713] text-[#ffffff]"
                                          : "bg-[#555B94] text-[#ffffff]"
                                      }`}
                                      onClick={() => {
                                        StateHandler(exchange.id);
                                        setChange(exchange.status);
                                      }}
                                    >
                                      {exchange.status === "Refunded"
                                        ? "환불"
                                        : "교환"}
                                    </button>
                                    <button
                                      className={`font-bold rounded-lg py-[4px] px-[3px] w-[48px] h-10 keep-all ${
                                        exchange.exchangeStatus === "ReDelivery"
                                          ? "bg-[#FFC700] text-[#3D3E49]"
                                          : exchange.exchangeStatus ===
                                            "Pending"
                                          ? "border-[#8B8C8C] border text-[#8B8C8C]"
                                          : exchange.exchangeStatus === "PickUp"
                                          ? "bg-[#8B8C8C] text-[#ffffff]"
                                          : "border-[#FFC700] border text-[#FFC700]"
                                      }  `}
                                      onClick={() => {
                                        ExchangeHandler(
                                          exchange.id,
                                          exchange.status
                                        );
                                        setExchangeStatus(
                                          exchange.exchangeStatus
                                        );
                                      }}
                                    >
                                      {exchange.exchangeStatus === "ReDelivery"
                                        ? "재발송"
                                        : exchange.exchangeStatus === "PickUp"
                                        ? "물품 수령"
                                        : exchange.exchangeStatus === "Pending"
                                        ? "대기"
                                        : exchange.exchangeStatus === "Refunded"
                                        ? "환불완료"
                                        : "교환 완료"}
                                    </button>
                                  </div>
                                </td>
                                <td className="table-text break-all">
                                  {exchange.receipt?.receiptNum}
                                </td>
                                <td className="table-text font-bold"></td>
                                <td className="table-text font-bold">
                                  {exchange.createdAt &&
                                    moment(exchange.createdAt).format("YYMMDD")}
                                  <br />{" "}
                                  {exchange.createdAt &&
                                    moment(exchange.createdAt).format("H : DD")}
                                </td>
                                <td className="table-text">
                                  {exchange.good?.title}
                                </td>
                                <td className="table-text">
                                  {exchange.goodOptionName}
                                </td>
                                <td className="table-text font-bold">
                                  {exchange.quantity}
                                </td>
                                <td className="table-text">
                                  {exchange.amount}
                                </td>
                                <td className="table-text">
                                  {exchange.receipt?.user?.name}
                                </td>
                                <td className="table-text font-bold">
                                  {exchange.receipt?.receiver}
                                </td>
                                <td className="table-text">
                                  {exchange.receipt?.address}
                                </td>
                                <td className="table-text">
                                  {exchange.receipt?.message}
                                </td>
                                <td className="table-text">
                                  {exchange.receipt?.phone}
                                </td>
                                <td className="table-text break-all">
                                  {exchange.good?.goodUuid}
                                </td>
                                <td className="table-text"></td>
                                <td className="table-text">
                                  {exchange.good?.weight &&
                                    exchange.good?.weight + "g"}
                                </td>
                                <td className="table-text">
                                  <button
                                    className="border border-[#B42713] rounded-lg py-[4px] px-[3px] w-[48px] h-10 keep-all font-bold text-[#B42713]"
                                    onClick={() => {
                                      setOpenManager(true);
                                      transModalDataHandler(exchange.id);
                                      if (exchange.manager?.id) {
                                        modalManagerHanler(exchange.manager.id);
                                      }
                                    }}
                                  >
                                    {exchange.manager?.id &&
                                    exchange.manager.name
                                      ? exchange.manager.name
                                      : "담당자 배정"}
                                  </button>
                                </td>
                              </tr>
                            ))}
                        </tbody>
                      </table>
                      {loading && (
                        <TableError errorMessage={`정보를 불러오는중입니다`} />
                      )}
                      {!loading &&
                        data?.listOrder.orders &&
                        data.listOrder.orders.length < 1 && (
                          <TableError
                            errorMessage={`${word} 정보가 없습니다`}
                          />
                        )}

                      {!loading &&
                        data?.listOrder.orders &&
                        data.listOrder.orders.length > 0 && (
                          <PaginationComponent
                            page={page}
                            totalPages={Number(data.listOrder.totalPages)}
                            totalResults={Number(data.listOrder.totalResults)}
                            pageHandler={pageHandler}
                          />
                        )}
                    </div>

                    <Modal
                      open={open}
                      onClose={() => {
                        setOpen(false);
                      }}
                    >
                      <div className="max-w-[360px] w-full absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2   bg-[#F9F8F9] rounded-lg">
                        <div className="text-center py-2 h-10 text-white font-bold bg-[#3D3E49] rounded-t-lg  ">
                          <div className="flex flex-wrap justify-between">
                            <p className="w-[240px] ml-[40px] font-bold text-base">
                              배송 상태변경
                            </p>
                            <img
                              src={exit}
                              alt="닫기"
                              className="w-6 mr-2 cursor-pointer"
                              onClick={() => setOpen(false)}
                            ></img>
                          </div>
                        </div>
                        <div className="flex flex-wrap content-center">
                          <div className="px-10 py-10">
                            <div className="grid grid-rows-6 gap-y-4">
                              <p className="font-bold">배송 준비</p>
                              <div>
                                <input
                                  type="radio"
                                  name="order"
                                  id="pending"
                                  onChange={(e) => {
                                    if (e.target.checked) {
                                      setChange(OrderStatus.Pending);
                                    }
                                  }}
                                ></input>
                                <span className="ml-4">배송 준비</span>
                              </div>
                              <p className="font-bold">배송중</p>

                              <div>
                                <input
                                  type="radio"
                                  name="order"
                                  id="Drone"
                                  value="Drone"
                                  onChange={(e) => {
                                    if (e.target.checked) {
                                      setChange(OrderStatus.Drone);
                                    }
                                  }}
                                ></input>
                                <span className="ml-4">드론 비행</span>
                              </div>
                              <div>
                                <input
                                  type="radio"
                                  name="order"
                                  id="Port"
                                  value="HandOut"
                                  onChange={(e) => {
                                    if (e.target.checked) {
                                      setChange(OrderStatus.Port);
                                    }
                                  }}
                                ></input>
                                <span className="ml-4">최종 배송준비</span>
                              </div>
                              <div>
                                <input
                                  type="radio"
                                  name="order"
                                  id="Stored"
                                  value="Stored"
                                  onChange={(e) => {
                                    if (e.target.checked) {
                                      setChange(OrderStatus.Stored);
                                    }
                                  }}
                                ></input>
                                <span className="ml-4">픽업포트</span>
                              </div>
                              <div>
                                <input
                                  type="radio"
                                  name="order"
                                  id="PendingDelivered"
                                  value="PendingDeliverd"
                                  onChange={(e) => {
                                    if (e.target.checked) {
                                      setChange(OrderStatus.PendingDelivered);
                                    }
                                  }}
                                ></input>
                                <span className="ml-4">최종 배송</span>
                              </div>
                              <p className="font-bold">배송 완료</p>
                              <div>
                                <input
                                  type="radio"
                                  name="order"
                                  id="check"
                                  value="Delivered"
                                  onChange={(e) => {
                                    if (e.target.checked) {
                                      setChange(OrderStatus.Delivered);
                                    }
                                  }}
                                ></input>
                                <span className="ml-4">배송 완료</span>
                              </div>
                              <p className="font-bold">환불/교환</p>

                              <div>
                                <input
                                  type="radio"
                                  name="order"
                                  id="refunded"
                                  checked={change === OrderStatus.Refunded}
                                  onChange={(e) => {
                                    setChange(OrderStatus.Refunded);
                                  }}
                                ></input>
                                <span className="ml-4">환불</span>
                              </div>
                              <div>
                                <input
                                  type="radio"
                                  name="order"
                                  id="exchange"
                                  checked={change === OrderStatus.Exchange}
                                  onChange={(e) => {
                                    setChange(OrderStatus.Exchange);
                                  }}
                                ></input>
                                <span className="ml-4">교환</span>
                              </div>
                            </div>
                          </div>
                        </div>
                        <button
                          className="w-full h-14 bg-[#F0F0F4] rounded-b-lg"
                          onClick={() => {
                            editOrderStatusHandler(stateId, change);
                            setOpen(false);
                          }}
                        >
                          확인
                        </button>
                      </div>
                    </Modal>
                    <Modal
                      open={openstate}
                      onClose={() => {
                        setOpenState(false);
                      }}
                    >
                      <div className="max-w-[360px] w-full absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-[#F9F8F9] rounded-lg">
                        <div className="text-center py-2 h-10 text-white font-bold bg-[#3D3E49] rounded-t-lg  ">
                          <div className="flex flex-wrap justify-between">
                            <p className="w-[240px] ml-[40px] font-bold text-base">
                              환불/교환상태
                            </p>
                            <img
                              src={exit}
                              alt="닫기"
                              className="w-6 mr-2 cursor-pointer"
                              onClick={() => setOpenState(false)}
                            ></img>
                          </div>
                        </div>
                        <div className="flex flex-wrap content-center">
                          <div className="px-10 py-10">
                            <div className="grid grid-rows-6 gap-y-4">
                              <div>
                                <input
                                  type="radio"
                                  name="redelivery"
                                  id="pending"
                                  checked={
                                    exchangeStatus === ExchangeStatus.Pending
                                  }
                                  onChange={(e) => {
                                    setExchangeStatus(ExchangeStatus.Pending);
                                  }}
                                ></input>
                                <span className="ml-4">대기</span>
                              </div>
                              <div>
                                <input
                                  type="radio"
                                  name="redelivery"
                                  id="pickup"
                                  checked={
                                    exchangeStatus === ExchangeStatus.PickUp
                                  }
                                  onChange={(e) => {
                                    setExchangeStatus(ExchangeStatus.PickUp);
                                  }}
                                ></input>
                                <span className="ml-4">물품 수령</span>
                              </div>
                              <div
                                hidden={orderStatus === OrderStatus.Refunded}
                              >
                                <input
                                  type="radio"
                                  name="redelivery"
                                  id="redelivery"
                                  disabled={
                                    orderStatus === OrderStatus.Refunded
                                  }
                                  checked={
                                    exchangeStatus === ExchangeStatus.ReDelivery
                                  }
                                  onChange={(e) => {
                                    setExchangeStatus(
                                      ExchangeStatus.ReDelivery
                                    );
                                  }}
                                ></input>
                                <span className="ml-4">재발송</span>
                              </div>
                              <div
                                hidden={orderStatus === OrderStatus.Exchange}
                              >
                                <input
                                  type="radio"
                                  name="redelivery"
                                  id="refunded"
                                  checked={
                                    exchangeStatus === ExchangeStatus.Refunded
                                  }
                                  onChange={(e) => {
                                    setExchangeStatus(ExchangeStatus.Refunded);
                                  }}
                                ></input>
                                <span className="ml-4">환불 완료</span>
                              </div>
                              <div
                                hidden={orderStatus === OrderStatus.Refunded}
                              >
                                <input
                                  type="radio"
                                  name="redelivery"
                                  id="complete"
                                  checked={
                                    exchangeStatus === ExchangeStatus.Complete
                                  }
                                  onChange={(e) => {
                                    setExchangeStatus(ExchangeStatus.Complete);
                                  }}
                                ></input>
                                <span className="ml-4">교환 완료</span>
                              </div>
                            </div>
                          </div>
                        </div>
                        <button
                          className="w-full h-14 bg-[#F0F0F4] rounded-b-lg"
                          onClick={() => {
                            editExchangeStatusHandler(
                              exchangeId,
                              exchangeStatus
                            );

                            setOpenState(false);
                          }}
                        >
                          확인
                        </button>
                      </div>
                    </Modal>

                    <Modal
                      open={openmanager}
                      onClose={() => {
                        setOpenManager(false);
                      }}
                    >
                      <form>
                        <div className="max-w-[360px] w-full absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-[#F9F8F9] rounded-lg">
                          <div className="text-center py-2 h-10 text-white font-bold bg-[#3D3E49] rounded-t-lg  ">
                            <div className="flex flex-wrap justify-between">
                              <p className="w-[240px] ml-[40px] font-bold text-base">
                                담당자 선택
                              </p>
                              <img
                                src={exit}
                                alt="닫기"
                                className="w-6 mr-2 cursor-pointer"
                                onClick={() => setOpenManager(false)}
                              ></img>
                            </div>
                          </div>
                          <div className="px-10 py-10">
                            <div className="space-y-5">
                              <div className="space-y-3">
                                <p className="font-bold text-[#8B8C8C] text-sm">
                                  담당자 이름
                                </p>
                                <select
                                  className="w-full border-[#F0F0F4] rounded-lg py-1 px-2"
                                  onChange={managerHandler}
                                  value={manager}
                                >
                                  {drivers?.listDriverSelect.users?.map(
                                    (driver) => (
                                      <option
                                        key={`driver-${driver.id}`}
                                        value={driver.id}
                                      >
                                        {driver.name}
                                      </option>
                                    )
                                  )}
                                </select>
                              </div>
                            </div>
                          </div>
                          <button
                            onClick={() => {
                              setOrderManagerHandler(orderId, manager);
                              setOpenManager(false);
                            }}
                            className="w-full h-14 bg-[#F0F0F4] rounded-b-lg"
                          >
                            확인
                          </button>
                        </div>
                      </form>
                    </Modal>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
};
