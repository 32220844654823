import { gql, useLazyQuery } from "@apollo/client";
import {
  GetBannerMainQuery,
  GetBannerMainQueryVariables,
} from "../generated/graphql";

const GET_BANNER_MAIN_QUERY = gql`
  query getBannerMain($input: GetBannerMainInput!) {
    getBannerMain(input: $input) {
      ok
      err
      bannerMain {
        id
        index
        link
        name
        mainType
        title
        note
        alt
        isRes
        isEvent
        isFree
        isOnePlusOne
        upload {
          id
          uuid
          url
          filename
        }
        event {
          title
          isActive
          link
          upload {
            id
            uuid
            url
            filename
          }
        }
        linkServices {
          id
          name
        }
      }
    }
  }
`;

export const useGetBannerMain = (
  onCompleted?: (data: GetBannerMainQuery) => void
) => {
  return useLazyQuery<GetBannerMainQuery, GetBannerMainQueryVariables>(
    GET_BANNER_MAIN_QUERY,
    {
      onCompleted,
      fetchPolicy: "network-only",
    }
  );
};
