import { ApolloError, gql, useMutation } from "@apollo/client";
import {
  EditDroneInput,
  EditDroneMutation,
  EditDroneMutationVariables,
} from "../generated/graphql";

export interface IEditDroneFormProps extends EditDroneInput {}

const EDIT_DRONE_QUERY = gql`
  mutation editDrone($input: EditDroneInput!) {
    editDrone(input: $input) {
      ok
      err
    }
  }
`;

export const useEditDrone = (
  onCompleted?: (data: EditDroneMutation) => void,
  onError?: (error: ApolloError) => void
) => {
  return useMutation<EditDroneMutation, EditDroneMutationVariables>(
    EDIT_DRONE_QUERY,
    {
      onCompleted,
      onError,
    }
  );
};
