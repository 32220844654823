import { ApolloError, gql, useMutation } from "@apollo/client";
import {
  CrLinkServiceInput,
  CrLinkServiceMutation,
  CrLinkServiceMutationVariables,
} from "../generated/graphql";

export interface ICrLinkServiceFormProps extends CrLinkServiceInput {}

const CR_LINKSERVICE_MUTATION = gql`
  mutation crLinkService($input: CrLinkServiceInput!) {
    crLinkService(input: $input) {
      ok
      err
    }
  }
`;

export const useCrLinkService = (
  onCompleted?: (data: CrLinkServiceMutation) => void,
  onError?: (error: ApolloError) => void
) => {
  return useMutation<CrLinkServiceMutation, CrLinkServiceMutationVariables>(
    CR_LINKSERVICE_MUTATION,
    { onCompleted, onError }
  );
};
