import { ApolloError, gql, useMutation } from "@apollo/client";
import {
  EditBannerMainIndexMutation,
  EditBannerMainIndexMutationVariables,
} from "../generated/graphql";

const EDIT_BANNER_MAIN_INDEX_QUERY = gql`
  mutation editBannerMainIndex($input: EditBannerMainIndexInput!) {
    editBannerMainIndex(input: $input) {
      ok
      err
    }
  }
`;

export const useEditBannerMainIndex = (
  onCompleted?: (data: EditBannerMainIndexMutation) => void,
  onError?: (error: ApolloError) => void
) => {
  return useMutation<
    EditBannerMainIndexMutation,
    EditBannerMainIndexMutationVariables
  >(EDIT_BANNER_MAIN_INDEX_QUERY, {
    onCompleted,
    onError,
  });
};
