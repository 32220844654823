import { ApolloError, gql, useMutation } from "@apollo/client";
import {
  EditOrderEndDateInput,
  EditOrderEndDateMutation,
  EditOrderEndDateMutationVariables,
} from "../generated/graphql";

export interface IEditOrderEndFormProps extends EditOrderEndDateInput {}

const EDIT_ORDER_END_DATE_MUTATION = gql`
  mutation editOrderEndDate($input: EditOrderEndDateInput!) {
    editOrderEndDate(input: $input) {
      ok
      err
    }
  }
`;

export const useEditOrderEndDate = (
  onCompleted?: (data: EditOrderEndDateMutation) => void,
  onError?: (error: ApolloError) => void
) => {
  return useMutation<
    EditOrderEndDateMutation,
    EditOrderEndDateMutationVariables
  >(EDIT_ORDER_END_DATE_MUTATION, {
    onCompleted,
    onError,
  });
};
