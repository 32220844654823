import { gql, useLazyQuery } from "@apollo/client";
import {
  ListPackOrderQuery,
  ListPackOrderQueryVariables,
} from "../generated/graphql";

const LIST_PACK_ORDER_QUERY = gql`
  query listPackOrder($input: ListPackOrderInput!) {
    listPackOrder(input: $input) {
      ok
      err
      orders {
        id
        goodName
        orderNum
        goodOptionName
        receipt {
          receiptNum
        }
      }
    }
  }
`;

export const useListPackOrder = (
  onCompleted?: (data: ListPackOrderQuery) => void
) => {
  return useLazyQuery<ListPackOrderQuery, ListPackOrderQueryVariables>(
    LIST_PACK_ORDER_QUERY,
    {
      onCompleted,
      fetchPolicy: "network-only",
    }
  );
};
