import { useState } from "react";
import { boundState, eDate, sDate } from "../../apollo";
import refresh from "../../assets/icon/refresh.png";
import { ko } from "date-fns/esm/locale";
import searchdate from "../../assets/icon/searchdate.png";
import { CSVLink } from "react-csv";
import excel from "../../assets/icon/excel.png";
import { boundheaders } from "../../constants/datas";
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import { IDateSearchFormProps } from "./component-tab-order";
import moment from "moment";
import { BoundType } from "../../generated/graphql";
export interface ComponentTabBound {
  dataList?: any[];
  DataCallback?: () => void;
  setDateSearch?: (sDate?: Date, eDate?: Date) => void;
}

export const ComponentTabBound: React.FC<ComponentTabBound> = ({
  dataList = [],
  DataCallback,
  setDateSearch,
}) => {
  const { register, getValues, handleSubmit } = useForm<IDateSearchFormProps>({
    mode: "all",
  });
  const datalist = dataList.map((row) => ({
    ...row,
    orderedAt: moment(row.orderedAt).format("YYYY-MM-DD HH:mm"),
    expiryDate: moment(row.expiryDate).format("YYYY-MM-DD"),
    type: BoundType.Inbound ? "입고" : "출고",
  }));
  const onSubmit = () => {
    const { sDate, eDate } = getValues();
    if (setDateSearch) {
      setDateSearch(sDate, eDate);
    }
  };
  const inBoundHandler = () => {
    boundState(BoundType.Inbound);
  };
  const outBoundHandler = () => {
    boundState(BoundType.Outbound);
  };
  const refetchHandler = () => {
    window.location.href = "/bound";
  };

  return (
    <div className="flex flex-wrap justify-between">
      <div className="flex flex-wrap">
        <button
          className="py-3 px-4 relative"
          onClick={() => {
            refetchHandler();
          }}
        >
          <img src={refresh} alt="새로고침" className="w-[30px] h-[30px]" />
        </button>
        <div>
          <button
            className="text-xs bg-white w-[53px] h-[30px] py-[7px] rounded-l-xl my-[13px] text-[#8B8C8C] border-r border-[#cccccc] font-bold"
            onClick={() => inBoundHandler()}
          >
            입고
          </button>
          <button
            onClick={() => outBoundHandler()}
            className="text-xs bg-white w-[53px] h-[30px] py-[7px] rounded-r-xl my-[13px] text-[#8B8C8C] font-bold "
          >
            출고
          </button>
        </div>
      </div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="flex flex-wrap gap-x-4 content-center">
          <p className="text-[#8B8C8C] text-sm flex flex-wrap content-center">
            기간
          </p>
          <div className="flex flex-wrap content-center">
            <input
              {...register("sDate")}
              type="date"
              className="border w-full  border-[#F0F0F4] text-base rounded-lg py-1 px-4"
            ></input>
          </div>
          <span className="text-white flex flex-wrap content-center">~</span>
          <div className="flex flex-wrap content-center">
            <input
              {...register("eDate")}
              type="date"
              className="border w-full  border-[#F0F0F4] text-base rounded-lg py-1 px-4"
            ></input>
          </div>

          <button className="py-3">
            <img src={searchdate} alt="찾기" className="w-[32px] h-[30px]" />
          </button>
        </div>
      </form>
      <div className="flex flex-wrap justify-end pr-8">
        <div className="flex flex-wrap gap-x-3">
          <CSVLink
            data={datalist}
            headers={boundheaders}
            filename={`입출고관리`}
            className={`flex flex-wrap gap-x-1  w-[72px] h-[30px] my-[13px] bg-white text-center items-center rounded-lg`}
          >
            <p className="pl-[15px] text-xs text-[#8B8C8C]">csv</p>
            <img src={excel} className="w-[10px] h-3" />
          </CSVLink>
        </div>
      </div>
    </div>
  );
};
