import { useState } from "react";
import { useForm } from "react-hook-form";
import img from "../assets/icon/coolicon.png";
import {
  IEditBannerOptionFormProps,
  useEditBannerOption,
} from "../hooks/useEditBannerOption";
import { Button } from "./button";
import { upload } from "../functions/uploads";
import { useActiveBannerOption } from "../hooks/useActiveBannerOption";
import {
  ActiveBannerOptionInput,
  ActiveBannerOptionMutation,
  EditBannerOptionInput,
  EditBannerOptionMutation,
  ListBannerOptionQuery,
} from "../generated/graphql";
import { ArrElement } from "../sub-routes/sub-admin-up-product-detail";

export type TBannerOption = ArrElement<
  ListBannerOptionQuery["listBannerOption"]["bannerOptions"]
>;

interface BannerOptionFormProps {
  bannerOption: TBannerOption;
  refetch: () => void;
  index: number;
}

export const BannerOptionForm: React.FC<BannerOptionFormProps> = ({
  bannerOption,
  refetch,
  index,
}) => {
  const [text, setText] = useState<string>("1");
  const [optionEvent, setOptionEvent] = useState<boolean>(
    bannerOption.isEvent || false
  );
  const [optionFree, setOptionFree] = useState<boolean>(
    bannerOption.isFree || false
  );
  const [optionRes, setOptionRes] = useState<boolean>(
    bannerOption.isRes || false
  );
  const [optionOnePlusOne, setOptionOnePlusOne] = useState<boolean>(
    bannerOption.isOnePlusOne || false
  );
  const [uploading, setUploading] = useState<boolean>(false);
  const {
    getValues,
    handleSubmit,
    register,
    formState: { errors, isValid },
  } = useForm<IEditBannerOptionFormProps>({ mode: "all" });

  const onEditCompleted = async (data: EditBannerOptionMutation) => {
    const {
      editBannerOption: { ok, err },
    } = data;
    if (ok) {
      await refetch();
    }

    setUploading(false);
  };

  const [
    editBannerOption,
    { loading: editBannerOptionLoading },
  ] = useEditBannerOption(onEditCompleted);
  const editBannerOptionHandler = (bannerId: number, viewUuid: string) => {
    const editBannerOptionInput: EditBannerOptionInput = {
      bannerId,
      viewUuid,
    };
    editBannerOption({ variables: { input: editBannerOptionInput } });
  };

  const onSubmit = () => {
    const { link, title, note, alt } = getValues();
    const input: EditBannerOptionInput = {
      bannerId: +bannerOption.id,
      link,
      title,
      note,
      alt,
      isEvent: optionEvent,
      isFree: optionFree,
      isRes: optionRes,
      isOnePlusOne: optionOnePlusOne,
    };
    editBannerOption({ variables: { input: input } });
  };

  const uploadBannerOptionHandler = async (
    event: any,
    bannerOptionId: number
  ) => {
    if (uploading) {
      alert("파일을 업로드 중입니다.");
      return;
    }
    if (!editBannerOptionLoading) {
      setUploading(true);
      const result = await upload(event);

      if (!bannerOptionId) {
        alert("배너 정보가 잘못되었습니다.");
      }
      if (result.data.ok) {
        editBannerOptionHandler(bannerOptionId, result.data.upload.uuid);
        alert("저장되었습니다.");
      } else {
        alert(`잘못된 파일입니다.`);
      }
    }
  };

  const onActiveCompleted = async (data: ActiveBannerOptionMutation) => {
    const {
      activeBannerOption: { ok, err },
    } = data;
    if (ok) {
      alert("활성화 되었습니다.");
      await refetch();
    } else {
      alert(err);
    }
  };
  const [
    activeBannerOption,
    { loading: activeBannerOptionLoading },
  ] = useActiveBannerOption(onActiveCompleted);
  const activeBannerOptionHandler = (bannerOptionId: number) => {
    if (!activeBannerOptionLoading) {
      const activeBannerOptionInput: ActiveBannerOptionInput = {
        bannerOptionId,
      };
      activeBannerOption({ variables: { input: activeBannerOptionInput } });
    }
  };

  return (
    <div className="space-y-2">
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="w-full flex flex-col flex-wrap gap-y-4 ">
          <p className="flex flex-wrap content-center gap-x-2 w-[50%]">
            <span className="flex flex-wrap content-center">
              <span className="font-bold">{index}. &nbsp;&nbsp;</span> URL:{" "}
            </span>
            <input
              {...register("link")}
              type="text"
              defaultValue={bannerOption.link ? bannerOption.link : ""}
              className="rounded-lg border flex-1 py-1 px-2"
            />
            <div className="justify-center content-center pl-4">
              <Button
                className={`py-1 px-2 rounded-lg bg-red-400 text-white`}
                canClick={true}
                actionText={`등록`}
                loading={editBannerOptionLoading}
                color={"text-white"}
              />
            </div>
          </p>
          <p className=" flex flex-wrap content-center gap-x-2 w-[50%]">
            <span className="flex flex-wrap content-center w-10">Title: </span>
            <input
              {...register("title")}
              type="text"
              defaultValue={bannerOption.title ? bannerOption.title : ""}
              className="rounded-lg border flex-1 py-1 px-2"
            />
          </p>
          <p className=" flex flex-wrap content-center gap-x-2 w-[50%]">
            <span className="flex flex-wrap content-center w-10">Note: </span>
            <input
              {...register("note")}
              type="text"
              defaultValue={bannerOption.note ? bannerOption.note : ""}
              className="rounded-lg border flex-1 py-1 px-2"
            />
          </p>
          <p className=" flex flex-wrap content-center gap-x-2 w-[50%]">
            <span className="flex flex-wrap content-center w-10">Alt: </span>
            <input
              {...register("alt")}
              type="text"
              defaultValue={bannerOption.alt ? bannerOption.alt : ""}
              className="rounded-lg border flex-1 py-1 px-2"
            />
          </p>
          <p className="flex flex-wrap content-center gap-x-8 w-full">
            <div className="flex flex-wrap gap-x-2 content-center">
              <input
                type="checkbox"
                name="event"
                checked={optionFree}
                onChange={() => setOptionFree(!optionFree)}
              />
              <button
                className={`py-[2px] px-[6px] font-semibold leading-[14px] rounded-[20px] bg-[#00B0EB] text-white text-[10px]`}
              >
                무료배송
              </button>
            </div>
            <div className="flex flex-wrap gap-x-2 content-center">
              <input
                type="checkbox"
                name="event"
                checked={optionRes}
                onChange={() => setOptionRes(!optionRes)}
              />
              <button
                className={`py-[2px] px-[6px] font-semibold leading-[14px] rounded-[20px] bg-[#FFE400] text-[#363A3D] text-[10px]`}
              >
                예약배송
              </button>
            </div>
            <div className="flex flex-wrap gap-x-2 content-center">
              <input
                type="checkbox"
                name="event"
                checked={optionEvent}
                onChange={() => setOptionEvent(!optionEvent)}
              />
              <button
                className={`py-[2px] px-[6px] font-semibold leading-[14px] rounded-[20px] bg-[#B42713] text-[#fff] text-[10px]`}
              >
                이벤트
              </button>
            </div>
            <div className="flex flex-wrap gap-x-2 content-center">
              <input
                type="checkbox"
                name="event"
                checked={optionOnePlusOne}
                onChange={() => setOptionOnePlusOne(!optionOnePlusOne)}
              />
              <button
                className={`py-[2px] px-[6px] font-semibold leading-[14px] rounded-[20px] bg-[#00B0EB] text-white text-[10px]`}
              >
                1+1
              </button>
            </div>
            <div>
              <button
                className={`rounded-lg  w-[48px] h-7 text-white ${
                  bannerOption.isActive === false
                    ? "bg-[#234873]"
                    : "bg-[#ffc2b8]"
                }`}
                onClick={() => {
                  if (bannerOption.isActive === false)
                    activeBannerOptionHandler(bannerOption.id);
                }}
              >
                {bannerOption.isActive === false ? "비활성화" : "활성화"}
              </button>
            </div>
          </p>
        </div>
      </form>
      <div className="w-full grid grid-cols-1 gap-3">
        <div
          key={`banner-${bannerOption.id}`}
          className="w-1/2 aspect-[7/3] border rounded-lg border-[#000000] relative"
        >
          <label
            htmlFor={`banner${bannerOption.id}`}
            className="flex flex-wrap w-full h-full content-center justify-center cursor-pointer"
          >
            <img src={img}></img>
            <img
              src={`${
                bannerOption.upload?.url !== null
                  ? bannerOption.upload?.url
                  : ""
              }`}
              alt="배너이미지"
              className={`absolute left-0 top-0 w-full h-full rounded-lg ${
                !Boolean(bannerOption.upload) ? "hidden" : ""
              } `}
            />
          </label>
          <input
            type="file"
            className="hidden"
            id={`banner${bannerOption.id}`}
            accept="image/png, image/jpeg, image/jpg"
            onChange={(event: any) =>
              uploadBannerOptionHandler(event, bannerOption.id)
            }
          ></input>
        </div>
      </div>
    </div>
  );
};
