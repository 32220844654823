import { ApolloError, gql, useMutation } from "@apollo/client";
import { CrPackMutation, CrPackMutationVariables } from "../generated/graphql";

const CR_PACK_MUTATION = gql`
  mutation crPack($input: CrPackInput!) {
    crPack(input: $input) {
      ok
      err
    }
  }
`;

export const useCrPack = (
  onCompleted?: (data: CrPackMutation) => void,
  onError?: (error: ApolloError) => void
) => {
  return useMutation<CrPackMutation, CrPackMutationVariables>(
    CR_PACK_MUTATION,
    {
      onCompleted,
      onError,
    }
  );
};
