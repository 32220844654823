import { ApolloError, gql, useMutation } from "@apollo/client";
import {
  EditOrderStatusMutation,
  EditOrderStatusMutationVariables,
} from "../generated/graphql";

const EDIT_ORDER_STATUS_MUTATION = gql`
  mutation editOrderStatus($input: EditOrderStatusInput!) {
    editOrderStatus(input: $input) {
      ok
      err
    }
  }
`;

export const useEditOrderStatus = (
  onCompleted?: (data: EditOrderStatusMutation) => void,
  onError?: (error: ApolloError) => void
) => {
  return useMutation<EditOrderStatusMutation, EditOrderStatusMutationVariables>(
    EDIT_ORDER_STATUS_MUTATION,
    {
      onCompleted,
      onError,
    }
  );
};
