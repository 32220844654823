import React, { useState } from "react";
import {
  ComponentTabLink,
  ITabLink,
  ITabTitle,
  PageType,
} from "../components/component-tab";
import { AsideAdminComponent } from "../layout/aside-admin";
import Papa from "papaparse";
import {
  CrGoodBulkElement,
  CrGoodBulkInput,
  CrGoodBulkMutation,
} from "../generated/graphql";
import { getGoodCategory, getGoodType } from "../constants/enum";
import { useCrGoodBulk } from "../hooks/useCrGoodBulk";
import { useNavigate } from "react-router";

export type ListGoodBulk = CrGoodBulkElement & {
  index: number;
  company: string;
};

export const AdminCrProductBulk = () => {
  const tabList: ITabLink[] = [];
  const stitle: ITabTitle = { text: "상품 등록" };
  const [listGood, setListGood] = useState<ListGoodBulk[]>([]);
  const navigate = useNavigate();

  const onComplete = (data: CrGoodBulkMutation) => {
    const {
      crGoodBulk: { ok },
    } = data;
    if (ok) {
      navigate("/product");
    }
  };
  const [crGoodBulk, { loading }] = useCrGoodBulk(onComplete);
  const crGoodBulkHandler = () => {
    if (loading) return;
    const goods: CrGoodBulkElement[] = [];
    for (const good of listGood) {
      const element: CrGoodBulkElement = {
        categoryFirst: good.categoryFirst,
        categorySecond: good.categorySecond,
        discountRate: good.discountRate,
        goodType: good.goodType,
        optionName: good.optionName,
        plu: good.plu,
        price: good.price,
        summary: good.summary,
        title: good.title,
        weight: good.weight,
      };
      goods.push(element);
    }
    const input: CrGoodBulkInput = { goods };
    crGoodBulk({ variables: { input } });
  };

  const uploadCsvEventHandler = async (event: any) => {
    Papa.parse(event.target.files[0], {
      skipEmptyLines: true,
      complete: (results) => {
        setListGood([]);
        const goodArray = results.data as string[];
        let index = 0;
        for (const good of goodArray) {
          // if (index === 0) {
          //   return;
          // }
          if (good.length < 9) return;
          if (!good[8]) return;
          const [categoryFirst, categorySecond] = getGoodCategory(good[5]);
          const goodType = getGoodType(good[6]);
          const price = +good[7].replaceAll(",", "").trim();

          const _good: ListGoodBulk = {
            index,
            categoryFirst,
            categorySecond,
            discountRate: good[8] ? +good[8] : 0,
            goodType,
            optionName: good[9],
            plu: good[2],
            price,
            summary: good[3],
            title: good[1],
            weight: good[4] ? +good[4] : 0,
            company: good[10],
          };
          if (index > 0) setListGood((goodList) => [...goodList, _good]);

          index = index + 1;
        }
      },
    });
  };

  return (
    <div className="bg-main bg-opacity-50 w-full h-full min-h-screen">
      <div className="w-full mx-auto h-full min-h-screen  bg-gray-100">
        <main id="main">
          <div className="grid grid-cols-[200px_1fr] max-w-full">
            <AsideAdminComponent />
            <div className="mypages bg-gray-100 w-full overflow-hidden">
              <div className="orderWrap">
                <div className="h-fix-content relative">
                  <div className={`w-full h-full px-10 py-8`}>
                    <ComponentTabLink
                      tabs={tabList}
                      stitle={stitle}
                      type={PageType.Create}
                    />
                    <div className="shadow-lg overflow-auto bg-white">
                      {/* Top Botton - Register, Upload Excel file */}
                      <div className="p-3">
                        <label
                          htmlFor="upload-button"
                          className="py-2 px-5 rounded-md bg-gray-300 cursor-pointer"
                        >
                          파일 가져오기
                        </label>
                        <input
                          type="file"
                          className="hidden"
                          id={`upload-button`}
                          accept=".csv"
                          onChange={(event: any) =>
                            uploadCsvEventHandler(event)
                          }
                        />
                        <button
                          className="py-2 px-5 rounded-md bg-gray-300"
                          onClick={crGoodBulkHandler}
                        >
                          등록하기
                        </button>
                      </div>
                    </div>

                    <div className=" shadow-lg h-[800px] h-fix-content w-full overflow-auto bg-white">
                      <table className="align-center w-[1623px] text-[14px]">
                        <colgroup>
                          <col className="w-1/12" />
                          <col className="w-1/12" />
                          <col className="w-1/12" />
                          <col className="w-2/12" />
                          <col className="w-1/12" />
                          <col className="w-1/12" />
                          <col className="w-1/12" />
                          <col className="w-1/12" />
                          <col className="w-1/12" />
                          <col className="w-1/12" />
                          <col className="w-1/12" />
                        </colgroup>
                        <thead>
                          <tr className=" border-b border-[#F9F8F9] h-8">
                            <th className="text-[#8B8C8C]">no</th>
                            <th className="text-[#8B8C8C]">상품명</th>
                            <th className="text-[#8B8C8C]">PLU</th>
                            <th className="text-[#8B8C8C]">상품설명</th>
                            <th className="text-[#8B8C8C]">무게</th>
                            <th className="text-[#8B8C8C]">소카테고리</th>
                            <th className="text-[#8B8C8C]">상품상태</th>
                            <th className="text-[#8B8C8C]">판매가</th>
                            <th className="text-[#8B8C8C]">할인율</th>
                            <th className="text-[#8B8C8C]">옵션명</th>
                            <th className="text-[#8B8C8C]">업체명</th>
                          </tr>
                        </thead>
                        <tbody className="">
                          {listGood.length > 0 &&
                            listGood.map((product, index) => (
                              <tr
                                key={`product-${index}`}
                                className="border-b border-[#F9F8F9] h-[68px]"
                              >
                                <td className="table-text">
                                  {`${product.index}`}
                                </td>
                                <td className="table-text">{`${product.title}`}</td>
                                <td className="table-text">{`${product.plu}`}</td>
                                <td className="table-text py-1">{`${product.summary}`}</td>
                                <td className="table-text">{`${product.weight}g`}</td>
                                <td className="table-text font-bold">{`${product.categorySecond}`}</td>
                                <td className="table-text font-bold">{`${product.goodType}`}</td>
                                <td className="table-text py-1">{`${product.price?.toLocaleString()}`}</td>
                                <td className="table-text">{`${product.discountRate}`}</td>
                                <td className="table-text">{`${product.optionName}`}</td>
                                <td className="table-text">{`${product.company}`}</td>
                              </tr>
                            ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
};
