import { useEffect, useState } from "react";
import { pageIndicator } from "../apollo";
import { AsideAdminComponent } from "../layout/aside-admin";
import { useLocation, useNavigate } from "react-router-dom";
import {
  ComponentTabLink,
  ITabLink,
  ITabTitle,
  PageType,
} from "../components/component-tab";
import { Link } from "react-router-dom";
import exit from "../assets/icon/exit.png";
import { Modal } from "@mui/material";

import React from "react";
import { useListInquiry } from "../hooks/useListInquiry";
import moment from "moment";
import { useDelInquiry } from "../hooks/useDelInquiry";
import { TableError } from "../components/table-error";
import { PaginationComponent } from "../components/pagination";
import { useEditInquiryStatus } from "../hooks/useEditInquiryStatus";
import {
  InquiryStatus,
  EditInquiryStatusInput,
  ListInquiryInput,
  DelInquiryInput,
  EditInquiryStatusMutation,
  DelInquiryMutation,
} from "../generated/graphql";

export interface ISearchFormProps {
  search: string;
}

export const AdminListDoor = () => {
  const location = useLocation();
  const [open, setOpen] = React.useState(false);
  const [page, setPage] = useState<number>(1);
  const [search, setSearch] = useState<string>("");
  const [value, setValue] = React.useState("확인중");
  const [stateId, setStateId] = useState<number>(0);
  const [change, setChange] = useState<InquiryStatus>(InquiryStatus.New);
  const navigate = useNavigate();
  const tabList: ITabLink[] = [];
  const stitle: ITabTitle = {
    text: "문의 관리",
  };
  const word = "문의";
  const onStatusChange = async (data: EditInquiryStatusMutation) => {
    const {
      editInquiryStatus: { ok, err },
    } = data;
    if (ok) {
      // alert("상태가 변경되었습니다.");
      await refetch();
    } else {
      alert(err);
    }
  };
  const [editInquiryStatus] = useEditInquiryStatus(onStatusChange);
  const upInquiryStatusHandler = (inquiryId: number, status: InquiryStatus) => {
    const input: EditInquiryStatusInput = {
      inquiryId: inquiryId,
      status: status,
    };
    editInquiryStatus({ variables: { input } });
  };
  const [listInquiry, { data, loading, refetch }] = useListInquiry();
  const listInquiryHandler = (
    page: number | null,
    search: string | null,
    start?: Date | null,
    end?: Date | null
  ) => {
    const input: ListInquiryInput = {
      ...(page && { page: +page }),
      ...(search && { search: decodeURI(search) }),
      ...(start && { start }),
      ...(end && { end }),
    };
    listInquiry({ variables: { input } });
  };

  const onDelCompleted = async (data: DelInquiryMutation) => {
    const {
      delInquiry: { ok, err },
    } = data;
    if (ok) {
      // alert("문의삭제");
      await refetch();
    } else {
      alert(err);
    }
  };

  const [delInquiry, { loading: delInquiryLoading }] = useDelInquiry(
    onDelCompleted
  );
  const delInquiryHandler = (inquiryId: number) => {
    if (delInquiryLoading) return;
    if (
      window.confirm(
        "삭제한 문의는 복구할 수 없습니다. 정말로 삭제하시겠습니까?"
      )
    ) {
      const input: DelInquiryInput = {
        inquiryId,
      };
      delInquiry({ variables: { input } });
    }
  };

  // --- Search ---
  const setSearchHandler = (search: string) => {
    navigate({
      pathname: "/door",
      search: `${search ? `?search=${search}` : ""}`,
    });
    listInquiryHandler(1, search);
  };

  // --- Page ---
  const pageHandler = (page: number) => {
    const search =
      location.search.split("search=").length > 1
        ? location.search.split("search=")[1].split("&")[0]
        : null;
    setPage(page);
    navigate({
      pathname: "/door",
      search: `?page=${page}${search ? `&search=${search}` : ""}`,
    });
    listInquiryHandler(page, search);
  };
  // --- Period ---
  const periodHandler = (sDate?: Date, eDate?: Date) => {
    listInquiryHandler(1, search, sDate, eDate);
  };
  useEffect(() => {
    pageIndicator("door");
    setPage(
      location.search.split("page=").length > 1
        ? +location.search.split("page=")[1].split("&")[0]
        : 1
    );
    setSearch(
      location.search.split("search=").length > 1
        ? location.search.split("search=")[1].split("&")[0]
        : ""
    );
    listInquiryHandler(page, search);
  }, [search, page]);

  return (
    <div className="bg-main bg-opacity-50 w-full h-full min-h-screen">
      <div className="w-full mx-auto h-full min-h-screen  bg-gray-100">
        <main id="main">
          <div className="grid grid-cols-[200px_1fr] max-w-full">
            <AsideAdminComponent />
            <div className="mypages bg-gray-100 w-full min-w-[1200px] overflow-hidden">
              <div className="orderWrap">
                <div className="  h-fix-content relative">
                  <div className={`w-full h-full px-10 py-8`}>
                    <ComponentTabLink
                      tabs={tabList}
                      stitle={stitle}
                      setSearchs={setSearchHandler}
                      setDateSearch={periodHandler}
                      type={PageType.Door}
                    />
                    {/* 여기 */}
                    <div className="shadow-lg h-[800px] h-fix-content w-full overflow-auto bg-white">
                      <table className="align-center min-w-[1160px] 2xl:min-w-[1623px] text-[14px] ">
                        <colgroup>
                          <col width="48"></col>
                          <col width="64"></col>
                          <col width="128"></col>
                          <col></col>
                          <col width="104"></col>
                          <col width="88"></col>
                          <col width="128"></col>
                          <col></col>
                        </colgroup>
                        <thead>
                          <tr className=" border-b border-[#F9F8F9] h-8">
                            <th className="text-[#8B8C8C]">no</th>
                            <th className="text-[#8B8C8C]">상태</th>
                            <th className="text-[#8B8C8C]">문의유형</th>
                            <th className="text-[#8B8C8C]">제목</th>
                            <th className="text-[#8B8C8C]">문의일</th>
                            <th className="text-[#8B8C8C]">고객명</th>
                            <th className="text-[#8B8C8C]">연락처</th>
                            <th className="text-[#8B8C8C]">비고</th>
                          </tr>
                        </thead>
                        <tbody className="">
                          {!loading &&
                            data?.listInquiry.inquiries &&
                            data.listInquiry.inquiries.map((inquiry, index) => (
                              <tr
                                key={`inquiry-${inquiry.id}`}
                                className="border-b border-[#F9F8F9] h-[68px]"
                              >
                                <td className="table-text">{index + 1}</td>
                                <td className="table-text">
                                  <button
                                    className={`rounded-lg  w-[48px] h-7 text-white ${
                                      inquiry.status === "Complete"
                                        ? "bg-[#8B8C8C]"
                                        : inquiry.status === "Fail"
                                        ? "bg-[#555B94]"
                                        : inquiry.status === "New"
                                        ? "bg-[#3D3E49]"
                                        : inquiry.status === "Pending"
                                        ? "bg-[#B42713]"
                                        : inquiry.status === "PendingRefund"
                                        ? "bg-[#FFC700]"
                                        : inquiry.status === "Refund"
                                        ? "bg-[#234873]"
                                        : "bg-[#ffc2b8]"
                                    }`}
                                    onClick={() => {
                                      setOpen(true);
                                      setStateId(inquiry.id);
                                    }}
                                  >
                                    {inquiry.status === "Complete"
                                      ? "완료"
                                      : inquiry.status === "Fail"
                                      ? "실패"
                                      : inquiry.status === "New"
                                      ? "New"
                                      : inquiry.status === "Pending"
                                      ? "보류"
                                      : inquiry.status === "PendingRefund"
                                      ? "환불보류"
                                      : inquiry.status === "Refund"
                                      ? "환불"
                                      : "Reply"}
                                  </button>
                                </td>
                                <td className="table-text">{inquiry.type}</td>
                                <td className="text-sm text-left  h-full overflow-hidden">
                                  <Link
                                    to={`/door/detail/${inquiry.id}`}
                                    className={`cursor-pointer hover:underline `}
                                  >
                                    {inquiry.title}
                                  </Link>
                                </td>
                                <td className="table-text">
                                  {inquiry.createdAt &&
                                    moment(inquiry.createdAt).format(
                                      "YYYYMMDD"
                                    )}
                                </td>
                                <td className="table-text">
                                  {inquiry.author.name}
                                </td>
                                <td className="table-text">
                                  {inquiry.author.phone}
                                </td>
                                <td className="table-text">
                                  <button
                                    className="bg-[#CCCCCC] rounded-lg py-[6px]  w-[48px] h-7 text-white"
                                    onClick={() =>
                                      delInquiryHandler(inquiry.id)
                                    }
                                  >
                                    삭제
                                  </button>
                                </td>
                              </tr>
                            ))}
                        </tbody>
                      </table>
                      {loading && (
                        <TableError errorMessage={`정보를 불러오는중입니다`} />
                      )}
                      {!loading &&
                        data?.listInquiry.inquiries &&
                        data.listInquiry.inquiries.length < 1 && (
                          <TableError
                            errorMessage={`${word} 정보가 없습니다`}
                          />
                        )}

                      {!loading &&
                        data?.listInquiry.inquiries &&
                        data.listInquiry.inquiries.length > 0 && (
                          <PaginationComponent
                            page={page}
                            totalPages={Number(data.listInquiry.totalPages)}
                            totalResults={Number(data.listInquiry.totalResults)}
                            pageHandler={pageHandler}
                          />
                        )}
                      <Modal
                        open={open}
                        onClose={() => {
                          setOpen(false);
                        }}
                      >
                        <div className="max-w-[360px] w-full absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2   bg-[#F9F8F9] rounded-lg">
                          <div className="text-center py-2 h-10 text-white font-bold bg-[#3D3E49] rounded-t-lg  ">
                            <div className="flex flex-wrap justify-between">
                              <p className="w-[240px] ml-[40px] font-bold text-base">
                                문의 상태 변경
                              </p>
                              <img
                                src={exit}
                                alt="닫기"
                                className="w-6 mr-2 cursor-pointer"
                                onClick={() => setOpen(false)}
                              ></img>
                            </div>
                          </div>
                          <div className="flex flex-wrap content-center">
                            <div className="px-10 py-10">
                              <div className="grid grid-rows-6 gap-y-4">
                                <div>
                                  <input
                                    type="radio"
                                    name="door"
                                    id="Complete"
                                    value=""
                                    checked={change === InquiryStatus.Complete}
                                    onChange={(e) => {
                                      setChange(InquiryStatus.Complete);
                                    }}
                                  ></input>
                                  <span className="ml-4">답변 완료</span>
                                </div>

                                <div>
                                  <input
                                    type="radio"
                                    name="door"
                                    id="Fail"
                                    checked={change === InquiryStatus.Fail}
                                    onChange={(e) => {
                                      setChange(InquiryStatus.Fail);
                                    }}
                                  ></input>
                                  <span className="ml-4">실패</span>
                                </div>
                                <div>
                                  <input
                                    type="radio"
                                    name="door"
                                    id="new"
                                    value="답변중"
                                    checked={change === InquiryStatus.New}
                                    onChange={(e) => {
                                      setChange(InquiryStatus.New);
                                    }}
                                  ></input>
                                  <span className="ml-4">New</span>
                                </div>
                                <div>
                                  <input
                                    type="radio"
                                    name="door"
                                    id="Pending"
                                    checked={change === InquiryStatus.Pending}
                                    onChange={(e) => {
                                      setChange(InquiryStatus.Pending);
                                    }}
                                  ></input>
                                  <span className="ml-4">보류</span>
                                </div>
                                <div>
                                  <input
                                    type="radio"
                                    name="door"
                                    id="PendingRefund"
                                    checked={
                                      change === InquiryStatus.PendingRefund
                                    }
                                    onChange={(e) => {
                                      setChange(InquiryStatus.PendingRefund);
                                    }}
                                  ></input>
                                  <span className="ml-4">환불 보류</span>
                                </div>
                                <div>
                                  <input
                                    type="radio"
                                    name="door"
                                    id="Refund"
                                    checked={change === InquiryStatus.Refund}
                                    onChange={(e) => {
                                      setChange(InquiryStatus.Refund);
                                    }}
                                  ></input>
                                  <span className="ml-4">환불</span>
                                </div>
                                <div>
                                  <input
                                    type="radio"
                                    name="door"
                                    id="PendingRefund"
                                    checked={change === InquiryStatus.Reply}
                                    onChange={(e) => {
                                      setChange(InquiryStatus.Reply);
                                    }}
                                  ></input>
                                  <span className="ml-4">Reply</span>
                                </div>
                              </div>
                            </div>
                          </div>
                          <button
                            className="w-full h-14 bg-[#F0F0F4] rounded-b-lg"
                            onClick={() => {
                              upInquiryStatusHandler(stateId, change);
                              setOpen(false);
                            }}
                          >
                            확인
                          </button>
                        </div>
                      </Modal>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
};
