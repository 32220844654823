import {
  ApolloClient,
  createHttpLink,
  InMemoryCache,
  makeVar,
  split,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { getToken } from "./constants/token";
import { WebSocketLink } from "@apollo/client/link/ws";
import { getMainDefinition } from "@apollo/client/utilities";
import { BoundType } from "./generated/graphql";
const token = getToken();
export const boundState = makeVar<BoundType | null>(null);
export const sDate = makeVar<Date | null>(new Date());
export const eDate = makeVar<Date | null>(new Date());
export const newOrder = makeVar<Boolean>(false);
export const pageIndicator = makeVar("");
export const isLoggedInVar = makeVar(Boolean(token));
export const authTokenVar = makeVar(token);

// 라이브 라이브  라이브 라이브 라이브 라이브 라이브 라이브
export const UPLOAD_URI =
  process.env.REACT_APP_URI === "dev"
    ? "http://localhost:4977/uploads/file"
    : process.env.REACT_APP_URI === "test"
    ? "https://testback.nilivery.com/uploads/file"
    : "https://mfc.nilivery.com/uploads/file";
const uri =
  process.env.REACT_APP_URI === "dev"
    ? "http://localhost:4977/graphql"
    : process.env.REACT_APP_URI === "test"
    ? "https://testback.nilivery.com/graphql" // ? "http://192.168.0.6:5001/graphql"
    : "https://mfc.nilivery.com/graphql";

const ws_uri =
  process.env.REACT_APP_URI === "dev"
    ? "ws://localhost:4977/graphql"
    : process.env.REACT_APP_URI === "test"
    ? "wss://testback.nilivery.com/graphql" // ? "ws://192.168.0.6:5001/graphql"
    : "wss://mfc.nilivery.com/graphql";
console.log(uri);
const wsLink = new WebSocketLink({
  uri: ws_uri,
  options: {
    reconnect: true,
    connectionParams: {
      "mfc-jwt": authTokenVar() || "",
    },
  },
});

const httpLink = createHttpLink({ uri });

const authLink = setContext((_, { headers }) => {
  return {
    headers: {
      ...headers,
      "mfc-jwt": authTokenVar() || "",
    },
  };
});

const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === "OperationDefinition" &&
      definition.operation === "subscription"
    );
  },
  wsLink,
  authLink.concat(httpLink)
);

export const client = new ApolloClient({
  link: splitLink,
  uri,
  cache: new InMemoryCache({
    typePolicies: {
      Query: {
        fields: {
          isLoggedInVar: {
            read() {
              return isLoggedInVar();
            },
          },
          token: {
            read() {
              return authTokenVar();
            },
          },
        },
      },
    },
  }),
});

export const calculate = (pr: number, per: number) => {
  if (per === 0) {
    return pr;
  } else {
    var result = pr * (1 - per / 100);
    return result;
  }
};
