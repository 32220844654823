import { ApolloError, gql, useMutation } from "@apollo/client";
import {
  EditGoodDetailMutation,
  EditGoodDetailMutationVariables,
} from "../generated/graphql";

const EDIT_GOOD_DETAIL_QUERY = gql`
  mutation editGoodDetail($input: EditGoodDetailInput!) {
    editGoodDetail(input: $input) {
      ok
      err
    }
  }
`;

export const useEditGoodDetail = (
  onCompleted?: (data: EditGoodDetailMutation) => void,
  onError?: (error: ApolloError) => void
) => {
  return useMutation<EditGoodDetailMutation, EditGoodDetailMutationVariables>(
    EDIT_GOOD_DETAIL_QUERY,
    {
      onCompleted,
      onError,
    }
  );
};
