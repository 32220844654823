import { ApolloError, gql, useMutation } from "@apollo/client";
import React from "react";
import {
  DelGoodOptionMutation,
  DelGoodOptionMutationVariables,
} from "../generated/graphql";

const DEL_GOOD_OPTION_MUTATION = gql`
  mutation delGoodOption($input: DelGoodOptionInput!) {
    delGoodOption(input: $input) {
      ok
      err
    }
  }
`;

export const useDelGoodOption = (
  onCompleted?: (data: DelGoodOptionMutation) => void,
  onError?: (Error: ApolloError) => void
) => {
  return useMutation<DelGoodOptionMutation, DelGoodOptionMutationVariables>(
    DEL_GOOD_OPTION_MUTATION,
    {
      onCompleted,
      onError,
    }
  );
};
