import { ApolloError, gql, useMutation } from "@apollo/client";
import {
  SetOrderManagerInput,
  SetOrderManagerMutation,
  SetOrderManagerMutationVariables,
} from "../generated/graphql";

export interface ISetOrderManagerFormProps extends SetOrderManagerInput {}

const SET_ORDER_MANAGER_MUTATION = gql`
  mutation setOrderManager($input: SetOrderManagerInput!) {
    setOrderManager(input: $input) {
      ok
      err
    }
  }
`;

export const useSetOrderManager = (
  onCompleted?: (data: SetOrderManagerMutation) => void,
  onError?: (error: ApolloError) => void
) => {
  return useMutation<SetOrderManagerMutation, SetOrderManagerMutationVariables>(
    SET_ORDER_MANAGER_MUTATION,
    {
      onCompleted,
      onError,
    }
  );
};
