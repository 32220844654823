import moment from "moment";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useParams } from "react-router";
import { pageIndicator } from "../apollo";
import { Button } from "../components/button";
import {
  ComponentTabLink,
  ITabLink,
  ITabTitle,
} from "../components/component-tab";
import { FormError2 } from "../components/form-error";
import {
  BoundStatusOption,
  BoundStatusOptionType,
  BoundTypeOption,
  BoundTypeOptionType,
} from "../constants/enum";
import { IEditBoundFormProps, useEditBound } from "../hooks/useEditBound";
import { useGetBound } from "../hooks/useGetBound";
import { useListCorporationSelect } from "../hooks/useListCorprationSelect";
import { AsideAdminComponent } from "../layout/aside-admin";
import {
  BoundStatus,
  BoundType,
  EditBoundInput,
  EditBoundMutation,
  GetBoundInput,
  GetBoundQuery,
} from "../generated/graphql";

export const AdminUpBound = () => {
  const tabList: ITabLink[] = [];

  const stitle: ITabTitle = { text: "입/출고 편집" };
  const [checked, setChecked] = useState<boolean>(true);
  const [
    listCorporationSelect,
    { data: corporations },
  ] = useListCorporationSelect();
  const params = useParams();
  useEffect(() => {
    pageIndicator("product");
  }, []);

  const {
    register,
    handleSubmit,
    getValues,
    setValue,
    setFocus,
    formState: { errors, isValid },
  } = useForm<IEditBoundFormProps>({ mode: "all" });

  const onGetComplete = (data: GetBoundQuery) => {
    const {
      getBound: { ok, err },
    } = data;
    if (!ok) {
      alert("잘못된 접근입니다.");
      window.location.href = "/bound";
      return;
    }
    setValue("desc", `${data.getBound.bound?.desc}`);
    setValue("stock", Number(`${data.getBound.bound?.stock}`));
    setValue("price", Number(`${data.getBound.bound?.price}`));
    setValue(
      "type",
      data.getBound.bound?.type ? data.getBound.bound?.type : BoundType.Inbound
    );
    setValue(
      "status",
      data.getBound.bound?.status
        ? data.getBound.bound?.status
        : BoundStatus.Gathering
    );
    setValue("corporationName", `${data.getBound.bound?.corporation?.name}`);
    setValue(
      "orderedAt",
      moment(data.getBound.bound?.orderedAt).format("YYMMDD")
    );

    if (data.getBound.bound?.expiryDate === null) {
      setValue("expiryDate", null);
      setChecked(false);
    }
    if (data.getBound.bound?.expiryDate !== null) {
      setValue(
        "expiryDate",
        moment(data.getBound.bound?.expiryDate).format("YYYY-MM-DD")
      );
      setChecked(true);
    }
    setFocus("desc");
  };

  const [getBound, { loading }] = useGetBound(onGetComplete);
  const getBoundHandler = (boundId: number) => {
    const getBoundInput: GetBoundInput = {
      boundId,
    };
    getBound({ variables: { input: getBoundInput } });
  };

  const onUpComplete = (data: EditBoundMutation) => {
    const {
      editBound: { ok, err },
    } = data;
    if (ok) {
      alert("저장되었습니다.");
      window.location.href = "/bound";
    } else {
      alert(err);
    }
  };
  const [editBound, { loading: editBoundLoading }] = useEditBound(onUpComplete);

  const onSubmit = () => {
    if (!params.boundId) return;
    if (!editBoundLoading) {
      const {
        desc,
        stock,
        price,
        type,
        status,
        corporationName,
        orderedAt,
        expiryDate,
      } = getValues();
      const editBoundInput: EditBoundInput = {
        desc,
        stock: +stock,
        price: +price,
        type,
        status,
        corporationName,
        orderedAt,
        expiryDate: checked ? expiryDate : null,
        boundId: +params.boundId,
      };
      editBound({ variables: { input: editBoundInput } });
    }
  };

  useEffect(() => {
    if (!params.boundId) return;
    const boundId = +params.boundId;
    getBoundHandler(boundId);
  }, []);

  useEffect(() => {
    listCorporationSelect();
  }, []);
  return (
    <div className="bg-main bg-opacity-50 w-full h-full min-h-screen">
      <div className="w-full mx-auto h-full min-h-screen bg-white">
        <main id="main">
          <div className="grid grid-cols-[200px_1fr]">
            <AsideAdminComponent />

            <div className="mypages bg-gray-100">
              <div className="orderWrap">
                <div className="bg-gray-100 h-fix-content relative">
                  <div className={`w-full h-full px-10 py-8`}>
                    <ComponentTabLink tabs={tabList} stitle={stitle} />
                    <div className="shadow-lg min-w-[1160px] max-w-[1160px]  2xl:max-w-[1623px]  bg-white">
                      <form onSubmit={handleSubmit(onSubmit)}>
                        <div className="pl-9 pr-11 py-8 space-y-4">
                          <div className="space-y-2">
                            <p className="font-bold text-[#4F4F4F] text-base">
                              desc
                            </p>
                            <input
                              {...register("desc", {
                                required: `desc을 입력해주세요`,
                              })}
                              defaultValue="desc"
                              type="text"
                              className="border w-full h-10 border-[#F0F0F4] text-base rounded-lg py-1 px-4"
                            />
                            {errors.desc?.message && (
                              <FormError2 errorMessage={errors.desc.message} />
                            )}
                          </div>
                          <div className="space-y-2">
                            <p className="font-bold text-[#4F4F4F] text-base">
                              수량
                            </p>
                            <input
                              {...register("stock", {
                                required: `수량을 입력해주세요`,
                              })}
                              defaultValue="stock"
                              type="text"
                              className="border w-full h-10 border-[#F0F0F4] text-base rounded-lg py-1 px-4"
                            />
                            {errors.stock?.message && (
                              <FormError2 errorMessage={errors.stock.message} />
                            )}
                          </div>
                          <div className="space-y-2">
                            <p className="font-bold text-[#4F4F4F] text-base">
                              가격
                            </p>
                            <input
                              {...register("price", {
                                required: `가격을 입력해주세요`,
                              })}
                              type="text"
                              defaultValue="price"
                              className="border w-full h-10 border-[#F0F0F4] text-base rounded-lg py-1 px-4"
                            />
                            {errors.price?.message && (
                              <FormError2 errorMessage={errors.price.message} />
                            )}
                          </div>

                          <div className="grid grid-cols-3 gap-x-6">
                            <div className="space-y-2">
                              <div className="font-bold text-[#4F4F4F] text-base flex flex-wrap justify-between">
                                <p className="font-bold text-[#4F4F4F] text-base">
                                  입/출고
                                </p>
                              </div>
                              <div>
                                <select
                                  {...register(`type`, {
                                    required: "입고/출고를 선택해주세요",
                                  })}
                                  defaultValue="type"
                                  className="border border-[#F0F0F4] rounded-lg py-1 px-2 w-full text-base"
                                >
                                  {Object(BoundTypeOption).map(
                                    (
                                      type: BoundTypeOptionType,
                                      index: number
                                    ) => (
                                      <option
                                        key={`type-option-${index}`}
                                        value={type.value}
                                        disabled={index === 0}
                                      >
                                        {type.label}
                                      </option>
                                    )
                                  )}
                                </select>
                                {errors.type?.message && (
                                  <FormError2
                                    errorMessage={errors.type.message}
                                  />
                                )}
                              </div>
                            </div>
                            <div className="space-y-2">
                              <div className="font-bold text-[#4F4F4F] text-base flex flex-wrap justify-between">
                                <p className="font-bold text-[#4F4F4F] text-base">
                                  상태
                                </p>
                              </div>
                              <div>
                                <select
                                  {...register(`status`, {
                                    required: "상태를 선택해주세요",
                                  })}
                                  defaultValue="status"
                                  className="border border-[#F0F0F4] rounded-lg py-1 px-2 w-full text-base"
                                >
                                  {Object(BoundStatusOption).map(
                                    (
                                      status: BoundStatusOptionType,
                                      index: number
                                    ) => (
                                      <option
                                        key={`status-option-${index}`}
                                        value={status.value}
                                        disabled={index === 0}
                                      >
                                        {status.label}
                                      </option>
                                    )
                                  )}
                                </select>
                                {errors.status?.message && (
                                  <FormError2
                                    errorMessage={errors.status.message}
                                  />
                                )}
                              </div>
                            </div>
                            <div className="space-y-2">
                              <p className="font-bold text-[#4F4F4F] text-base">
                                <input
                                  type="checkbox"
                                  className="mr-2"
                                  checked={checked}
                                  onChange={() => {
                                    setChecked(!checked);
                                  }}
                                ></input>
                                유통기한
                              </p>
                              <input
                                {...register("expiryDate")}
                                type="date"
                                disabled={!checked}
                                className="border w-1/2 h-10 border-[#F0F0F4] text-base rounded-lg py-1 px-4"
                              />
                              {/* {errors.expiryDate?.message && (
                                <FormError2
                                  errorMessage={errors.expiryDate.message}
                                />
                              )} */}
                            </div>
                          </div>

                          <div className="space-y-2">
                            <p className="font-bold text-[#4F4F4F] text-base">
                              거래처 이름
                            </p>
                            <select
                              {...register(`corporationName`, {
                                required: "거래처 이름을 선택해주세요",
                              })}
                              defaultValue="corporationName"
                              className="border w-full border-[#F0F0F4] rounded-lg py-1 px-2"
                            >
                              <option disabled>거래처 선택</option>
                              {corporations?.listCorporationSelect.corporations?.map(
                                (corporation) => (
                                  <option
                                    key={`corporation-${corporation.id}`}
                                    value={corporation.name}
                                  >
                                    {corporation.name}
                                  </option>
                                )
                              )}
                            </select>
                            {errors.corporationName?.message && (
                              <FormError2
                                errorMessage={errors.corporationName.message}
                              />
                            )}
                          </div>
                          <div className="flex flex-wrap justify-center pt-6">
                            <Button
                              className={`w-[240px] h-[40px] bg-[#3D3E49] text-white rounded-lg`}
                              canClick={true}
                              actionText={`등록`}
                              loading={loading}
                              color={"text-white"}
                            />
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
};
