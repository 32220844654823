import { useEffect, useState } from "react";
import { AsideAdminComponent } from "../layout/aside-admin";
import React from "react";
import exit from "../assets/icon/exit.png";
import {
  PageType,
  ComponentTabLink,
  ITabTitle,
} from "../components/component-tab";
import { ITabLink } from "../components/component-tab";
import { useListOrder } from "../hooks/useListOrder";
import { pageIndicator } from "../apollo";
import { Modal } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { useEditOrderStatus } from "../hooks/useEditOrderStatus";
import { PaginationComponent } from "../components/pagination";
import { TableError } from "../components/table-error";
import moment from "moment";
import { useCrPack } from "../hooks/useCrPack";
import { ListOrder } from "./admin-list-order";
import {
  OrderStatus,
  ListOrderInput,
  EditOrderStatusInput,
  CrPackInput,
  EditOrderStatusMutation,
  CrPackMutation,
} from "../generated/graphql";

export const AdminListOrderDelivery = () => {
  const word = "배송준비중";
  const location = useLocation();
  const [open, setOpen] = useState(false);
  const [change, setChange] = React.useState<OrderStatus>(OrderStatus.Pending);
  const [page, setPage] = useState<number>(1);
  const [search, setSearch] = useState<string>("");
  const [stateId, setStateId] = useState<number>(1);
  const [checkedLists, setCheckedLists] = useState<ListOrder[]>([]);
  const [allChecked, setAllChecked] = useState<boolean>(false);
  const [orderIds, setOrderIds] = useState<number[]>([]);
  const navigate = useNavigate();

  // --- 리스트 ---
  const [listOrder, { data, loading, refetch }] = useListOrder();
  const listOrderHandler = (
    page: number | null,
    search: string | null,
    statuses: OrderStatus[],
    start?: Date | null,
    end?: Date | null
  ) => {
    const input: ListOrderInput = {
      ...(page && { page: +page }),
      ...(search && { search: decodeURI(search) }),
      ...(statuses.length > 0 && { statuses }),
      ...(start && { start }),
      ...(end && { end }),
    };
    listOrder({ variables: { input } });
  };

  // --- 상태 변경 ---
  const StateHandler = (id: number) => {
    setStateId(id);
    setOpen(true);
  };

  const onEditCompleted = async (data: EditOrderStatusMutation) => {
    const {
      editOrderStatus: { ok, err },
    } = data;
    if (ok) {
      // alert(`주문상태가 수정되었습니다.`);
      await refetch();
    } else {
      alert(err);
    }
  };
  const [
    editOrderStatus,
    { loading: editOrderStatusLoading },
  ] = useEditOrderStatus(onEditCompleted);
  const editOrderStatusHandler = (orderId: number, status: OrderStatus) => {
    if (!editOrderStatusLoading) {
      const editOrderStatusInput: EditOrderStatusInput = {
        orderId,
        status,
      };
      editOrderStatus({ variables: { input: editOrderStatusInput } });
    }
  };

  // --- Component ---
  const tabList: ITabLink[] = [
    { text: "주문확인", to: "/order" },
    { text: "출고대기", to: "/release" },
    { text: "배송준비중", to: "/order/delivery", selected: true },
    { text: "배송중", to: "/order/start" },
    { text: "배송완료", to: "/order/end" },
    { text: "교환/환불", to: "/order/exchange" },
    { text: "배송확정", to: "/order/confirm" },
  ];

  const stitle: ITabTitle = { text: "배송관리" };

  // --- CheckBox ---
  const setAllCheckedHandler = () => {
    setAllChecked(!allChecked);
    if (allChecked) setCheckedLists([]);

    if (!allChecked && data?.listOrder.orders) {
      setCheckedLists([]);
      for (const delivery of data.listOrder.orders) {
        setCheckedLists((checkedList) => [...checkedList, delivery]);
      }
    }
  };

  const setCheckedListHandler = (deliveryId: number, data: any) => {
    if (
      Boolean(checkedLists.find((checkedId) => checkedId.id === deliveryId))
    ) {
      setCheckedLists(
        checkedLists.filter((checkedId) => checkedId.id !== deliveryId)
      );
    } else {
      setCheckedLists((originalList) => [...originalList, data]);
    }
  };
  useEffect(() => {
    if (checkedLists.length === data?.listOrder.orders?.length)
      setAllChecked(true);
    else setAllChecked(false);
    orderIdsHandler();
  }, [checkedLists]);

  // --- Search ---
  const setSearchHandler = (search: string) => {
    navigate({
      pathname: "/order/delivery",
      search: `${search ? `?search=${search}` : ""}`,
    });
    const statuses: OrderStatus[] = [OrderStatus.Pending];
    listOrderHandler(1, search, statuses);
  };

  const onComplete = async (data: CrPackMutation) => {
    const {
      crPack: { ok, err },
    } = data;
    if (ok) {
      alert("저장되었습니다");
    } else {
      alert(err);
    }
  };

  const orderIdsHandler = () => {
    var _array = [];
    for (var i = 0; i < checkedLists.length; i++) {
      _array.push(checkedLists[i].id);
    }
    setOrderIds(_array);
  };

  const [crPack, { loading: packLoading }] = useCrPack(onComplete);
  const testHandler = () => {
    if (!packLoading) {
      const crPackInput: CrPackInput = {
        orderIds: orderIds,
      };
      crPack({ variables: { input: crPackInput } });
    }
  };

  // --- Page ---
  const pageHandler = (page: number) => {
    const search =
      location.search.split("search=").length > 1
        ? location.search.split("search=")[1].split("&")[0]
        : null;
    setPage(page);
    navigate({
      pathname: "/order/delivery",
      search: `?page=${page}${search ? `&search=${search}` : ""}`,
    });
    const statuses: OrderStatus[] = [OrderStatus.Pending];
    listOrderHandler(page, search, statuses);
  };
  // --- Period ---
  const periodHandler = (sDate?: Date, eDate?: Date) => {
    const statuses: OrderStatus[] = [OrderStatus.Pending];
    listOrderHandler(1, search, statuses, sDate, eDate);
  };

  useEffect(() => {
    pageIndicator("delivery");
    setPage(
      location.search.split("page=").length > 1
        ? +location.search.split("page=")[1].split("&")[0]
        : 1
    );
    setSearch(
      location.search.split("search=").length > 1
        ? location.search.split("search=")[1].split("&")[0]
        : ""
    );
    const statuses: OrderStatus[] = [OrderStatus.Pending];
    listOrderHandler(page, search, statuses);
  }, [search, page]);

  return (
    <div className="bg-main bg-opacity-50 w-full h-full min-h-screen">
      <div className="w-full mx-auto h-full min-h-screen  bg-gray-100">
        <main id="main">
          <div className="grid grid-cols-[200px_1fr] max-w-full">
            <AsideAdminComponent />
            <div className="mypages bg-gray-100 w-full min-w-[1200px] overflow-hidden">
              <div className="orderWrap">
                <div className=" h-fix-content relative">
                  <div className={`w-full h-full px-10 py-8`}>
                    <ComponentTabLink
                      tabs={tabList}
                      stitle={stitle}
                      type={PageType.Delivery}
                      dataList={checkedLists}
                      setSearchs={setSearchHandler}
                      setDateSearch={periodHandler}
                      refetch={refetch}
                    />

                    {/* 목록 */}
                    <div className=" shadow-lg h-[800px] h-fix-content overflow-auto bg-white">
                      <table className="align-center w-[1796px]  text-sm  border-[#F9F8F9] ">
                        <thead>
                          <tr className="border-b border-[#F9F8F9] h-8">
                            <th className="text-[#8B8C8C] leading-3 min-w-[36px]">
                              <input
                                type="checkbox"
                                name="allChk"
                                className="h-5 w-5"
                                checked={allChecked}
                                onChange={setAllCheckedHandler}
                              />
                            </th>
                            <th className="text-[#8B8C8C] min-w-[40px]">no</th>
                            <th className="text-[#8B8C8C] min-w-[64px]">
                              상태
                            </th>
                            <th className="text-[#8B8C8C] min-w-[88px]">
                              주문번호
                            </th>
                            <th className="text-[#8B8C8C] min-w-[112px]">
                              QR코드/송장번호
                            </th>
                            <th className="text-[#8B8C8C] min-w-[88px]">
                              주문일시
                            </th>
                            <th className="text-[#8B8C8C] min-w-[168px]">
                              상품명
                            </th>
                            <th className="text-[#8B8C8C] min-w-[168px]">
                              상품옵션
                            </th>
                            <th className="text-[#8B8C8C] min-w-[48px]">
                              수량
                            </th>
                            <th className="text-[#8B8C8C] min-w-[88px]">
                              구매자
                            </th>
                            <th className="text-[#8B8C8C] min-w-[88px]">
                              수취인
                            </th>
                            <th className="text-[#8B8C8C] min-w-[168px]">
                              주소
                            </th>
                            <th className="text-[#8B8C8C] min-w-[168px]">
                              배송메세지
                            </th>
                            <th className="text-[#8B8C8C] min-w-[128px]">
                              연락처
                            </th>
                            <th className="text-[#8B8C8C] min-w-[88px]">
                              상품코드
                            </th>
                            <th className="text-[#8B8C8C] min-w-[88px]">
                              위치코드
                            </th>
                            <th className="text-[#8B8C8C] min-w-[88px]">
                              무게
                            </th>
                            <th className="text-[#8B8C8C] min-w-[88px]">
                              PACK
                            </th>
                            <th className="text-[#8B8C8C] min-w-[80px]">
                              재배송
                            </th>
                          </tr>
                        </thead>

                        <tbody className="">
                          {!loading &&
                            data?.listOrder.orders &&
                            data.listOrder.orders.map((delivery, index) => (
                              <tr
                                key={`delivery-${delivery.id}`}
                                className="border-b border-[#F9F8F9] h-[68px]"
                              >
                                <td className="table-text">
                                  <input
                                    type="checkbox"
                                    className="h-5 w-5"
                                    checked={
                                      checkedLists.find(
                                        (checkedId) =>
                                          checkedId.id === delivery.id
                                      ) !== undefined
                                    }
                                    onChange={(e) => {
                                      setCheckedListHandler(
                                        delivery.id,
                                        delivery
                                      );
                                    }}
                                  />
                                </td>

                                <td className="table-text">
                                  {" "}
                                  {(page - 1) * 20 + index + 1}
                                </td>

                                <td className="table-text">
                                  <button
                                    className="bg-[#cccccc] rounded-lg py-[4px] font-bold px-[3px]  w-[48px] h-10 text-white keep-all"
                                    onClick={() => {
                                      StateHandler(delivery.id);
                                    }}
                                  >
                                    {delivery.status === "Pending"
                                      ? "배송 준비중"
                                      : ""}
                                  </button>
                                </td>

                                <td className="table-text break-all">
                                  {delivery.receipt?.receiptNum}
                                </td>

                                <td className="table-text font-bold"></td>

                                <td className="table-text font-bold">
                                  {delivery.createdAt &&
                                    moment(delivery.createdAt).format(
                                      "YYMMDD"
                                    )}{" "}
                                  <br />
                                  {delivery.createdAt &&
                                    moment(delivery.createdAt).format("h : mm")}
                                </td>

                                <td className="table-text">
                                  {delivery.good?.title}
                                </td>

                                <td className="table-text">
                                  {delivery.goodOptionName}
                                </td>

                                <td className="table-text font-bold">
                                  {delivery.quantity}
                                </td>

                                <td className="table-text">
                                  {delivery.receipt?.user?.name}
                                </td>

                                <td className="table-text font-bold">
                                  {delivery.receipt?.receiver}
                                </td>

                                <td className="table-text">
                                  {delivery.receipt?.address}
                                </td>

                                <td className="table-text">
                                  {delivery.receipt?.message}
                                </td>

                                <td className="table-text">
                                  {delivery.receipt?.phone}
                                </td>

                                <td className="table-text break-all">
                                  {delivery.good?.goodUuid}
                                </td>

                                <td className="table-text"></td>

                                <td className="table-text">
                                  {delivery.good?.weight &&
                                    delivery.good?.weight + "g"}
                                </td>
                                <td className="table-text">
                                  {delivery.pack?.id}
                                </td>
                                <td className="table-text">
                                  {delivery.redelivery && "재배송"}
                                </td>
                              </tr>
                            ))}

                          <Modal
                            open={open}
                            onClose={() => {
                              setOpen(false);
                            }}
                          >
                            <div className="max-w-[360px] w-full absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2   bg-[#F9F8F9] rounded-lg">
                              <div className="text-center py-2 h-10 text-white font-bold bg-[#3D3E49] rounded-t-lg  ">
                                <div className="flex flex-wrap justify-between">
                                  <p className="w-[240px] ml-[40px] font-bold text-base">
                                    배송 상태변경
                                  </p>
                                  <img
                                    src={exit}
                                    alt="닫기"
                                    className="w-6 mr-2 cursor-pointer"
                                    onClick={() => setOpen(false)}
                                  ></img>
                                </div>
                              </div>
                              <div className="flex flex-wrap content-center">
                                <div className="px-10 py-10">
                                  <div className="grid grid-rows-6 gap-y-4">
                                    <p className="font-bold">주문내역</p>
                                    <div>
                                      <input
                                        type="radio"
                                        name="order"
                                        id="bound"
                                        value={OrderStatus.Bound}
                                        checked={change === OrderStatus.Bound}
                                        onChange={(e) => {
                                          if (e.target.checked) {
                                            setChange(OrderStatus.Bound);
                                          }
                                        }}
                                      ></input>
                                      <span className="ml-4">출고대기</span>
                                    </div>
                                    <p className="font-bold">배송준비</p>
                                    <div>
                                      <input
                                        type="radio"
                                        name="order"
                                        id="pending"
                                        value={OrderStatus.Pending}
                                        checked={change === OrderStatus.Pending}
                                        onChange={(e) => {
                                          if (e.target.checked) {
                                            setChange(OrderStatus.Pending);
                                          }
                                        }}
                                      ></input>
                                      <span className="ml-4">배송 준비</span>
                                    </div>

                                    <p className="font-bold">배송중</p>
                                    <div>
                                      <input
                                        type="radio"
                                        name="order"
                                        id="Drone"
                                        value="Drone"
                                        onChange={(e) => {
                                          if (e.target.checked) {
                                            setChange(OrderStatus.Drone);
                                          }
                                        }}
                                      ></input>
                                      <span className="ml-4">드론 비행</span>
                                    </div>
                                    <div>
                                      <input
                                        type="radio"
                                        name="order"
                                        id="Port"
                                        value="HandOut"
                                        onChange={(e) => {
                                          if (e.target.checked) {
                                            setChange(OrderStatus.Port);
                                          }
                                        }}
                                      ></input>
                                      <span className="ml-4">
                                        최종 배송준비
                                      </span>
                                    </div>
                                    <div>
                                      <input
                                        type="radio"
                                        name="order"
                                        id="Stored"
                                        value="Stored"
                                        onChange={(e) => {
                                          if (e.target.checked) {
                                            setChange(OrderStatus.Stored);
                                          }
                                        }}
                                      ></input>
                                      <span className="ml-4">픽업포트</span>
                                    </div>
                                    <div>
                                      <input
                                        type="radio"
                                        name="order"
                                        id="PendingDelivered"
                                        value="PendingDeliverd"
                                        onChange={(e) => {
                                          if (e.target.checked) {
                                            setChange(
                                              OrderStatus.PendingDelivered
                                            );
                                          }
                                        }}
                                      ></input>
                                      <span className="ml-4">최종 배송</span>
                                    </div>
                                    <p className="font-bold">배송완료</p>
                                    <div>
                                      <input
                                        type="radio"
                                        name="order"
                                        id="check"
                                        value="Delivered"
                                        onChange={(e) => {
                                          if (e.target.checked) {
                                            setChange(OrderStatus.Delivered);
                                          }
                                        }}
                                      ></input>
                                      <span className="ml-4">배송 완료</span>
                                    </div>
                                    <p className="font-bold">환불/교환</p>
                                    <div>
                                      <input
                                        type="radio"
                                        name="order"
                                        id="Refunded"
                                        onChange={(e) => {
                                          if (e.target.checked) {
                                            setChange(OrderStatus.Refunded);
                                          }
                                        }}
                                      ></input>
                                      <span className="ml-4">환불</span>
                                    </div>
                                    <div>
                                      <input
                                        type="radio"
                                        name="order"
                                        id="end"
                                        value="Exchange"
                                        onChange={(e) => {
                                          if (e.target.checked) {
                                            setChange(OrderStatus.Exchange);
                                          }
                                        }}
                                      ></input>
                                      <span className="ml-4">교환</span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <button
                                className="w-full h-14 bg-[#F0F0F4] rounded-b-lg"
                                onClick={() => {
                                  editOrderStatusHandler(stateId, change);
                                  setOpen(false);
                                }}
                              >
                                확인
                              </button>
                            </div>
                          </Modal>
                        </tbody>
                      </table>
                      {loading && (
                        <TableError errorMessage={`정보를 불러오는중입니다`} />
                      )}
                      {!loading &&
                        data?.listOrder.orders &&
                        data.listOrder.orders.length < 1 && (
                          <TableError
                            errorMessage={`${word} 정보가 없습니다`}
                          />
                        )}

                      {!loading &&
                        data?.listOrder.orders &&
                        data.listOrder.orders.length > 0 && (
                          <PaginationComponent
                            page={page}
                            totalPages={Number(data.listOrder.totalPages)}
                            totalResults={Number(data.listOrder.totalResults)}
                            pageHandler={pageHandler}
                          />
                        )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
};
