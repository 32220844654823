import { gql, useLazyQuery } from "@apollo/client";
import {
  ListProjectQuery,
  ListProjectQueryVariables,
} from "../generated/graphql";

const LIST_PROJECT_QUERY = gql`
  query listProject($input: ListProjectInput!) {
    listProject(input: $input) {
      ok
      err
      totalPages
      totalResults
      row
      projects {
        id
        createdAt
        name
        note
        status
        startAt
        endAt
        flights {
          id
        }
        ports {
          id
        }
      }
    }
  }
`;

export const useListProject = (
  onCompleted?: (data: ListProjectQuery) => void
) => {
  return useLazyQuery<ListProjectQuery, ListProjectQueryVariables>(
    LIST_PROJECT_QUERY,
    {
      onCompleted,
      fetchPolicy: "network-only",
    }
  );
};
