import { useState } from "react";
import { eDate, sDate } from "../../apollo";
import refresh from "../../assets/icon/refresh.png";
import { ko } from "date-fns/esm/locale";
import searchdate from "../../assets/icon/searchdate.png";

import { Link } from "react-router-dom";

export interface ComponentTabProject {
  dataList?: any[];
  DataCallback?: () => void;
}

export const ComponentTabProject: React.FC<ComponentTabProject> = ({
  dataList = [],
  DataCallback,
}) => {
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());

  const dateHandler = (date: Date) => {
    sDate(date);
  };
  const edateHandler = (date: Date) => {
    eDate(date);
  };
  const refetchHandler = () => {
    window.location.href = "/project";
  };
  return (
    <div className="flex flex-wrap justify-between">
      <div className="flex flex-wrap">
        <button
          className="py-3 px-4 relative"
          onClick={() => {
            refetchHandler();
          }}
        >
          <img src={refresh} alt="새로고침" className="w-[30px] h-[30px]" />
        </button>
      </div>
      <div className="flex flex-wrap space-x-2"></div>
      <div className="flex flex-wrap justify-end pr-8">
        <Link
          to={`/project/create`}
          className="bg-transparent bg-[#8b8c8c] w-[104px] h-[30px] py-[7px] text-xs font-bold text-center rounded-lg my-[13px] text-white"
        >
          프로젝트 등록
        </Link>
      </div>
    </div>
  );
};
