import { useRef, useState } from "react";
import { eDate, sDate } from "../../apollo";
import refresh from "../../assets/icon/refresh.png";
import { ComponentToPrint } from "../component-to-print";
import { ko } from "date-fns/esm/locale";
import searchdate from "../../assets/icon/searchdate.png";
import { CSVLink } from "react-csv";
import exit from "../../assets/icon/exit.png";
import excel from "../../assets/icon/excel.png";
import ReactToPrint from "react-to-print";
import { releaseheaders } from "../../constants/datas";
import { useEditOrdersStatus } from "../../hooks/useEditOrdersStatus";
import React from "react";
import { Modal } from "@mui/material";
import { IDateSearchFormProps } from "./component-tab-order";
import { useForm } from "react-hook-form";
import moment from "moment";
import {
  OrderStatus,
  EditOrdersStatusInput,
  EditOrdersStatusMutation,
} from "../../generated/graphql";

export interface ComponentTabRelease {
  dataList?: any[];
  DataCallback?: () => void;
  refetch?(): void;
  setDateSearch?: (sDate?: Date, eDate?: Date) => void;
}

export const ComponentTabRelease: React.FC<ComponentTabRelease> = ({
  dataList = [],
  DataCallback,
  refetch,
  setDateSearch,
}) => {
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [open, setOpen] = React.useState(false);
  const [change, setChange] = React.useState<OrderStatus>(OrderStatus.Bound);
  const { register, getValues, handleSubmit } = useForm<IDateSearchFormProps>({
    mode: "all",
  });
  const pageStyle =
    "@page { size: 8cm 6cm ; } @media print{footer{display:none}} @media print{header{display:none} .page-break{page-break-after:always; display:blcok;}} @media all{.page-break{display:none}} ";
  const componentRef1 = useRef(null);
  const datalist = dataList.map((row) => ({
    ...row,
    phone: `=""${row.receipt.phone}""`,
    createdAt: moment(row.createAt).format("YYYY-MM-DD HH:mm"),
  }));
  const dateHandler = (date: Date) => {
    sDate(date);
  };
  const edateHandler = (date: Date) => {
    eDate(date);
  };
  const refetchHandler = () => {
    window.location.href = "/release";
  };
  // 기간설정
  const onSubmit = () => {
    const { sDate, eDate } = getValues();
    if (setDateSearch) {
      setDateSearch(sDate, eDate);
    }
  };

  // --- 상태 변경 한번에 ---
  const onEditCompleted = async (data: EditOrdersStatusMutation) => {
    const {
      editOrdersStatus: { ok, err },
    } = data;
    if (ok && refetch) {
      await refetch();
    } else {
      alert(err);
    }
  };
  const [editOrdersStatus, { loading }] = useEditOrdersStatus(onEditCompleted);
  const editOrdersStatusHandler = (status: OrderStatus) => {
    var orderIds: number[] = [];
    for (const data of dataList) {
      if (data.id) {
        orderIds.push(Number(data.id));
      }
    }
    if (!loading) {
      const editOrdersStatusInput: EditOrdersStatusInput = {
        orderIds,
        status,
      };
      editOrdersStatus({ variables: { input: editOrdersStatusInput } });
    }
  };
  return (
    <div className="flex flex-wrap justify-between">
      <div className="flex flex-wrap">
        <button
          className="py-3 px-4 relative"
          onClick={() => {
            refetchHandler();
          }}
        >
          <img src={refresh} alt="새로고침" className="w-[30px] h-[30px]" />
        </button>
        <button
          onClick={() => {
            setOpen(true);
          }}
          className="bg-[#555B94] w-[104px] h-[30px] py-[7px] text-xs text-center rounded-lg my-[13px] text-white"
        >
          상태변경
        </button>
      </div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="flex flex-wrap gap-x-4 content-center">
          <h1 className="text-[#8B8C8C] text-sm flex flex-wrap content-center">
            기간
          </h1>
          <div className="flex flex-wrap content-center">
            <input
              {...register("sDate")}
              type="date"
              className="border w-full  border-[#F0F0F4] text-base rounded-lg py-1 px-4"
            ></input>
          </div>
          <span className="text-white flex flex-wrap content-center">~</span>
          <div className="flex flex-wrap content-center">
            <input
              {...register("eDate")}
              type="date"
              className="border w-full  border-[#F0F0F4] text-base rounded-lg py-1 px-4"
            ></input>
          </div>

          <button className="py-3">
            <img src={searchdate} alt="찾기" className="w-[32px] h-[30px]" />
          </button>
        </div>
      </form>
      <div className="flex flex-wrap justify-end pr-8">
        <div className="flex flex-wrap gap-x-3">
          <CSVLink
            data={datalist}
            headers={releaseheaders}
            filename={`출고대기`}
            className={`flex flex-wrap gap-x-1  w-[72px] h-[30px] my-[13px] bg-white text-center items-center rounded-lg`}
          >
            <p className="pl-[15px] text-xs text-[#8B8C8C]">csv</p>
            <img src={excel} className="w-[10px] h-3" />
          </CSVLink>
          <ReactToPrint
            pageStyle={pageStyle}
            trigger={() => (
              <button className="bg-transparent bg-[#8b8c8c] w-[104px] h-[30px] py-[7px] text-xs font-bold text-center rounded-lg my-[13px] text-white">
                운송장 출력
              </button>
            )}
            content={() => componentRef1.current}
          ></ReactToPrint>

          <div className="hidden">
            <ComponentToPrint check={dataList} printRef={componentRef1} />
          </div>
        </div>
      </div>
      <Modal
        open={open}
        onClose={() => {
          setOpen(false);
        }}
      >
        <div className="max-w-[360px] w-full absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2   bg-[#F9F8F9] rounded-lg">
          <div className="text-center py-2 h-10 text-white font-bold bg-[#3D3E49] rounded-t-lg  ">
            <div className="flex flex-wrap justify-between">
              <p className="w-[240px] ml-[40px] font-bold text-base">
                배송 상태변경
              </p>
              <img
                src={exit}
                alt="닫기"
                className="w-6 mr-2 cursor-pointer"
                onClick={() => setOpen(false)}
              ></img>
            </div>
          </div>
          <div className="flex flex-wrap content-center">
            <div className="px-10 py-10">
              <div className="grid grid-rows-6 gap-y-4">
                <div>
                  <input
                    type="radio"
                    name="order"
                    id="inspect"
                    value={OrderStatus.Inspect}
                    onChange={(e) => {
                      if (e.target.checked) {
                        setChange(OrderStatus.Inspect);
                      }
                    }}
                  ></input>
                  <span className="ml-4">주문 확인</span>
                </div>

                <div>
                  <input
                    type="radio"
                    name="order"
                    id="bound"
                    value={OrderStatus.Bound}
                    checked={change === OrderStatus.Bound}
                    onChange={(e) => {
                      if (e.target.checked) {
                        setChange(OrderStatus.Bound);
                      }
                    }}
                  ></input>
                  <span className="ml-4">출고대기</span>
                </div>
                {/* <div>
                                  <input
                                    type="radio"
                                    name="order"
                                    id="bound"
                                    value={OrderStatus.Delivered}
                                    onChange={(e) => {
                                      if (e.target.checked) {
                                        setChange(OrderStatus.Delivered);
                                      }
                                    }}
                                  ></input>
                                  <span className="ml-4">배송완료</span>
                                </div> */}
                <div>
                  <input
                    type="radio"
                    name="order"
                    id="pending"
                    value={OrderStatus.Pending}
                    onChange={(e) => {
                      if (e.target.checked) {
                        setChange(OrderStatus.Pending);
                      }
                    }}
                  ></input>
                  <span className="ml-4">배송 준비</span>
                </div>
              </div>
            </div>
          </div>
          <button
            className="w-full h-14 bg-[#F0F0F4] rounded-b-lg"
            onClick={() => {
              editOrdersStatusHandler(change);
              setOpen(false);
            }}
          >
            확인
          </button>
        </div>
      </Modal>
    </div>
  );
};
