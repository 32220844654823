import { ApolloError, gql, useMutation } from "@apollo/client";
import {
  DelFlightMutation,
  DelFlightMutationVariables,
} from "../generated/graphql";

const DEL_FlIGHT_MUTATION = gql`
  mutation delFlight($input: DelFlightInput!) {
    delFlight(input: $input) {
      ok
      err
    }
  }
`;

export const useDelFlight = (
  onCompleted?: (data: DelFlightMutation) => void,
  onError?: (Error: ApolloError) => void
) => {
  return useMutation<DelFlightMutation, DelFlightMutationVariables>(
    DEL_FlIGHT_MUTATION,
    {
      onCompleted,
      onError,
    }
  );
};
