import { ApolloError, gql, useMutation } from "@apollo/client";
import {
  CrFlightInput,
  CrFlightMutation,
  CrFlightMutationVariables,
} from "../generated/graphql";

export interface ICrFlightFormProps extends CrFlightInput {}
const CR_FLIGHT_MUTATION = gql`
  mutation crFlight($input: CrFlightInput!) {
    crFlight(input: $input) {
      ok
      err
    }
  }
`;

export const useCrFlight = (
  onCompleted?: (data: CrFlightMutation) => void,
  onError?: (error: ApolloError) => void
) => {
  return useMutation<CrFlightMutation, CrFlightMutationVariables>(
    CR_FLIGHT_MUTATION,
    {
      onCompleted,
      onError,
    }
  );
};
