import { gql, useLazyQuery } from "@apollo/client";
import {
  ListFlightQuery,
  ListFlightQueryVariables,
} from "../generated/graphql";

const LIST_FILGHT_QUERY = gql`
  query listFlight($input: ListFlightInput!) {
    listFlight(input: $input) {
      ok
      err
      totalPages
      totalResults
      row
      flights {
        id
        createdAt
        takeOffAt
        flightTime
        landAt
        orderAt
        note
        status
        pack {
          id
          orders {
            goodOption {
              name
              good {
                title
              }
            }
          }
        }
        portTo {
          id
          name
        }
        portFrom {
          id
          name
        }
        pilotTo {
          id
          name
        }
        pilotFrom {
          id
          name
        }
        project {
          id
          name
          ports {
            author {
              name
            }
          }
        }
        drone {
          id
          serial
          model
          name
        }
      }
    }
  }
`;

export const useListFlight = (
  onCompleted?: (data: ListFlightQuery) => void
) => {
  return useLazyQuery<ListFlightQuery, ListFlightQueryVariables>(
    LIST_FILGHT_QUERY,
    {
      onCompleted,
      fetchPolicy: "network-only",
    }
  );
};
