import { gql, useQuery } from "@apollo/client";
import { MeQuery } from "../generated/graphql";

const ME_QUERY = gql`
  query me {
    me {
      id
      name
      username
      role
      phone
      workerType
    }
  }
`;

export const useMe = (onCompleted?: (data: MeQuery) => void) => {
  return useQuery<MeQuery>(ME_QUERY, { onCompleted });
};
//
