import { AsideAdminComponent } from "../layout/aside-admin";
import { useEffect, useState } from "react";
import { pageIndicator } from "../apollo";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { ITabLink } from "../components/component-tab";
import {
  PageType,
  ComponentTabLink,
  ITabTitle,
} from "../components/component-tab";
import { useListCorporation } from "../hooks/useListCorporation";
import { useDelCorporation } from "../hooks/useDelCorporation";
import { TableError } from "../components/table-error";
import { PaginationComponent } from "../components/pagination";
import {
  ListCorporationInput,
  DelCorporationInput,
  DelCorporationMutation,
  ListCorporationQuery,
} from "../generated/graphql";
import { ArrElement } from "../sub-routes/sub-admin-up-product-detail";

export type ListCorporation = ArrElement<
  ListCorporationQuery["listCorporation"]["corporations"]
>;

export const AdminListCorporation = () => {
  const [page, setPage] = useState<number>(1);
  const [search, setSearch] = useState<string>("");
  const [checkedLists, setCheckedLists] = useState<ListCorporation[]>([]);
  const [allChecked, setAllChecked] = useState<boolean>(false);

  const location = useLocation();
  const tabList: ITabLink[] = [
    { text: "거래처", to: "/corporation", selected: true },
    { text: "서비스", to: "/service" },
  ];

  const stitle: ITabTitle = { text: "거래처" };
  const word = "거래처";
  const [listCorporation, { data, loading, refetch }] = useListCorporation();
  const listCorporationHandler = (
    page: number | null,
    search: string | null
  ) => {
    const input: ListCorporationInput = {
      ...(page && { page: +page }),
      ...(search && { search: decodeURI(search) }),
    };
    listCorporation({ variables: { input } });
  };

  const navigate = useNavigate();

  const onDelCompleted = async (data: DelCorporationMutation) => {
    const {
      delCorporation: { ok, err },
    } = data;
    if (ok) {
      // alert(`거래처 삭제`);
      await refetch();
    } else {
      alert(err);
    }
  };
  const [
    delCorporation,
    { loading: delCorporationLoading },
  ] = useDelCorporation(onDelCompleted);
  const delCorporationHandler = (corporationId: number) => {
    if (delCorporationLoading) return;
    if (
      window.confirm(
        "삭제한 자료는 복구할 수 없습니다, 정말로 삭제하시겠습니까?"
      )
    ) {
      const input: DelCorporationInput = {
        corporationId,
      };
      delCorporation({ variables: { input } });
    }
  };

  // --- Search ---
  const setSearchHandler = (search: string) => {
    navigate({
      pathname: "/corporation",
      search: `${search ? `?search=${search}` : ""}`,
    });
    listCorporationHandler(1, search);
  };

  // --- CheckBox ---
  const setAllCheckedHandler = () => {
    setAllChecked(!allChecked);
    if (allChecked) setCheckedLists([]);

    if (!allChecked && data?.listCorporation.corporations) {
      setCheckedLists([]);
      for (const corporation of data.listCorporation.corporations) {
        setCheckedLists((checkedList) => [...checkedList, corporation]);
      }
    }
  };

  const setCheckedListHandler = (corporationId: number, data: any) => {
    if (
      Boolean(checkedLists.find((checkedId) => checkedId.id === corporationId))
    ) {
      setCheckedLists(
        checkedLists.filter((checkedId) => checkedId.id !== corporationId)
      );
    } else {
      setCheckedLists((originalList) => [...originalList, data]);
    }
  };
  useEffect(() => {
    if (checkedLists.length === data?.listCorporation.corporations?.length)
      setAllChecked(true);
    else setAllChecked(false);
  }, [checkedLists]);

  // --- Page ---
  const pageHandler = (page: number) => {
    const search =
      location.search.split("search=").length > 1
        ? location.search.split("search=")[1].split("&")[0]
        : null;
    setPage(page);
    navigate({
      pathname: "/corporation",
      search: `?page=${page}${search ? `&search=${search}` : ""}`,
    });
    listCorporationHandler(page, search);
  };
  useEffect(() => {
    pageIndicator("corporation");
    setPage(
      location.search.split("page=").length > 1
        ? +location.search.split("page=")[1].split("&")[0]
        : 1
    );
    setSearch(
      location.search.split("search=").length > 1
        ? location.search.split("search=")[1].split("&")[0]
        : ""
    );
    listCorporationHandler(page, search);
  }, [search, page]);

  return (
    <div className="bg-main bg-opacity-50 w-full h-full min-h-screen">
      <div className="w-full mx-auto h-full min-h-screen bg-gray-100">
        <main id="main">
          <div className="grid grid-cols-[200px_1fr] max-w-full">
            <AsideAdminComponent />
            <div className="mypages bg-gray-100 w-full min-w-[1200px] overflow-hidden">
              <div className="orderWrap">
                <div className="h-fix-content relative">
                  <div className={`w-full h-full px-10 py-8`}>
                    <ComponentTabLink
                      tabs={tabList}
                      stitle={stitle}
                      type={PageType.Corporation}
                      setSearchs={setSearchHandler}
                    />
                    {/* 목록 */}
                    <div className=" shadow-lg h-[800px] h-fix-content w-full overflow-auto bg-white">
                      <table
                        className="align-center w-[1623px]  text-sm  border-[#F9F8F9] "
                        id="table"
                      >
                        <colgroup>
                          <col width="36" />
                          <col width="64" />
                          <col width="128" />
                          <col width="128" />
                          <col width="88" />
                          <col width="168" />
                          <col width="128" />
                          <col width="128" />
                          <col width="88" />
                          <col />
                        </colgroup>
                        <thead>
                          <tr className="border-b border-[#F9F8F9] h-8">
                            <th className="text-[#8B8C8C] leading-3 ">
                              <input
                                type="checkbox"
                                name="allChk"
                                className="h-[20px] w-[20px]"
                                checked={allChecked}
                                onChange={setAllCheckedHandler}
                              />
                            </th>
                            <th className="text-[#8B8C8C] ">no</th>
                            <th className="text-[#8B8C8C] ">이름</th>
                            <th className="text-[#8B8C8C]">서비스명</th>
                            <th className="text-[#8B8C8C] ">담당자 이름</th>
                            <th className="text-[#8B8C8C] ">주소</th>
                            <th className="text-[#8B8C8C] ">번호</th>
                            <th className="text-[#8B8C8C] ">배송가능지역</th>
                            <th className="text-[#8B8C8C] ">Ceo</th>

                            <th className="text-[#8B8C8C] ">기능</th>
                          </tr>
                        </thead>
                        <tbody>
                          {!loading &&
                            data?.listCorporation.corporations &&
                            data.listCorporation.corporations.map(
                              (corporation, index) => (
                                <tr
                                  className="border-b border-[#F9F8F9] h-[68px]"
                                  key={`corporation-list-${corporation.id}`}
                                >
                                  <td className="table-text">
                                    <input
                                      type="checkbox"
                                      className="h-[20px] w-[20px]"
                                      checked={
                                        checkedLists.find(
                                          (checkedId) =>
                                            checkedId.id === corporation.id
                                        ) !== undefined
                                      }
                                      onChange={(e) => {
                                        setCheckedListHandler(
                                          corporation.id,
                                          corporation
                                        );
                                      }}
                                    />
                                  </td>
                                  <td className="table-text">{index + 1}</td>
                                  <td className="table-text">
                                    <Link
                                      to={`/corporation/update/${corporation.id}`}
                                      className={`cursor-pointer`}
                                    >
                                      {corporation.name}
                                    </Link>
                                  </td>
                                  <td className="table-text">
                                    {corporation.linkService?.name}
                                  </td>
                                  <td className="table-text">
                                    {corporation.managerName}
                                  </td>
                                  <td className="table-text">
                                    {corporation.address}
                                  </td>
                                  <td className="table-text">
                                    {corporation.phone}
                                  </td>
                                  <td className="table-text">
                                    {corporation.regionKeyWord
                                      ? String(corporation.regionKeyWord).split(
                                          " "
                                        )
                                      : null}
                                  </td>
                                  <td className="table-text">
                                    {corporation.ceoName}
                                  </td>
                                  <td className="table-text py-1">
                                    <Link
                                      to={`/corporation/update/${corporation.id}`}
                                      className="inline-block bg-[#CCCCCC] 
                                      rounded-lg py-[6px] px-[3px] w-[48px] h-7 text-xs text-white"
                                    >
                                      편집
                                    </Link>
                                    <button
                                      className="bg-[#CCCCCC] rounded-lg py-[6px]  w-[48px] h-7 ml-4 text-white"
                                      onClick={() =>
                                        delCorporationHandler(corporation.id)
                                      }
                                    >
                                      삭제
                                    </button>
                                  </td>
                                </tr>
                              )
                            )}
                        </tbody>
                      </table>
                      {loading && (
                        <TableError errorMessage={`정보를 불러오는중입니다`} />
                      )}
                      {!loading &&
                        data?.listCorporation.corporations &&
                        data.listCorporation.corporations.length < 1 && (
                          <TableError
                            errorMessage={`${word} 정보가 없습니다`}
                          />
                        )}

                      {!loading &&
                        data?.listCorporation.corporations &&
                        data.listCorporation.corporations.length > 0 && (
                          <PaginationComponent
                            page={page}
                            totalPages={Number(data.listCorporation.totalPages)}
                            totalResults={Number(
                              data.listCorporation.totalResults
                            )}
                            pageHandler={pageHandler}
                          />
                        )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
};
