import { gql, useLazyQuery } from "@apollo/client";
import { ListDriverSelectQuery } from "../generated/graphql";

const LIST_DRIVER_SELECT = gql`
  query listDriverSelect {
    listDriverSelect {
      ok
      err
      users {
        name
        id
      }
    }
  }
`;

export const useListDriverSelect = (
  onCompleted?: (data: ListDriverSelectQuery) => void
) => {
  return useLazyQuery<ListDriverSelectQuery>(LIST_DRIVER_SELECT, {
    onCompleted,
  });
};
