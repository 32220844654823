import { ApolloError, gql, useMutation } from "@apollo/client";
import {
  DelOrderMutation,
  DelOrderMutationVariables,
} from "../generated/graphql";

const DEL_ORDER_MUTATION = gql`
  mutation delOrder($input: DelOrderInput!) {
    delOrder(input: $input) {
      ok
      err
    }
  }
`;

export const useDelOrder = (
  onCompleted?: (data: DelOrderMutation) => void,
  onError?: (Error: ApolloError) => void
) => {
  return useMutation<DelOrderMutation, DelOrderMutationVariables>(
    DEL_ORDER_MUTATION,
    {
      onCompleted,
      onError,
    }
  );
};
