import { useEffect, useState } from "react";
import { AsideAdminComponent } from "../layout/aside-admin";
import {
  ComponentTabLink,
  ITabLink,
  ITabTitle,
} from "../components/component-tab";
import { useGetInquiry } from "../hooks/useGetInuqiry";
import { useParams } from "react-router";
import moment from "moment";
import { useMe } from "../hooks/useMe";
import { useListComment } from "../hooks/useListComment";
import { ComponentCrComment } from "../components/component-cr-comment";
import { ComponentEditComment } from "../components/component-edit-comment";
import { pageIndicator } from "../apollo";
import { Dialog } from "@mui/material";
import { GetInquiryInput, ListCommentInput } from "../generated/graphql";

export const AdminDetailDoor = () => {
  const params = useParams();
  const { data: me } = useMe();
  const [alertOpen, setAlertOpen] = useState(false);
  const [dialogUrl, setDialogUrl] = useState("");
  const [edit, setEdit] = useState<boolean>(false);
  const editHandler = () => {
    setEdit(!edit);
  };

  const [getInquiry, { data, loading, refetch }] = useGetInquiry();
  const getInquiryHandler = (inquiryId: number) => {
    const input: GetInquiryInput = {
      inquiryId,
    };
    getInquiry({ variables: { input } });
  };

  const [
    listComment,
    {
      data: listCommentData,
      loading: listCommentLoading,
      refetch: listCommentRefetch,
    },
  ] = useListComment();
  const listCommentHandler = (inquiryId: number) => {
    const input: ListCommentInput = {
      inquiryId,
    };
    listComment({ variables: { input } });
  };
  const tabList: ITabLink[] = [];
  const stitle: ITabTitle = {
    text: "문의관리",
  };

  useEffect(() => {
    pageIndicator("door");
    if (params.inquiryId) {
      getInquiryHandler(+params.inquiryId);
      listCommentHandler(+params.inquiryId);
    }
  }, []);

  return (
    <div className="bg-main bg-opacity-50 w-full h-full min-h-screen">
      <div className="w-full mx-auto h-full min-h-screen  bg-white">
        <main id="main">
          <div className="grid grid-cols-[200px_1fr] max-w-full">
            <AsideAdminComponent />
            <div className="mypages bg-gray-100 w-full min-w-[1200px] overflow-hidden">
              <div className="orderWrap">
                <div className="  h-fix-content relative box-border">
                  <div className={`w-full h-full px-10 py-8`}>
                    <ComponentTabLink tabs={tabList} stitle={stitle} />

                    <div className=" shadow-lg h-[800px] h-fix-content w-full overflow-auto bg-white">
                      <div className="pl-9 pr-11 py-8">
                        <div className="flex flex-wrap justify-between border-b border-[#F0F0F4] text-sm h-14 py-3">
                          <div className="flex flex-wrap gap-x-6">
                            <h1 className="border border-[#EAEAEA] p-1 w-[112px] text-center rounded-[20px] ">
                              {data?.getInquiry.inquiry?.type}
                            </h1>
                            <p className="p-1">
                              {data?.getInquiry.inquiry?.title}
                            </p>
                          </div>
                          <div className="flex flex-wrap gap-x-6 ">
                            <p className="p-1 text-left ">
                              {moment(
                                data?.getInquiry.inquiry?.createdAt
                              ).format("yyyy.MM.DD")}
                            </p>
                            <p className="rounded-lg  min-w-[48px] p-2 h-7 text-white bg-[#8B8C8C] text-xs text-center self-center py-1">
                              {data?.getInquiry.inquiry?.status}
                            </p>
                          </div>
                        </div>

                        <div className="flex flex-wrap justify-between border-b border-[#F0F0F4] text-sm h-14 py-3">
                          <div className="flex flex-wrap">
                            <h1 className=" w-[120px] py-[6px] pl-4 text-[#8B8C8C]">
                              고객명
                            </h1>
                            <p className="py-[6px] w-[120px]">
                              {data?.getInquiry.inquiry?.author.name}
                            </p>
                          </div>
                          <div className="flex flex-wrap">
                            <p className="text-center w-[120px] py-[6px]">
                              연락처
                            </p>
                            <p className="text-center py-[6px] w-[120px]">
                              {data?.getInquiry.inquiry?.author.phone}
                            </p>
                          </div>
                        </div>
                        <div className="flex flex-wrap border-b border-[#F0F0F4] text-sm h-14 py-3">
                          <h1 className=" w-[120px] py-[6px] pl-4 text-[#8B8C8C]">
                            문의내용
                          </h1>
                        </div>
                        <div className="pt-4">
                          <textarea
                            value={data?.getInquiry.inquiry?.note}
                            disabled={!edit}
                            id="desc"
                            className="text-sm w-full resize-none h-48 whitespace-pre-wrap "
                          ></textarea>

                          {/* <div className="flex flex-wrap justify-between h-14 py-3">
                            <div className="flex flex-wrap text-sm ">
                              <h1 className=" w-[120px] py-[6px] pl-4 text-[#8B8C8C]">
                                답변등록
                              </h1>
                            </div>
                            {data?.getInquiry.inquiry?.author.id ===
                              me?.me.id && (
                              <div className="flex flex-wrap">
                                <p className={`text-right ${edit && "hidden"}`}>
                                  <button
                                    type="button"
                                    onClick={editHandler}
                                    className={`text-sm rounded-md py-1 px-3 hover:opacity-60 `}
                                  >
                                    수정
                                  </button>
                                </p>
                               
                                <p>
                                  <button className="text-sm rounded-md py-1 px-3 hover:opacity-60">
                                    삭제
                                  </button>
                                </p>
                              </div>
                            )}
                          </div> */}
                        </div>
                        <div className="py-4">
                          {data?.getInquiry?.inquiry?.inquiryPosters &&
                            data?.getInquiry?.inquiry?.inquiryPosters.length >
                              0 &&
                            data?.getInquiry?.inquiry?.inquiryPosters.map(
                              (poster) => (
                                <img
                                  onClick={() => {
                                    setAlertOpen(true);
                                    setDialogUrl(`${poster.upload?.url}`);
                                  }}
                                  className="w-40 h-40 object-contain border"
                                  src={`${poster.upload?.url}`}
                                  alt={`${data.getInquiry.inquiry?.author.name} 님의 문의 이미지${poster.index}`}
                                />
                              )
                            )}
                        </div>

                        {params.inquiryId && (
                          <ComponentCrComment
                            inquiryId={+params.inquiryId}
                            listRefetch={listCommentRefetch}
                            getRefetch={refetch}
                          />
                        )}
                        {!listCommentLoading &&
                          listCommentData?.listComment.comments?.map(
                            (comment) => (
                              <ComponentEditComment
                                key={`comment-${comment.id}`}
                                comment={comment}
                                listRefetch={listCommentRefetch}
                              />
                            )
                          )}
                        <div>
                          <div className="flex flex-wrap justify-end mt-4">
                            <button
                              type="button"
                              onClick={editHandler}
                              className={`inline-block rounded-lg text-center w-[200px] py-2 bg-[#3D3E49] text-white ${!edit &&
                                "hidden"}`}
                            >
                              수정완료
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
      <Dialog
        open={alertOpen}
        onClose={() => {
          setAlertOpen(false);
        }}
      >
        <img src={dialogUrl} alt={"이미지 자세하기 보기"} />
      </Dialog>
    </div>
  );
};
