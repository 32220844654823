import { ApolloError, gql, useMutation } from "@apollo/client";
import {
  EditGoodInput,
  EditGoodMutation,
  EditGoodMutationVariables,
} from "../generated/graphql";

export interface IEditGoodFormProps extends EditGoodInput {}
const EDIT_GOOD_QUERY = gql`
  mutation editGood($input: EditGoodInput!) {
    editGood(input: $input) {
      ok
      err
    }
  }
`;

export const useEditGood = (
  onCompleted?: (data: EditGoodMutation) => void,
  onError?: (error: ApolloError) => void
) => {
  return useMutation<EditGoodMutation, EditGoodMutationVariables>(
    EDIT_GOOD_QUERY,
    {
      onCompleted,
      onError,
    }
  );
};
