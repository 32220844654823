import { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router";
import { useEditGoodDetail } from "../hooks/useEditGoodDetail";
import { useListGoodDetail } from "../hooks/useListGoodDetail";
import { useCrGoodDetail } from "../hooks/useCrGoodDetail";
import { useForm } from "react-hook-form";
import { DetailText } from "../components/detail-text";
import { DetailImage } from "../components/detail-image";
import { DetailVideo } from "../components/detail-video";
import React from "react";
import { useEditGoodDetailIndex } from "../hooks/useEditGoodDetailIndex";
import { Card } from "../components/Card";
import update from "immutability-helper";
import { EditProductProps } from "../constants/enum";
import { DetailContent } from "../components/detail-content";
import {
  CrGoodDetailInput,
  CrGoodDetailMutation,
  EditGoodDetailIndexInput,
  EditGoodDetailIndexMutation,
  EditGoodDetailInput,
  EditGoodDetailMutation,
  GoodDetailType,
  ListGoodDetailInput,
  ListGoodDetailQuery,
} from "../generated/graphql";

export type ArrElement<ArrType> = ArrType extends readonly (infer ElementType)[]
  ? ElementType
  : never;

export type GoodDetailElement = ArrElement<
  ListGoodDetailQuery["listGoodDetail"]["goodDetails"]
>;

export type UpDetailElement = GoodDetailElement & { file?: any };

export const SubAdminEditProductDetail: React.FC<EditProductProps> = ({
  tabs,
  setTabHandler,
}) => {
  const params = useParams();
  const [uploading, setUploading] = useState<boolean>(false);
  const [detailsData, setDetailsData] = useState<UpDetailElement[]>([]);
  const [detailData, setDetailData] = useState<
    ListGoodDetailQuery["listGoodDetail"]["goodDetails"]
  >([]);
  let arrays = detailsData;

  const { register, setValue, getValues, formState } = useForm<
    CrGoodDetailInput
  >({ mode: "all" });
  /////////////////////////////////// 리스트
  const onListCompleted = async (data: ListGoodDetailQuery) => {
    const {
      listGoodDetail: { ok, goodDetails },
    } = data;
    if (goodDetails) {
      setDetailData(goodDetails);
      setDetailsData(goodDetails);
    }
  };

  const [
    listGoodDetail,
    { data: listGoodDetailData, loading, refetch },
  ] = useListGoodDetail(onListCompleted);
  /////////////////////////////////////////// Create
  const onCrCompleted = async (data: CrGoodDetailMutation) => {
    const {
      crGoodDetail: { ok, err },
    } = data;
    if (ok) {
      await refetch();
    } else {
      alert(err);
    }
  };

  const [crGoodDetail, { loading: crGoodDetailLoading }] = useCrGoodDetail(
    onCrCompleted
  );
  const crGoodDetailHandler = (typeValue: GoodDetailType) => {
    if (!crGoodDetailLoading) {
      const crGoodDetailInput: CrGoodDetailInput = {
        goodId: params.goodId ? +params.goodId : 0,
        detailType: typeValue,
      };
      crGoodDetail({ variables: { input: crGoodDetailInput } });
    }
  };

  //////////////////////////////////////// Sortable
  const onEditIndexCompleted = async (data: EditGoodDetailIndexMutation) => {
    const {
      editGoodDetailIndex: { ok },
    } = data;
    if (ok) {
      await refetch();
    }
  };

  const [
    editGoodDetailIndex,
    { loading: editGoodDetailIndexLoading },
  ] = useEditGoodDetailIndex(onEditIndexCompleted);
  const editGoodDetailIndexHandler = (goodDetailId: number, index: number) => {
    const editGoodDetailIndexInput: EditGoodDetailIndexInput = {
      index,
      goodDetailId,
    };
    editGoodDetailIndex({ variables: { input: editGoodDetailIndexInput } });
  };

  useEffect(() => {
    if (params.goodId) {
      const listGoodDetailInput: ListGoodDetailInput = {
        goodId: +params.goodId,
      };
      listGoodDetail({ variables: { input: listGoodDetailInput } });
    }
  }, []);
  const moveCard = useCallback((dragIndex: number, hoverIndex: number) => {
    setDetailData(
      (prevCards: ListGoodDetailQuery["listGoodDetail"]["goodDetails"]) => {
        if (!prevCards) return prevCards;
        update(prevCards, {
          $splice: [
            [dragIndex, 1],
            [hoverIndex, 0, prevCards[dragIndex] as GoodDetailElement],
          ],
        });
      }
    );
  }, []);
  const renderCard = useCallback((card: { id: number }, index: number) => {
    return (
      <Card
        key={card.id}
        index={index}
        id={card.id}
        moveCard={moveCard}
        editGoodDetailIndex={editGoodDetailIndexHandler}
      />
    );
  }, []);

  //////////////////////////////////////////// Edit

  const onEditCompleted = async (data: EditGoodDetailMutation) => {
    const {
      editGoodDetail: { ok, err },
    } = data;
    if (ok) {
      await refetch();
    } else {
      alert(err);
      return;
    }
    setUploading(false);
  };
  const [
    editGoodDetail,
    { loading: editGoodDetailLoading },
  ] = useEditGoodDetail(onEditCompleted);

  const editGoodDetailImageHandler = (
    index: number,
    note: string | null | undefined,
    url: string | null | undefined,
    goodDetailId: number,
    imageUuid: string | null | undefined
  ) => {
    const editGoodDetailInput: EditGoodDetailInput = {
      index,
      note,
      url,
      goodDetailId,
      imageUuid,
    };
    editGoodDetail({ variables: { input: editGoodDetailInput } });
  };

  const detailHandler = (
    id: number,
    index: number,
    note: string | null,
    url: string | null,
    detailType: GoodDetailType,
    file?: any | null
  ) => {
    if (!arrays) return;
    let _index = arrays.findIndex((el) => el.index === index);
    if (detailType === GoodDetailType.TextArea) {
      arrays[_index].note = note;
      arrays[_index].url = url;
      arrays[_index].detailType = detailType;
    }
    if (detailType === GoodDetailType.ContentArea) {
      arrays[_index].note = note;
      arrays[_index].file = file;
      arrays[_index].url = url;
      arrays[_index].detailType = detailType;
    }
    if (detailType === GoodDetailType.Image) {
      arrays[_index].url = url;
      arrays[_index].detailType = detailType;
    }
    if (detailType === GoodDetailType.Video) {
      arrays[_index].url = url;
      arrays[_index].detailType = detailType;
    }
  };

  const uploadDetailsIteration = () => {
    if (!arrays) return;

    for (var i = 0; i < arrays.length; i++) {
      if (arrays[i].detailType === GoodDetailType.ContentArea) {
        editGoodDetailImageHandler(
          arrays[i].index,
          arrays[i].note,
          arrays[i].url,
          arrays[i].id,
          arrays[i].upload?.uuid
        );
      }
      if (arrays[i].detailType === GoodDetailType.Image) {
        editGoodDetailImageHandler(
          arrays[i].index,
          null,
          arrays[i].url,
          arrays[i].id,
          arrays[i].upload?.uuid
        );
      }
      if (arrays[i].detailType === GoodDetailType.TextArea) {
        editGoodDetailImageHandler(
          arrays[i].index,
          arrays[i].note,
          arrays[i].url,
          arrays[i].id,
          null
        );
      }
      if (arrays[i].detailType === GoodDetailType.Video) {
        editGoodDetailImageHandler(
          arrays[i].index,
          null,
          arrays[i].url,
          arrays[i].id,
          null
        );
      }
    }
    alert("업로드를 완료했습니다.");
    setTabHandler(tabs + 1);
  };
  return (
    <div className="shadow-lg min-w-[1160px] min-h-[800px] h-fix-content max-w-[1160px]  2xl:max-w-[1623px]  bg-white">
      <div className="pl-9 pr-11 py-8 space-y-4">
        <div className="space-y-2">
          {/* <div className="max-w-[376px]">
            <p className="font-bold text-[#4F4F4F] text-base">대표 이미지</p>
            <div className="px-4 imageBox space-y-3">
              {!loading &&
                listGoodDetailData?.listGoodDetail.goodDetails &&
                listGoodDetailData?.listGoodDetail.goodDetails
                  .filter(
                    (detail) =>
                      detail.detailType === GoodDetailType.Image &&
                      detail.index === 1
                  )
                  .map((goodDetail) => (
                    <div key={`thumbnail-${goodDetail.id}`}>
                      <DetailImage
                        goodDetail={goodDetail}
                        refetch={refetch}
                        detailHandler={detailHandler}
                      />
                    </div>
                  ))}
            </div>
          </div> */}
          {/* <div className=" flex flex-wrap">
            <p className="font-bold text-[#4F4F4F] text-base">
              상세페이지

              <button
                className="ml-8 min-w-[104px] px-4 h-[30px] bg-[#8B8C8C] text-white rounded-lg"
                onClick={() => crGoodDetailHandler(GoodDetailType.ContentArea)}
              >
                이미지 + 텍스트
              </button>
              <button
                className="ml-8 min-w-[104px] px-4 h-[30px] bg-[#8B8C8C] text-white rounded-lg"
                onClick={() => crGoodDetailHandler(GoodDetailType.Image)}
              >
                이미지
              </button>
              <button
                className="ml-8 min-w-[104px] px-4 h-[30px] bg-[#8B8C8C] text-white rounded-lg"
                onClick={() => crGoodDetailHandler(GoodDetailType.TextArea)}
              >
                텍스트
              </button>
              <button
                className="ml-8 min-w-[104px] px-4 h-[30px] bg-[#8B8C8C] text-white rounded-lg"
                onClick={() => crGoodDetailHandler(GoodDetailType.Video)}
              >
                비디오
              </button>
            </p>
          </div>
          {loading && <div className="pt-5">상세정보를 불러오는 중입니다</div>}
          {!loading &&
            listGoodDetailData?.listGoodDetail.goodDetails &&
            listGoodDetailData.listGoodDetail.goodDetails.length === 0 && (
              <div className="pt-5">상세 정보가 없습니다.</div>
            )} */}
          <div className="py-5 relative flex flex-wrap gap-x-4">
            <div className="px-4 min-h-[600px] py-8 w-[400px]">
              <div className="sticky top-1/4 left-0">
                <p className="font-bold text-[#4F4F4F] text-base py-2">
                  상세페이지
                </p>
                {/* <select
                {...register(`detailType`, {
                  required: "어떤 정보를 넣을지 선정해주세요.",
                })}
                className="ml-8 w-[130px] h-[30px] border pl-4 rounded-lg"
              >
                <option value={GoodDetailType.Image}>이미지</option>
                <option value={GoodDetailType.Paragraph}>타이틀</option>
                <option value={GoodDetailType.TextArea}>상세설명</option>
              </select> */}
                <button
                  className="ml-4 min-w-[50px] px-2 h-[30px] bg-[#8B8C8C] text-white rounded-lg"
                  onClick={() =>
                    crGoodDetailHandler(GoodDetailType.ContentArea)
                  }
                >
                  이미지 + 텍스트
                </button>
                <button
                  className="ml-4 min-w-[50px] px-2 h-[30px] bg-[#8B8C8C] text-white rounded-lg"
                  onClick={() => crGoodDetailHandler(GoodDetailType.Image)}
                >
                  이미지
                </button>
                <button
                  className="ml-4 min-w-[50px] px-2 h-[30px] bg-[#8B8C8C] text-white rounded-lg"
                  onClick={() => crGoodDetailHandler(GoodDetailType.TextArea)}
                >
                  텍스트
                </button>
                <button
                  className="ml-4 min-w-[50px] px-2 h-[30px] bg-[#8B8C8C] text-white rounded-lg"
                  onClick={() => crGoodDetailHandler(GoodDetailType.Video)}
                >
                  비디오
                </button>
                {loading && (
                  <div className="pt-5">상세정보를 불러오는 중입니다</div>
                )}

                <p className="py-2">순서 재배치</p>
                <div className="overflow-auto h-96 w-full">
                  {detailData &&
                    detailData.map((card, i) => renderCard(card, i))}
                </div>
                <div className="flex flex-wrap justify-center py-6">
                  <button
                    onClick={() => {
                      uploadDetailsIteration();
                    }}
                    className="w-[240px] h-[40px] bg-[#3D3E49] text-white rounded-lg"
                  >
                    저장하기
                  </button>
                </div>
              </div>
            </div>
            <div className="px-4 w-full max-w-screen-sm">
              {!loading &&
                listGoodDetailData?.listGoodDetail.goodDetails &&
                listGoodDetailData.listGoodDetail.goodDetails.length === 0 && (
                  <div className="pt-5">상세 정보가 없습니다.</div>
                )}
              {!loading &&
                listGoodDetailData?.listGoodDetail.goodDetails &&
                listGoodDetailData?.listGoodDetail.goodDetails.map(
                  (goodDetail) => (
                    <div key={`texts-${goodDetail.id}`}>
                      {goodDetail.detailType === GoodDetailType.TextArea ? (
                        <DetailText
                          goodDetail={goodDetail}
                          refetch={refetch}
                          detailHandler={detailHandler}
                        />
                      ) : goodDetail.detailType ===
                        GoodDetailType.ContentArea ? (
                        <DetailContent
                          goodDetail={goodDetail}
                          refetch={refetch}
                          detailHandler={detailHandler}
                        />
                      ) : goodDetail.detailType === GoodDetailType.Video ? (
                        <DetailVideo
                          goodDetail={goodDetail}
                          refetch={refetch}
                          detailHandler={detailHandler}
                        />
                      ) : (
                        <div key={`thumbnail-${goodDetail.id}`}>
                          <DetailImage
                            goodDetail={goodDetail}
                            refetch={refetch}
                            detailHandler={detailHandler}
                          />
                        </div>
                      )}
                    </div>
                  )
                )}
            </div>
            {/* <div className="border-l px-4 imageBox space-y-3">
              {!loading &&
                listGoodDetailData?.listGoodDetail.goodDetails &&
                listGoodDetailData?.listGoodDetail.goodDetails
                  .filter(
                    (detail) =>
                      detail.detailType === GoodDetailType.Image &&
                      detail.index !== 1
                  )
                  .map((goodDetail) => (
                    <div key={`thumbnail-${goodDetail.id}`}>
                      <DetailImage
                        goodDetail={goodDetail}
                        refetch={refetch}
                        detailHandler={detailHandler}
                      />
                    </div>
                  ))}
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};
