import { useEffect, useState } from "react";
import { pageIndicator } from "../apollo";
import { AsideAdminComponent } from "../layout/aside-admin";
import { SubAdminEditProductInfo } from "../sub-routes/sub-admin-up-product-info";
import { SubAdminEditProductPoster } from "../sub-routes/sub-admin-up-product-poster";
import { SubAdminEditProductDetail } from "../sub-routes/sub-admin-up-product-detail";
import { SubAdminEditProductAttr } from "../sub-routes/sub-admin-up-product-attr";
import { SubAdminEditProductAttrInfo } from "../sub-routes/sub-admin-up-product-attrInfo";
export const AdminUpProduct = () => {
  const [tabs, setTabs] = useState(0);

  useEffect(() => {
    pageIndicator("product");
  }, []);

  return (
    <div className="bg-main bg-opacity-50 w-full h-full min-h-screen">
      <div className="w-full mx-auto h-full min-h-screen bg-white">
        <main id="main">
          <div className="grid grid-cols-[200px_1fr]">
            <AsideAdminComponent />
            <div className="mypages bg-gray-100">
              <div className="orderWrap">
                <div className="bg-gray-100 h-fix-content relative">
                  <div className={`w-full h-full px-10 py-8`}>
                    <div className="grid grid-cols-[300px_1fr_200px]">
                      <div className="flex flex-wrap">
                        <div className="border-l-4 border-[#3D3E49] my-2 ml-2 w-4 h-5"></div>
                        <h1 className="inline-block font-bold mb-2 text-[24px] text-[#3D3E49]">
                          상품 편집
                        </h1>
                      </div>
                      <div className="subtitle h-full flex flex-wrap content-end">
                        <button
                          className={`inline-block w-[104px] py-2 text-center rounded-t-xl  ${
                            tabs === 0 ? `bg-[#3D3E49]` : `bg-white`
                          }`}
                          onClick={() =>
                            setTabs(() => {
                              return 0;
                            })
                          }
                        >
                          <p
                            className={`block leading-normal font-bold hover:opacity-60 w-full  ${
                              tabs === 0 ? `text-white` : `text-[#4F4F4F]`
                            }`}
                          >
                            정보
                          </p>
                        </button>
                        <button
                          className={`inline-block w-[104px] py-2 text-center rounded-t-xl  ${
                            tabs === 1 ? `bg-[#3D3E49]` : `bg-white`
                          }`}
                          onClick={() =>
                            setTabs(() => {
                              return 1;
                            })
                          }
                        >
                          <p
                            className={`block leading-normal font-bold hover:opacity-60 w-full  ${
                              tabs === 1 ? `text-white` : `text-[#4F4F4F]`
                            }`}
                          >
                            상단노출정보
                          </p>
                        </button>
                        <button
                          className={`inline-block w-[104px] py-2 text-center rounded-t-xl  ${
                            tabs === 2 ? `bg-[#3D3E49]` : `bg-white`
                          }`}
                          onClick={() =>
                            setTabs(() => {
                              return 2;
                            })
                          }
                        >
                          <p
                            className={`block leading-normal font-bold hover:opacity-60 w-full  ${
                              tabs === 2 ? `text-white` : `text-[#4F4F4F]`
                            }`}
                          >
                            썸네일
                          </p>
                        </button>
                        <button
                          className={`inline-block w-[104px] py-2 text-center rounded-t-xl  ${
                            tabs === 3 ? `bg-[#3D3E49]` : `bg-white`
                          }`}
                          onClick={() =>
                            setTabs(() => {
                              return 3;
                            })
                          }
                        >
                          <p
                            className={`block leading-normal font-bold hover:opacity-60 w-full  ${
                              tabs === 3 ? `text-white` : `text-[#4F4F4F]`
                            }`}
                          >
                            상세페이지
                          </p>
                        </button>
                        <button
                          className={`inline-block w-[104px] py-2 text-center rounded-t-xl  ${
                            tabs === 4 ? `bg-[#3D3E49]` : `bg-white`
                          }`}
                          onClick={() =>
                            setTabs(() => {
                              return 4;
                            })
                          }
                        >
                          <p
                            className={`block leading-normal font-bold hover:opacity-60 w-full  ${
                              tabs === 4 ? `text-white` : `text-[#4F4F4F]`
                            }`}
                          >
                            제품정보고시
                          </p>
                        </button>
                      </div>
                    </div>
                    <div className="h-14 w-full max-w-[1623px] rounded-t-2xl bg-[#3D3E49] ">
                      <div className="flex flex-wrap justify-end pr-8">
                        <div className="flex flex-wrap gap-x-3">
                          {tabs === 0 && (
                            <button
                              form="update"
                              className="bg-transparent bg-[#8b8c8c] w-[104px] h-[30px] py-[7px] text-xs font-bold text-center rounded-lg my-[13px] text-white"
                            >
                              등록
                            </button>
                          )}
                        </div>
                      </div>
                    </div>

                    {tabs === 0 && (
                      <SubAdminEditProductInfo
                        tabs={tabs}
                        setTabHandler={setTabs}
                      />
                    )}
                    {tabs === 1 && (
                      <SubAdminEditProductAttrInfo
                        tabs={tabs}
                        setTabHandler={setTabs}
                      />
                    )}
                    {tabs === 2 && (
                      <SubAdminEditProductPoster
                        tabs={tabs}
                        setTabHandler={setTabs}
                      />
                    )}
                    {tabs === 3 && (
                      <SubAdminEditProductDetail
                        tabs={tabs}
                        setTabHandler={setTabs}
                      />
                    )}
                    {tabs === 4 && <SubAdminEditProductAttr />}
                    {/* {tabs === 1 && <SubAdminEditProductPoster goodId={11} />} */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
};
