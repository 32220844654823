import { useDelGoodDetail } from "../hooks/useDelGoodDetail";

import { useEffect, useState } from "react";
import {
  DelGoodDetailInput,
  DelGoodDetailMutation,
  GoodDetailType,
  ListGoodDetailQuery,
} from "../generated/graphql";
import { ArrElement } from "../sub-routes/sub-admin-up-product-detail";

export type ListGoodDetail = ArrElement<
  ListGoodDetailQuery["listGoodDetail"]["goodDetails"]
>;

interface DetailVideoProps {
  goodDetail: ListGoodDetail;
  refetch: () => void;
  detailHandler: (
    id: number,
    index: number,
    note: string | null,
    url: string | null,
    detailType: GoodDetailType,
    file?: any
  ) => void;
}

export const DetailVideo: React.FC<DetailVideoProps> = ({
  goodDetail,
  refetch,
  detailHandler,
}) => {
  // edit
  const [detailUrl, setDetailUrl] = useState<string>(
    goodDetail.url ? goodDetail.url : ""
  );
  //   delete
  const onDelCompleted = async (data: DelGoodDetailMutation) => {
    const {
      delGoodDetail: { ok, err },
    } = data;
    if (ok) {
      await refetch();
    } else {
      alert(err);
    }
  };
  const [delGoodDetail, { loading: delGoodDetailLoading }] = useDelGoodDetail(
    onDelCompleted
  );
  const delGoodDetailHandler = (goodDetailId: number) => {
    if (!delGoodDetailLoading && goodDetailId) {
      const delGoodDetailInput: DelGoodDetailInput = {
        goodDetailId,
      };
      delGoodDetail({ variables: { input: delGoodDetailInput } });
    }
  };

  useEffect(() => {
    if (!goodDetail) return;
    detailHandler(
      goodDetail.id,
      goodDetail.index,
      null,
      detailUrl,
      GoodDetailType.Video,
      null
    );
  }, [detailUrl]);

  return (
    <div className="pb-8">
      <p className="font-bold">{goodDetail.index}.</p>
      <p className="pt-2 pb-4 flex flex-wrap content-center gap-x-2">
        <span className="flex flex-wrap content-center">YouTube: </span>
        <input
          type="text"
          value={detailUrl}
          className="rounded-lg border flex-1 py-1 px-2"
          onChange={(e) => setDetailUrl(e.target.value)}
        />
      </p>

      <div className="py-2 text-right space-x-4">
        <button
          className={`py-1 px-2 rounded-lg bg-red-400 text-white`}
          onClick={() => delGoodDetailHandler(goodDetail.id)}
        >
          삭제하기
        </button>
      </div>
    </div>
  );
};
