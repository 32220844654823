import { ApolloError, gql, useMutation } from "@apollo/client";
import {
  EditGoodDetailIndexMutation,
  EditGoodDetailIndexMutationVariables,
} from "../generated/graphql";

const EDIT_GOOD_DETAIL_INDEX_QUERY = gql`
  mutation editGoodDetailIndex($input: EditGoodDetailIndexInput!) {
    editGoodDetailIndex(input: $input) {
      ok
      err
    }
  }
`;

export const useEditGoodDetailIndex = (
  onCompleted?: (data: EditGoodDetailIndexMutation) => void,
  onError?: (error: ApolloError) => void
) => {
  return useMutation<
    EditGoodDetailIndexMutation,
    EditGoodDetailIndexMutationVariables
  >(EDIT_GOOD_DETAIL_INDEX_QUERY, {
    onCompleted,
    onError,
  });
};
