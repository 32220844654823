import moment from "moment";
import React from "react";
// import { listGood_listGood_goods } from "../__generated__/listGood";
// import { listGoodOption_listGoodOption_goodOptions } from "../__generated__/listGoodOption";
import { ListGood } from "../routes/admin-list-product";

interface ExpirydateProps {
  data: ListGood;
}

export const ComponentExpirydate: React.FC<ExpirydateProps> = ({ data }) => {
  return (
    <table className="w-full">
      <thead></thead>
      <tbody>
        {/* {!data.goodStocks ||
          (data.goodStocks.length === 0 && (
            <tr className=" border-b border-[#F9F8F9] h-8 text-center">
              <td colSpan={18} className="text-[#8B8C8C]">
                해당 정보가 없습니다.
              </td>
            </tr>
          ))} */}
        {data?.goodStocks ? (
          data?.goodStocks.length > 0 ? (
            data?.goodStocks?.map((goodStock, index) => (
              <tr
                className=" border-b border-[#F9F8F9] h-8 text-center"
                key={`list-stock-by-expirydate in` + data?.title + index}
              >
                <td className="text-[#8B8C8C] leading-3 min-w-[36px]"></td>
                <td className="text-[#8B8C8C] min-w-[40px]"></td>
                <td className="text-[#8B8C8C] min-w-[72px]"></td>
                <td className="text-[#8B8C8C] min-w-[72px]"></td>
                <td className="text-[#8B8C8C] min-w-[88px]"></td>
                <td className="text-[#8B8C8C] min-w-[88px]"></td>
                <td className="text-[#8B8C8C] min-w-[168px]">{data?.title}</td>
                {/* <td className="text-[#8B8C8C] min-w-[168px]">{data.name}</td> */}
                <td className="text-[#8B8C8C] min-w-[48px]">
                  {goodStock.stock}
                </td>
                <td className="text-[#8B8C8C] min-w-[88px]">
                  {goodStock.price}
                </td>
                <td className="text-[#8B8C8C] min-w-[72px]"></td>
                <td className="text-[#8B8C8C] min-w-[72px]"></td>
                <td className="text-[#8B8C8C] min-w-[72px]"></td>
                <td className="text-[#8B8C8C] min-w-[88px]">
                  {goodStock.expirydate
                    ? moment(goodStock.expirydate).format("YY.MM.DD")
                    : "정보없음"}
                </td>
                <td className="text-[#8B8C8C] min-w-[88px]">{data.weight}g</td>
                <td className="text-[#8B8C8C] min-w-[168px]"></td>
                <td className="text-[#8B8C8C] min-w-[128px]"></td>
                <td className="text-[#8B8C8C] min-w-[67px]"></td>
              </tr>
            ))
          ) : (
            <tr className=" border-b border-[#F9F8F9] h-8 text-center">
              <td colSpan={18} className="text-[#8B8C8C]">
                해당 정보가 없습니다.
              </td>
            </tr>
          )
        ) : (
          <tr className=" border-b border-[#F9F8F9] h-8 text-center">
            <td colSpan={18} className="text-[#8B8C8C]">
              해당 정보가 없습니다.
            </td>
          </tr>
        )}
      </tbody>
    </table>
  );
};
