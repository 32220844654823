import { gql, useLazyQuery } from "@apollo/client";
import { ListBoundQuery, ListBoundQueryVariables } from "../generated/graphql";

const LIST_BOUND_QUERY = gql`
  query listBound($input: ListBoundInput!) {
    listBound(input: $input) {
      ok
      err
      totalPages
      totalResults
      row
      bounds {
        id
        createdAt
        desc
        stock
        price
        amount
        type
        status
        orderedAt
        expiryDate
        deliveredAt
        author {
          id
        }
        good {
          title
          stock
          price
          weight
          goodOrder
          category
          categoryFirst
          status
          goodType
          goodUuid
        }
        goodOption {
          deliveryDate
          expirydate
          name
          id
        }
        corporation {
          name
        }
        receipts {
          uuid
        }
      }
    }
  }
`;

export const useListBound = (onCompleted?: (data: ListBoundQuery) => void) => {
  return useLazyQuery<ListBoundQuery, ListBoundQueryVariables>(
    LIST_BOUND_QUERY,
    {
      onCompleted,
      fetchPolicy: "network-only",
    }
  );
};
