import { gql, useLazyQuery } from "@apollo/client";
import { ListLinkServiceSelectQuery } from "../generated/graphql";

const LIST_LINKSERVICE_SELECT = gql`
  query listLinkServiceSelect {
    listLinkServiceSelect {
      ok
      err
      linkservice {
        id
        name
      }
    }
  }
`;

export const useListLinkServiceSelect = (
  onCompleted?: (data: ListLinkServiceSelectQuery) => void
) => {
  return useLazyQuery<ListLinkServiceSelectQuery>(LIST_LINKSERVICE_SELECT, {
    onCompleted,
  });
};
