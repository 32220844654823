import { ApolloError, gql, useMutation } from "@apollo/client";
import {
  DelPackOrderMutation,
  DelPackOrderMutationVariables,
} from "../generated/graphql";

const DEL_PACK_ORDER_MUTATION = gql`
  mutation delPackOrder($input: DelPackOrderInput!) {
    delPackOrder(input: $input) {
      ok
      err
    }
  }
`;

export const useDelPackOrder = (
  onCompleted?: (data: DelPackOrderMutation) => void,
  onError?: (Error: ApolloError) => void
) => {
  return useMutation<DelPackOrderMutation, DelPackOrderMutationVariables>(
    DEL_PACK_ORDER_MUTATION,
    {
      onCompleted,
      onError,
    }
  );
};
