import img from "../assets/icon/coolicon.png";
import { useEffect, useState } from "react";
import React from "react";
import { useListGoodPoster } from "../hooks/useListGoodPoster";
import { useEditGoodPoster } from "../hooks/useEditGoodPoster";
import remove from "../assets/icon/exit.png";
import { useParams } from "react-router-dom";
import { useCrGoodPoster } from "../hooks/useCrGoodPoster";
import { useDelGoodPoster } from "../hooks/useDelGoodPoster";
import { upload } from "../functions/uploads";
import { EditProductProps } from "../constants/enum";
import {
  CrGoodPosterInput,
  CrGoodPosterMutation,
  DelGoodPosterInput,
  DelGoodPosterMutation,
  EditGoodPosterInput,
  EditGoodPosterMutation,
  ListGoodPosterInput,
  ListGoodPosterQuery,
} from "../generated/graphql";

export interface ISubAdminEditProductPoster {
  goodId: number;
}

export const SubAdminEditProductPoster: React.FC<EditProductProps> = ({
  tabs,
  setTabHandler,
}) => {
  const params = useParams();
  const [uploading, setUploading] = useState<boolean>(false);

  const onListCompleted = async (data: ListGoodPosterQuery) => {};
  const [
    listGoodPoster,
    { data: listGoodPosterData, loading, refetch },
  ] = useListGoodPoster(onListCompleted);

  const onDelCompleted = async (data: DelGoodPosterMutation) => {
    const {
      delGoodPoster: { ok, err },
    } = data;
    if (ok) {
      await refetch();
    } else {
      alert(err);
    }
  };
  const [delGoodPoster, { loading: delGoodPosterLoading }] = useDelGoodPoster(
    onDelCompleted
  );
  const delGoodPosterHandler = (goodPosterId: number) => {
    if (!delGoodPosterLoading && goodPosterId) {
      const delGoodPosterInput: DelGoodPosterInput = {
        goodPosterId,
      };
      delGoodPoster({ variables: { input: delGoodPosterInput } });
    }
  };
  const onEditCompleted = async (data: EditGoodPosterMutation) => {
    const {
      editGoodPoster: { ok },
    } = data;
    if (ok) {
      await refetch();
    }
    setUploading(false);
  };

  const [
    editGoodPoster,
    { loading: editGoodPosterLoading },
  ] = useEditGoodPoster(onEditCompleted);
  const editGoodPosterHandler = (
    index: number,
    goodPosterId: number,
    imageUuid: string
  ) => {
    const editGoodPosterInput: EditGoodPosterInput = {
      index,
      goodPosterId,
      imageUuid,
    };
    editGoodPoster({ variables: { input: editGoodPosterInput } });
  };
  const uploadGoodPorsterHandler = async (
    index: number,
    event: any,
    goodPosterId: number
  ) => {
    if (uploading) {
      alert("파일을 업로드 중입니다.");
      return;
    }
    if (!editGoodPosterLoading) {
      setUploading(true);
      const result = await upload(event);

      if (!goodPosterId) {
        alert("포스터 정보가 잘못되었습니다.");
        return;
      }
      if (result.data.ok) {
        editGoodPosterHandler(index, goodPosterId, result.data.upload.uuid);
        alert("저장되었습니다.");
      } else {
        alert(`잘못된 파일`);
      }
    }
  };

  const onCrCompleted = async (data: CrGoodPosterMutation) => {
    const {
      crGoodPoster: { ok, err },
    } = data;
    if (ok) {
      await refetch();
    } else {
      alert(err);
    }
  };

  const [crGoodPoster, { loading: crGoodPosterLoading }] = useCrGoodPoster(
    onCrCompleted
  );
  const crGoodPosterHandler = () => {
    if (!crGoodPosterLoading) {
      const crGoodPosterInput: CrGoodPosterInput = {
        goodId: params.goodId ? +params.goodId : 0,
      };
      crGoodPoster({ variables: { input: crGoodPosterInput } });
    }
  };

  useEffect(() => {
    if (params.goodId) {
      const listGoodPosterInput: ListGoodPosterInput = {
        goodId: +params.goodId,
      };
      listGoodPoster({ variables: { input: listGoodPosterInput } });
    }
  }, []);

  return (
    <div className="shadow-lg min-w-[1160px] min-h-[800px] h-fix-content max-w-[1160px]  2xl:max-w-[1623px]  bg-white">
      <div className="pl-9 pr-11 py-8 space-y-4">
        <div className="space-y-2">
          <div className="w-[365px] flex flex-wrap">
            <p className="font-bold text-[#4F4F4F] text-base">
              썸네일
              <button
                className="ml-8 w-[104px] h-[30px] bg-[#8B8C8C] text-white rounded-lg"
                onClick={crGoodPosterHandler}
              >
                이미지추가
              </button>
            </p>
          </div>
          <div className="pt-5 grid grid-cols-4 gap-6 ">
            {loading && <div>메인 이미지를 불러오는 중입니다</div>}
            {!loading &&
              listGoodPosterData?.listGoodPoster.goodPosters &&
              listGoodPosterData?.listGoodPoster.goodPosters.map(
                (goodPoster) => (
                  <div
                    key={`thumbnail-${goodPoster.id}`}
                    className="w-full aspect-square border rounded-lg  border-[#000000] relative"
                  >
                    <button
                      className={`absolute -top-2 -right-2 bg-black rounded-full z-10`}
                      onClick={() => delGoodPosterHandler(goodPoster.id)}
                    >
                      <img src={remove} alt={`이 이미지 없애기`} />
                    </button>
                    <label
                      htmlFor={`poster${goodPoster.id}`}
                      className="flex flex-wrap w-full h-full content-center justify-center cursor-pointer"
                    >
                      <img src={img}></img>
                      <img
                        src={`${
                          goodPoster.upload?.url !== null
                            ? goodPoster.upload?.url
                            : ""
                        }`}
                        alt="썸네일"
                        className={`absolute left-0 top-0 w-full h-full rounded-lg ${
                          !Boolean(goodPoster.upload) ? "hidden" : ""
                        } `}
                      />
                    </label>
                    <input
                      type="file"
                      className="hidden"
                      id={`poster${goodPoster.id}`}
                      accept="image/png, image/jpeg, image/jpg , image/gif, video/mp4, video/mov, video/avi"
                      onChange={(event: any) =>
                        uploadGoodPorsterHandler(
                          goodPoster.index,
                          event,
                          goodPoster.id
                        )
                      }
                    ></input>
                  </div>
                )
              )}
            {!loading &&
              listGoodPosterData?.listGoodPoster.goodPosters &&
              listGoodPosterData.listGoodPoster.goodPosters.length < 1 && (
                <div>이미지가 없습니다</div>
              )}
          </div>
        </div>
      </div>
    </div>
  );
};
