import { ApolloError, gql, useMutation } from "@apollo/client";
import {
  DelBannerMainMutation,
  DelBannerMainMutationVariables,
} from "../generated/graphql";

const DEL_BANNER_MAIN_MUTATION = gql`
  mutation delBannerMain($input: DelBannerMainInput!) {
    delBannerMain(input: $input) {
      ok
      err
    }
  }
`;

export const useDelBannerMain = (
  onCompleted?: (data: DelBannerMainMutation) => void,
  onError?: (Error: ApolloError) => void
) => {
  return useMutation<DelBannerMainMutation, DelBannerMainMutationVariables>(
    DEL_BANNER_MAIN_MUTATION,
    {
      onCompleted,
      onError,
    }
  );
};
