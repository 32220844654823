import { ApolloError, gql, useMutation } from "@apollo/client";
import {
  EditOrderStatusWithQuantityMutation,
  EditOrderStatusWithQuantityMutationVariables,
} from "../generated/graphql";

const EDIT_ORDER_STATUS_WITH_QUANTITY_MUTATION = gql`
  mutation editOrderStatusWithQuantity(
    $input: EditOrderStatusWithQuantityInput!
  ) {
    editOrderStatusWithQuantity(input: $input) {
      ok
      err
    }
  }
`;

export const useEditOrderStatusWithQuantity = (
  onCompleted?: (data: EditOrderStatusWithQuantityMutation) => void,
  onError?: (error: ApolloError) => void
) => {
  return useMutation<
    EditOrderStatusWithQuantityMutation,
    EditOrderStatusWithQuantityMutationVariables
  >(EDIT_ORDER_STATUS_WITH_QUANTITY_MUTATION, {
    onCompleted,
    onError,
  });
};
