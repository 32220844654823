import { ApolloError, gql, useMutation } from "@apollo/client";
import {
  EditCommentInput,
  EditCommentMutation,
  EditCommentMutationVariables,
} from "../generated/graphql";

export interface IEditCommentFormProps extends EditCommentInput {}
const EDIT_COMMENT_QUERT = gql`
  mutation editComment($input: EditCommentInput!) {
    editComment(input: $input) {
      ok
      err
    }
  }
`;

export const useEditComment = (
  onCompleted?: (data: EditCommentMutation) => void,
  onError?: (error: ApolloError) => void
) => {
  return useMutation<EditCommentMutation, EditCommentMutationVariables>(
    EDIT_COMMENT_QUERT,
    {
      onCompleted,
      onError,
    }
  );
};
