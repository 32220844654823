import { useReactiveVar } from "@apollo/client";
import { useEffect, useRef, useState } from "react";
import { newOrder, eDate, sDate } from "../../apollo";
import { NewOrderLists } from "../../hooks/subNewOrderList";
import refresh from "../../assets/icon/refresh.png";
import { ComponentToPrint } from "../component-to-print";
import { ko } from "date-fns/esm/locale";
import searchdate from "../../assets/icon/searchdate.png";
import { CSVLink } from "react-csv";
import excel from "../../assets/icon/excel.png";
import exit from "../../assets/icon/exit.png";
import { Modal } from "@mui/material";
import ReactToPrint from "react-to-print";
import { useEditOrderStatus } from "../../hooks/useEditOrderStatus";
import { headers } from "../../constants/datas";
import { useEditOrdersStatusRelease } from "../../hooks/useEditOrderStatusRelease";
import moment from "moment";
import { useForm } from "react-hook-form";
import { all } from "q";
import {
  EditOrdersStatusInput,
  EditOrdersStatusMutation,
  EditOrdersStatusReleaseMutation,
  OrderStatus,
} from "../../generated/graphql";
import { ListOrder } from "../../routes/admin-list-order";
import React from "react";
import { useEditOrdersStatus } from "../../hooks/useEditOrdersStatus";

export interface ComponentTabOrder {
  dataList?: any[];
  DataCallback?: () => void;
  refetch?(): void;
  setDateSearch?: (sDate?: Date, eDate?: Date) => void;
}

export interface IDateSearchFormProps {
  sDate: Date;
  eDate: Date;
}
export const ComponentTabOrder: React.FC<ComponentTabOrder> = ({
  dataList = [],
  DataCallback,
  setDateSearch,
  refetch,
}) => {
  const [orderIds, setOrderIds] = useState<number[]>([]);
  const [open, setOpen] = React.useState(false);
  const [change, setChange] = React.useState<OrderStatus>(OrderStatus.Inspect);
  const { register, getValues, handleSubmit } = useForm<IDateSearchFormProps>({
    mode: "all",
  });
  const pageStyle =
    "@page { size: 8cm 6cm ; } @media print{footer{display:none}} @media print{header{display:none} .page-break{page-break-after:always; display:blcok;}} @media all{.page-break{display:none}} ";
  const componentRef = useRef(null);

  const datalist = dataList.map((row) => ({
    ...row,
    phone: row.receipt?.phone ? `=""${row.receipt.phone}""` : null,
    createdAt: moment(row.createdAt).format("YYYY-MM-DD HH:mm"),
  }));

  const onSubmit = () => {
    const { sDate, eDate } = getValues();
    if (setDateSearch) {
      setDateSearch(sDate, eDate);
    }
  };
  // --- 상태 변경 한번에 ---
  const onEditStatusCompleted = async (data: EditOrdersStatusMutation) => {
    const {
      editOrdersStatus: { ok, err },
    } = data;
    if (ok && refetch) {
      await refetch();
    } else {
      alert(err);
    }
  };
  const [editOrdersStatus, { loading }] = useEditOrdersStatus(
    onEditStatusCompleted
  );
  const editOrdersStatusHandler = (status: OrderStatus) => {
    var orderIds: number[] = [];

    for (const data of dataList) {
      if (data.id) {
        orderIds.push(Number(data.id));
      }
    }
    if (!loading) {
      const editOrdersStatusInput: EditOrdersStatusInput = {
        orderIds,
        status,
      };
      editOrdersStatus({ variables: { input: editOrdersStatusInput } });
    }
  };
  // const dateHandler = (date: Date) => {
  //   sDate(date);
  // };
  // const edateHandler = (date: Date) => {
  //   eDate(date);
  // };
  const refetchHandler = () => {
    window.location.href = "/order";
  };
  const IsNewOrder = useReactiveVar(newOrder);
  const { data: newOrderList } = NewOrderLists();

  // const onEditCompleted = async (data: editOrderStatus) => {
  //   const {
  //     editOrderStatus: { ok, err },
  //   } = data;
  //   if (ok) {
  //     // alert(`출고대기 상태입니다`);
  //     window.location.href = "/order";
  //   } else {
  //     alert(err);
  //   }
  // };
  // const [editOrderStatus, {}] = useEditOrderStatus(onEditCompleted);

  // const checkState = () => {
  //   if (window.confirm("운송장 출력하셨습니까?")) {
  //     var checklist: checkList[] = [...dataList];
  //     for (var i = 0; i < checklist.length; i++) {
  //       const orderId = checklist[i].id;
  //       const status = OrderStatus.Bound;
  //       const editOrderstatusInput: EditOrderStatusInput = {
  //         orderId,
  //         status,
  //       };
  //       editOrderStatus({ variables: { input: editOrderstatusInput } });
  //     }
  //   }
  // };
  const orderIdsHandler = () => {
    var _array = [];
    var checklist: ListOrder[] = [...dataList];
    for (var i = 0; i < checklist.length; i++) {
      _array.push(checklist[i].id);
    }
    setOrderIds(_array);
  };

  const onEditCompleted = async (data: EditOrdersStatusReleaseMutation) => {
    const {
      editOrdersStatusRelease: { ok, err },
    } = data;
    if (ok) {
      // alert(`출고대기 상태입니다`);
      window.location.href = "/order";
    } else {
      alert(err);
    }
  };
  const [editOrdersStatusRelease, {}] = useEditOrdersStatusRelease(
    onEditCompleted
  );
  const checkState = () => {
    if (window.confirm("운송장 출력하셨습니까?")) {
      const editOrdersStatusInput: EditOrdersStatusInput = {
        orderIds: orderIds,
        status: OrderStatus.Bound,
      };
      editOrdersStatusRelease({
        variables: { input: editOrdersStatusInput },
      });
    }
  };
  useEffect(() => {
    orderIdsHandler();
    if (newOrderList && newOrderList.newOrderList.length > 0) {
      newOrder(true);
    }
  }, [newOrderList, dataList]);

  return (
    <div className="flex flex-wrap justify-between">
      <div className="flex flex-wrap">
        <button
          className="py-3 px-4 relative"
          onClick={() => {
            refetchHandler();
            newOrder(false);
          }}
        >
          {IsNewOrder && (
            <div className="absolute right-2 top-2 w-3 h-3 block rounded-full bg-red-400">
              <span className="w-full h-full block rounded-full animate-ping bg-red-400"></span>
            </div>
          )}
          <img src={refresh} alt="새로고침" className="w-[30px] h-[30px]" />
        </button>
        <button
          onClick={() => {
            setOpen(true);
          }}
          className="bg-[#555B94] w-[104px] h-[30px] py-[7px] text-xs text-center rounded-lg my-[13px] text-white"
        >
          상태변경
        </button>
      </div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="flex flex-wrap gap-x-4 content-center">
          <p className="text-[#8B8C8C] text-sm flex flex-wrap content-center">
            기간
          </p>
          <div className="flex flex-wrap content-center">
            <input
              {...register("sDate")}
              type="date"
              className="border w-full  border-[#F0F0F4] text-base rounded-lg py-1 px-4"
            ></input>
          </div>
          <span className="text-white flex flex-wrap content-center">~</span>
          <div className="flex flex-wrap content-center">
            <input
              {...register("eDate")}
              type="date"
              className="border w-full  border-[#F0F0F4] text-base rounded-lg py-1 px-4"
            ></input>
          </div>

          <button className="py-3">
            <img src={searchdate} alt="찾기" className="w-[32px] h-[30px]" />
          </button>
        </div>
      </form>
      <div className="flex flex-wrap justify-end pr-8">
        <div className="flex flex-wrap gap-x-3">
          <CSVLink
            data={datalist}
            headers={headers}
            filename={`주문내역`}
            className={`flex flex-wrap gap-x-1  w-[72px] h-[30px] my-[13px] bg-white text-center items-center rounded-lg`}
          >
            <p className="pl-[15px] text-xs text-[#8B8C8C]">csv</p>
            <img src={excel} className="w-[10px] h-3" />
          </CSVLink>

          <ReactToPrint
            pageStyle={pageStyle}
            onAfterPrint={() => checkState()}
            trigger={() => (
              <button className="bg-transparent bg-[#8b8c8c] w-[104px] h-[30px] py-[7px] text-xs font-bold text-center rounded-lg my-[13px] text-white">
                운송장 출력
              </button>
            )}
            content={() => componentRef.current}
          ></ReactToPrint>
          <div className="hidden">
            <ComponentToPrint check={dataList} printRef={componentRef} />
          </div>
        </div>
      </div>
      <Modal
        open={open}
        onClose={() => {
          setOpen(false);
        }}
      >
        <div className="max-w-[360px] w-full absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2   bg-[#F9F8F9] rounded-lg">
          <div className="text-center py-2 h-10 text-white font-bold bg-[#3D3E49] rounded-t-lg  ">
            <div className="flex flex-wrap justify-between">
              <p className="w-[240px] ml-[40px] font-bold text-base">
                배송 상태변경
              </p>
              <img
                src={exit}
                alt="닫기"
                className="w-6 mr-2 cursor-pointer"
                onClick={() => setOpen(false)}
              ></img>
            </div>
          </div>
          <div className="flex flex-wrap content-center">
            <div className="px-10 py-10">
              <div className="grid grid-rows-6 gap-y-4">
                <p className="font-bold">배송 준비</p>
                <div>
                  <input
                    type="radio"
                    name="order"
                    id="pending"
                    onChange={(e) => {
                      if (e.target.checked) {
                        setChange(OrderStatus.Pending);
                      }
                    }}
                  ></input>
                  <span className="ml-4">배송 준비</span>
                </div>
                <p className="font-bold">배송중</p>
                <div>
                  <input
                    type="radio"
                    name="order"
                    id="Drone"
                    value="Drone"
                    onChange={(e) => {
                      if (e.target.checked) {
                        setChange(OrderStatus.Drone);
                      }
                    }}
                  ></input>
                  <span className="ml-4">드론 비행</span>
                </div>
                <div>
                  <input
                    type="radio"
                    name="order"
                    id="Port"
                    value="HandOut"
                    onChange={(e) => {
                      if (e.target.checked) {
                        setChange(OrderStatus.Port);
                      }
                    }}
                  ></input>
                  <span className="ml-4">최종 배송준비</span>
                </div>
                <div>
                  <input
                    type="radio"
                    name="order"
                    id="Stored"
                    value="Stored"
                    onChange={(e) => {
                      if (e.target.checked) {
                        setChange(OrderStatus.Stored);
                      }
                    }}
                  ></input>
                  <span className="ml-4">픽업포트</span>
                </div>
                <div>
                  <input
                    type="radio"
                    name="order"
                    id="PendingDelivered"
                    value="PendingDeliverd"
                    onChange={(e) => {
                      if (e.target.checked) {
                        setChange(OrderStatus.PendingDelivered);
                      }
                    }}
                  ></input>
                  <span className="ml-4">최종 배송</span>
                </div>
                <p className="font-bold">배송 완료</p>
                <div>
                  <input
                    type="radio"
                    name="order"
                    id="check"
                    value="Delivered"
                    checked={change === OrderStatus.Delivered}
                    onChange={(e) => {
                      if (e.target.checked) {
                        setChange(OrderStatus.Delivered);
                      }
                    }}
                  ></input>
                  <span className="ml-4">배송 완료</span>
                </div>
                <p className="font-bold">환불/교환</p>
                <div>
                  <input
                    type="radio"
                    name="order"
                    id="confirm"
                    onChange={(e) => {
                      if (e.target.checked) {
                        setChange(OrderStatus.Refunded);
                      }
                    }}
                  ></input>
                  <span className="ml-4">환불</span>
                </div>
                <div>
                  <input
                    type="radio"
                    name="order"
                    id="end"
                    value="Exchange"
                    onChange={(e) => {
                      if (e.target.checked) {
                        setChange(OrderStatus.Exchange);
                      }
                    }}
                  ></input>
                  <span className="ml-4">교환</span>
                </div>
                <p className="font-bold">확정</p>
                <div>
                  <input
                    type="radio"
                    name="order"
                    id="Complete"
                    onChange={(e) => {
                      if (e.target.checked) {
                        setChange(OrderStatus.Complete);
                      }
                    }}
                  ></input>
                  <span className="ml-4">확정</span>
                </div>
              </div>
            </div>
          </div>
          <button
            className="w-full h-14 bg-[#F0F0F4] rounded-b-lg"
            onClick={() => {
              editOrdersStatusHandler(change);
              setOpen(false);
            }}
          >
            확인
          </button>
        </div>
      </Modal>
    </div>
  );
};
