import { gql, useLazyQuery } from "@apollo/client";
import {
  ListGoodPosterQuery,
  ListGoodPosterQueryVariables,
} from "../generated/graphql";

const LIST_GOOD_POSTER_QUERY = gql`
  query listGoodPoster($input: ListGoodPosterInput!) {
    listGoodPoster(input: $input) {
      ok
      err
      goodPosters {
        id
        createdAt
        index
        isRep
        upload {
          id
          url
        }
        good {
          id
        }
      }
    }
  }
`;

export const useListGoodPoster = (
  onCompleted?: (data: ListGoodPosterQuery) => void
) => {
  return useLazyQuery<ListGoodPosterQuery, ListGoodPosterQueryVariables>(
    LIST_GOOD_POSTER_QUERY,
    {
      onCompleted,
      fetchPolicy: "network-only",
    }
  );
};
