import { ApolloError, gql, useMutation } from "@apollo/client";
import {
  EditBoundInput,
  EditBoundMutation,
  EditBoundMutationVariables,
} from "../generated/graphql";

export interface IEditBoundFormProps extends EditBoundInput {}
const EDIT_BOUND_QUERY = gql`
  mutation editBound($input: EditBoundInput!) {
    editBound(input: $input) {
      ok
      err
    }
  }
`;

export const useEditBound = (
  onCompleted?: (data: EditBoundMutation) => void,
  onError?: (error: ApolloError) => void
) => {
  return useMutation<EditBoundMutation, EditBoundMutationVariables>(
    EDIT_BOUND_QUERY,
    {
      onCompleted,
      onError,
    }
  );
};
