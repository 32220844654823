import { useEffect, useState } from "react";
import { AsideAdminComponent } from "../layout/aside-admin";
import React from "react";
import { eDate, newOrder, pageIndicator, sDate } from "../apollo";
import { useLocation, useNavigate } from "react-router-dom";
import exit from "../assets/icon/exit.png";
import {
  PageType,
  ComponentTabLink,
  ITabTitle,
} from "../components/component-tab";
import { ITabLink } from "../components/component-tab";
import { Modal } from "@mui/material";
import { TableError } from "../components/table-error";
import { PaginationComponent } from "../components/pagination";
import { useDelOrder } from "../hooks/useDelOrder";
import { useListOrder } from "../hooks/useListOrder";
import { useEditOrderStatus } from "../hooks/useEditOrderStatus";
import moment from "moment";
import { useReactiveVar } from "@apollo/client";
import {
  DelOrderInput,
  DelOrderMutation,
  EditOrderStatusInput,
  EditOrderStatusMutation,
  ExchangeStatus,
  ListOrderInput,
  ListOrderQuery,
  OrderStatus,
  Redelivery,
} from "../generated/graphql";
import { ArrElement } from "../sub-routes/sub-admin-up-product-detail";

export type ListOrder = ArrElement<ListOrderQuery["listOrder"]["orders"]>;

// 여기에 interface 설정

export const AdminListOrder = () => {
  const [open, setOpen] = React.useState(false);
  const location = useLocation();
  const [change, setChange] = React.useState<OrderStatus>(OrderStatus.Inspect);
  const [stateId, setStateId] = useState<number>(1);
  const [page, setPage] = useState<number>(1);
  const [search, setSearch] = useState<string>("");
  const [checkedLists, setCheckedLists] = useState<ListOrder[]>([]);
  const [allChecked, setAllChecked] = useState<boolean>(false);
  const sOrderDate = useReactiveVar(sDate);
  const eOrderDate = useReactiveVar(eDate);

  const word = "주문내역";

  // -- 주문내역 리스트 --
  const onCompleted = async (data: ListOrderQuery) => {
    const {
      listOrder: { ok, err },
    } = data;
    if (ok) {
      await refetch();
    } else {
      alert(err);
    }
  };

  const [listOrder, { data, loading, refetch }] = useListOrder(onCompleted);
  const listOrderHandler = (
    page: number | null,
    search: string | null,
    statuses: OrderStatus[],
    start?: Date | null,
    end?: Date | null
  ) => {
    const input: ListOrderInput = {
      ...(page && { page: +page }),
      ...(search && { search: decodeURI(search) }),
      ...(statuses.length > 0 && { statuses }),
      ...(start && { start }),
      ...(end && { end }),
    };
    listOrder({ variables: { input } });
  };

  const navigate = useNavigate();

  // --- 상태 변경 ---
  const onEditCompleted = async (data: EditOrderStatusMutation) => {
    const {
      editOrderStatus: { ok, err },
    } = data;
    if (ok) {
      // alert(`주문상태가 수정되었습니다.`);
      await refetch();
    } else {
      alert(err);
    }
  };
  const [
    editOrderStatus,
    { loading: editOrderStatusLoading },
  ] = useEditOrderStatus(onEditCompleted);
  const editOrderStatusHandler = (orderId: number, status: OrderStatus) => {
    if (!editOrderStatusLoading) {
      const editOrderstatusInput: EditOrderStatusInput = {
        orderId,
        status,
      };
      editOrderStatus({ variables: { input: editOrderstatusInput } });
    }
  };

  const StateHandler = (id: number) => {
    setStateId(id);
    setOpen(true);
  };

  // --- Search ---
  const setSearchHandler = (search: string) => {
    navigate({
      pathname: "/order",
      search: `${search ? `?search=${search}` : ""}`,
    });

    const statuses: OrderStatus[] = [OrderStatus.Inspect];
    listOrderHandler(1, search, statuses);
  };

  // --- Page ---
  const pageHandler = (page: number) => {
    const search =
      location.search.split("search=").length > 1
        ? location.search.split("search=")[1].split("&")[0]
        : null;
    setPage(page);
    navigate({
      pathname: "/order",
      search: `?page=${page}${search ? `&search=${search}` : ""}`,
    });
    setPage(page);
    const statuses: OrderStatus[] = [OrderStatus.Inspect];
    listOrderHandler(page, search, statuses);
  };
  // --- Period ---
  const periodHandler = (sDate?: Date, eDate?: Date) => {
    const statuses: OrderStatus[] = [OrderStatus.Inspect];
    listOrderHandler(1, search, statuses, sDate, eDate);
  };

  useEffect(() => {
    pageIndicator("delivery");
    setPage(
      location.search.split("page=").length > 1
        ? +location.search.split("page=")[1].split("&")[0]
        : 1
    );
    setSearch(
      location.search.split("search=").length > 1
        ? location.search.split("search=")[1].split("&")[0]
        : ""
    );
    const statuses: OrderStatus[] = [OrderStatus.Inspect];
    listOrderHandler(page, search, statuses);
  }, [sOrderDate, eOrderDate, search, page]);

  const tabList: ITabLink[] = [
    { text: "주문확인", to: "/order", selected: true },
    { text: "출고대기", to: "/release" },
    { text: "배송준비중", to: "/order/delivery" },
    { text: "배송중", to: "/order/start" },
    { text: "배송완료", to: "/order/end" },
    { text: "교환/환불", to: "/order/exchange" },
    { text: "배송확정", to: "/order/confirm" },
  ];

  const stitle: ITabTitle = { text: "배송관리" };

  const setAllCheckedHandler = () => {
    setAllChecked(!allChecked);
    if (allChecked) setCheckedLists([]);

    if (!allChecked && data?.listOrder.orders) {
      setCheckedLists([]);
      for (const order of data.listOrder.orders) {
        setCheckedLists((checkedList) => [...checkedList, order]);
      }
    }
  };

  const setCheckedListHandler = (orderId: number, data: any) => {
    if (Boolean(checkedLists.find((checkedId) => checkedId.id === orderId))) {
      setCheckedLists(
        checkedLists.filter((checkedId) => checkedId.id !== orderId)
      );
    } else {
      setCheckedLists((originalList) => [...originalList, data]);
    }
  };

  // ----- 삭제 ------
  const onDelCompleted = async (data: DelOrderMutation) => {
    const {
      delOrder: { ok, err },
    } = data;
    if (ok) {
      alert("주문내역 삭제");
      await refetch();
    } else {
      alert(err);
    }
  };
  const [delOrder, { loading: delOrderLoading }] = useDelOrder(onDelCompleted);
  const delOrderHandler = (orderId: number) => {
    if (delOrderLoading) return;
    if (
      window.confirm(
        "삭제한 주문내역은 복구할 수 없습니다. 정말로 삭제하시겠습니까?"
      )
    ) {
      const input: DelOrderInput = {
        orderId,
      };
      delOrder({ variables: { input } });
    }
  };

  // const fetch = require("node-fetch");

  // const url = {};
  // const options = {
  //   method: "POST",
  //   async: true,
  //   crossDomain: true,
  //   url: "https://onesignal.com/api/v1/notifications",
  //   headers: {
  //     accept: "application/json",
  //     "content-type": "application/json",
  //     Authorization: "Basic N2E4NTc5NDgtZDM4Ni00Yzk1LTk3NWEtOTkxZTcxNDcwNmVi",
  //   },
  //   body: JSON.stringify({
  //     app_id: "94eb32d9-dba1-48e7-9d90-3dde88a1fd35",
  //     name: "string",
  //     included_segments: ["string"],
  //     include_player_ids: "string",
  //     filters: [
  //       { field: "string", key: "string", relation: "string", value: "string" },
  //     ],
  //     contents: { en: "푸시테스트" },
  //     headings: { en: "푸시알림 테스트입니다." },
  //     template_id: "string",
  //     data: JSON.stringify(url),
  //     // url: "https://onesignal.com/api/v1/notifications",
  //     big_picture: "string",
  //     small_icon: "icon_96",
  //     large_icon: "icon_48",
  //   }),
  // };

  // fetch(url, options)
  //   .then((res: any) => res.json())
  //   .then((json: any) => console.log(json))
  //   .catch((err: any) => console.error("error:" + err));

  useEffect(() => {
    if (checkedLists.length === data?.listOrder.orders?.length)
      setAllChecked(true);
    else setAllChecked(false);
  }, [checkedLists]);

  return (
    <div className="bg-main bg-opacity-50 w-full h-full min-h-screen">
      <div className="w-full mx-auto h-full min-h-screen bg-gray-100">
        <main id="main">
          <div className="grid grid-cols-[200px_1fr] max-w-full">
            <AsideAdminComponent />
            <div className="mypages bg-gray-100 w-full min-w-[1200px] overflow-hidden">
              <div className="orderWrap">
                <div className="h-fix-content relative">
                  <div className={`w-full h-full px-10 py-8`}>
                    <ComponentTabLink
                      tabs={tabList}
                      stitle={stitle}
                      type={PageType.Order}
                      dataList={checkedLists}
                      setSearchs={setSearchHandler}
                      setDateSearch={periodHandler}
                      refetch={refetch}
                    />
                    {/* 목록 */}
                    <div className=" shadow-lg h-[800px] h-fix-content w-full overflow-auto bg-white">
                      <table className="align-center text-sm ">
                        <thead>
                          <tr className="border-b border-[#F9F8F9] h-8 break-all">
                            <th className="text-[#8B8C8C] leading-3 min-w-[36px]">
                              <input
                                type="checkbox"
                                name="allChk"
                                checked={allChecked}
                                onChange={setAllCheckedHandler}
                                className="h-[20px] w-[20px]"
                              />
                            </th>
                            <th className="text-[#8B8C8C] min-w-[40px]">no</th>
                            <th className="text-[#8B8C8C] min-w-[64px]">
                              상태
                            </th>
                            <th className="text-[#8B8C8C] min-w-[88px]">
                              주문번호
                            </th>
                            <th className="text-[#8B8C8C] min-w-[72px]">
                              주문일시
                            </th>
                            <th className="text-[#8B8C8C] min-w-[168px]">
                              상품명
                            </th>
                            <th className="text-[#8B8C8C] min-w-[168px]">
                              상품옵션
                            </th>
                            <th className="text-[#8B8C8C] min-w-[48px]">
                              수량
                            </th>
                            <th className="text-[#8B8C8C] min-w-[88px]">
                              결제금액
                            </th>
                            <th className="text-[#8B8C8C] min-w-[88px]">
                              구매자
                            </th>
                            <th className="text-[#8B8C8C] min-w-[88px]">
                              수취인
                            </th>
                            <th className="text-[#8B8C8C] min-w-[168px]">
                              주소
                            </th>
                            <th className="text-[#8B8C8C] min-w-[168px]">
                              배송메세지
                            </th>
                            <th className="text-[#8B8C8C] min-w-[168px]">
                              공동현관
                            </th>
                            <th className="text-[#8B8C8C] min-w-[128px]">
                              연락처
                            </th>
                            <th className="text-[#8B8C8C] min-w-[88px]">
                              상품코드
                            </th>
                            <th className="text-[#8B8C8C] min-w-[88px]">
                              위치코드
                            </th>
                            <th className="text-[#8B8C8C] min-w-[88px]">
                              무게
                            </th>
                            <th className="text-[#8B8C8C] min-w-[88px]">
                              재배송
                            </th>
                            <th className="text-[#8B8C8C] min-w-[80px]">
                              비고
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {!loading &&
                            data?.listOrder.orders &&
                            data.listOrder.orders.map((order, index) => (
                              <tr
                                className="border-b border-[#F9F8F9] h-[68px]"
                                key={`order-list-${order.id}`}
                              >
                                <td className="table-text">
                                  <input
                                    type="checkbox"
                                    name={`input ${order.id}`}
                                    checked={
                                      checkedLists.find(
                                        (checkedId) => checkedId.id === order.id
                                      ) !== undefined
                                    }
                                    onChange={(e) => {
                                      setCheckedListHandler(order.id, order);
                                    }}
                                    className="h-[20px] w-[20px]"
                                  />
                                </td>
                                <td className="table-text">
                                  {(page - 1) * 20 + index + 1}
                                </td>
                                <td className="table-text">
                                  <button
                                    className="border text-xs border-[#cccccc] rounded-lg py-[4px] px-[3px] w-[48px] h-10 text-[#cccccc] font-bold keep-all"
                                    onClick={() => {
                                      StateHandler(order.id);
                                    }}
                                  >
                                    {order.status === "Inspect"
                                      ? "주문 확인"
                                      : ""}
                                  </button>
                                </td>
                                <td className="table-text break-all">
                                  {order.receipt?.receiptNum}
                                </td>
                                <td className="table-text font-bold">
                                  {order.createdAt &&
                                    moment(order.createdAt).format(
                                      "YYMMDD"
                                    )}{" "}
                                  <br />
                                  {order.createdAt &&
                                    moment(order.createdAt).format("H : mm")}
                                </td>
                                <td className="table-text">
                                  {/* {order.goodName} */}
                                  {order.orderReceipt?.good_title}
                                </td>
                                <td className="table-text">
                                  {/* {order.goodOptionName} */}
                                  {order.orderReceipt?.goodOption_name}
                                </td>
                                <td className="table-text font-bold">
                                  {/* {order.quantity} */}
                                  {order.orderReceipt?.order_quantity}
                                </td>
                                <td className="table-text">
                                  {order.orderReceipt?.order_amount}
                                  {/* {order.amount} */}
                                </td>
                                <td className="table-text">
                                  {order.receipt?.user?.name}
                                </td>
                                <td className="table-text font-bold">
                                  {order.receipt?.receiver
                                    ? order.receipt.receiver
                                    : order.receipt?.user?.name}
                                </td>
                                <td className="table-text">
                                  {order.receipt?.address}
                                </td>
                                <td className="table-text">
                                  {order.receipt?.message}
                                </td>
                                <td className="table-text">
                                  {order.receipt?.pass}
                                </td>
                                <td className="table-text">
                                  {order.receipt?.phone
                                    ? order.receipt.phone
                                    : order.receipt?.user?.phone}
                                </td>
                                <td className="table-text break-all">
                                  {order.good?.goodUuid}
                                </td>
                                <td className="table-text"></td>
                                <td className="table-text">
                                  {order.good?.weight &&
                                    order.good?.weight + "g"}
                                </td>
                                <td className="table-text">
                                  {order.redelivery &&
                                  order.redelivery === "Redelivery"
                                    ? "재배송"
                                    : ""}
                                </td>
                                <td className="table-text">
                                  <button
                                    className="bg-[#CCCCCC] rounded-lg py-[6px]  w-[48px] h-7 text-white"
                                    onClick={() => delOrderHandler(order.id)}
                                  >
                                    삭제
                                  </button>
                                </td>
                              </tr>
                            ))}
                        </tbody>
                      </table>

                      {loading && (
                        <TableError errorMessage={`정보를 불러오는중입니다`} />
                      )}
                      {!loading &&
                        data?.listOrder.orders &&
                        data.listOrder.orders.length < 1 && (
                          <TableError
                            errorMessage={`해당하는 ${word} 정보가 없습니다`}
                          />
                        )}

                      {!loading &&
                        data?.listOrder.orders &&
                        data.listOrder.orders.length > 0 && (
                          <PaginationComponent
                            page={page}
                            totalPages={Number(data.listOrder.totalPages)}
                            totalResults={Number(data.listOrder.totalResults)}
                            pageHandler={pageHandler}
                          />
                        )}

                      <Modal
                        open={open}
                        onClose={() => {
                          setOpen(false);
                        }}
                      >
                        <div className="max-w-[360px] w-full absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2   bg-[#F9F8F9] rounded-lg">
                          <div className="text-center py-2 h-10 text-white font-bold bg-[#3D3E49] rounded-t-lg  ">
                            <div className="flex flex-wrap justify-between">
                              <p className="w-[240px] ml-[40px] font-bold text-base">
                                배송 상태변경
                              </p>
                              <img
                                src={exit}
                                alt="닫기"
                                className="w-6 mr-2 cursor-pointer"
                                onClick={() => setOpen(false)}
                              ></img>
                            </div>
                          </div>
                          <div className="flex flex-wrap content-center">
                            <div className="px-10 py-10">
                              <div className="grid grid-rows-3 gap-y-4">
                                <div>
                                  <input
                                    type="radio"
                                    name="order"
                                    id="inspect"
                                    value={OrderStatus.Inspect}
                                    defaultChecked
                                    onChange={(e) => {
                                      if (e.target.checked) {
                                        setChange(OrderStatus.Inspect);
                                      }
                                    }}
                                  ></input>
                                  <span className="ml-4">주문 확인</span>
                                </div>

                                <div>
                                  <input
                                    type="radio"
                                    name="order"
                                    id="bound"
                                    value={OrderStatus.Bound}
                                    onChange={(e) => {
                                      if (e.target.checked) {
                                        setChange(OrderStatus.Bound);
                                      }
                                    }}
                                  ></input>
                                  <span className="ml-4">출고대기</span>
                                </div>
                              </div>
                            </div>
                          </div>
                          <button
                            className="w-full h-14 bg-[#F0F0F4] rounded-b-lg"
                            onClick={() => {
                              editOrderStatusHandler(stateId, change);
                              setOpen(false);
                            }}
                          >
                            확인
                          </button>
                        </div>
                      </Modal>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
};
