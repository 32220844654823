import { Modal } from "@mui/material";
import moment from "moment";
import React, { useState } from "react";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useParams } from "react-router";
import { pageIndicator } from "../apollo";
import { Button } from "../components/button";
import exit from "../assets/icon/exit.png";
import {
  ComponentTabLink,
  ITabLink,
  ITabTitle,
  PageType,
} from "../components/component-tab";
import { FormError2 } from "../components/form-error";
import { IEditFlightFormProps, useEditFlight } from "../hooks/useEditFlight";
import { useGetFlight } from "../hooks/useGetFlight";
import { useListDroneSelect } from "../hooks/useListDroneSelect";
import { useListPortSelect } from "../hooks/useListPortSelect";
import { useListProjectSelect } from "../hooks/useListProjectSelect";
import { useListUserSelect } from "../hooks/useListUserSelect";
import { AsideAdminComponent } from "../layout/aside-admin";

import { useListPackEdit } from "../hooks/useListPackEdit";
import {
  EditFlightInput,
  EditFlightMutation,
  GetFlightInput,
  GetFlightQuery,
  ListPackEditInput,
  ListPackEditQuery,
} from "../generated/graphql";

export const AdminUpFlight = () => {
  const tabList: ITabLink[] = [];

  const stitle: ITabTitle = { text: "비행기록 편집" };
  const [open, setOpen] = React.useState(false);
  const [pack, setPack] = useState<number>();
  const [changePack, setChangePack] = useState<number>();
  useEffect(() => {
    pageIndicator("flight");
  }, []);
  const [listProjectSelect, { data: projects }] = useListProjectSelect();
  const [listDroneSelect, { data: drones }] = useListDroneSelect();
  const [listPortSelect, { data: ports }] = useListPortSelect();
  const [listUserSelect, { data: users }] = useListUserSelect();

  const params = useParams();

  const {
    register,
    handleSubmit,
    getValues,
    setValue,
    setFocus,
    formState: { errors, isValid },
  } = useForm<IEditFlightFormProps>({ mode: "all" });

  const onGetComplete = (data: GetFlightQuery) => {
    const {
      getFlight: { ok, err },
    } = data;
    if (!ok) {
      alert("잘못된 접근입니다");
      window.location.href = "/flight";
      return;
    }
    setValue(
      "takeOffAt",
      moment(data.getFlight.flight?.takeOffAt).format("YYYY-MM-DD HH:mm")
    );
    setValue(
      "orderAt",
      moment(data.getFlight.flight?.orderAt).format("YYYY-MM-DD HH:mm")
    );
    setValue(
      "landAt",
      moment(data.getFlight.flight?.landAt).format("YYYY-MM-DD HH:mm")
    );
    setValue("note", `${data.getFlight.flight?.note}`);
    setValue("status", data.getFlight.flight?.status);
    setValue("packId", Number(`${data.getFlight.flight?.pack?.id}`));
    setValue("projectId", Number(`${data.getFlight.flight?.project?.id}`));
    setValue("droneId", Number(`${data.getFlight.flight?.drone?.id}`));
    setValue("portToId", Number(`${data.getFlight.flight?.portTo?.id}`));
    setValue("portFromId", Number(`${data.getFlight.flight?.portFrom?.id}`));
    setValue("pilotToId", Number(`${data.getFlight.flight?.pilotTo?.id}`));
    setValue("pilotFromId", Number(`${data.getFlight.flight?.pilotFrom?.id}`));
    setFocus("droneId");
    setPack(data.getFlight.flight?.pack?.id);
    setChangePack(data.getFlight.flight?.pack?.id);
  };
  const [getFlight, { data, loading }] = useGetFlight(onGetComplete);
  const getFlightHandler = (flightId: number) => {
    const getFlightInput: GetFlightInput = {
      flightId,
    };
    getFlight({ variables: { input: getFlightInput } });
  };
  const onCompleted = async (data: ListPackEditQuery) => {
    const {
      listPackEdit: { ok, err },
    } = data;
    if (ok) {
      await refetch();
    } else {
      alert(err);
    }
  };
  const [
    listPackEdit,
    { data: packData, loading: packLoading, refetch },
  ] = useListPackEdit(onCompleted);

  const packHandler = () => {
    const input: ListPackEditInput = {
      packId: getValues("packId") ? Number(getValues("packId")) : 1,
    };
    listPackEdit({ variables: { input: input } });
    setOpen(true);
  };

  const onUpComplete = (data: EditFlightMutation) => {
    const {
      editFlight: { ok, err },
    } = data;
    if (ok) {
      alert("저장되었습니다");
      window.location.href = "/flight";
    } else {
      alert(err);
    }
  };
  const [editFlight, { loading: editFlightLoading }] = useEditFlight(
    onUpComplete
  );

  const onSubmit = () => {
    if (!params.flightId) return;
    if (!editFlightLoading) {
      const {
        takeOffAt,
        landAt,
        orderAt,
        note,
        status,

        projectId,
        droneId,
        portToId,
        portFromId,
        pilotToId,
        pilotFromId,
      } = getValues();
      const editFlightInput: EditFlightInput = {
        takeOffAt: moment(takeOffAt),
        landAt,
        orderAt,
        note,
        status,
        packId: pack ? +pack : undefined,
        projectId: +projectId,
        droneId: +droneId,
        portToId: +portToId,
        portFromId: +portFromId,
        pilotToId: pilotToId ? +pilotToId : 1,
        pilotFromId: pilotFromId ? +pilotFromId : 1,
        flightId: +params.flightId,
      };
      editFlight({ variables: { input: editFlightInput } });
    }
  };
  useEffect(() => {
    listDroneSelect();
    listPortSelect();
    listProjectSelect();
    listUserSelect();
  }, []);

  useEffect(() => {
    if (!params.flightId) return;
    const flightId = +params.flightId;
    getFlightHandler(flightId);
  }, []);

  return (
    <div className="bg-main bg-opacity-50 w-full h-full min-h-screen">
      <div className="w-full mx-auto h-full min-h-screen bg-white">
        <main id="main">
          <div className="grid grid-cols-[200px_1fr]">
            <AsideAdminComponent />

            <div className="mypages bg-gray-100">
              <div className="orderWrap">
                <div className="bg-gray-100 h-fix-content relative">
                  <div className={`w-full h-full px-10 py-8`}>
                    <ComponentTabLink
                      tabs={tabList}
                      stitle={stitle}
                      type={PageType.Create}
                    />
                    <div className="shadow-lg min-w-[1160px] max-w-[1160px]  2xl:max-w-[1623px]  bg-white">
                      <form onSubmit={handleSubmit(onSubmit)} id="create">
                        <div className="pl-9 pr-11 py-8 space-y-4">
                          <div className="space-y-2">
                            <p className="font-bold text-[#4F4F4F] text-base">
                              드론 선택
                            </p>
                            <div>
                              <select
                                {...register(`droneId`, {
                                  required: "드론 ID를 선택해주세요",
                                })}
                                className="border w-full border-[#F0F0F4] rounded-lg py-1 px-2"
                              >
                                {drones?.listDroneSelect.drones?.map(
                                  (drone) => (
                                    <option
                                      key={`drone-${drone.id}`}
                                      value={drone.id}
                                    >
                                      {drone.name}
                                    </option>
                                  )
                                )}
                              </select>

                              {errors.droneId?.message && (
                                <FormError2
                                  errorMessage={errors.droneId.message}
                                />
                              )}
                            </div>
                          </div>
                          <div className="space-y-2">
                            <p className="font-bold text-[#4F4F4F] text-base">
                              note
                            </p>
                            <input
                              {...register("note", {})}
                              type="text"
                              className="border w-full h-10
                            border-[#F0F0F4] text-base rounded-lg py-1 px-4"
                            />
                          </div>
                          <div className="space-y-2">
                            <p className="font-bold text-[#8B8C8C] text-sm">
                              프로젝트 아이디
                            </p>
                            <select
                              {...register(`projectId`, {
                                required: "프로젝트 ID를 선택해주세요",
                              })}
                              className="border w-full border-[#F0F0F4] rounded-lg py-1 px-2"
                            >
                              {projects?.listProjectSelect.projects?.map(
                                (project) => (
                                  <option
                                    key={`project-${project.id}`}
                                    value={project.id}
                                  >
                                    {project.name}
                                  </option>
                                )
                              )}
                            </select>

                            {errors.projectId?.message && (
                              <FormError2
                                errorMessage={errors.projectId.message}
                              />
                            )}
                          </div>
                          <div className="space-y-2">
                            <p className="font-bold text-[#8B8C8C] text-sm">
                              주문날짜
                            </p>
                            <input
                              {...register("orderAt", {
                                required: `주문날짜를 입력해주세요`,
                              })}
                              type="datetime-local"
                              className="border w-full h-10 border-[#F0F0F4] text-base rounded-lg py-1 px-4"
                            />
                          </div>
                          <div className="space-y-2">
                            <p className="font-bold text-[#8B8C8C] text-sm">
                              출발시간
                            </p>
                            <input
                              {...register("takeOffAt", {
                                required: `출발시간을 입력해주세요`,
                              })}
                              type="datetime-local"
                              className="border w-full h-10 border-[#F0F0F4] text-base rounded-lg py-1 px-4"
                            />
                          </div>

                          <div className="space-y-2">
                            <p className="font-bold text-[#8B8C8C] text-sm">
                              도착시간
                            </p>
                            <input
                              {...register("landAt", {
                                required: `도착시간을 입력해주세요`,
                              })}
                              type="datetime-local"
                              className="border w-full h-10 border-[#F0F0F4] text-base rounded-lg py-1 px-4"
                            />
                          </div>
                          <div className="space-y-2">
                            <p className="font-bold text-[#8B8C8C] text-sm">
                              출발지 포트
                            </p>
                            <select
                              defaultValue="출발지 포트를 선택해주세요"
                              {...register(`portToId`, {
                                required: "출발지 포트를 선택해주세요",
                              })}
                              className="border w-full border-[#F0F0F4] rounded-lg py-1 px-2"
                            >
                              {ports?.listPortSelect.ports?.map((port) => (
                                <option key={`port-${port.id}`} value={port.id}>
                                  {port.name}
                                </option>
                              ))}
                            </select>

                            {errors.portToId?.message && (
                              <FormError2
                                errorMessage={errors.portToId.message}
                              />
                            )}
                          </div>
                          <div className="space-y-2">
                            <p className="font-bold text-[#8B8C8C] text-sm">
                              도착지 포트
                            </p>
                            <select
                              {...register(`portFromId`, {
                                required: "도착지 포트를 선택해주세요",
                              })}
                              className="border w-full border-[#F0F0F4] rounded-lg py-1 px-2"
                            >
                              {ports?.listPortSelect.ports?.map((port) => (
                                <option key={`port-${port.id}`} value={port.id}>
                                  {port.name}
                                </option>
                              ))}
                            </select>

                            {errors.portFromId?.message && (
                              <FormError2
                                errorMessage={errors.portFromId.message}
                              />
                            )}
                          </div>
                          <div className="space-y-2">
                            <p className="font-bold text-[#8B8C8C] text-sm">
                              출발지 조종사
                            </p>
                            <select
                              {...register(`pilotToId`, {
                                required: "출발지 조종사를 선택해주세요",
                              })}
                              className="border w-full border-[#F0F0F4] rounded-lg py-1 px-2"
                            >
                              {users?.listUserSelect.users?.map((user) => (
                                <option key={`user-${user.id}`} value={user.id}>
                                  {user.name}
                                </option>
                              ))}
                            </select>

                            {errors.pilotToId?.message && (
                              <FormError2
                                errorMessage={errors.pilotToId.message}
                              />
                            )}
                          </div>
                          <div className="space-y-2">
                            <p className="font-bold text-[#8B8C8C] text-sm">
                              도착지 조종사
                            </p>
                            <select
                              {...register(`pilotFromId`, {
                                required: "도착지 조종사를 선택해주세요",
                              })}
                              className="border w-full border-[#F0F0F4] rounded-lg py-1 px-2"
                            >
                              {users?.listUserSelect.users?.map((user) => (
                                <option key={`user-${user.id}`} value={user.id}>
                                  {user.name}
                                </option>
                              ))}
                            </select>

                            {errors.pilotFromId?.message && (
                              <FormError2
                                errorMessage={errors.pilotFromId.message}
                              />
                            )}
                          </div>
                          <div className="space-y-2">
                            <p className="font-bold text-[#8B8C8C] text-sm">
                              Pack
                            </p>
                            <div className="flex flex-wrap">
                              <p className="py-1 px-2 rounded-lg border border-b-2 mr-4">
                                {pack === undefined ? "PACK 선택" : pack}
                              </p>
                              <button
                                className="table-text rounded-lg py-[6px] w-[48px] h-7 text-white bg-[#3D3E49]"
                                type="button"
                                onClick={() => packHandler()}
                              >
                                PACK
                              </button>
                            </div>
                          </div>
                          <div className="flex flex-wrap justify-center pt-6">
                            <Button
                              className={`w-[240px] h-[40px] bg-[#3D3E49] text-white rounded-lg`}
                              canClick={true}
                              actionText={`등록`}
                              loading={loading}
                              color={"text-white"}
                            />
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
        <Modal
          open={open}
          onClose={() => {
            setOpen(false);
          }}
        >
          <div className="max-w-[720px] w-full absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2   bg-[#F9F8F9] rounded-lg ">
            <div className="text-center py-2 h-10 text-white font-bold bg-[#3D3E49] rounded-t-lg  ">
              <div className="flex flex-wrap justify-between">
                <p className="w-[480px] ml-[40px] font-bold text-base">Pack</p>
                <img
                  src={exit}
                  alt="닫기"
                  className="w-6 mr-2 cursor-pointer"
                  onClick={() => setOpen(false)}
                ></img>
              </div>
            </div>
            <div className="flex flex-wrap ">
              <div className="px-10 py-10 w-[720px] overflow-auto">
                <div className=" inline-grid grid-flow-col">
                  {packData?.listPackEdit.packs &&
                    packData.listPackEdit.packs.map((pack) => (
                      <button
                        key={pack.id}
                        className={`packButton border border-b-2 p-2 w-[240px] h-[240px] hover:bg-[#CCCCCC] ${
                          changePack === pack.id ? "bg-[#cccccc]" : ""
                        } `}
                        onClick={() => {
                          setChangePack(pack.id);
                        }}
                      >
                        <p>팩 {pack.id}</p>
                        {pack.orders?.map((test) => (
                          <div
                            className="py-2 flex flex-wrap justify-between"
                            key={test.id}
                          >
                            <p>주문번호 : {test.orderNum}</p>
                            <p>상품명 : {test.goodOptionName}</p>
                          </div>
                        ))}
                      </button>
                    ))}
                </div>
              </div>
            </div>
            <button
              className="w-full h-14 bg-[#F0F0F4] rounded-b-lg"
              onClick={() => {
                setOpen(false);
                setPack(changePack);
              }}
            >
              확인
            </button>
          </div>
        </Modal>
      </div>
    </div>
  );
};
