import React, { useEffect, useState } from "react";
import { AsideAdminComponent } from "../layout/aside-admin";
import { pageIndicator } from "../apollo";
import Collapse from "@mui/material/Collapse";
import {
  ComponentTabLink,
  ITabLink,
  ITabTitle,
  PageType,
} from "../components/component-tab";
import exit from "../assets/icon/exit.png";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Modal } from "@mui/material";
import { useDelGood } from "../hooks/useDelGood";
import { TableError } from "../components/table-error";
import { PaginationComponent } from "../components/pagination";
import { useEditGoodStatus } from "../hooks/useEditGoodStatus";
import { useEditGoodOrder } from "../hooks/useEditGoodOrder";
import { isExpiryDateOrder } from "../constants/function";
import { ComponentExpirydate } from "../components/component-expirydate";
import { useListGood } from "../hooks/useListGood";
import { ArrElement } from "../sub-routes/sub-admin-up-product-detail";
import {
  DelGoodInput,
  DelGoodMutation,
  EditGoodOrderInput,
  EditGoodOrderMutation,
  EditGoodStatusInput,
  EditGoodStatusMutation,
  GoodOrder,
  GoodStatus,
  ListGoodInput,
  ListGoodQuery,
} from "../generated/graphql";

export type ListGood = ArrElement<ListGoodQuery["listGood"]["goods"]>;

export const AdminListProduct = () => {
  const location = useLocation();
  const [openorder, setOpenOrder] = useState(false);
  const [opensale, setOpenSale] = useState(false);
  const [page, setPage] = useState<number>(1);
  const [openCollapse, setOpenCollapse] = useState<number>(-1);
  const [search, setSearch] = useState<string>("");
  const [status, setStatus] = useState<string>("");
  const [category, setCategory] = useState<string>("");
  const [checkedLists, setCheckedLists] = useState<ListGood[]>([]);
  const [allChecked, setAllChecked] = useState<boolean>(false);
  const [change, setChange] = React.useState<GoodStatus>(GoodStatus.GoodReady);
  const [goodOrder, setGoodOrder] = React.useState<GoodOrder>(GoodOrder.None);
  const [stateId, setStateId] = useState<number>(1);
  const [goodOrderId, setGoodOrderId] = useState<number>(1);
  const tabList: ITabLink[] = [
    { text: "재고관리", to: "/product", selected: true },
    { text: "입출고관리", to: "/bound" },
    // { text: "반품재고관리", to: "/rproduct" },
  ];
  const stitle: ITabTitle = {
    text: "상품관리",
  };
  const word = "상품리스트";
  const listCome = (data: ListGoodQuery) => {
    const {
      listGood: { ok, err, goods },
    } = data;
  };
  const [listGood, { data, loading, refetch }] = useListGood(listCome);
  const listGoodHandler = (
    page: number | null,
    search: string | null,
    status: string | null,
    category: string | null
  ) => {
    const input: ListGoodInput = {
      ...(page && { page: +page }),
      ...(search && { search: decodeURI(search) }),
    };
    listGood({ variables: { input } });
  };

  const navigate = useNavigate();
  const openCollapseHandler = (index: number) => {
    if (index === openCollapse) {
      setOpenCollapse(-1);
    } else {
      setOpenCollapse(index);
    }
  };
  const onDelCompleted = async (data: DelGoodMutation) => {
    const {
      delGood: { ok, err },
    } = data;
    if (ok) {
      alert(`상품 삭제`);
      await refetch();
    } else {
      alert(err);
    }
  };
  const [delGood, { loading: delGoodLoading }] = useDelGood(onDelCompleted);
  const delGoodHandler = (goodId: number) => {
    if (delGoodLoading) return;
    if (
      window.confirm(
        "삭제한 상품은 복구할 수 없습니다. 정말로 삭제하시겠습니까?"
      )
    ) {
      const input: DelGoodInput = {
        goodId,
      };
      delGood({ variables: { input } });
    }
  };

  // --- 상태 변경 ---
  const onEditCompleted = async (data: EditGoodStatusMutation) => {
    const {
      editGoodStatus: { ok, err },
    } = data;
    if (ok) {
      // alert(`상품상태가 수정되었습니다`);
      await refetch();
    } else {
      alert(err);
    }
  };
  const [
    editGoodStatus,
    { loading: editGoodStatusLoading },
  ] = useEditGoodStatus(onEditCompleted);
  const editGoodStatusHandler = (goodId: number, status: GoodStatus) => {
    if (!editGoodStatusLoading) {
      const editGoodStatusInput: EditGoodStatusInput = {
        goodId,
        status,
      };
      editGoodStatus({ variables: { input: editGoodStatusInput } });
    }
  };
  const StateHandler = (id: number, status: GoodStatus | undefined) => {
    setStateId(id);
    setOpenSale(true);
    if (status !== undefined) {
      setChange(status);
    }
  };

  // --- 발주 상태 변경 ---
  const onEditGoodOrderCompleted = async (data: EditGoodOrderMutation) => {
    const {
      editGoodOrder: { ok, err },
    } = data;
    if (ok) {
      // alert(`발주 상태가 수정되었습니다`);
      await refetch();
    } else {
      alert(err);
    }
  };
  const [editGoodOrder, { loading: editGoodOrderLoading }] = useEditGoodOrder(
    onEditGoodOrderCompleted
  );
  const editGoodOrderHandler = (goodId: number, goodOrder: GoodOrder) => {
    if (!editGoodOrderLoading) {
      const editGoodOrderInput: EditGoodOrderInput = {
        goodId,
        goodOrder,
      };
      editGoodOrder({ variables: { input: editGoodOrderInput } });
    }
  };
  const OrderHandler = (id: number, goodOrder?: GoodOrder | null) => {
    setGoodOrderId(id);
    setOpenOrder(true);
    if (!goodOrder) return;
    setGoodOrder(goodOrder);
  };

  // --- CheckBox ---
  const setAllCheckedHandler = () => {
    setAllChecked(!allChecked);
    if (allChecked) setCheckedLists([]);

    if (!allChecked && data?.listGood.goods) {
      setCheckedLists([]);
      for (const good of data.listGood.goods) {
        setCheckedLists((checkedList) => [...checkedList, good]);
      }
    }
  };

  const setCheckedListHandler = (goodId: number, data: any) => {
    if (Boolean(checkedLists.find((checkedId) => checkedId.id === goodId))) {
      setCheckedLists(
        checkedLists.filter((checkedId) => checkedId.id !== goodId)
      );
    } else {
      setCheckedLists((originalList) => [...originalList, data]);
    }
  };
  useEffect(() => {
    if (checkedLists.length === data?.listGood.goods?.length)
      setAllChecked(true);
    else setAllChecked(false);
  }, [checkedLists]);

  // --- Search ---
  const setSearchHandler = (search: string) => {
    navigate({
      pathname: "/product",
      search: `${search ? `?search=${search}` : ""}`,
    });
    listGoodHandler(1, search, status, category);
  };

  // --- Page ---
  const pageHandler = (page: number) => {
    const search =
      location.search.split("search=").length > 1
        ? location.search.split("search=")[1].split("&")[0]
        : null;
    setPage(page);
    navigate({
      pathname: "/product",
      search: `?page=${page}${search ? `&search=${search}` : ""}`,
    });
    listGoodHandler(page, search, status, category);
  };
  useEffect(() => {}, [change]);
  useEffect(() => {
    pageIndicator("product");
    setPage(
      location.search.split("page=").length > 1
        ? +location.search.split("page=")[1].split("&")[0]
        : 1
    );
    setSearch(
      location.search.split("search=").length > 1
        ? location.search.split("search=")[1].split("&")[0]
        : ""
    );
    listGoodHandler(page, search, status, category);
  }, [search, page]);
  return (
    <div className="bg-main bg-opacity-50 w-full h-full min-h-screen">
      <div className="w-full mx-auto h-full min-h-screen  bg-gray-100">
        <main id="main">
          <div className="grid grid-cols-[200px_1fr] max-w-full">
            <AsideAdminComponent />
            <div className="mypages bg-gray-100 w-full min-w-[1200px] overflow-hidden">
              <div className="orderWrap">
                <div className="h-fix-content relative">
                  <div className={`w-full h-full px-10 py-8`}>
                    <ComponentTabLink
                      tabs={tabList}
                      stitle={stitle}
                      type={PageType.Product}
                      dataList={checkedLists}
                      setSearchs={setSearchHandler}
                      refetch={refetch}
                    />

                    <div className=" shadow-lg h-[800px] h-fix-content w-full overflow-auto bg-white">
                      <table className="align-center w-[1623px] text-[14px]">
                        <thead>
                          <tr className=" border-b border-[#F9F8F9] h-8">
                            <th className="text-[#8B8C8C] leading-3 min-w-[36px]">
                              <input
                                type="checkbox"
                                name="allChk"
                                checked={allChecked}
                                onChange={setAllCheckedHandler}
                                className="w-5 h-5"
                              />
                            </th>
                            <th className="text-[#8B8C8C] min-w-[40px]">no</th>
                            <th className="text-[#8B8C8C] min-w-[72px]">
                              판매상태
                            </th>
                            <th className="text-[#8B8C8C] min-w-[72px]">
                              상품상태
                            </th>
                            <th className="text-[#8B8C8C] min-w-[88px]">
                              상품코드
                            </th>
                            {/* <th className="text-[#8B8C8C] min-w-[88px]">
                              위치코드
                            </th> */}
                            <th className="text-[#8B8C8C] min-w-[168px]">
                              상품명
                            </th>
                            {/* <th className="text-[#8B8C8C] min-w-[168px]">
                              상품옵션
                            </th> */}
                            <th className="text-[#8B8C8C] min-w-[48px]">
                              재고
                            </th>
                            <th className="text-[#8B8C8C] min-w-[88px]">
                              상품금액
                            </th>
                            <th className="text-[#8B8C8C] min-w-[72px]">
                              상품편집
                            </th>
                            <th className="text-[#8B8C8C] min-w-[72px]">
                              발주
                            </th>
                            <th className="text-[#8B8C8C] min-w-[72px]">
                              입출고등록
                            </th>
                            <th className="text-[#8B8C8C] min-w-[88px]">
                              유통기한
                            </th>
                            <th className="text-[#8B8C8C] min-w-[88px]">
                              무게
                            </th>
                            <th className="text-[#8B8C8C] min-w-[168px]">
                              카테고리
                            </th>
                            <th className="text-[#8B8C8C] min-w-[128px]">
                              공급사
                            </th>
                            <th className="text-[#8B8C8C] min-w-[67px]">
                              비고
                            </th>
                          </tr>
                        </thead>
                        <tbody className="">
                          {!loading &&
                            data?.listGood.goods &&
                            data.listGood.goods.map((product, index) => (
                              <React.Fragment key={`{product_${product.id}`}>
                                <tr
                                  key={`product-${product.id}`}
                                  className="border-b border-[#F9F8F9] h-[68px]"
                                >
                                  <td className="table-text">
                                    <input
                                      type="checkbox"
                                      className="h-5 w-5"
                                      checked={
                                        checkedLists.find(
                                          (checkedId) =>
                                            checkedId.id === product.id
                                        ) !== undefined
                                      }
                                      onChange={(e) => {
                                        setCheckedListHandler(
                                          product.id,
                                          product
                                        );
                                      }}
                                    />
                                  </td>
                                  <td className="table-text">
                                    {(page - 1) * 20 + index + 1}
                                  </td>
                                  <td className="table-text py-1">
                                    <button
                                      className={`rounded-lg py-[6px] px-[3px]  w-[48px] h-7 text-white text-xs ${
                                        product?.status === "GoodSales"
                                          ? "bg-[#555B94]"
                                          : product?.status === "goodReady"
                                          ? "bg-[#CCCCCC]"
                                          : product?.status === "Ready"
                                          ? "bg-[#CCCCCC]"
                                          : "text-[#B42713] border border-[#B42713]"
                                      }  `}
                                      onClick={() => {
                                        StateHandler(
                                          product.id,
                                          product.status
                                        );
                                      }}
                                    >
                                      {product?.status === "goodReady"
                                        ? "커밍순"
                                        : product?.status === "GoodSales"
                                        ? "판매중"
                                        : product.status === "Make"
                                        ? " 보류"
                                        : product.status === "Ready"
                                        ? "준비"
                                        : product.status === "SoldOut"
                                        ? "품절"
                                        : "기타"}
                                    </button>
                                  </td>
                                  <td className="table-text py-1">
                                    <p
                                      className={`rounded-lg mx-auto py-[6px] px-[3px]  w-[48px] h-7 text-white text-xs ${
                                        product.goodType === "Fridge"
                                          ? "bg-[#555B94]"
                                          : product.goodType === "Freezer"
                                          ? "bg-[#CCCCCC]"
                                          : "text-[#B42713] border border-[#B42713]"
                                      }  `}
                                    >
                                      {product.goodType === "Fridge"
                                        ? "냉동"
                                        : product.goodType === "Freezer"
                                        ? "냉장"
                                        : product.goodType === "RoomTemperature"
                                        ? "실온"
                                        : "기타"}
                                    </p>
                                  </td>
                                  <td className="table-text">
                                    {product.goodUuid}
                                  </td>
                                  {/* <td className="table-text"></td> */}
                                  <td className="table-text">
                                    <button
                                      onClick={() => openCollapseHandler(index)}
                                    >
                                      {product.title}
                                    </button>
                                  </td>
                                  {/* <td className="table-text">
                                    <button
                                      onClick={() => openCollapseHandler(index)}
                                    >
                                      {product.name}
                                    </button>
                                  </td> */}
                                  <td className="table-text font-bold">
                                    {product.stock}
                                  </td>
                                  <td className="table-text font-bold">
                                    {product.price}
                                  </td>
                                  <td className="table-text py-1">
                                    <Link
                                      to={`/product/update/${product.id}`}
                                      className="inline-block border  border-[#8B8C8C]
                                       rounded-lg py-[6px] px-[3px] w-[48px] h-7 text-[#8B8C8C] text-xs"
                                    >
                                      편집
                                    </Link>
                                  </td>
                                  <td className="table-text">
                                    <button
                                      // className={`py-[6px] px-[3px] leading-4 rounded-lg w-[48px] h-7  `}
                                      className={`py-[6px] px-[3px] leading-4 rounded-lg w-[48px] h-7 ${
                                        product.goodOrder === "Order"
                                          ? "bg-[#FFC700] text-[##3D3E49] "
                                          : "border border-[#FFC700] text-[#FFC700] "
                                      } `}
                                      onClick={() => {
                                        OrderHandler(
                                          product.id,
                                          product.goodOrder
                                        );
                                        // setOpenOrder(true);
                                        // if (product.goodOrder) {
                                        //   setGoodOrder(product.goodOrder);
                                        // }
                                      }}
                                    >
                                      {product.goodOrder &&
                                      product.goodOrder === "Order"
                                        ? "발주"
                                        : "미발주"}
                                    </button>
                                  </td>
                                  <td className="table-text">
                                    <Link
                                      to={`/bound/create/${product.id}`}
                                      className="inline-block border  border-[#3D3E49]
                                      rounded-lg py-[6px] px-[3px] w-[48px] h-7 text-xs text-[#3D3E49]"
                                    >
                                      입출고
                                    </Link>
                                  </td>
                                  <td className="text- text-center  h-full overflow-hidden">
                                    {product.goodStocks &&
                                      isExpiryDateOrder(product.goodStocks)}
                                  </td>
                                  <td className="table-text">
                                    {product.weight && product.weight + "g"}
                                  </td>
                                  <td className="table-text">
                                    {product?.categoryFirst &&
                                    product.categoryFirst === "Etc"
                                      ? "기타"
                                      : product.categoryFirst === "Meal"
                                      ? "간편식/요리"
                                      : product.categoryFirst === "Snack"
                                      ? "디저트/간식"
                                      : product.categoryFirst === "Toys"
                                      ? "장난감/완구류"
                                      : product.categoryFirst === "Fresh"
                                      ? "신선푸드"
                                      : product.categoryFirst === "Drink"
                                      ? "생수/커피/음료"
                                      : product.categoryFirst === "Household"
                                      ? "생활용품"
                                      : ""}
                                  </td>
                                  <td className="table-text">
                                    {product.corporations?.map((cor) => (
                                      <p>{cor.name}</p>
                                    ))}
                                  </td>

                                  <td className="table-text">
                                    <button
                                      className="bg-[#CCCCCC] rounded-lg py-[6px]  w-[48px] h-7 text-white "
                                      onClick={() => delGoodHandler(product.id)}
                                    >
                                      삭제
                                    </button>
                                  </td>
                                </tr>
                                <tr>
                                  <td
                                    colSpan={17}
                                    className="w-full removepadding"
                                  >
                                    <Collapse in={openCollapse === index}>
                                      <ComponentExpirydate data={product} />
                                    </Collapse>
                                  </td>
                                </tr>
                              </React.Fragment>
                            ))}
                        </tbody>
                      </table>
                      {loading && (
                        <TableError errorMessage={`정보를 불러오는중입니다`} />
                      )}
                      {!loading &&
                        data?.listGood.goods &&
                        data.listGood.goods.length < 1 && (
                          <TableError
                            errorMessage={`${word} 정보가 없습니다`}
                          />
                        )}

                      {!loading &&
                        data?.listGood.goods &&
                        data.listGood.goods.length > 0 && (
                          <PaginationComponent
                            page={page}
                            totalPages={Number(data.listGood.totalPages)}
                            totalResults={Number(data.listGood.totalResults)}
                            pageHandler={pageHandler}
                          />
                        )}
                      <Modal
                        open={opensale}
                        onClose={() => {
                          setOpenSale(false);
                        }}
                      >
                        <div className="max-w-[360px] w-full absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-[#F9F8F9] rounded-lg">
                          <div className="text-center py-2 h-10 text-white font-bold bg-[#3D3E49] rounded-t-lg  ">
                            <div className="flex flex-wrap justify-between">
                              <p className="w-[240px] ml-[40px] font-bold text-base">
                                판매상태변경
                              </p>
                              <img
                                src={exit}
                                alt="닫기"
                                className="w-6 mr-2 cursor-pointer"
                                onClick={() => setOpenSale(false)}
                              ></img>
                            </div>
                          </div>
                          <div className="">
                            <div className="px-10 py-10">
                              <div className="grid grid-rows-6 gap-y-4">
                                <div>
                                  <input
                                    type="radio"
                                    checked={change === GoodStatus.Ready}
                                    name="product"
                                    id="ready"
                                    value={GoodStatus.Ready}
                                    onChange={(e) => {
                                      setChange(GoodStatus.Ready);
                                    }}
                                  ></input>
                                  <span className="ml-4">준비</span>
                                </div>
                                <div>
                                  <input
                                    type="radio"
                                    checked={change === GoodStatus.GoodReady}
                                    name="product"
                                    id="ready"
                                    value={GoodStatus.GoodReady}
                                    onChange={(e) => {
                                      setChange(GoodStatus.GoodReady);
                                    }}
                                  ></input>
                                  <span className="ml-4">커밍순</span>
                                </div>

                                <div>
                                  <input
                                    type="radio"
                                    checked={change === GoodStatus.Make}
                                    name="product"
                                    id="sales"
                                    value={GoodStatus.Make}
                                    onChange={(e) => {
                                      setChange(GoodStatus.Make);
                                    }}
                                  ></input>
                                  <span className="ml-4">판매 보류</span>
                                </div>
                                <div>
                                  <input
                                    type="radio"
                                    checked={change === GoodStatus.GoodSales}
                                    name="product"
                                    id="sales"
                                    value={GoodStatus.GoodSales}
                                    onChange={(e) => {
                                      setChange(GoodStatus.GoodSales);
                                    }}
                                  ></input>
                                  <span className="ml-4">판매중</span>
                                </div>

                                <div>
                                  <input
                                    type="radio"
                                    name="product"
                                    id="soldOut"
                                    checked={change === GoodStatus.SoldOut}
                                    value={GoodStatus.SoldOut}
                                    onChange={(e) => {
                                      setChange(GoodStatus.SoldOut);
                                    }}
                                  ></input>
                                  <span className="ml-4">품절</span>
                                </div>
                              </div>
                            </div>
                          </div>
                          <button
                            className="w-full h-14 bg-[#F0F0F4] rounded-b-lg"
                            onClick={() => {
                              editGoodStatusHandler(stateId, change);
                              setOpenSale(false);
                            }}
                          >
                            확인
                          </button>
                        </div>
                      </Modal>

                      <Modal
                        open={openorder}
                        onClose={() => {
                          setOpenOrder(false);
                        }}
                      >
                        <div className="max-w-[360px] w-full absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-[#F9F8F9] rounded-lg">
                          <div className="text-center py-2 h-10 text-white font-bold bg-[#3D3E49] rounded-t-lg">
                            <div className="flex flex-wrap justify-between">
                              <p className="w-[240px] ml-[40px] font-bold text-base">
                                발주상태
                              </p>
                              <img
                                src={exit}
                                alt="닫기"
                                className="w-6 mr-2 cursor-pointer"
                                onClick={() => setOpenOrder(false)}
                              ></img>
                            </div>
                          </div>
                          <div className="">
                            <div className="px-10 py-10">
                              <div className="space-y-4">
                                <div>
                                  <input
                                    type="radio"
                                    name="delivery"
                                    id="a"
                                    checked={goodOrder === GoodOrder.None}
                                    onChange={(e) => {
                                      setGoodOrder(GoodOrder.None);
                                    }}
                                  ></input>
                                  <span className="ml-4">미발주</span>
                                </div>
                                <div>
                                  <input
                                    type="radio"
                                    name="delivery"
                                    id="b"
                                    checked={goodOrder === GoodOrder.Order}
                                    onChange={(e) => {
                                      setGoodOrder(GoodOrder.Order);
                                    }}
                                  ></input>
                                  <span className="ml-4">발주</span>
                                </div>
                              </div>
                            </div>
                          </div>
                          <button
                            className="w-full h-14 bg-[#F0F0F4] rounded-b-lg"
                            onClick={() => {
                              editGoodOrderHandler(goodOrderId, goodOrder);
                              setOpenOrder(false);
                            }}
                          >
                            확인
                          </button>
                        </div>
                      </Modal>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
};
