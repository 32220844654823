import { useEffect, useState } from "react";
import { eDate, sDate } from "../../apollo";
import refresh from "../../assets/icon/refresh.png";
import { ko } from "date-fns/esm/locale";
import searchdate from "../../assets/icon/searchdate.png";
import { Modal } from "@mui/material";
import { useForm } from "react-hook-form";
import { FormError2 } from "../form-error";
import exit from "../../assets/icon/exit.png";

import { ICrPortFormProps, useCrPort } from "../../hooks/useCrPort";
import { PortStatusOption, PortStatusOptionType } from "../../constants/enum";
import { useListProjectSelect } from "../../hooks/useListProjectSelect";
import { CrPortInput, CrPortMutation } from "../../generated/graphql";

export interface ComponentTabPort {
  dataList?: any[];
  DataCallback?: () => void;
}

export const ComponentTabPort: React.FC<ComponentTabPort> = ({
  dataList = [],
  DataCallback,
}) => {
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [popen, setPortOpen] = useState(false);
  const [
    listProjectSelect,
    { data: projects, loading },
  ] = useListProjectSelect();

  const dateHandler = (date: Date) => {
    sDate(date);
  };
  const edateHandler = (date: Date) => {
    eDate(date);
  };
  const refetchHandler = () => {
    window.location.href = "/port";
  };
  const {
    getValues: portGetValues,
    register: portRegister,
    handleSubmit: portHandleSubmit,
    formState: { errors: portErrors, isValid: portIsValid },
  } = useForm<ICrPortFormProps>({ mode: "all" });
  const portComplete = async (data: CrPortMutation) => {
    const {
      crPort: { ok, err },
    } = data;
    if (ok) {
      // alert("저장되었습니다");
      window.location.href = `/port`;
    } else {
      alert(err);
    }
  };

  const [crPort, { loading: portLoading }] = useCrPort(portComplete);
  const portSubmit = () => {
    if (!portLoading) {
      const { name, status, projectId } = portGetValues();
      const crPortInput: CrPortInput = {
        name,
        status,
        projectId: projectId ? +projectId : 1,
      };
      crPort({ variables: { input: crPortInput } });
    }
  };
  useEffect(() => {
    listProjectSelect();
  }, []);
  return (
    <div className="flex flex-wrap justify-between">
      <div className="flex flex-wrap">
        <button
          className="py-3 px-4 relative"
          onClick={() => {
            refetchHandler();
          }}
        >
          <img src={refresh} alt="새로고침" className="w-[30px] h-[30px]" />
        </button>
      </div>
      <div className="flex flex-wrap space-x-2"></div>
      <div className="flex flex-wrap justify-end pr-8">
        <div className="flex flex-wrap gap-x-3">
          <button
            className="bg-transparent bg-[#8b8c8c] w-[104px] h-[30px] py-[7px] text-xs font-bold text-center rounded-lg my-[13px] text-white"
            onClick={() => setPortOpen(true)}
          >
            포트 생성
          </button>
        </div>
      </div>

      <Modal
        open={popen}
        onClose={() => {
          setPortOpen(false);
        }}
      >
        <form onSubmit={portHandleSubmit(portSubmit)}>
          <div className="max-w-[360px] w-full absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-[#F9F8F9] rounded-lg">
            <div className="text-center py-2 h-10 text-white font-bold bg-[#3D3E49] rounded-t-lg  ">
              <div className="flex flex-wrap justify-between">
                <p className="w-[240px] ml-[40px] font-bold text-base">
                  포트 생성
                </p>
                <img
                  src={exit}
                  alt="닫기"
                  className="w-6 mr-2 cursor-pointer"
                  onClick={() => setPortOpen(false)}
                ></img>
              </div>
            </div>
            <div className="px-10 py-10">
              <div className="space-y-5">
                <div className="space-y-3">
                  <p className="font-bold text-[#8B8C8C] text-sm">포트이름</p>
                  <input
                    {...portRegister("name", {
                      required: `포트이름을 입력해주세요`,
                    })}
                    type="text"
                    className="w-full border-[#F0F0F4] rounded-lg py-1 px-4 h-10"
                  ></input>
                  {portErrors.name?.message && (
                    <FormError2 errorMessage={portErrors.name.message} />
                  )}
                </div>
                <div className="space-y-3">
                  <p className="font-bold text-[#8B8C8C] text-sm">포트 상태</p>
                  <div>
                    <select
                      {...portRegister(`status`, {
                        required: "상태를 선택해주세요",
                      })}
                      className="w-full border-[#F0F0F4] rounded-lg py-1 px-2"
                    >
                      {Object(PortStatusOption).map(
                        (model: PortStatusOptionType, index: number) => (
                          <option
                            key={`drone-model-option-${index}`}
                            value={model.value}
                            disabled={index === 0}
                          >
                            {model.label}
                          </option>
                        )
                      )}
                    </select>
                    {portErrors.status?.message && (
                      <FormError2 errorMessage={portErrors.status.message} />
                    )}
                  </div>
                </div>
                <div className="space-y-3">
                  <p className="font-bold text-[#8B8C8C] text-sm">
                    프로젝트 아이디
                  </p>
                  <select
                    {...portRegister(`projectId`, {
                      required: "프로젝트 ID를 선택해주세요",
                    })}
                    className="w-full border-[#F0F0F4] rounded-lg py-1 px-2"
                  >
                    <option selected={true} disabled value="">
                      프로젝트를 선택해주세요
                    </option>
                    {projects?.listProjectSelect.projects?.map((project) => (
                      <option key={`project-${project.id}`} value={project.id}>
                        {project.name}
                      </option>
                    ))}
                  </select>

                  {portErrors.projectId?.message && (
                    <FormError2 errorMessage={portErrors.projectId.message} />
                  )}
                </div>
              </div>
            </div>
            <button
              type="submit"
              className="w-full h-14 bg-[#F0F0F4] rounded-b-lg"
            >
              {loading ? "Loading..." : "확인"}
            </button>
          </div>
        </form>
      </Modal>
    </div>
  );
};
