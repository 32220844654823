import { useEffect, useState } from "react";
import { pageIndicator } from "../apollo";
import { AsideAdminComponent } from "../layout/aside-admin";
import { useLocation, useNavigate } from "react-router-dom";
import {
  ComponentTabLink,
  ITabLink,
  ITabTitle,
  PageType,
} from "../components/component-tab";
import { Link } from "react-router-dom";
import exit from "../assets/icon/exit.png";
import { Modal } from "@mui/material";

import React from "react";

import { useListPack } from "../hooks/useListPack";
import { TableError } from "../components/table-error";
import { PaginationComponent } from "../components/pagination";
import { useListPackOrder } from "../hooks/useListPackOrder";
import { MakeOrderNumber } from "../constants/function";
import { useDelPack } from "../hooks/useDelPack";
import { useDelPackOrder } from "../hooks/useDelPackOrder";
import {
  ListPackInput,
  ListPackOrderInput,
  DelPackInput,
  DelPackOrderInput,
  DelPackMutation,
  DelPackOrderMutation,
} from "../generated/graphql";

export interface ISearchFormProps {
  search: string;
}
export const AdminListPack = () => {
  const location = useLocation();
  const [open, setOpen] = React.useState(false);
  const [page, setPage] = useState<number>(1);
  const [search, setSearch] = useState<string>("");
  const [packID, setPackID] = useState<number>(1);
  const navigate = useNavigate();
  const tabList: ITabLink[] = [
    { text: "비행기록", to: "/flight" },
    { text: "드론관리", to: "/drone" },
    { text: "프로젝트관리", to: "/project" },
    { text: "포트관리", to: "/port" },
    { text: "Pack관리", to: "/pack", selected: true },
  ];
  const stitle: ITabTitle = { text: "비행/드론 관리" };
  const word = "Pack";

  // --- List ---
  const [listPack, { data, loading, refetch }] = useListPack();
  const listPackHandler = (page: number | null, search: string | null) => {
    const input: ListPackInput = {
      ...(page && { page: +page }),
      ...(search && { search: decodeURI(search) }),
    };
    listPack({ variables: { input } });
  };
  const [
    packOrder,
    { data: packData, loading: packOrderLoading },
  ] = useListPackOrder();
  const packHandler = (packId: number) => {
    const input: ListPackOrderInput = {
      packId,
    };
    packOrder({ variables: { input } });

    setOpen(true);
    setPackID(packId);
  };

  const onDelCompleted = async (data: DelPackMutation) => {
    const {
      delPack: { ok, err },
    } = data;
    if (ok) {
      alert("Pack 삭제");
      await refetch();
    } else {
      alert(err);
    }
  };
  const [delPack, { loading: delPackLoading }] = useDelPack(onDelCompleted);
  const delPackHandler = (packId: number) => {
    if (
      window.confirm(
        "삭제한 PACK은 복구할 수 없습니다. 정말로 삭제하시겠습니까?"
      )
    ) {
      const input: DelPackInput = {
        packId,
      };
      delPack({ variables: { input } });
    }
  };

  const onDelOrderCompleted = async (data: DelPackOrderMutation) => {
    const {
      delPackOrder: { ok, err },
    } = data;
    if (ok) {
      alert("Order PACK 삭제");
      await refetch();
      packHandler(packID);
    } else {
      alert(err);
    }
  };
  const [delPackOrder, { loading: delPackOrderLoading }] = useDelPackOrder(
    onDelOrderCompleted
  );
  const delPackOrderHandler = (orderId: number) => {
    if (
      window.confirm(
        "삭제한 주문은 복구 할 수 없습니다. 정말로 삭제하시겠습니까?"
      )
    ) {
      const input: DelPackOrderInput = {
        orderId,
      };
      delPackOrder({ variables: { input } });
    }
  };
  // --- Search ---
  const setSearchHandler = (search: string) => {
    navigate({
      pathname: "/pack",
      search: `${search ? `?search=${search}` : ""}`,
    });
    listPackHandler(1, search);
  };

  // --- 페이지 ---
  const pageHandler = (page: number) => {
    const search =
      location.search.split("search=").length > 1
        ? location.search.split("search=")[1].split("&")[0]
        : null;
    setPage(page);
    navigate({
      pathname: "/pack",
      search: `?page=${page}${search ? `&search=${search}` : ""}`,
    });
    listPackHandler(page, search);
  };

  useEffect(() => {
    pageIndicator("flight");
    setPage(
      location.search.split("page=").length > 1
        ? +location.search.split("page=")[1].split("&")[0]
        : 1
    );
    setSearch(
      location.search.split("search=").length > 1
        ? location.search.split("search=")[1].split("&")[0]
        : ""
    );
    listPackHandler(page, search);
  }, [search, page]);
  return (
    <div className="bg-main bg-opacity-50 w-full h-full min-h-screen">
      <div className="w-full mx-auto h-full min-h-screen  bg-gray-100">
        <main id="main">
          <div className="grid grid-cols-[200px_1fr] max-w-full">
            <AsideAdminComponent />
            <div className="mypages bg-gray-100 w-full min-w-[1200px] overflow-hidden">
              <div className="orderWrap">
                <div className="  h-fix-content relative">
                  <div className={`w-full h-full px-10 py-8`}>
                    <ComponentTabLink
                      tabs={tabList}
                      stitle={stitle}
                      // setSearchs={setSearchHandler}
                      type={PageType.Pack}
                    />
                    {/* 여기 */}
                    <div className="shadow-lg h-[800px] h-fix-content w-full overflow-auto bg-white">
                      <table className="align-center min-w-[1160px] 2xl:min-w-[1623px] text-[14px] ">
                        <colgroup>
                          <col width="48"></col>
                          <col width="64"></col>
                          <col width="128"></col>
                          <col></col>
                          <col width="120"></col>
                        </colgroup>
                        <thead>
                          <tr className=" border-b border-[#F9F8F9] h-8">
                            <th className="text-[#8B8C8C]">no</th>
                            <th className="text-[#8B8C8C]">id</th>
                            <th className="text-[#8B8C8C]">주문번호</th>
                            <th className="text-[#8B8C8C]">주문내용</th>
                            <th className="text-[#8B8C8C]">비고</th>
                          </tr>
                        </thead>
                        <tbody className="">
                          {!loading &&
                            data?.listPack.packs &&
                            data.listPack.packs.map((pack, index) => (
                              <tr
                                key={`pack-${pack.id}`}
                                className="border-b border-[#F9F8F9] h-[68px]"
                              >
                                <td className="table-text">{index + 1}</td>
                                <td className="table-text">{pack.id}</td>
                                <td className="table-text">
                                  {/* {pack.orders?.map((test) => (
                                    <p>
                                      {test.orderNum &&
                                        MakeOrderNumber(
                                          test.orderNum.toString()
                                        )}
                                    </p>
                                  ))} */}
                                  {pack.orders && pack.orders[0]?.orderNum}
                                </td>
                                <td className="text-sm text-center h-full overflow-hidden">
                                  {/* {pack.orders?.map((test) => (
                                    <p>{test.goodOption?.name}</p>
                                  ))} */}
                                  <button
                                    className="text-sm"
                                    onClick={() => packHandler(pack.id)}
                                  >
                                    {pack.orders &&
                                      pack.orders.length === 1 &&
                                      pack.orders[0]?.good?.title}
                                    {pack.orders &&
                                      pack.orders.length > 1 &&
                                      pack.orders[0]?.good?.title + " ... "}
                                  </button>
                                </td>
                                <td className="table-text">
                                  <button
                                    className="bg-[#CCCCCC] rounded-lg py-[6px] w-[48px] h-7 text-white"
                                    onClick={() => delPackHandler(pack.id)}
                                  >
                                    삭제
                                  </button>
                                </td>
                              </tr>
                            ))}
                        </tbody>
                      </table>
                      {loading && (
                        <TableError errorMessage={`정보를 불러오는중입니다`} />
                      )}
                      {!loading &&
                        data?.listPack.packs &&
                        data.listPack.packs.length < 1 && (
                          <TableError
                            errorMessage={`${word} 정보가 없습니다`}
                          />
                        )}

                      {!loading &&
                        data?.listPack.packs &&
                        data.listPack.packs.length > 0 && (
                          <PaginationComponent
                            page={page}
                            totalPages={Number(data.listPack.totalPages)}
                            totalResults={Number(data.listPack.totalResults)}
                            pageHandler={pageHandler}
                          />
                        )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
        <Modal
          open={open}
          onClose={() => {
            setOpen(false);
          }}
        >
          <div className="max-w-[720px] w-full absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2   bg-[#F9F8F9] rounded-lg ">
            <div className="text-center py-2 h-10 text-white font-bold bg-[#3D3E49] rounded-t-lg  ">
              <div className="flex flex-wrap justify-between">
                <p className="w-[480px] ml-[40px] font-bold text-base">Pack</p>
                <img
                  src={exit}
                  alt="닫기"
                  className="w-6 mr-2 cursor-pointer"
                  onClick={() => setOpen(false)}
                ></img>
              </div>
            </div>
            <div className="flex flex-wrap ">
              <div className="px-10 py-10 w-[720px] overflow-auto">
                <table className="align-center text-lg">
                  <tr className="border-b border-[#cccccc] h-8 break-all">
                    <th className="text-[#8B8C8C] min-w-[88px]">주문번호</th>
                    <th className="text-[#8B8C8C] min-w-[88px]">주문번호</th>
                    <th className="text-[#8B8C8C] min-w-[200px]">상품명</th>
                    <th className="text-[#8B8C8C] min-w-[200px]">상품옵션</th>
                    <th className="text-[#8B8C8C] min-w-[60px]">기능</th>
                  </tr>
                  {packData?.listPackOrder.orders?.map((test) => (
                    <tr className="h-[40px]">
                      <td className=" text-base text-center h-full overflow-hidden">
                        {test.receipt?.receiptNum}
                      </td>
                      <td className=" text-base text-center h-full overflow-hidden">
                        {test.orderNum &&
                          MakeOrderNumber(test.orderNum.toString())}
                      </td>
                      <td className=" text-base text-center h-full overflow-hidden">
                        {test.goodOptionName}
                      </td>
                      <td className=" text-base text-center h-full overflow-hidden">
                        {test.goodName}
                      </td>
                      <td className="text-base text-center h-full overflow-hidden">
                        <button
                          className="bg-[#CCCCCC] rounded-lg py-[6px] w-[48px] h-7 text-white"
                          onClick={() => delPackOrderHandler(test.id)}
                        >
                          삭제
                        </button>
                      </td>
                    </tr>
                  ))}
                </table>
              </div>
            </div>
            <button
              className="w-full h-14 bg-[#F0F0F4] rounded-b-lg"
              onClick={() => {
                setOpen(false);
              }}
            >
              확인
            </button>
          </div>
        </Modal>
      </div>
    </div>
  );
};
