import { useState } from "react";
import { eDate, sDate } from "../../apollo";
import refresh from "../../assets/icon/refresh.png";
import { ko } from "date-fns/esm/locale";
import searchdate from "../../assets/icon/searchdate.png";
import { IDateSearchFormProps } from "./component-tab-order";
import { useForm } from "react-hook-form";

export interface ComponentTabDoor {
  dataList?: any[];
  DataCallback?: () => void;
  setDateSearch?: (sDate?: Date, eDate?: Date) => void;
}

export const ComponentTabDoor: React.FC<ComponentTabDoor> = ({
  dataList = [],
  DataCallback,
  setDateSearch,
}) => {
  const { register, getValues, handleSubmit } = useForm<IDateSearchFormProps>({
    mode: "all",
  });
  const dateHandler = (date: Date) => {
    sDate(date);
  };
  const edateHandler = (date: Date) => {
    eDate(date);
  };
  const refetchHandler = () => {
    window.location.href = "/door";
  };
  const onSubmit = () => {
    const { sDate, eDate } = getValues();
    if (setDateSearch) {
      setDateSearch(sDate, eDate);
    }
  };
  return (
    <div className="flex flex-wrap justify-between">
      <div className="flex flex-wrap">
        <button
          className="py-3 px-4 relative"
          onClick={() => {
            refetchHandler();
          }}
        >
          <img src={refresh} alt="새로고침" className="w-[30px] h-[30px]" />
        </button>
      </div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="flex flex-wrap gap-x-4 content-center">
          <p className="text-[#8B8C8C] text-sm flex flex-wrap content-center">
            기간
          </p>
          <div className="flex flex-wrap content-center">
            <input
              {...register("sDate")}
              type="date"
              className="border w-full  border-[#F0F0F4] text-base rounded-lg py-1 px-4"
            ></input>
          </div>
          <span className="text-white flex flex-wrap content-center">~</span>
          <div className="flex flex-wrap content-center">
            <input
              {...register("eDate")}
              type="date"
              className="border w-full  border-[#F0F0F4] text-base rounded-lg py-1 px-4"
            ></input>
          </div>

          <button className="py-3">
            <img src={searchdate} alt="찾기" className="w-[32px] h-[30px]" />
          </button>
        </div>
      </form>
      <div className="flex flex-wrap justify-end pr-8"></div>
    </div>
  );
};
