import { ApolloError, gql, useMutation } from "@apollo/client";
import {
  DelBoundMutation,
  DelBoundMutationVariables,
} from "../generated/graphql";

const DEL_BOUND_MUTATION = gql`
  mutation delBound($input: DelBoundInput!) {
    delBound(input: $input) {
      ok
      err
    }
  }
`;

export const useDelBound = (
  onCompleted?: (data: DelBoundMutation) => void,
  onError?: (Error: ApolloError) => void
) => {
  return useMutation<DelBoundMutation, DelBoundMutationVariables>(
    DEL_BOUND_MUTATION,
    {
      onCompleted,
      onError,
    }
  );
};
