import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { pageIndicator } from "../apollo";
import {
  ComponentTabLink,
  ITabLink,
  ITabTitle,
  PageType,
} from "../components/component-tab";
import { AsideAdminComponent } from "../layout/aside-admin";
import { FormError2 } from "../components/form-error";
import { Button } from "../components/button";
import {
  ICrCorporationFormProps,
  useCrCorporation,
} from "../hooks/useCrCorporation";
import { usePrompt } from "../constants/function";
import {
  CrCorporationInput,
  CrCorporationMutation,
} from "../generated/graphql";
import { useDaumPostcodePopup } from "react-daum-postcode";
import { useListLinkServiceSelect } from "../hooks/useListLinkServiceSelect";
export const AdminCrCorporation = () => {
  const [out, setOut] = useState<boolean>(true);
  const { confirmNavigation, showPrompt, cancelNavigation } = usePrompt(out);
  const [
    listLinkServiceSelect,
    { data: linkservice },
  ] = useListLinkServiceSelect();

  useEffect(() => {
    if (showPrompt) {
      if (
        window.confirm("변경사항이 저장 되지 않았습니다. 이동하시겠습니까?")
      ) {
        confirmNavigation();
      } else {
        cancelNavigation();
      }
    }
    pageIndicator("corporation");
    if (!out) {
      window.location.href = `/corporation`;
    }
  }, [showPrompt, out]);

  const {
    getValues,
    setValue,
    setFocus,
    register,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm<ICrCorporationFormProps>({ mode: "all" });

  const onComplete = async (data: CrCorporationMutation) => {
    const {
      crCorporation: { ok, err },
    } = data;
    if (ok) {
      // alert("저장되었습니다");
      setOut(false);
    } else {
      alert(err);
    }
  };
  const [crCorporation, { loading }] = useCrCorporation(onComplete);
  const onSubmit = () => {
    if (!loading) {
      const {
        managerName,
        phone,
        address,
        // ceoName,
        service,
        name,
        regionKeyWord,
        serviceId,
      } = getValues();
      const crCorporationInput: CrCorporationInput = {
        managerName,
        phone,
        address,
        // ceoName,
        service,
        name,
        serviceId: serviceId ? +serviceId : 1,
        regionKeyWord: regionKeyWord ? String(regionKeyWord).split(" ") : null,
      };
      crCorporation({ variables: { input: crCorporationInput } });
    }
  };
  const tabList: ITabLink[] = [];

  const stitle: ITabTitle = { text: "거래처 등록" };

  const handlePostCode = (data: any) => {
    let fullAddress = data.address;
    let extraAddress = "";
    if (data.addressType === "R") {
      if (data.bname !== "") {
        extraAddress += data.bname;
      }
      if (data.buildingName !== "") {
        extraAddress +=
          extraAddress !== "" ? `, ${data.buildingName}` : data.buildingName;
      }
      fullAddress += extraAddress !== "" ? ` (${extraAddress})` : "";
      setValue(`address`, fullAddress);
      setFocus(`address`);
    }
  };
  const scriptUrl =
    "https://t1.daumcdn.net/mapjsapi/bundle/postcode/prod/postcode.v2.js";
  const open = useDaumPostcodePopup(scriptUrl);
  const handlerClick = () => {
    open({ onComplete: handlePostCode });
  };

  useEffect(() => {
    listLinkServiceSelect();
  }, []);

  return (
    <div className="bg-main bg-opacity-50 w-full h-full min-h-screen">
      <div className="w-full mx-auto h-full min-h-screen bg-gray-100">
        <main id="main">
          <div className="grid grid-cols-[200px_1fr] max-w-full">
            <AsideAdminComponent />

            <div className="mypages bg-gray-100 w-full min-w-[1200px] overflow-hidden">
              <div className="orderWrap">
                <div className="h-fix-content relative">
                  <div className={`w-full h-full px-10 py-8`}>
                    <ComponentTabLink
                      tabs={tabList}
                      stitle={stitle}
                      type={PageType.Create}
                    />
                    <div className="shadow-lg w-full h-fix-content bg-white">
                      <form onSubmit={handleSubmit(onSubmit)} id="create">
                        <div className="pl-9 pr-11 py-8 space-y-4">
                          <div className="space-y-2">
                            <p className="font-bold text-[#4F4F4F] text-base">
                              회사 이름
                            </p>
                            <input
                              {...register("name", {
                                required: `이름을 입력해주세요`,
                              })}
                              type="text"
                              className="border w-full h-10 border-[#F0F0F4] text-base rounded-lg py-1 px-4"
                            />
                            {errors.name?.message && (
                              <FormError2 errorMessage={errors.name.message} />
                            )}
                          </div>
                          <div className="space-y-2">
                            <p className="font-bold text-[#4F4F4F] text-base">
                              서비스명
                            </p>
                            <select
                              {...register(`serviceId`, {
                                required: "서비스 ID를 선택해주세요",
                              })}
                              className="border w-full border-[#F0F0F4] rounded-lg py-1 px-2"
                            >
                              <option selected={true} disabled value="">
                                서비스를 선택해주세요
                              </option>
                              {linkservice?.listLinkServiceSelect.linkservice?.map(
                                (service) => (
                                  <option
                                    key={`service-${service.id}`}
                                    value={service.id}
                                  >
                                    {service.name}
                                  </option>
                                )
                              )}
                            </select>

                            {errors.serviceId?.message && (
                              <FormError2
                                errorMessage={errors.serviceId.message}
                              />
                            )}
                          </div>
                          {/* <div className="space-y-2">
                            <p className="font-bold text-[#4F4F4F] text-base">
                              서비스 이름
                            </p>
                            <input
                              {...register("service", {})}
                              type="text"
                              className="border w-full h-10 border-[#F0F0F4] text-base rounded-lg py-1 px-4"
                            ></input>
                          </div> */}
                          <div className="space-y-2">
                            <p className="font-bold text-[#4F4F4F] text-base">
                              담당자 이름
                            </p>
                            <input
                              {...register("managerName", {})}
                              type="text"
                              className="border w-full h-10 border-[#F0F0F4] text-base rounded-lg py-1 px-4"
                            />
                          </div>
                          <div className="space-y-2">
                            <p className="font-bold text-[#4F4F4F] text-base">
                              번호
                            </p>
                            <input
                              {...register("phone", {})}
                              type="text"
                              className="border w-full h-10 border-[#F0F0F4] text-base rounded-lg py-1 px-4"
                            />
                          </div>
                          <div className="space-y-2">
                            <p className="font-bold text-[#4F4F4F] text-base">
                              <span className="pr-10">주소</span>
                              <button
                                className="py-2 px-4 border border-btnDarkLine rounded-lg text-btn text-grayFont"
                                type="button"
                                onClick={handlerClick}
                              >
                                우편번호찾기
                              </button>
                            </p>

                            <input
                              {...register("address", {})}
                              type="text"
                              className="border w-full h-10 border-[#F0F0F4] text-base rounded-lg py-1 px-4"
                            />
                          </div>
                          <div className="space-y-2">
                            <p className="font-bold text-[#4F4F4F] text-base">
                              배송 가능 지역
                            </p>
                            <input
                              {...register("regionKeyWord")}
                              type="text"
                              placeholder="동,리 단위로 작성 ( 예시 : 율곡동 송곡리 )"
                              className="border w-full h-10 border-[#F0F0F4] text-base rounded-lg py-1 px-4"
                            />
                            <p className="text-sm text-blue-400">
                              여러 개 작성시 띄워쓰기로 구분됩니다.
                            </p>
                          </div>

                          {/* <div className="space-y-2">
                            <p className="font-bold text-[#4F4F4F] text-base">
                              Ceo이름
                            </p>
                            <input
                              {...register("ceoName", {})}
                              type="text"
                              className="border w-full h-10 border-[#F0F0F4] text-base rounded-lg py-1 px-4"
                            ></input>
                          </div> */}

                          <div className="flex flex-wrap justify-center pt-6">
                            <Button
                              className={`w-[240px] h-[40px] bg-[#3D3E49] text-white rounded-lg`}
                              canClick={true}
                              actionText={`등록`}
                              loading={loading}
                              color={"text-white"}
                            />
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
};
