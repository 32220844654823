import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { DOMRoutes } from "../constants/props";
import { AdminDetailDoor } from "../routes/admin-detail-door";
import { AdminListBound } from "../routes/admin-list-bound";
import { AdminListOrderConfirm } from "../routes/admin-list-order-confirm";
import { AdminListOrderDelivery } from "../routes/admin-list-order-delivery";
import { AdminListDoor } from "../routes/admin-list-door";
import { AdminListDrone } from "../routes/admin-list-drone";
import { AdminListOrderEnd } from "../routes/admin-list-order-end";
import { AdminListOrderExchange } from "../routes/admin-list-order-exchange";
import { AdminListFlight } from "../routes/admin-list-flight";
import { AdminListOrder } from "../routes/admin-list-order";
import { AdminListPayments } from "../routes/admin-list-payments";
import { AdminListProduct } from "../routes/admin-list-product";
import { AdminListRelease } from "../routes/admin-list-release";
import { AdminListRproduct } from "../routes/admin-list-rproduct";
import { AdminListOrderStart } from "../routes/admin-list-order-start";
import { AdminListCorporation } from "../routes/admin-list-corporation";
import { AdminUpProduct } from "../routes/admin-up-product";
import { AdminUpCorporation } from "../routes/admin-up-corporation";
import { AdminCrCorporation } from "../routes/admin-cr-corporation";
import { useMe } from "../hooks/useMe";
import { AdminListProject } from "../routes/admin-list-project";
import { AdminLogin } from "../routes/login/admin-login";
import { AdminCrBound } from "../routes/admin-cr-bound";
import { AdminUpBound } from "../routes/admin-up-bound";
import { AdminCrProject } from "../routes/admin-cr-project";
import { AdminListPort } from "../routes/admin-list-port";
import { AdminCrProduct } from "../routes/admin-cr-product";
import { AdminCrPort } from "../routes/admin-cr-port";
import { AdminCrFlight } from "../routes/admin-cr-flight";
import { AdminUpFlight } from "../routes/admin-up-flight";
import { AdminUpProject } from "../routes/admin-up-project";
import { AdminListEvents } from "../routes/admin-list-event";
import { AdminListPack } from "../routes/admin-list-pack";
import { AdminCrBanner } from "../routes/admin-cr-banner";
import { AdminUpBanner } from "../routes/admin-up-banner";
import { AdminUpBannerMain } from "../routes/admin-up-banner-main";
import { AdminUpPort } from "../routes/admin-up-port";
import { AdminCrProductBulk } from "../routes/admin-cr-product-bulk";
import { UserRole, WorkerType } from "../generated/graphql";
import { AdminListLinkService } from "../routes/admin-list-linkservice";
import { AdminListReceipt } from "../routes/admin-list-receipt";

export const PilotRoutes: DOMRoutes[] = [
  {
    path: "/flight/create",
    key: "/flight/create",
    component: <AdminCrFlight />,
  },
  {
    path: "/project/create",
    key: "/project/create",
    component: <AdminCrProject />,
  },

  {
    path: "/flight/update/:flightId",
    key: "/flight/update/:flightId",
    component: <AdminUpFlight />,
  },

  {
    path: "/project/update/:projectId",
    key: "/project/update/:projectId",
    component: <AdminUpProject />,
  },
  {
    path: "/corporation",
    key: "/corporation",
    component: <AdminListCorporation />,
  },
  {
    path: "/port",
    key: "/port",
    component: <AdminListPort />,
  },
  {
    path: "/project",
    key: "/project",
    component: <AdminListProject />,
  },
  {
    path: "/flight",
    key: "/flight",
    component: <AdminListFlight />,
  },
  {
    path: "/drone",
    key: "/drone",
    component: <AdminListDrone />,
  },
];

export const AdminRoutes: DOMRoutes[] = [
  { path: "/receipt", key: "/receipt", component: <AdminListReceipt /> },
  {
    path: "/order",
    key: "/order",
    component: <AdminListOrder />,
  },
  {
    path: "/release",
    key: "/release",
    component: <AdminListRelease />,
  },
  {
    path: "/order/delivery",
    key: "/order/delivery",
    component: <AdminListOrderDelivery />,
  },
  {
    path: "/order/start",
    key: "/order/start",
    component: <AdminListOrderStart />,
  },
  {
    path: "/order/end",
    key: "/order/end",
    component: <AdminListOrderEnd />,
  },
  {
    path: "/order/exchange",
    key: "/order/exchange",
    component: <AdminListOrderExchange />,
  },
  {
    path: "/order/confirm",
    key: "/order/confirm",
    component: <AdminListOrderConfirm />,
  },
  {
    path: "/door",
    key: "/door",
    component: <AdminListDoor />,
  },
  {
    path: "/product",
    key: "/product",
    component: <AdminListProduct />,
  },
  {
    path: "/bound",
    key: "/bound",
    component: <AdminListBound />,
  },
  {
    path: "/rproduct",
    key: "/rproduct",
    component: <AdminListRproduct />,
  },
  {
    path: "/corporation",
    key: "/corporation",
    component: <AdminListCorporation />,
  },
  {
    path: "/port",
    key: "/port",
    component: <AdminListPort />,
  },
  {
    path: "/project",
    key: "/project",
    component: <AdminListProject />,
  },
  {
    path: "/pack",
    key: "/pack",
    component: <AdminListPack />,
  },
  { path: "/service", key: "/service", component: <AdminListLinkService /> },
  {
    path: "/product/create",
    key: "/product/create",
    component: <AdminCrProduct />,
  },
  {
    path: "/product/create-bulk",
    key: "/product/create-bulk",
    component: <AdminCrProductBulk />,
  },
  {
    path: "/bound/create/:goodId",
    key: "/bound/create/:goodId",
    component: <AdminCrBound />,
  },
  {
    path: "/port/update/:portId",
    key: "/port/update/:portId",
    component: <AdminUpPort />,
  },
  {
    path: "/flight/create",
    key: "/flight/create",
    component: <AdminCrFlight />,
  },
  {
    path: "/project/create",
    key: "/project/create",
    component: <AdminCrProject />,
  },
  {
    path: "/port/create",
    key: "/port/create",
    component: <AdminCrPort />,
  },
  {
    path: "/corporation/create",
    key: "/corporation/create",
    component: <AdminCrCorporation />,
  },
  {
    path: "/events/create",
    key: "/events/create",
    component: <AdminCrBanner />,
  },
  {
    path: "/product/update/:goodId",
    key: "/product/update/:goodId",
    component: <AdminUpProduct />,
  },
  {
    path: "/events/update",
    key: "/events/update",
    component: <AdminUpBanner />,
  },

  {
    path: "/corporation/update/:corporationId",
    key: "/corporation/update/:corporationId",
    component: <AdminUpCorporation />,
  },

  {
    path: "/bound/update/:boundId",
    key: "/bound/update/:boundId",
    component: <AdminUpBound />,
  },
  {
    path: "/flight/update/:flightId",
    key: "/flight/update/:flightId",
    component: <AdminUpFlight />,
  },
  {
    path: "/project/update/:projectId",
    key: "/project/update/:projectId",
    component: <AdminUpProject />,
  },

  {
    path: "/product/update",
    key: "/product/update",
    component: <AdminUpProduct />,
  },
  {
    path: "/drone",
    key: "/drone",
    component: <AdminListDrone />,
  },
  {
    path: "/door/detail/:inquiryId",
    key: "/door/detail/:inquiryId",
    component: <AdminDetailDoor />,
  },
  {
    path: "/flight",
    key: "/flight",
    component: <AdminListFlight />,
  },
  {
    path: "/payments",
    key: "/payments",
    component: <AdminListPayments />,
  },
  {
    path: "/events",
    key: "/events",
    component: <AdminListEvents />,
  },
  {
    path: "/events/update/bannerMain/:bannerMainId",
    key: "/events/update/bannerMain/:bannerMainId",
    component: <AdminUpBannerMain />,
  },
];
export const LoggedInRouter = () => {
  const { data: me, loading } = useMe();
  return (
    <Router>
      <Routes>
        {me?.me.role === UserRole.Admin &&
          AdminRoutes.map((route) => (
            <Route
              key={route.key}
              path={route.path}
              element={route.component}
            />
          ))}
        {me?.me.role === UserRole.Worker &&
          me?.me.workerType === WorkerType.Pilot &&
          PilotRoutes.map((route) => (
            <Route
              key={route.key}
              path={route.path}
              element={route.component}
            />
          ))}
        {me?.me.role === UserRole.General && (
          <Route path={`/*`} element={<AdminLogin />} />
        )}
        {me?.me.role === UserRole.Admin && (
          <Route path={`/*`} element={<AdminListOrder />} />
        )}
        {me?.me.role === UserRole.Worker &&
          me?.me.workerType === WorkerType.Pilot && (
            <Route path={`/*`} element={<AdminListFlight />} />
          )}
      </Routes>
    </Router>
  );
};
