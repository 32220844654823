import { gql, useLazyQuery } from "@apollo/client";
import { ListPortQuery, ListPortQueryVariables } from "../generated/graphql";

const LIST_PORT_QUERY = gql`
  query listPort($input: ListPortInput!) {
    listPort(input: $input) {
      ok
      err
      totalPages
      totalResults
      row
      ports {
        id
        createdAt
        name
        status
        GPS {
          lng
          lnt
        }
        flightTos {
          takeOffAt
        }
        flightFroms {
          landAt
        }
        author {
          name
        }
        project {
          id
          name
        }
      }
    }
  }
`;

export const useListPort = (onCompleted?: (data: ListPortQuery) => void) => {
  return useLazyQuery<ListPortQuery, ListPortQueryVariables>(LIST_PORT_QUERY, {
    onCompleted,
    fetchPolicy: "network-only",
  });
};
