import { gql, useLazyQuery } from "@apollo/client";
import { GetPortQuery, GetPortQueryVariables } from "../generated/graphql";

const GET_PORT_QUERY = gql`
  query getPort($input: GetPortInput!) {
    getPort(input: $input) {
      ok
      err
      port {
        id
        name
        status
        address
        usageTime
        pickUp
        deliveryRegion
        regionKeyWord
        phone
        project {
          id
          name
        }
        author {
          name
          phone
        }
      }
    }
  }
`;

export const useGetPort = (onCompleted?: (data: GetPortQuery) => void) => {
  return useLazyQuery<GetPortQuery, GetPortQueryVariables>(GET_PORT_QUERY, {
    onCompleted,
    fetchPolicy: "network-only",
  });
};
