import { ApolloError, gql, useMutation } from "@apollo/client";
import React from "react";
import {
  CrGoodDetailMutation,
  CrGoodDetailMutationVariables,
} from "../generated/graphql";

const CR_GOOD_DETAIL_MUTATION = gql`
  mutation crGoodDetail($input: CrGoodDetailInput!) {
    crGoodDetail(input: $input) {
      ok
      err
    }
  }
`;

export const useCrGoodDetail = (
  onCompleted?: (data: CrGoodDetailMutation) => void,
  onError?: (error: ApolloError) => void
) => {
  return useMutation<CrGoodDetailMutation, CrGoodDetailMutationVariables>(
    CR_GOOD_DETAIL_MUTATION,
    {
      onCompleted,
      onError,
    }
  );
};
