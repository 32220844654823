import { ApolloError, gql, useMutation } from "@apollo/client";
import {
  CrDroneInput,
  CrDroneMutation,
  CrDroneMutationVariables,
} from "../generated/graphql";
export interface ICrDroneFormProps extends CrDroneInput {}

const CR_DRONE_MUTATION = gql`
  mutation crDrone($input: CrDroneInput!) {
    crDrone(input: $input) {
      ok
      err
    }
  }
`;

export const useCrDrone = (
  onCompleted?: (data: CrDroneMutation) => void,
  onError?: (error: ApolloError) => void
) => {
  return useMutation<CrDroneMutation, CrDroneMutationVariables>(
    CR_DRONE_MUTATION,
    {
      onCompleted,
      onError,
    }
  );
};
