import { ApolloError, gql, useMutation } from "@apollo/client";
import {
  EditGoodPosterMutation,
  EditGoodPosterMutationVariables,
} from "../generated/graphql";

const EDIT_GOOD_POSTER_QUERY = gql`
  mutation editGoodPoster($input: EditGoodPosterInput!) {
    editGoodPoster(input: $input) {
      ok
      err
    }
  }
`;

export const useEditGoodPoster = (
  onCompleted?: (data: EditGoodPosterMutation) => void,
  onError?: (error: ApolloError) => void
) => {
  return useMutation<EditGoodPosterMutation, EditGoodPosterMutationVariables>(
    EDIT_GOOD_POSTER_QUERY,
    {
      onCompleted,
      onError,
    }
  );
};
