import { useEffect, useState } from "react";
import { useParams } from "react-router";
import { AttrInfoSet, EditProductProps } from "../constants/enum";
import { useGetGood } from "../hooks/useGetGood";
import { useEditGoodAttrInfo } from "../hooks/useEditGoodAttrInfo";
import {
  EditGoodAttrInfoInput,
  EditGoodAttrInfoMutation,
  GetGoodInput,
  GetGoodQuery,
} from "../generated/graphql";
export const SubAdminEditProductAttrInfo: React.FC<EditProductProps> = ({
  tabs,
  setTabHandler,
}) => {
  const params = useParams();
  const [pushAttr, setPushAttr] = useState<string>();
  const [attrSet, setAttrSet] = useState<AttrInfoSet[]>([
    { label: "판매단위", value: "" },
    { label: "중량/용량", value: "" },
    { label: "포장타입", value: "" },
  ]);
  const addAttrInfoHandler = () => {
    var _arrays = [...attrSet];
    var obj: AttrInfoSet = { label: "", value: "" };
    _arrays.push(obj);
    setAttrSet(_arrays);
  };
  const removeAttrInfoHandler = (index: number) => {
    var _arrays = [...attrSet];
    _arrays.splice(index, 1);
    setAttrSet(_arrays);
  };
  const valueChangeHandler = (
    index: number,
    value: string,
    isLabel: boolean
  ) => {
    let _arrays = [...attrSet];
    if (isLabel) {
      _arrays[index].label = value;
    } else {
      _arrays[index].value = value;
    }
    setAttrSet(_arrays);
  };

  const onGetComplete = (datas: GetGoodQuery) => {
    const {
      getGood: { ok, good },
    } = datas;
    if (!ok) {
      alert("잘못된 접근입니다");
      window.location.href = "/product";
      return;
    } else {
      var json = JSON.parse(String(good?.goodAttrInfo));
      if (json.length) {
        setAttrSet(json);
      }
    }
  };
  const [getGood, { data, loading }] = useGetGood(onGetComplete);
  const getGoodHandler = (goodId: number) => {
    const getGoodInput: GetGoodInput = {
      goodId,
    };
    getGood({ variables: { input: getGoodInput } });
  };

  //  editup
  const onEditComleted = (data: EditGoodAttrInfoMutation) => {
    const {
      editGoodAttrInfo: { ok, err },
    } = data;
    if (ok) {
      alert("저장되었습니다.");
      setTabHandler(tabs + 1);
    } else {
      alert(err);
    }
  };
  const [editGoodAttrInfo] = useEditGoodAttrInfo(onEditComleted);
  const editGoodAttrHandler = () => {
    const editGoodAttrInfoInput: EditGoodAttrInfoInput = {
      goodId: Number(params.goodId),
      goodAttrInfo: pushAttr,
    };
    editGoodAttrInfo({
      variables: { input: editGoodAttrInfoInput },
    });
  };
  useEffect(() => {
    if (!params.goodId) return;
    const goodId = +params.goodId;
    getGoodHandler(goodId);
  }, []);
  useEffect(() => {
    var pushJson = JSON.stringify(attrSet);
    setPushAttr(pushJson);
  }, [attrSet]);

  return (
    <div className="shadow-lg min-w-[1160px] min-h-[800px] h-fix-content max-w-[1160px]  2xl:max-w-[1623px]  bg-white">
      <div className="pl-9 pr-11 py-8 ">
        <p className="font-bold text-[#4F4F4F] text-base pb-12">
          상품노출정보{" "}
          <button
            onClick={addAttrInfoHandler}
            className="ml-4 px-3 bg-back text-white rounded-md"
          >
            추가하기
          </button>
        </p>
        <form>
          <div className="grid grid-cols-2 gap-x-16 gap-y-8">
            {attrSet &&
              attrSet.map((attrInfo, index) => (
                <div key={`attrInfos-${index}`} className="space-y-2">
                  <input
                    type="text"
                    defaultValue={attrInfo.label}
                    placeholder="노출정보명"
                    name={`label${index}`}
                    onChange={(e) => {
                      var Value = e.target.value;
                      valueChangeHandler(index, Value, true);
                    }}
                    className="w-80 border-[#F0F0F4] border px-2 h-8"
                  />
                  {index > 2 && (
                    <button
                      onClick={() => removeAttrInfoHandler(index)}
                      className="text-red-400 text-sm ml-4 hover:underline"
                    >
                      삭제
                    </button>
                  )}

                  <input
                    type="text"
                    defaultValue={attrInfo.value}
                    placeholder="정보 값"
                    name={`value${index}`}
                    onChange={(e) => {
                      var Value = e.target.value;
                      valueChangeHandler(index, Value, false);
                    }}
                    className="border w-full h-10 border-[#F0F0F4] text-base rounded-lg py-1 px-4"
                  />
                </div>
              ))}
            {/* <div className="space-y-2">
            <p className="font-bold text-[#4F4F4F] text-base">판매단위</p>
            <input
              type="text"
              defaultValue={attrSet?.unit}
              onKeyUp={(e) => {
                var Value = e.currentTarget.value;
                setAttrSet((prev) => ({
                  ...prev,
                  unit: Value,
                }));
              }}
              className="border w-full h-10 border-[#F0F0F4] text-base rounded-lg py-1 px-4"
            />
          </div>
          <div className="space-y-2">
            <p className="font-bold text-[#4F4F4F] text-base">중량/용량</p>
            <input
              defaultValue={attrSet?.weight}
              onKeyUp={(e) => {
                let Value = e.currentTarget.value;
                setAttrSet((prev) => ({
                  ...prev,
                  weight: Value,
                }));
              }}
              type="text"
              className="border w-full h-10 border-[#F0F0F4] text-base rounded-lg py-1 px-4"
            />
          </div>
          <div className="space-y-2">
            <p className="font-bold text-[#4F4F4F] text-base">배송구분</p>
            <input
              defaultValue={attrSet?.deliveryType}
              onKeyUp={(e) => {
                let Value = e.currentTarget.value;
                setAttrSet((prev) => ({
                  ...prev,
                  deliveryType: Value,
                }));
              }}
              type="text"
              className="border w-full h-10 border-[#F0F0F4] text-base rounded-lg py-1 px-4"
            />
          </div>
          <div className="space-y-2">
            <p className="font-bold text-[#4F4F4F] text-base">포장타입</p>
            <input
              defaultValue={attrSet?.packType}
              onKeyUp={(e) => {
                let Value = e.currentTarget.value;
                setAttrSet((prev) => ({
                  ...prev,
                  packType: Value,
                }));
              }}
              type="text"
              className="border w-full h-10 border-[#F0F0F4] text-base rounded-lg py-1 px-4"
            />
          </div>
          <div className="space-y-2">
            <p className="font-bold text-[#4F4F4F] text-base">알레르기</p>
            <input
              defaultValue={attrSet?.allergyInfo}
              onKeyUp={(e) => {
                let Value = e.currentTarget.value;
                setAttrSet((prev) => ({
                  ...prev,
                  allergyInfo: Value,
                }));
              }}
              type="text"
              className="border w-full h-10 border-[#F0F0F4] text-base rounded-lg py-1 px-4"
            />
          </div>
          <div className="space-y-2">
            <p className="font-bold text-[#4F4F4F] text-base">
              식품이력정보(축산물 등)
            </p>
            <input
              defaultValue={attrSet?.foodCerInfo}
              onKeyUp={(e) => {
                let Value = e.currentTarget.value;
                setAttrSet((prev) => ({
                  ...prev,
                  foodCerInfo: Value,
                }));
              }}
              type="text"
              className="border w-full h-10 border-[#F0F0F4] text-base rounded-lg py-1 px-4"
            />
          </div> */}
          </div>
        </form>
      </div>
      <div className="flex flex-wrap justify-center py-6">
        <button
          onClick={editGoodAttrHandler}
          className="w-[240px] h-[40px] bg-[#3D3E49] text-white rounded-lg"
        >
          저장
        </button>
      </div>
    </div>
  );
};
