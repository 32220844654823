import { ApolloError, gql, useMutation } from "@apollo/client";
import { CrBannerOptionMutation } from "../generated/graphql";

const CR_BANNER_OPTION_MUTATION = gql`
  mutation crBannerOption {
    crBannerOption {
      ok
      err
    }
  }
`;

export const useCrBannerOption = (
  onCompleted?: (data: CrBannerOptionMutation) => void,
  onError?: (error: ApolloError) => void
) => {
  return useMutation<CrBannerOptionMutation>(CR_BANNER_OPTION_MUTATION, {
    onCompleted,
    onError,
  });
};
