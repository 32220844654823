import { useEffect, useState } from "react";
import { AsideAdminComponent } from "../layout/aside-admin";
import {
  ComponentTabLink,
  ITabTitle,
  PageType,
} from "../components/component-tab";
import { ITabLink } from "../components/component-tab";
import { useLocation, useNavigate } from "react-router-dom";
import { Modal } from "@mui/material";
import exit from "../assets/icon/exit.png";
import React from "react";
import { pageIndicator } from "../apollo";
import DatePick from "react-datepicker";
import { useListOrder } from "../hooks/useListOrder";

import { TableError } from "../components/table-error";
import { PaginationComponent } from "../components/pagination";
import { ko } from "date-fns/esm/locale";
import moment from "moment";

import { useEditOrderStatusWithQuantity } from "../hooks/useEditOrderStatusWithQuantity";
import { useDelOrder } from "../hooks/useDelOrder";
import { useForm } from "react-hook-form";
import {
  IEditOrderEndFormProps,
  useEditOrderEndDate,
} from "../hooks/useEditOrderEndDate";
import { ListOrder } from "./admin-list-order";
import {
  OrderStatus,
  EditOrderEndDateInput,
  ListOrderInput,
  EditOrderStatusWithQuantityInput,
  DelOrderInput,
  EditOrderEndDateMutation,
  EditOrderStatusWithQuantityMutation,
  DelOrderMutation,
} from "../generated/graphql";

export const AdminListOrderEnd = () => {
  const tabList: ITabLink[] = [
    { text: "주문확인", to: "/order" },
    { text: "출고대기", to: "/release" },
    { text: "배송준비중", to: "/order/delivery" },
    { text: "배송중", to: "/order/start" },
    { text: "배송완료", to: "/order/end", selected: true },
    { text: "교환/환불", to: "/order/exchange" },
    { text: "배송확정", to: "/order/confirm" },
  ];
  const stitle: ITabTitle = { text: "배송관리" };
  const word = "배송완료";
  const location = useLocation();
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [change, setChange] = React.useState<OrderStatus>(
    OrderStatus.Delivered
  );
  const [checkedLists, setCheckedLists] = useState<ListOrder[]>([]);
  const [allChecked, setAllChecked] = useState<boolean>(false);
  const [quantity, setQuantity] = useState<number>(0);
  const [page, setPage] = useState<number>(1);
  const [search, setSearch] = useState<string>("");
  const [stateId, setStateId] = useState<number>(1);
  const [edit, setEdit] = useState(false);
  const [text, setText] = useState<string>("");

  // --- 배송완료 시간 변경 ---
  const transModalDataHandler = (end: ListOrder) => {
    setValue("endDate", moment(end.EndDate).format("YYYY-MM-DD HH:mm"));
    setValue("orderId", end.id);
  };

  const {
    register,
    handleSubmit,
    getValues,
    setValue,
    formState: { errors, isValid },
  } = useForm<IEditOrderEndFormProps>({ mode: "all" });
  const onEditComplete = (data: EditOrderEndDateMutation) => {
    const {
      editOrderEndDate: { ok, err },
    } = data;
    if (ok) {
      window.location.href = "/order/end";
    } else {
      alert(err);
    }
  };
  const [
    editOrderEndDate,
    { loading: editOrderEndDateLoading },
  ] = useEditOrderEndDate(onEditComplete);

  const onSubmit = () => {
    if (!editOrderEndDateLoading) {
      const { endDate, orderId } = getValues();
      const editOrderEndDateInput: EditOrderEndDateInput = {
        orderId: orderId ? +orderId : 1,
        endDate,
      };
      editOrderEndDate({ variables: { input: editOrderEndDateInput } });
      setEdit(false);
    }
  };

  // --- 배송완료 시간 변경 ---

  const StateHandler = (id: number) => {
    setStateId(id);
    setOpen(true);
  };
  const [listOrder, { data, loading, refetch }] = useListOrder();
  const listOrderHandler = (
    page: number | null,
    search: string | null,
    statuses: OrderStatus[],
    start?: Date | null,
    end?: Date | null
  ) => {
    const input: ListOrderInput = {
      ...(page && { page: +page }),
      ...(search && { search: decodeURI(search) }),
      ...(statuses.length > 0 && { statuses }),
      ...(start && { start }),
      ...(end && { end }),
    };
    listOrder({ variables: { input } });
  };
  const dateHandelr = (date: Date) => {};
  const onEditQuantityCompleted = async (
    data: EditOrderStatusWithQuantityMutation
  ) => {
    const {
      editOrderStatusWithQuantity: { ok, err },
    } = data;
    if (ok) {
      // alert("주문상태가 수정되었습니다");
      await refetch();
    } else {
      alert(err);
    }
  };

  const [
    editOrderStatusWithQuantity,
    { loading: editOrderStatusWithQuantityLoading },
  ] = useEditOrderStatusWithQuantity(onEditQuantityCompleted);
  const editOrderStatusWithQuantityHandler = (
    orderId: number,
    status: OrderStatus,
    quantity: number
  ) => {
    if (!editOrderStatusWithQuantityLoading) {
      const editOrderStatusWithQuantityInput: EditOrderStatusWithQuantityInput = {
        orderId,
        status,
        quantity,
      };
      editOrderStatusWithQuantity({
        variables: { input: editOrderStatusWithQuantityInput },
      });
    }
  };

  // ----- 삭제 ------
  const onDelCompleted = async (data: DelOrderMutation) => {
    const {
      delOrder: { ok, err },
    } = data;
    if (ok) {
      alert("주문내역 삭제");
      await refetch();
    } else {
      alert(err);
    }
  };
  const [delOrder, { loading: delOrderLoading }] = useDelOrder(onDelCompleted);
  const delOrderHandler = (orderId: number) => {
    if (delOrderLoading) return;
    if (
      window.confirm(
        "삭제한 주문내역은 복구할 수 없습니다. 정말로 삭제하시겠습니까?"
      )
    ) {
      const input: DelOrderInput = {
        orderId,
      };
      delOrder({ variables: { input } });
    }
  };
  // --- CheckBox ---
  const setAllCheckedHandler = () => {
    setAllChecked(!allChecked);
    if (allChecked) setCheckedLists([]);

    if (!allChecked && data?.listOrder.orders) {
      setCheckedLists([]);
      for (const end of data?.listOrder.orders) {
        setCheckedLists((checkedList) => [...checkedList, end]);
      }
    }
  };

  const setCheckedListHandler = (endId: number, data: any) => {
    if (Boolean(checkedLists.find((checkedId) => checkedId.id === endId))) {
      setCheckedLists(
        checkedLists.filter((checkedId) => checkedId.id !== endId)
      );
    } else {
      setCheckedLists((originalList) => [...originalList, data]);
    }
  };
  useEffect(() => {
    if (checkedLists.length === data?.listOrder.orders?.length)
      setAllChecked(true);
    else setAllChecked(false);
  }, [checkedLists]);

  // --- Search ---
  const setSearchHandler = (search: string) => {
    navigate({
      pathname: "/order/end",
      search: `${search ? `?search=${search}` : ""}`,
    });
    const statuses: OrderStatus[] = [OrderStatus.Delivered];
    listOrderHandler(1, search, statuses);
  };

  // --- Page ---
  const pageHandler = (page: number) => {
    const search =
      location.search.split("search=").length > 1
        ? location.search.split("search=")[1].split("&")[0]
        : null;
    setPage(page);
    navigate({
      pathname: "/order/end",
      search: `?page=${page}${search ? `&search=${search}` : ""}`,
    });
    const statuses: OrderStatus[] = [OrderStatus.Delivered];
    listOrderHandler(page, search, statuses);
  };
  // --- Period ---
  const periodHandler = (sDate?: Date, eDate?: Date) => {
    const statuses: OrderStatus[] = [OrderStatus.Delivered];
    listOrderHandler(1, search, statuses, sDate, eDate);
  };
  useEffect(() => {
    pageIndicator("delivery");
    setPage(
      location.search.split("page=").length > 1
        ? +location.search.split("page=")[1].split("&")[0]
        : 1
    );
    setSearch(
      location.search.split("search=").length > 1
        ? location.search.split("search=")[1].split("&")[0]
        : ""
    );
    const statuses: OrderStatus[] = [OrderStatus.Delivered];
    listOrderHandler(page, search, statuses);
  }, [search, page]);

  return (
    <div className="bg-slate-500 bg-opacity-50 w-full h-full min-h-screen">
      <div className="w-full mx-auto h-full min-h-screen  bg-gray-100">
        <main id="main">
          <div className="grid grid-cols-[200px_1fr] max-w-full">
            <AsideAdminComponent />
            <div className="mypages bg-gray-100 w-full min-w-[1200px] overflow-hidden">
              <div className="orderWrap">
                <div className="h-fix-content relative">
                  <div className={`w-full h-full px-10 py-8`}>
                    <ComponentTabLink
                      tabs={tabList}
                      stitle={stitle}
                      dataList={checkedLists}
                      type={PageType.End}
                      setSearchs={setSearchHandler}
                      setDateSearch={periodHandler}
                      refetch={refetch}
                    />
                    {/* 목록 */}
                    <div className=" shadow-lg h-[800px] h-fix-content w-full overflow-auto bg-white">
                      <table className="align-center w-[1972px]  text-sm  border-[#F9F8F9] ">
                        <colgroup>
                          <col width="36"></col>
                          <col width="40"></col>
                          <col width="64"></col>
                          <col width="88"></col>
                          <col width="112"></col>
                          <col width="168"></col>
                          <col width="88"></col>
                          <col width="168"></col>
                          <col width="168"></col>
                          <col width="48"></col>
                          <col width="88"></col>
                          <col width="88"></col>
                          <col width="88"></col>
                          <col width="168"></col>
                          <col width="168"></col>
                          <col width="128"></col>
                          <col width="88"></col>
                          <col width="88"></col>
                          <col width="80"></col>
                        </colgroup>
                        <thead>
                          <tr className=" border-b border-[#F9F8F9] h-8">
                            <th className="text-[#8B8C8C] leading-3 min-w-[36px]">
                              <input
                                type="checkbox"
                                className="w-5 h-5"
                                checked={allChecked}
                                onChange={setAllCheckedHandler}
                              />
                            </th>
                            <th className="text-[#8B8C8C] min-w-[40px]">no</th>
                            <th className="text-[#8B8C8C] min-w-[64px]">
                              상태
                            </th>
                            <th className="text-[#8B8C8C] min-w-[88px]">
                              주문번호
                            </th>
                            <th className="text-[#8B8C8C] min-w-[112px]">
                              상품 PLU
                            </th>
                            <th className="text-[#8B8C8C] min-w-[112px]">
                              거래처
                            </th>
                            <th className="text-[#8B8C8C] min-w-[88px]">
                              배송완료시간
                            </th>

                            <th className="text-[#8B8C8C] min-w-[168px]">
                              상품명
                            </th>
                            <th className="text-[#8B8C8C] min-w-[168px]">
                              상품옵션
                            </th>
                            <th className="text-[#8B8C8C] min-w-[48px]">
                              수량
                            </th>
                            <th className="text-[#8B8C8C] min-w-[88px]">
                              금액
                            </th>
                            <th className="text-[#8B8C8C] min-w-[88px]">
                              구매자
                            </th>
                            <th className="text-[#8B8C8C] min-w-[88px]">
                              수취인
                            </th>
                            <th className="text-[#8B8C8C] min-w-[168px]">
                              주소
                            </th>
                            <th className="text-[#8B8C8C] min-w-[168px]">
                              배송메시지
                            </th>
                            <th className="text-[#8B8C8C] min-w-[128px]">
                              연락처
                            </th>
                            <th className="text-[#8B8C8C] min-w-[88px]">
                              상품코드
                            </th>
                            <th className="text-[#8B8C8C] min-w-[88px]">
                              구매확정일
                            </th>
                            <th className="text-[#8B8C8C] min-w-[80px]">
                              비고
                            </th>
                          </tr>
                        </thead>
                        <tbody className="">
                          {!loading &&
                            data?.listOrder.orders &&
                            data.listOrder.orders.map((end, index) => (
                              <tr
                                key={`end-${end.id}`}
                                className="border-b border-[#F9F8F9] h-[68px]"
                              >
                                <td className="table-text ">
                                  <input
                                    type="checkbox"
                                    className="h-5 w-5"
                                    checked={
                                      checkedLists.find(
                                        (checkedId) => checkedId.id === end.id
                                      ) !== undefined
                                    }
                                    onChange={(e) => {
                                      setCheckedListHandler(end.id, end);
                                    }}
                                  />
                                </td>
                                <td className="table-text">
                                  {(page - 1) * 20 + index + 1}
                                </td>
                                <td className="table-text ">
                                  <button
                                    className="bg-[#3D3E49] rounded-lg py-[4px] px-[3px] w-[48px] h-10 text-white keep-all"
                                    onClick={() => {
                                      StateHandler(end.id);
                                      setChange(end.status);
                                      setQuantity(end.quantity);
                                    }}
                                  >
                                    {end.status === "Delivered"
                                      ? "배송 완료"
                                      : ""}
                                  </button>
                                </td>
                                <td className="table-text break-all">
                                  {end.receipt?.receiptNum}
                                </td>
                                <td className="table-text font-bold">
                                  {end.good?.plu && end.good.plu}
                                </td>
                                <td className="table-text font-bold">
                                  {end.good?.corporations?.map((cor) => (
                                    <p key={`cor-${cor.id}`}>{cor.name}</p>
                                  ))}
                                </td>
                                <td className="table-text font-bold">
                                  <button
                                    onClick={() => {
                                      setEdit(true);
                                      transModalDataHandler(end);
                                    }}
                                    className="table-text font-bold"
                                  >
                                    {end.EndDate &&
                                      moment(end.EndDate).format("YYMMDD")}{" "}
                                    <br />
                                    {end.EndDate &&
                                      moment(end.EndDate).format("H : mm")}
                                  </button>
                                </td>

                                <td className="table-text">
                                  {end.good?.title}
                                </td>
                                <td className="table-text">
                                  {end.goodOptionName}
                                </td>
                                <td className="table-text">{end.quantity}</td>
                                <td className="table-text">{end.amount}</td>
                                <td className="table-text">
                                  {end.receipt?.user?.name}
                                </td>
                                <td className="table-text font-bold">
                                  {end.receipt?.receiver}
                                </td>
                                <td className="table-text">
                                  {end.receipt?.address}
                                </td>
                                <td className="table-text">
                                  {end.receipt?.message}
                                </td>
                                <td className="table-text">
                                  {end.receipt?.phone}
                                </td>
                                <td className="table-text break-all">
                                  {end.good?.goodUuid}
                                </td>
                                <td className="table-text">
                                  {end.createdAt &&
                                    moment(end.createdAt).format("YYMMDD")}
                                </td>
                                <td className="table-text">
                                  <button
                                    className="bg-[#CCCCCC] rounded-lg py-[6px]  w-[48px] h-7 text-white"
                                    onClick={() => delOrderHandler(end.id)}
                                  >
                                    삭제
                                  </button>
                                </td>
                              </tr>
                            ))}
                        </tbody>
                      </table>
                      {loading && (
                        <TableError errorMessage={`정보를 불러오는중입니다`} />
                      )}
                      {!loading &&
                        data?.listOrder.orders &&
                        data.listOrder.orders.length < 1 && (
                          <TableError
                            errorMessage={`${word} 정보가 없습니다`}
                          />
                        )}

                      {!loading &&
                        data?.listOrder.orders &&
                        data.listOrder.orders.length > 0 && (
                          <PaginationComponent
                            page={page}
                            totalPages={Number(data.listOrder.totalPages)}
                            totalResults={Number(data.listOrder.totalResults)}
                            pageHandler={pageHandler}
                          />
                        )}
                      <Modal
                        open={open}
                        onClose={() => {
                          setOpen(false);
                        }}
                      >
                        <div className="max-w-[360px] w-full absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2   bg-[#F9F8F9] rounded-lg">
                          <div className="text-center py-2 h-10 text-white font-bold bg-[#3D3E49] rounded-t-lg  ">
                            <div className="flex flex-wrap justify-between">
                              <p className="w-[240px] ml-[40px] font-bold text-base">
                                배송 상태변경
                              </p>
                              <img
                                src={exit}
                                alt="닫기"
                                className="w-6 mr-2 cursor-pointer"
                                onClick={() => setOpen(false)}
                              ></img>
                            </div>
                          </div>
                          <div className="flex flex-wrap content-center">
                            <div className="px-10 py-10">
                              <div className="grid grid-rows-6 gap-y-4">
                                <p className="font-bold">배송 준비</p>
                                <div>
                                  <input
                                    type="radio"
                                    name="order"
                                    id="pending"
                                    onChange={(e) => {
                                      if (e.target.checked) {
                                        setChange(OrderStatus.Pending);
                                      }
                                    }}
                                  ></input>
                                  <span className="ml-4">배송 준비</span>
                                </div>
                                <p className="font-bold">배송중</p>
                                <div>
                                  <input
                                    type="radio"
                                    name="order"
                                    id="Drone"
                                    value="Drone"
                                    onChange={(e) => {
                                      if (e.target.checked) {
                                        setChange(OrderStatus.Drone);
                                      }
                                    }}
                                  ></input>
                                  <span className="ml-4">드론 비행</span>
                                </div>
                                <div>
                                  <input
                                    type="radio"
                                    name="order"
                                    id="Port"
                                    value="HandOut"
                                    onChange={(e) => {
                                      if (e.target.checked) {
                                        setChange(OrderStatus.Port);
                                      }
                                    }}
                                  ></input>
                                  <span className="ml-4">최종 배송준비</span>
                                </div>
                                <div>
                                  <input
                                    type="radio"
                                    name="order"
                                    id="Stored"
                                    value="Stored"
                                    onChange={(e) => {
                                      if (e.target.checked) {
                                        setChange(OrderStatus.Stored);
                                      }
                                    }}
                                  ></input>
                                  <span className="ml-4">픽업포트</span>
                                </div>
                                <div>
                                  <input
                                    type="radio"
                                    name="order"
                                    id="PendingDelivered"
                                    value="PendingDeliverd"
                                    onChange={(e) => {
                                      if (e.target.checked) {
                                        setChange(OrderStatus.PendingDelivered);
                                      }
                                    }}
                                  ></input>
                                  <span className="ml-4">최종 배송</span>
                                </div>
                                <p className="font-bold">배송 완료</p>
                                <div>
                                  <input
                                    type="radio"
                                    name="order"
                                    id="check"
                                    value="Delivered"
                                    checked={change === OrderStatus.Delivered}
                                    onChange={(e) => {
                                      if (e.target.checked) {
                                        setChange(OrderStatus.Delivered);
                                      }
                                    }}
                                  ></input>
                                  <span className="ml-4">배송 완료</span>
                                </div>
                                <p className="font-bold">환불/교환</p>
                                <div>
                                  <input
                                    type="radio"
                                    name="order"
                                    id="confirm"
                                    onChange={(e) => {
                                      if (e.target.checked) {
                                        setChange(OrderStatus.Refunded);
                                      }
                                    }}
                                  ></input>
                                  <span className="ml-4">환불</span>
                                  <div className="pt-2">
                                    <input
                                      type="text"
                                      className={`w-full`}
                                      disabled={change !== OrderStatus.Refunded}
                                      onChange={(e) =>
                                        setQuantity(Number(e.target.value))
                                      }
                                    ></input>
                                  </div>
                                </div>
                                <div>
                                  <input
                                    type="radio"
                                    name="order"
                                    id="end"
                                    value="Exchange"
                                    onChange={(e) => {
                                      if (e.target.checked) {
                                        setChange(OrderStatus.Exchange);
                                      }
                                    }}
                                  ></input>
                                  <span className="ml-4">교환</span>
                                  <div className="pt-2">
                                    <input
                                      type="text"
                                      className={`w-full`}
                                      disabled={change !== OrderStatus.Exchange}
                                      onChange={(e) =>
                                        setQuantity(Number(e.target.value))
                                      }
                                    ></input>
                                  </div>
                                </div>
                                <p className="font-bold">확정</p>
                                <div>
                                  <input
                                    type="radio"
                                    name="order"
                                    id="Complete"
                                    onChange={(e) => {
                                      if (e.target.checked) {
                                        setChange(OrderStatus.Complete);
                                      }
                                    }}
                                  ></input>
                                  <span className="ml-4">확정</span>
                                </div>
                              </div>
                            </div>
                          </div>
                          <button
                            className="w-full h-14 bg-[#F0F0F4] rounded-b-lg"
                            onClick={() => {
                              editOrderStatusWithQuantityHandler(
                                stateId,
                                change,
                                quantity
                              );
                              setOpen(false);
                            }}
                          >
                            확인
                          </button>
                        </div>
                      </Modal>
                      <Modal
                        open={edit}
                        onClose={() => {
                          setEdit(false);
                        }}
                      >
                        <form onSubmit={handleSubmit(onSubmit)}>
                          <div className="max-w-[360px] w-full absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-[#F9F8F9] rounded-lg">
                            <div className="text-center py-2 h-10 text-white font-bold bg-[#3D3E49] rounded-t-lg  ">
                              <div className="flex flex-wrap justify-between">
                                <p className="w-[240px] ml-[40px] font-bold text-base">
                                  배송완료 시간 변경
                                </p>
                                <img
                                  src={exit}
                                  alt="닫기"
                                  className="w-6 mr-2 cursor-pointer"
                                  onClick={() => setEdit(false)}
                                ></img>
                              </div>
                            </div>
                            <div className="px-10 py-10">
                              <div className="space-y-5">
                                <div className="space-y-3">
                                  <p className="font-bold text-[#8B8C8C] text-sm">
                                    배송완료시간
                                  </p>
                                  <input
                                    {...register("endDate", {
                                      required: `출발시간을 입력해주세요`,
                                    })}
                                    type="datetime-local"
                                    className="border w-full h-10 border-[#F0F0F4] text-base rounded-lg py-1 px-4"
                                  />
                                </div>
                              </div>
                            </div>
                            <button
                              type="submit"
                              className="w-full h-14 bg-[#F0F0F4] rounded-b-lg"
                            >
                              {loading ? "Loading..." : "확인"}
                            </button>
                          </div>
                        </form>
                      </Modal>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
};
