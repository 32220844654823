import { gql, useLazyQuery } from "@apollo/client";
import { ListOrderQuery, ListOrderQueryVariables } from "../generated/graphql";

const LIST_ORDER_QUERY = gql`
  query listOrder($input: ListOrderInput!) {
    listOrder(input: $input) {
      ok
      err
      totalPages
      totalResults
      row
      orders {
        id
        createdAt
        EndDate
        goodName
        goodOptionName
        quantity
        amount
        orderNum
        manager {
          id
          name
        }
        receipt {
          id
          uuid
          receiver
          address
          paid_amount
          receiptNum
          message
          pass
          phone
          receiptStatus
          user {
            username
            name
            phone
          }
        }
        good {
          id
          title
          weight
          uuid
          goodType
          goodUuid
          plu
          corporations {
            id
            name
          }
        }
        goodOption {
          id
          name
          goodOptionUuid
        }

        status
        exchangeStatus
        pack {
          id
          status
        }
        redelivery
        orderReceipt {
          order_quantity
          order_price
          order_amount
          good_discountRate
          goodOption_name
          good_title
        }
      }
    }
  }
`;

export const useListOrder = (onCompleted?: (data: ListOrderQuery) => void) => {
  return useLazyQuery<ListOrderQuery, ListOrderQueryVariables>(
    LIST_ORDER_QUERY,
    {
      onCompleted,
      fetchPolicy: "network-only",
    }
  );
};
