import { useCallback, useEffect, useState } from "react";

import { useParams } from "react-router";
import { pageIndicator } from "../apollo";
import { EditBannerProps } from "../constants/enum";

import { Button } from "../components/button";

import { useListBannerMain } from "../hooks/useListBannerMain";
import update from "immutability-helper";
import { MainImage } from "../components/main-image";
import { useCrBannerMain } from "../hooks/useCrBannerMain";
import { MainVideo } from "../components/main-video";
import { useEditBannerMainIndex } from "../hooks/useEditBannerMainIndex";
import { CardBanner } from "../components/CardBanner";
import { useEditBannerMain } from "../hooks/useEditBannerMain";
import { Link } from "react-router-dom";
import {
  BannerMainType,
  CrBannerMainInput,
  CrBannerMainMutation,
  EditBannerMainIndexInput,
  EditBannerMainIndexMutation,
  EditBannerMainInput,
  EditBannerMainMutation,
  ListBannerMainQuery,
} from "../generated/graphql";
import { ArrElement } from "./sub-admin-up-product-detail";

const style = {
  width: "100%",
};

type ListBannerMain = ArrElement<
  ListBannerMainQuery["listBannerMain"]["bannerMain"]
>;

type upMainsProps = ListBannerMain & { file?: any };

export interface Item extends ListBannerMain {}
export const SubAdminEditBannerMain: React.FC<EditBannerProps> = ({
  tabs,
  setTabHandler,
}) => {
  const params = useParams();
  const [mainData, setMainData] = useState<Item[]>([]);
  const [uploading, setUploading] = useState<boolean>(false);

  // --- 배너 이미지, 비디오 생성 ---
  const onCrCompleted = async (data: CrBannerMainMutation) => {
    const {
      crBannerMain: { ok, err },
    } = data;
    if (ok) {
      await refetch();
    } else {
      alert(err);
    }
  };
  const [crBannerMain, { loading: crBannerMainLoading }] = useCrBannerMain(
    onCrCompleted
  );
  const crBannerMainHandler = (typeValue: BannerMainType) => {
    if (!crBannerMainLoading) {
      const crBannerMainInput: CrBannerMainInput = {
        mainType: typeValue,
      };
      crBannerMain({ variables: { input: crBannerMainInput } });
    }
  };
  //

  // --- 메인배너 리스트 Sortable ---
  const onEditIndexCompleted = async (data: EditBannerMainIndexMutation) => {
    const {
      editBannerMainIndex: { ok, err },
    } = data;
    if (ok) {
      await refetch();
    }
  };
  const [
    editBannerMainIndex,
    { loading: editBannerMainIndexLoading },
  ] = useEditBannerMainIndex(onEditIndexCompleted);
  const editBannerMainIndexHandler = (bannerMainId: number, index: number) => {
    const editBannerMainIndexInput: EditBannerMainIndexInput = {
      index,
      bannerMainId,
    };
    editBannerMainIndex({ variables: { input: editBannerMainIndexInput } });
  };

  const moveCard = useCallback((dragIndex: number, hoverIndex: number) => {
    setMainData((prevCards: Item[]) =>
      update(prevCards, {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, prevCards[dragIndex] as Item],
        ],
      })
    );
  }, []);
  const renderCard = useCallback(
    (card: { id: number; name?: string | null }, index: number) => {
      return (
        <CardBanner
          key={card.id}
          index={index}
          id={card.id}
          name={card.name}
          // moveCard={moveCard}
          editBannerMainIndex={editBannerMainIndexHandler}
        />
      );
    },
    []
  );

  ///  --- URL 등록 ---

  const onEditCompleted = async (data: EditBannerMainMutation) => {
    const {
      editBannerMain: { ok, err },
    } = data;
    if (ok) {
      await refetch();
    } else {
      alert(err);
      return;
    }
    setUploading(false);
  };
  const [
    editBannerMain,
    { loading: editBannerMainLoading },
  ] = useEditBannerMain(onEditCompleted);
  const editBannerMainHandler = (
    bannerMainId: number,
    link: string | null,
    title: string | null,
    note: string | null,
    alt: string | null,
    isFree: boolean,
    isRes: boolean,
    isEvent: boolean,
    isOnePlusOne: boolean
  ) => {
    const editBannerMainInput: EditBannerMainInput = {
      bannerMainId,
      link,
      title,
      note,
      alt,
      isFree,
      isRes,
      isEvent,
      isOnePlusOne,
    };
    editBannerMain({ variables: { input: editBannerMainInput } });
  };

  // --- 메인배너 리스트 ---
  const onListCompleted = async (data: ListBannerMainQuery) => {
    const {
      listBannerMain: { ok, bannerMain, err },
    } = data;
    if (ok && bannerMain) {
      setMainData(bannerMain);
    } else {
      console.log(err);
    }
  };
  const [
    listBannerMain,
    { data: listBannerMainData, loading, refetch },
  ] = useListBannerMain(onListCompleted);

  useEffect(() => {
    pageIndicator("events");
    listBannerMain();
  }, []);
  return (
    <div className="shadow-lg min-w-[1160px] h-fix-content max-w-[1160px]  2xl:max-w-[1623px]  bg-white">
      <div className="pl-9 pr-11 py-8 space-y-4 min-h-[800px]">
        <div className="relative grid grid-cols-[1fr] gap-x-4">
          <div className="min-h-[600px]">
            <div className="font-bold text-[#4F4F4F] text-base">
              메인배너
              <button
                className="ml-4 min-w-[50px] px-2 h-[30px] bg-[#8B8C8C] text-white rounded-lg"
                onClick={() => crBannerMainHandler(BannerMainType.Image)}
              >
                생성
              </button>
              {/* <button
                  className="ml-4 min-w-[50px] px-2 h-[30px] bg-[#8B8C8C] text-white rounded-lg"
                  onClick={() => crBannerMainHandler(BannerMainType.Video)}
                >
                  비디오
                </button> */}
              {loading && (
                <div className="pt-5">상세정보를 불러오는 중입니다</div>
              )}
              <p className="py-2">순서 재배치</p>
              <div style={style} className="overflow-auto h-96 space-y-4">
                {listBannerMainData?.listBannerMain.bannerMain &&
                  listBannerMainData.listBannerMain.bannerMain.map((card, i) =>
                    renderCard(card, i)
                  )}
              </div>
            </div>
          </div>
          {/* <div className="w-full">
            <div className="grid grid-cols-3 gap-8">
              {loading && <div>정보를 불러오는 중입니다</div>}
              {!loading &&
                listBannerMainData?.listBannerMain.bannerMain &&
                listBannerMainData.listBannerMain.bannerMain.map((banner) => (
                  <>
                    <MainImage
                      bannerMain={banner}
                      refetch={refetch}
                      index={banner.index}
                      mainHandler={mainHandler}
                    />
                  </>
                ))}
            </div>
          </div> */}
        </div>
      </div>
    </div>
  );
};
