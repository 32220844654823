import { ApolloError, gql, useMutation } from "@apollo/client";
import {
  DelProjectMutation,
  DelProjectMutationVariables,
} from "../generated/graphql";

const DEL_PROJECT_MUTATION = gql`
  mutation delProject($input: DelProjectInput!) {
    delProject(input: $input) {
      ok
      err
    }
  }
`;

export const useDelProject = (
  onCompleted?: (data: DelProjectMutation) => void,
  onError?: (Error: ApolloError) => void
) => {
  return useMutation<DelProjectMutation, DelProjectMutationVariables>(
    DEL_PROJECT_MUTATION,
    {
      onCompleted,
      onError,
    }
  );
};
