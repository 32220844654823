import { ApolloError, gql, useMutation } from "@apollo/client";
import {
  EditGoodAttrMutation,
  EditGoodAttrMutationVariables,
} from "../generated/graphql";

const EDIT_GOOD_ATTR_MUTATION = gql`
  mutation editGoodAttr($input: EditGoodAttrInput!) {
    editGoodAttr(input: $input) {
      ok
      err
    }
  }
`;

export const useEditGoodAttr = (
  onCompleted?: (data: EditGoodAttrMutation) => void,
  onError?: (error: ApolloError) => void
) => {
  return useMutation<EditGoodAttrMutation, EditGoodAttrMutationVariables>(
    EDIT_GOOD_ATTR_MUTATION,
    {
      onCompleted,
      onError,
    }
  );
};
