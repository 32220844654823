import logo from "../../assets/icon/NINANO.png";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet-async";

export const FindPwd = () => {
  return (
    <div className="w-full h-full">
      <Helmet>
        <title>List Porject | Anne</title>
      </Helmet>
      <div className="w-full h-full mx-auto flex flex-wrap min-h-screen justify-center content-center overflow-hidden">
        <div className="w-full text-center">
          <Link to="/admin" className="inline-block w-[200px] mx-auto">
            <img src={logo} alt="로고" className="w-full" />
          </Link>
          <p className="text-center pt-5">
            회원정보에 등록된 이메일을 입력해주세요
          </p>
          <form className="p-4 mx-auto w-full max-w-md">
            <label className="py-10 flex">
              <span className="inline-block w-full max-w-[90px] text-[#3d3e49] font-bold ">
                이메일
              </span>
              <input
                type="email"
                className="inline-block border-b rounded-none border-[#3d3e49] focus:outline-none pl-1 leading-normal flex-1"
              ></input>
            </label>
            <div className="pt-10">
              <Link
                to="/admin"
                className="inline-block w-32 py-1 px-2 border-grays border rounded mr-3"
              >
                취소
              </Link>
              <button
                className="inline-block w-32 py-1 px-2 text-bold bg-main border border-main
                   rounded text-white text-base"
              >
                확인
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};
