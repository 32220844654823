import { gql, useLazyQuery } from "@apollo/client";
import { ListCorporationSelectQuery } from "../generated/graphql";

const LIST_CORPORATION_SELECT = gql`
  query listCorporationSelect {
    listCorporationSelect {
      ok
      err
      corporations {
        id
        name
      }
    }
  }
`;

export const useListCorporationSelect = (
  onCompleted?: (data: ListCorporationSelectQuery) => void
) => {
  return useLazyQuery<ListCorporationSelectQuery>(LIST_CORPORATION_SELECT, {
    onCompleted,
    fetchPolicy: "network-only",
  });
};
