import { gql, useLazyQuery } from "@apollo/client";
import { ListBannerOptionQuery } from "../generated/graphql";

const LIST_BANNER_OPTION_QUERY = gql`
  query listBannerOption {
    listBannerOption {
      ok
      err
      bannerOptions {
        id
        index
        link
        title
        note
        alt
        isActive
        isRes
        isEvent
        isFree
        isOnePlusOne
        upload {
          id
          uuid
          url
          filename
        }
      }
    }
  }
`;

export const useListBannerOption = (
  onCompleted?: (data: ListBannerOptionQuery) => void
) => {
  return useLazyQuery<ListBannerOptionQuery>(LIST_BANNER_OPTION_QUERY, {
    onCompleted,
  });
};
