import { ApolloError, gql, useMutation } from "@apollo/client";
import {
  EditGoodOrderMutation,
  EditGoodOrderMutationVariables,
} from "../generated/graphql";

const Edit_GOOD_ORDER_MUTATION = gql`
  mutation editGoodOrder($input: EditGoodOrderInput!) {
    editGoodOrder(input: $input) {
      ok
      err
    }
  }
`;

export const useEditGoodOrder = (
  onCompleted?: (data: EditGoodOrderMutation) => void,
  onError?: (error: ApolloError) => void
) => {
  return useMutation<EditGoodOrderMutation, EditGoodOrderMutationVariables>(
    Edit_GOOD_ORDER_MUTATION,
    {
      onCompleted,
      onError,
    }
  );
};
