import { useEffect, useState } from "react";
import { AsideAdminComponent } from "../layout/aside-admin";
import { Modal } from "@mui/material";
import React from "react";
import exit from "../assets/icon/exit.png";

import {
  PageType,
  ComponentTabLink,
  ITabTitle,
} from "../components/component-tab";
import { ITabLink } from "../components/component-tab";
import { pageIndicator } from "../apollo";
import { useLocation, useNavigate } from "react-router-dom";
import { useListOrder } from "../hooks/useListOrder";
import moment from "moment";

import { TableError } from "../components/table-error";
import { PaginationComponent } from "../components/pagination";
import { useEditOrderStatus } from "../hooks/useEditOrderStatus";
import {
  EditOrderStatusInput,
  EditOrderStatusMutation,
  ListOrderInput,
  ListOrderQuery,
  OrderStatus,
} from "../generated/graphql";
import { ListOrder } from "./admin-list-order";

export const AdminListRelease = () => {
  const location = useLocation();
  const [open, setOpen] = React.useState(false);
  const [change, setChange] = React.useState<OrderStatus>(OrderStatus.Bound);
  const [stateId, setStateId] = useState<number>(1);
  const stitle: ITabTitle = { text: "배송관리" };
  const [page, setPage] = useState<number>(1);
  const [search, setSearch] = useState<string>("");
  const [checkedLists, setCheckedLists] = useState<ListOrder[]>([]);
  const [allChecked, setAllChecked] = useState<boolean>(false);

  const word = "출고대기";
  const tabList: ITabLink[] = [
    { text: "주문확인", to: "/order" },
    { text: "출고대기", to: "/release", selected: true },
    { text: "배송준비중", to: "/order/delivery" },
    { text: "배송중", to: "/order/start" },
    { text: "배송완료", to: "/order/end" },
    { text: "교환/환불", to: "/order/exchange" },
    { text: "배송확정", to: "/order/confirm" },
  ];

  const onCompleted = async (data: ListOrderQuery) => {
    const {
      listOrder: { ok, err },
    } = data;
    if (ok) {
      await refetch();
    } else {
      alert(err);
    }
  };

  const [listOrder, { data, loading, refetch }] = useListOrder();
  const listOrderHandler = (
    page: number | null,
    search: string | null,
    statuses: OrderStatus[],
    start?: Date | null,
    end?: Date | null
  ) => {
    const input: ListOrderInput = {
      ...(page && { page: +page }),
      ...(search && { search: decodeURI(search) }),
      ...(statuses.length > 0 && { statuses }),
      ...(start && { start }),
      ...(end && { end }),
    };
    listOrder({ variables: { input } });
  };

  const navigate = useNavigate();

  // --- 상태 변경 ---
  const onEditCompleted = async (data: EditOrderStatusMutation) => {
    const {
      editOrderStatus: { ok, err },
    } = data;
    if (ok) {
      // alert(`주문상태가 수정되었습니다.`);
      await refetch();
    } else {
      alert(err);
    }
  };
  const [
    editOrderStatus,
    { loading: editOrderStatusLoading },
  ] = useEditOrderStatus(onEditCompleted);
  const editOrderStatusHandler = (orderId: number, status: OrderStatus) => {
    if (!editOrderStatusLoading) {
      const input: EditOrderStatusInput = {
        orderId,
        status,
      };
      editOrderStatus({ variables: { input } });
    }
  };

  const StateHandler = (id: number) => {
    setStateId(id);
    setOpen(true);
  };

  // --- Check ---
  const setAllCheckedHandler = () => {
    setAllChecked(!allChecked);
    if (allChecked) setCheckedLists([]);

    if (!allChecked && data?.listOrder.orders) {
      setCheckedLists([]);
      for (const release of data.listOrder.orders) {
        setCheckedLists((checkedList) => [...checkedList, release]);
      }
    }
  };

  const setCheckedListHandler = (releaseId: number, data: any) => {
    if (Boolean(checkedLists.find((checkedId) => checkedId.id === releaseId))) {
      setCheckedLists(
        checkedLists.filter((checkedId) => checkedId.id !== releaseId)
      );
    } else {
      setCheckedLists((originalList) => [...originalList, data]);
    }
  };
  useEffect(() => {
    if (checkedLists.length === data?.listOrder.orders?.length)
      setAllChecked(true);
    else setAllChecked(false);
  }, [checkedLists]);

  // --- Search ---
  const setSearchHandler = (search: string) => {
    navigate({
      pathname: "/release",
      search: `${search ? `?search=${search}` : ""}`,
    });
    const statuses: OrderStatus[] = [OrderStatus.Bound];
    listOrderHandler(1, search, statuses);
  };

  // --- Page ---
  const pageHandler = (page: number) => {
    setPage(page);
    const search =
      location.search.split("search=").length > 1
        ? location.search.split("search=")[1].split("&")[0]
        : null;
    navigate({
      pathname: "/release",
      search: `?page=${page}${search ? `&search=${search}` : ""}`,
    });
    const statuses: OrderStatus[] = [OrderStatus.Bound];
    listOrderHandler(page, search, statuses);
  };
  // --- Period ---
  const periodHandler = (sDate?: Date, eDate?: Date) => {
    const statuses: OrderStatus[] = [OrderStatus.Bound];
    listOrderHandler(1, search, statuses, sDate, eDate);
  };

  useEffect(() => {
    pageIndicator("delivery");

    setPage(
      location.search.split("page=").length > 1
        ? +location.search.split("page=")[1].split("&")[0]
        : 1
    );
    setSearch(
      location.search.split("search=").length > 1
        ? location.search.split("search=")[1].split("&")[0]
        : ""
    );
    const statuses: OrderStatus[] = [OrderStatus.Bound];
    listOrderHandler(page, search, statuses);
  }, [search, page]);

  return (
    <div className="bg-main bg-opacity-50 w-full h-full min-h-screen">
      <div className="w-full mx-auto h-full min-h-screen  bg-gray-100">
        <main id="main">
          <div className="grid grid-cols-[200px_1fr] max-w-full">
            <AsideAdminComponent />
            <div className="mypages bg-gray-100 w-full min-w-[1200px] overflow-hidden">
              <div className="orderWrap">
                <div className="bg-gray-100 h-fix-content relative">
                  <div className={`w-full h-full px-10 py-8`}>
                    <ComponentTabLink
                      tabs={tabList}
                      stitle={stitle}
                      type={PageType.Release}
                      dataList={checkedLists}
                      setDateSearch={periodHandler}
                      setSearchs={setSearchHandler}
                      refetch={refetch}
                    />
                    {/* 목록 */}
                    <div className=" shadow-lg  h-[800px] h-fix-content w-full overflow-auto bg-white ">
                      <table className="align-center w-[1780px]  text-sm  border-[#F9F8F9] ">
                        <thead>
                          <tr className="border-b border-[#F9F8F9] h-8">
                            <th className="text-[#8B8C8C] leading-3 min-w-[36px]">
                              <input
                                type="checkbox"
                                name="allChk"
                                checked={allChecked}
                                onChange={setAllCheckedHandler}
                                className="w-5 h-5"
                              />
                            </th>
                            <th className="text-[#8B8C8C] min-w-[40px]">no</th>
                            <th className="text-[#8B8C8C] min-w-[64px]">
                              상태
                            </th>
                            <th className="text-[#8B8C8C] min-w-[88px]">
                              주문번호
                            </th>
                            <th className="text-[#8B8C8C] min-w-[112px]">
                              QR코드/송장번호
                            </th>
                            <th className="text-[#8B8C8C] min-w-[72px]">
                              주문일시
                            </th>
                            <th className="text-[#8B8C8C] min-w-[168px]">
                              상품명
                            </th>
                            <th className="text-[#8B8C8C] min-w-[168px]">
                              상품옵션
                            </th>
                            <th className="text-[#8B8C8C] min-w-[48px]">
                              수량
                            </th>
                            <th className="text-[#8B8C8C] min-w-[88px]">
                              구매자
                            </th>
                            <th className="text-[#8B8C8C] min-w-[88px]">
                              수취인
                            </th>
                            <th className="text-[#8B8C8C] min-w-[168px]">
                              주소
                            </th>
                            <th className="text-[#8B8C8C] min-w-[168px]">
                              배송메세지
                            </th>
                            <th className="text-[#8B8C8C] min-w-[168px]">
                              공동현관
                            </th>
                            <th className="text-[#8B8C8C] min-w-[128px]">
                              연락처
                            </th>
                            <th className="text-[#8B8C8C] min-w-[88px]">
                              상품코드
                            </th>
                            <th className="text-[#8B8C8C] min-w-[88px]">
                              위치코드
                            </th>
                            <th className="text-[#8B8C8C] min-w-[88px]">
                              무게
                            </th>
                            <th className="text-[#8B8C8C] min-w-[88px]">
                              재배송
                            </th>
                          </tr>
                        </thead>
                        <tbody className="">
                          {!loading &&
                            data?.listOrder.orders &&
                            data.listOrder.orders.map((release, index) => (
                              <tr
                                key={`release-${release.id}`}
                                className="border-b border-[#F9F8F9] h-[68px]"
                              >
                                <td className="table-text">
                                  <input
                                    type="checkbox"
                                    name={`input ${release.id}`}
                                    checked={
                                      checkedLists.find(
                                        (checkedId) =>
                                          checkedId.id === release.id
                                      ) !== undefined
                                    }
                                    onChange={(e) => {
                                      setCheckedListHandler(
                                        release.id,
                                        release
                                      );
                                    }}
                                    className="h-5 w-5"
                                  />
                                </td>
                                <td className="table-text">
                                  {(page - 1) * 20 + index + 1}
                                </td>
                                <td className="table-text">
                                  <button
                                    className="border border-[#cccccc] rounded-lg py-[4px] px-[3px] w-[48px] h-10 text-[#cccccc] font-bold keep-all"
                                    onClick={() => {
                                      StateHandler(release.id);
                                    }}
                                  >
                                    {release.status === "Bound"
                                      ? "출고 대기"
                                      : ""}
                                  </button>
                                </td>
                                <td className="table-text break-all">
                                  {release.receipt?.receiptNum}
                                </td>
                                <td className="table-text font-bold"></td>
                                <td className=" table-text font-bold">
                                  {release.createdAt &&
                                    moment(release.createdAt).format("YYMMDD")}
                                  <br />
                                  {release.createdAt &&
                                    moment(release.createdAt).format("h : mm")}
                                </td>
                                <td className="table-text">
                                  {release.good?.title}
                                </td>
                                <td className="table-text">
                                  {release.goodOptionName}
                                </td>
                                <td className="table-text font-bold">
                                  {release.quantity}
                                </td>
                                <td className="table-text">
                                  {release.receipt?.user?.name}
                                </td>
                                <td className="table-text font-bold">
                                  {release.receipt?.receiver}
                                </td>
                                <td className="table-text">
                                  {release.receipt?.address}
                                </td>
                                <td className="table-text">
                                  {release.receipt?.message}
                                </td>
                                <td className="table-text">
                                  {release.receipt?.pass}
                                </td>
                                <td className="table-text">
                                  {release.receipt?.phone}
                                </td>
                                <td className="text-sm text-center h-full break-all ">
                                  {release.good?.goodUuid}
                                </td>
                                <td className="table-text"></td>
                                <td className="table-text">
                                  {release.good?.weight &&
                                    release.good?.weight + "g"}
                                </td>
                                <td className="table-text">
                                  {release.redelivery}
                                </td>
                              </tr>
                            ))}
                        </tbody>
                      </table>

                      {loading && (
                        <TableError errorMessage={`정보를 불러오는중입니다`} />
                      )}
                      {!loading &&
                        data?.listOrder.orders &&
                        data.listOrder.orders.length < 1 && (
                          <TableError
                            errorMessage={`해당하는 ${word} 정보가 없습니다`}
                          />
                        )}

                      {!loading &&
                        data?.listOrder.orders &&
                        data.listOrder.orders.length > 0 && (
                          <PaginationComponent
                            page={page}
                            totalPages={Number(data.listOrder.totalPages)}
                            totalResults={Number(data.listOrder.totalResults)}
                            pageHandler={pageHandler}
                          />
                        )}
                      <Modal
                        open={open}
                        onClose={() => {
                          setOpen(false);
                        }}
                      >
                        <div className="max-w-[360px] w-full absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2   bg-[#F9F8F9] rounded-lg">
                          <div className="text-center py-2 h-10 text-white font-bold bg-[#3D3E49] rounded-t-lg  ">
                            <div className="flex flex-wrap justify-between">
                              <p className="w-[240px] ml-[40px] font-bold text-base">
                                배송 상태변경
                              </p>
                              <img
                                src={exit}
                                alt="닫기"
                                className="w-6 mr-2 cursor-pointer"
                                onClick={() => setOpen(false)}
                              ></img>
                            </div>
                          </div>
                          <div className="flex flex-wrap content-center">
                            <div className="px-10 py-10">
                              <div className="grid grid-rows-6 gap-y-4">
                                <div>
                                  <input
                                    type="radio"
                                    name="order"
                                    id="inspect"
                                    value={OrderStatus.Inspect}
                                    onChange={(e) => {
                                      if (e.target.checked) {
                                        setChange(OrderStatus.Inspect);
                                      }
                                    }}
                                  ></input>
                                  <span className="ml-4">주문 확인</span>
                                </div>

                                <div>
                                  <input
                                    type="radio"
                                    name="order"
                                    id="bound"
                                    value={OrderStatus.Bound}
                                    checked={change === OrderStatus.Bound}
                                    onChange={(e) => {
                                      if (e.target.checked) {
                                        setChange(OrderStatus.Bound);
                                      }
                                    }}
                                  ></input>
                                  <span className="ml-4">출고대기</span>
                                </div>
                                {/* <div>
                                  <input
                                    type="radio"
                                    name="order"
                                    id="bound"
                                    value={OrderStatus.Delivered}
                                    onChange={(e) => {
                                      if (e.target.checked) {
                                        setChange(OrderStatus.Delivered);
                                      }
                                    }}
                                  ></input>
                                  <span className="ml-4">배송완료</span>
                                </div> */}
                                <div>
                                  <input
                                    type="radio"
                                    name="order"
                                    id="pending"
                                    value={OrderStatus.Pending}
                                    onChange={(e) => {
                                      if (e.target.checked) {
                                        setChange(OrderStatus.Pending);
                                      }
                                    }}
                                  ></input>
                                  <span className="ml-4">배송 준비</span>
                                </div>
                              </div>
                            </div>
                          </div>
                          <button
                            className="w-full h-14 bg-[#F0F0F4] rounded-b-lg"
                            onClick={() => {
                              editOrderStatusHandler(stateId, change);
                              setOpen(false);
                            }}
                          >
                            확인
                          </button>
                        </div>
                      </Modal>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
};
