import { ApolloError, gql, useMutation } from "@apollo/client";
import {
  CrBannerMainMutation,
  CrBannerMainMutationVariables,
} from "../generated/graphql";

const CR_BANNER_MAIN_MUTATION = gql`
  mutation crBannerMain($input: CrBannerMainInput!) {
    crBannerMain(input: $input) {
      ok
      err
    }
  }
`;

export const useCrBannerMain = (
  onCompleted?: (data: CrBannerMainMutation) => void,
  onError?: (error: ApolloError) => void
) => {
  return useMutation<CrBannerMainMutation, CrBannerMainMutationVariables>(
    CR_BANNER_MAIN_MUTATION,
    {
      onCompleted,
      onError,
    }
  );
};
