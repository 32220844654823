import { ApolloError, gql, useMutation } from "@apollo/client";
import {
  CrCommentInput,
  CrCommentMutation,
  CrCommentMutationVariables,
} from "../generated/graphql";

export interface ICrCommentFormProps extends CrCommentInput {}
const CR_COMMENT_MUTATION = gql`
  mutation crComment($input: CrCommentInput!) {
    crComment(input: $input) {
      ok
      err
    }
  }
`;

export const useCrComment = (
  onCompleted?: (data: CrCommentMutation) => void,
  onError?: (error: ApolloError) => void
) => {
  return useMutation<CrCommentMutation, CrCommentMutationVariables>(
    CR_COMMENT_MUTATION,
    {
      onCompleted,
      onError,
    }
  );
};
