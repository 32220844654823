import { gql, useLazyQuery } from "@apollo/client";
import {
  ListLinkServiceQuery,
  ListLinkServiceQueryVariables,
} from "../generated/graphql";

const LIST_LINKSERVICE_QUERY = gql`
  query listLinkService($input: ListLinkServiceInput!) {
    listLinkService(input: $input) {
      ok
      err
      totalPages
      totalResults
      row
      linkservice {
        id
        name
        minAmount
      }
    }
  }
`;

export const useListLinkService = (
  onCompleted?: (data: ListLinkServiceQuery) => void
) => {
  return useLazyQuery<ListLinkServiceQuery, ListLinkServiceQueryVariables>(
    LIST_LINKSERVICE_QUERY,
    { onCompleted, fetchPolicy: "network-only" }
  );
};
