import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { useMe } from "../hooks/useMe";
import logo from "../assets/icon/NINANO.png";
import { pageIndicator, newOrder } from "../apollo";
import { NewOrderLists } from "../hooks/subNewOrderList";
import bell from "../assets/icon/bell.png";
import setting from "../assets/icon/setting.png";
import order from "../assets/icon/order.png";
import delivery from "../assets/icon/delivery.png";
import product from "../assets/icon/product.png";
import drone from "../assets/icon/drone.png";
import door from "../assets/icon/door.png";
import payments from "../assets/icon/payments.png";
import empty from "../assets/icon/Empty.svg";
import { useReactiveVar } from "@apollo/client";

import { signOut } from "../constants/token";
import { UserRole, WorkerType } from "../generated/graphql";
export const AsideAdminComponent: React.FC = () => {
  const { data: me, loading } = useMe();
  const usePageIndicator = useReactiveVar(pageIndicator);
  const IsNewOrder = useReactiveVar(newOrder);
  const { data: newOrderList } = NewOrderLists();

  useEffect(() => {
    if (newOrderList && newOrderList.newOrderList.length > 0) {
      newOrder(true);
    }
  }, [newOrderList]);
  return (
    <div className="rounded-r-3xl bg-[#3D3E49] min-h-screen relative">
      <div>
        <div className="flex flex-wrap justify-center mt-8 mb-8">
          <h1 className="flex flex-wrap content-center ">
            <a href="/admin">
              <img src={logo} alt="MFC 로고" className="w-32"></img>
            </a>
          </h1>
        </div>
        {!loading && (
          <ul className="text-white">
            {me?.me.role === UserRole.Admin && (
              <li className="mb-4">
                <Link
                  className={`py-2 w-full h-full pl-3 text-left block ${usePageIndicator ===
                    `receipt` && "border-r-4 border-[#FF8672]"}`}
                  to={`/receipt`}
                  onClick={() => {
                    if (IsNewOrder) {
                      newOrder(false);
                    }
                  }}
                >
                  <img
                    src={order}
                    alt="주문내역 아이콘"
                    className="inline-block ml-2 mr-2 w-[18px] h-[18px]"
                  />
                  주문 내역
                  {IsNewOrder && (
                    <div className="w-3 h-3 rounded-full bg-red-400 ml-4 inline-block">
                      <span className="w-full h-full block bg-red-400 animate-ping rounded-full"></span>
                    </div>
                  )}
                </Link>
              </li>
            )}

            {me?.me.role === UserRole.Admin && (
              <li className="mb-4">
                <Link
                  className={`py-2 w-full h-full pl-3 text-left block ${usePageIndicator ===
                    `delivery` && "border-r-4 border-[#FF8672]"}`}
                  to={`/order`}
                >
                  <img
                    src={delivery}
                    alt="배송관리 아이콘"
                    className="inline-block ml-2 mr-2 w-[18px] h-[18px]"
                  />
                  배송 관리
                </Link>
              </li>
            )}
            {me?.me.role === UserRole.Admin && (
              <li className="mb-4">
                <Link
                  className={`py-2 w-full h-full pl-3 text-left block ${usePageIndicator ===
                    `product` && "border-r-4 border-[#FF8672]"}`}
                  to={`/product`}
                >
                  <img
                    src={product}
                    alt="상품관리 아이콘"
                    className="inline-block  ml-2 mr-2 w-[18px] h-[18px]"
                  />
                  상품 관리
                </Link>
              </li>
            )}
            {(me?.me.role === UserRole.Admin ||
              (me?.me.role === UserRole.Worker &&
                me.me.workerType === WorkerType.Pilot)) && (
              <li className="mb-4">
                <Link
                  className={`py-2 w-full h-full pl-3 text-left block ${usePageIndicator ===
                    `flight` && "border-r-4 border-[#FF8672]"}`}
                  to={`/flight`}
                >
                  <img
                    src={drone}
                    alt="비행관리 아이콘"
                    className="inline-block  ml-2 mr-2 w-[18px] h-[18px]"
                  />
                  비행/드론관리
                </Link>
              </li>
            )}
            {me?.me.role === UserRole.Admin && (
              <li className="mb-4">
                <Link
                  className={`py-2 w-full h-full pl-3 text-left block ${usePageIndicator ===
                    `door` && " border-r-4 border-[#FF8672]"}`}
                  to={`/door`}
                >
                  <img
                    src={door}
                    alt="문의관리 아이콘"
                    className="inline-block  ml-2 mr-2 w-[19px] h-[19px]"
                  />
                  문의 관리
                </Link>
              </li>
            )}

            {/* {me?.me.role == UserRole.Admin && (
              <li className="mb-4">
                <Link
                  className={`py-2 w-full h-full pl-3 text-left block ${usePageIndicator ===
                    `payments` && " border-r-4 border-[#FF8672]"}`}
                  to={`/payments`}
                >
                  <img
                    src={payments}
                    alt="정산 관리 아이콘"
                    className="inline-block  ml-2 mr-2 w-[19px] h-[19px]"
                  />
                  정산 관리
                </Link>
              </li>
            )} */}
            {me?.me.role === UserRole.Admin && (
              <li className="mb-4">
                <Link
                  className={`py-2 w-full h-full pl-3 text-left block ${usePageIndicator ===
                    `corporation` && " border-r-4 border-[#FF8672]"}`}
                  to={`/corporation`}
                >
                  <img
                    src={payments}
                    alt="정산 관리 아이콘"
                    className="inline-block  ml-2 mr-2 w-[19px] h-[19px]"
                  />
                  거래처 관리
                </Link>
              </li>
            )}
            {me?.me.role == UserRole.Admin && (
              <li className="mb-4">
                <Link
                  className={`py-2 w-full h-full pl-3 text-left block ${usePageIndicator ===
                    `events` && " border-r-4 border-[#FF8672]"}`}
                  to={`/events/update`}
                >
                  <img
                    src={empty}
                    alt="행사 관리 아이콘"
                    className="inline-block  ml-2 mr-2 w-[19px] h-[19px]"
                  />
                  배너 관리
                </Link>
              </li>
            )}
          </ul>
        )}
      </div>

      <footer className="grid grid-rows-2 gap-y-6 absolute bottom-5 left-2">
        <div className="">
          <div className="grid grid-cols-[1fr_48px] justify-between py-8 mx-auto w-[180px] h-full text-left text-white border-y border-[#8B8C8C] ">
            {me?.me.role === UserRole.Admin && (
              <h1 className="mr-4 text-[16px] font-bold ">관리자</h1>
            )}
            {me?.me.role === UserRole.Worker &&
              me.me.workerType === WorkerType.Pilot && (
                <h1 className="mr-4 text-[16px] font-bold ">조종사</h1>
              )}
            <div className="flex flex-wrap items-center justify-end">
              <Link className={`flex flex-wrap`} to={`/admin/`}>
                <img src={bell} alt="" className="inline-block mr-2 w-5 h-5" />
              </Link>
              <Link className={`inline-block`} to={`/admin/`}>
                <img src={setting} alt="" className="inline-block w-5 h-5" />
              </Link>
            </div>
            {me?.me.role === UserRole.Admin && (
              <div className="text-xs inline-block h-[18px] self-center ">
                Admin
              </div>
            )}
            {me?.me.role === UserRole.Worker &&
              me.me.workerType === WorkerType.Pilot && (
                <div className="text-xs inline-block h-[18px] self-center ">
                  Pilot
                </div>
              )}
            <div>
              <button
                className="text-[10px] w-12 h-5 bg-[#8B8C8C]/80 rounded-[20px]"
                onClick={signOut}
              >
                logout
              </button>
            </div>
          </div>
        </div>
        <div className=" w-[180px] mx-auto">
          <h1 className="text-[#8B8C8C] font-bold">(주)니나노컴퍼니</h1>
          <h1 className="text-[#8B8C8C] text-[10px] leading-4 font-bold">
            <p>대표이사 김건홍</p>
            <p>사업자등록번호 828-87-00831</p>
            <p>주소 : 경북 김천시 율곡동 1105 헥스테크노피아 2층</p>
            <p>Copyright @ ninano.All rights reserved.</p>
          </h1>
        </div>
      </footer>
    </div>
  );
};
