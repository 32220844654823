import logo from "../../assets/icon/NINANO.png";
import { Link } from "react-router-dom";

export const FindId = () => {
  return (
    <div className="w-full h-full">
      <div className="max-w-screen-yl mx-auto w-full h-full min-h-screen bg-white flex flex-wrap justify-center content-center overflow-hidden">
        <div className="w-full text-center">
          <Link to="/admin" className="inline-block w-[200px] mx-auto">
            <img src={logo} alt="로고" className="w-full" />
          </Link>
          <p className="pt-5 text-center">
            회원정보에 등록한 이름과 번호를 입력해주세요
          </p>
          <form className="p-4 mx-auto w-full max-w-md">
            <label className="py-10 flex">
              <span className="inline-block w-full max-w-[90px] text-[#2d2f30] font-bold">
                이름
              </span>
              <input
                type="text"
                className="inline-block border-b rounded-none border-[#3d3e49] pl-1 leading-normal flex-1 focus:outline-none"
              />
            </label>
            <label className="flex pt-10 pb-12">
              <span className="inline-block w-full max-w-[90px] text-[#2d2f30] font-bold">
                휴대전화
              </span>
              <input
                type="number"
                placeholder="-을 뺀 11자리로 입력해주세요"
                className="inline-block border-b rounded-none border-[#3d3e49]
                   focus:outline-none pl-1 leading-normal flex-1"
              />
            </label>
            <div className="pt-10 text-center">
              <Link
                to="/admin"
                className="inline-block w-32 py-1 px-2 border-grays border rounded mr-3 focus:outline-none"
              >
                취소
              </Link>
              <button
                className="inline-block w-32 py-1 px-2 text-bold bg-main border border-main
                   rounded text-white text-base"
              >
                확인
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};
