import { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { pageIndicator } from "../apollo";
import {
  ComponentTabLink,
  ITabLink,
  ITabTitle,
  PageType,
} from "../components/component-tab";
import { AsideAdminComponent } from "../layout/aside-admin";
import plus from "../assets/icon/plus.svg";
import { FormError2 } from "../components/form-error";
import {
  CategoryFirstOption,
  CategoryFirstType,
  CategorySecondOption,
  CategorySecondType,
  GoodStatusOption,
  GoodStatusOptionType,
  GoodTypeOption,
  GoodTypeOptionType,
  relatedProps,
  TagList,
} from "../constants/enum";
import { Button } from "../components/button";
import { useListCorporationSelect } from "../hooks/useListCorprationSelect";
import { Autocomplete, TextField } from "@mui/material";
import { useListGoodAll } from "../hooks/useListGoodAll";
import del from "../assets/icon/exit.png";
import { ICrGoodFormProps, useCrGood } from "../hooks/useCrGood";
import {
  Tags,
  CrGoodInput,
  CrGoodMutation,
  ListGoodAllQuery,
  ListCorporationSelectQuery,
} from "../generated/graphql";

export interface corporationProps {
  label: string;
  value: number;
}

export const AdminCrProduct = () => {
  const [out, setOut] = useState<boolean>(true);
  // const { confirmNavigation, showPrompt, cancelNavigation } = usePrompt(out);
  const [test, setTest] = useState(false);
  const [corporationList, setCorporationList] = useState<corporationProps[]>(
    []
  );
  const [cor, setCor] = useState<corporationProps[]>([
    { label: "불러올 거래처가 없습니다.", value: 0 },
  ]);
  // useEffect(() => {
  // pageIndicator("product");
  //   if (showPrompt) {
  //     if (
  //       window.confirm("변경사항이 저장 되지 않았습니다. 이동하시겠습니까?")
  //     ) {
  //       confirmNavigation();
  //     } else {
  //       cancelNavigation();
  //     }
  //   }
  //   if (!out) {
  //     document.location.href = "/product";
  //   }
  // }, [showPrompt, out]);
  // 태그
  const [tags, setTags] = useState<Tags[]>([]);
  const addTagHandler = (values: Tags) => {
    setTags([...tags, values]);
  };
  const removeTagHandler = (values: Tags) => {
    setTags(tags.filter((a) => a !== values));
  };
  useEffect(() => {
    pageIndicator("product");
  }, []);

  const onListCorporation = (data: ListCorporationSelectQuery) => {
    const {
      listCorporationSelect: { ok, err, corporations },
    } = data;
    var arrays: corporationProps[] = [...cor];
    arrays.splice(0);
    console.log(corporations);
    if (corporations && corporations.length > 0)
      for (const corporation of corporations) {
        var obj = {
          label: corporation.name,
          value: corporation.id,
        };
        arrays.push(obj);
      }
    setCor(arrays);
    console.log(cor);
  };
  const [
    listCorporationSelect,
    { data: corporations },
  ] = useListCorporationSelect(onListCorporation);
  const {
    getValues,
    register,
    handleSubmit,
    watch,
    formState: { errors, isValid },
  } = useForm<ICrGoodFormProps>({ mode: "all" });

  const onComplete = async (data: CrGoodMutation) => {
    const {
      crGood: { ok, err },
    } = data;
    if (ok) {
      setOut(false);
      // alert("저장되었습니다");
      window.location.href = `/product/update/${data.crGood.createdId}`;
    } else {
      alert(err);
    }
  };

  const [crGood, { loading }] = useCrGood(onComplete);
  const onSubmit = () => {
    if (!loading) {
      const {
        // name,
        // desc,
        price,
        discountRate,
        weight,
        // expirydate,
        goodType,
        title,
        summary,
        status,
        categoryFirst,
        categorySecond,
      } = getValues();
      const crGoodInput: CrGoodInput = {
        // name,
        // desc,
        discountRate: discountRate ? +discountRate : 0,
        tags: tags,
        price: price ? +price : 1000,
        weight: weight ? +weight : null,
        title,
        goodType,
        // expirydate,
        summary,
        status,
        categoryFirst,
        categorySecond,
        corporationIds: makeCorporationInputData(corporationList),
        goodRelationIds: makeRelInputData(relatedGoods),
      };
      crGood({ variables: { input: crGoodInput } });
    }
  };
  /// 거래처

  const makeCorporationInputData = (data: corporationProps[]) => {
    var corporationData: number[] = [];
    for (let item of data) {
      corporationData.push(item.value);
    }
    return corporationData;
  };

  const makeCorporation = (data: corporationProps | null) => {
    if (data) {
      if (
        corporationList.length > 0 &&
        corporationList.filter((el) => el.value === data.value).length > 0
      ) {
        return;
      }
      setCorporationList((corporation) => [...corporation, data]);
    }
  };

  const deleteCorporation = (data: corporationProps | null) => {
    if (data) {
      var newList = corporationList.filter((val) => val.value !== data.value);
      setCorporationList(newList);
    }
  };

  /// 거래처
  const tabList: ITabLink[] = [];
  const stitle: ITabTitle = { text: "상품 등록" };
  const [relations, setRelations] = useState<relatedProps[]>([
    { label: "불러올 상품이 없습니다.", value: 0 },
  ]);
  const onlistCall = (data: ListGoodAllQuery) => {
    const {
      listGoodAll: { ok, err, goods },
    } = data;
    var arrays: relatedProps[] = [...relations];
    arrays.splice(0);
    if (goods && goods.length > 0)
      for (const good of goods) {
        var obj = {
          label: good?.title,
          value: good.id,
        };
        arrays.push(obj);
      }
    setRelations(arrays);
  };
  const [listGoodAll] = useListGoodAll(onlistCall);
  const [relatedGoods, setRelatedGoods] = useState<relatedProps[]>([]);
  const makeRelInputData = (data: relatedProps[]) => {
    var relData: number[] = [];
    for (let item of data) {
      relData.push(item.value);
    }
    return relData;
  };
  const makeRelation = (data: relatedProps | null) => {
    if (data) {
      if (
        relatedGoods.length > 0 &&
        relatedGoods.filter((el) => el.value === data.value).length > 0
      ) {
        return;
      }
      setRelatedGoods((order) => [...order, data]);
    }
  };
  const deleteRelation = (data: relatedProps | null) => {
    if (data) {
      var newList = relatedGoods.filter((val) => val.value !== data.value);
      setRelatedGoods(newList);
    }
  };

  useEffect(() => {}, [relatedGoods, corporationList]);
  useEffect(() => {
    listGoodAll();
    listCorporationSelect();
  }, []);

  return (
    <div className="bg-main bg-opacity-50 w-full h-full min-h-screen">
      <div className="w-full mx-auto h-full min-h-screen  bg-gray-100">
        <main id="main">
          <div className="grid grid-cols-[200px_1fr] max-w-full">
            <AsideAdminComponent />
            <div className="mypages bg-gray-100 w-full min-w-[1200px] overflow-hidden">
              <div className="orderWrap">
                <div className="h-fix-content relative">
                  <div className={`w-full h-full px-10 py-8`}>
                    <ComponentTabLink
                      tabs={tabList}
                      stitle={stitle}
                      type={PageType.Create}
                    />
                    <div className="shadow-lg h-fix-content overflow-auto bg-white">
                      <form id="create" onSubmit={handleSubmit(onSubmit)}>
                        <div className="pl-9 pr-11 py-8 space-y-4">
                          <div className="space-y-2">
                            <p className="font-bold text-[#4F4F4F] text-base">
                              상품명
                            </p>
                            <input
                              {...register("title", {
                                required: `상품명을 입력해주세요`,
                              })}
                              type="text"
                              className="border w-full h-10 border-[#F0F0F4] text-base rounded-lg py-1 px-4"
                            />
                            {errors.title?.message && (
                              <FormError2 errorMessage={errors.title.message} />
                            )}
                          </div>
                          <div className="grid grid-cols-2 gap-x-6">
                            <div className="space-y-2">
                              <p className="font-bold text-[#4F4F4F] text-base">
                                상품설명
                              </p>
                              <input
                                {...register("summary", {
                                  required: "부제 입력해주세요",
                                })}
                                type="text"
                                className="border w-full h-10 border-[#F0F0F4] text-base rounded-lg py-1 px-4"
                              ></input>
                              {errors.summary?.message && (
                                <FormError2
                                  errorMessage={errors.summary.message}
                                />
                              )}
                            </div>
                          </div>
                          {/* <div className="space-y-2">
                            <p className="font-bold text-[#4F4F4F] text-base">
                              상품설명
                            </p>
                            <input
                              {...register("summary", {
                                required: `상품설명을 입력해주세요`,
                              })}
                              type="text"
                              className="border w-full h-10 border-[#F0F0F4] text-base rounded-lg py-1 px-4"
                            ></input>
                            {errors.desc?.message && (
                              <FormError2 errorMessage={errors.desc.message} />
                            )}
                          </div>
                          <div className="space-y-2">
                            <p className="font-bold text-[#4F4F4F] text-base">
                              옵션명
                            </p>
                            <input
                              {...register("name", {})}
                              type="text"
                              className="border w-full h-10 border-[#F0F0F4] text-base rounded-lg py-1 px-4"
                            ></input>
                          </div> */}

                          <div className="space-y-2">
                            <div className="font-bold text-[#4F4F4F] text-base flex flex-wrap justify-between h-6">
                              <p>카테고리</p>
                              <button className="ml-3">
                                <img src={plus} alt="더하기" />
                              </button>
                            </div>
                            <div className="grid grid-cols-3 gap-x-6">
                              <div className="space-y-2">
                                <select
                                  {...register(`categoryFirst`, {
                                    required: "카테고리를 선택해주세요",
                                  })}
                                  className="border border-[#F0F0F4]  rounded-lg py-1 px-2 w-full text-base"
                                >
                                  {Object(CategoryFirstOption).map(
                                    (
                                      categoryFirst: CategoryFirstType,
                                      index: number
                                    ) => (
                                      <option
                                        key={`product-category-option-${index}`}
                                        value={categoryFirst.value}
                                        selected={index === 0}
                                        disabled={index === 0}
                                      >
                                        {categoryFirst.label}
                                      </option>
                                    )
                                  )}
                                </select>
                                {errors.categoryFirst?.message && (
                                  <FormError2
                                    errorMessage={errors.categoryFirst.message}
                                  />
                                )}
                              </div>
                              <div className="space-y-2">
                                <select
                                  {...register(`categorySecond`, {
                                    required: "카테고리를 선택해주세요",
                                  })}
                                  className="border border-[#F0F0F4]  rounded-lg py-1 px-2 w-full text-base"
                                >
                                  {Object(CategorySecondOption)
                                    .filter((second: any) => {
                                      return (
                                        watch(`categoryFirst`) === second.depth
                                      );
                                    })
                                    .map(
                                      (
                                        categorySecond: CategorySecondType,
                                        index: number
                                      ) => (
                                        <option
                                          key={`product-category-second-${index}`}
                                          value={categorySecond.value}
                                        >
                                          {categorySecond.label}
                                        </option>
                                      )
                                    )}
                                </select>
                                {errors.categoryFirst?.message && (
                                  <FormError2
                                    errorMessage={errors.categoryFirst.message}
                                  />
                                )}
                              </div>
                            </div>
                          </div>
                          <div className="grid grid-cols-2 gap-x-6">
                            <div className="space-y-2">
                              <p className="font-bold text-[#4F4F4F] text-base">
                                판매 상태
                              </p>
                              <div className="grid grid-cols-4">
                                {Object(GoodStatusOption).map(
                                  (
                                    goodStatus: GoodStatusOptionType,
                                    index: number
                                  ) => (
                                    <div
                                      className=""
                                      key={`goodStatus-option-${index}`}
                                    >
                                      <input
                                        {...register(`status`, {})}
                                        className="border border-[#F0F0F4] rounded-lg py-1 px-2 w-full text-base"
                                        type="radio"
                                        name="status"
                                        defaultChecked={index === 0}
                                        id={goodStatus.label}
                                        value={goodStatus.value}
                                      ></input>
                                      <label
                                        htmlFor={goodStatus.label}
                                        className="px-2"
                                      >
                                        {goodStatus.label}
                                      </label>
                                    </div>
                                  )
                                )}
                              </div>
                              {/* <select
                                {...register(`status`, {})}
                                className="border border-[#F0F0F4] rounded-lg py-1 px-2 w-full text-base"
                              >
                                {Object(GoodStatusOption).map(
                                  (
                                    goodStatus: GoodStatusOptionType,
                                    index: number
                                  ) => (
                                    <option
                                      key={`goodStatus-option-${index}`}
                                      value={goodStatus.value}
                                      selected={index === 1}
                                      disabled={index === 0}
                                    >
                                      {goodStatus.label}
                                    </option>
                                  )
                                )}
                              </select> */}
                            </div>
                            <div className="space-y-2">
                              <p className="font-bold text-[#4F4F4F] text-base">
                                상품 상태
                              </p>
                              <select
                                {...register(`goodType`, {
                                  required: "상품 상태를 선택해주세요",
                                })}
                                className="border border-[#F0F0F4] rounded-lg py-1 px-2 w-full text-base"
                              >
                                {Object(GoodTypeOption).map(
                                  (
                                    goodType: GoodTypeOptionType,
                                    index: number
                                  ) => (
                                    <option
                                      key={`goodType-option-${index}`}
                                      value={goodType.value}
                                      selected={index === 0}
                                      disabled={index === 0}
                                    >
                                      {goodType.label}
                                    </option>
                                  )
                                )}
                              </select>
                              {errors.goodType?.message && (
                                <FormError2
                                  errorMessage={errors.goodType.message}
                                />
                              )}
                            </div>
                            {/* <div className="space-y-2">
                              <p className="font-bold text-[#4F4F4F] text-base">
                                유통기한
                              </p>
                              <input
                                {...register("expirydate", {
                                  required: `유통기한을 입력해주세요`,
                                })}
                                type="date"
                                className="border w-full h-10 border-[#F0F0F4] text-base rounded-lg py-1 px-4"
                              />
                              {errors.expirydate?.message && (
                                <FormError2
                                  errorMessage={errors.expirydate.message}
                                />
                              )}
                            </div> */}
                          </div>
                          <div className="grid grid-cols-2 gap-x-6">
                            <div className="space-y-2">
                              <p className="font-bold text-[#4F4F4F] text-base">
                                거래처 이름
                              </p>
                              <p className="w-full">
                                <Autocomplete
                                  disablePortal
                                  options={cor}
                                  autoComplete
                                  sx={{ padding: 0 }}
                                  onChange={(detail, value) => {
                                    makeCorporation(value);
                                  }}
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      sx={{ width: "100%" }}
                                      placeholder="등록할 거래처를 적어주세요"
                                    />
                                  )}
                                />
                              </p>
                              <p>
                                {corporationList &&
                                  corporationList.length > 0 &&
                                  corporationList.map((el, index) => (
                                    <span
                                      key={`relateion-width-${el.value}-${index}`}
                                      className="text-sm text-darkFont bg-gray-200 rounded-full py-1 px-2  inlie-flex flex-wrap content-center"
                                    >
                                      {el.label}
                                      <button
                                        className="px-2"
                                        onClick={() => {
                                          deleteCorporation(el);
                                        }}
                                      >
                                        <img
                                          src={del}
                                          alt="remove this relations"
                                          className="inline invert w-4"
                                        />
                                      </button>
                                    </span>
                                  ))}
                              </p>
                              {/* <select
                                {...register(`corporationId`, {
                                  required: "거래처 이름을 선택해주세요",
                                })}
                                className="border w-full border-[#F0F0F4] rounded-lg py-1 px-2 text-base"
                              >
                                <option disabled selected value="">
                                  거래처 선택
                                </option>
                                {corporations?.listCorporationSelect.corporations?.map(
                                  (corporation) => (
                                    <option
                                      key={`corporation-${corporation.id}`}
                                      value={corporation.id}
                                      // selected={corporation.name === "기타"}
                                    >
                                      {corporation.name}
                                    </option>
                                  )
                                )}
                              </select>
                              {errors.corporationId?.message && (
                                <FormError2
                                  errorMessage={errors.corporationId.message}
                                />
                              )} */}
                            </div>
                          </div>
                          {/* <div className="space-y-2">
                            <p className="font-bold text-[#4F4F4F] text-base">
                              거래처 이름
                            </p>

                            <CreatableSelect
                              isClearable
                              isDisabled
                              {...(register("corporationId"), {})}
                              className=" w-full  rounded-lg py-1 px-2"
                              onChange={() => testHandler()}
                              onCreateOption={() => testHandler()}
                            >
                              <option>z</option>
                            </CreatableSelect>
                          </div> */}
                          <div className="grid grid-cols-3 gap-x-6">
                            <div className="space-y-2">
                              <p className="font-bold text-[#4F4F4F] text-base">
                                무게
                              </p>
                              <div className="flex flex-wrap">
                                <input
                                  {...register("weight", {})}
                                  type="number"
                                  min={0}
                                  className="border  w-4/5 h-10 border-[#F0F0F4] text-base rounded-lg py-1 px-4"
                                ></input>
                                <p className="pl-2 my-2">g</p>
                              </div>
                              {errors.weight?.message && (
                                <FormError2
                                  errorMessage={errors.weight.message}
                                />
                              )}
                            </div>
                            <div className="space-y-2">
                              <p className="font-bold text-[#4F4F4F] text-base">
                                판매가
                              </p>
                              <div className="flex flex-wrap">
                                <input
                                  {...register("price", {
                                    required: `가격을 입력해주세요`,
                                  })}
                                  type="number"
                                  min={0}
                                  className="price border w-4/5 h-10 border-[#F0F0F4] text-base rounded-lg py-1 px-4"
                                ></input>
                                <p className="pl-2 my-2">원</p>
                              </div>
                              {errors.price?.message && (
                                <FormError2
                                  errorMessage={errors.price.message}
                                />
                              )}
                            </div>
                            <div className="space-y-2">
                              <p className="font-bold text-[#4F4F4F] text-base">
                                할인율
                              </p>
                              <div className="flex flex-wrap">
                                <input
                                  {...register("discountRate", {
                                    required: `할인율을 입력해주세요`,
                                  })}
                                  type="number"
                                  min={0}
                                  max={100}
                                  defaultValue={0}
                                  className="border w-4/5 h-10 border-[#F0F0F4] text-base rounded-lg py-1 px-4"
                                ></input>
                                <p className="pl-2 my-2">%</p>
                              </div>
                              {errors.discountRate?.message && (
                                <FormError2
                                  errorMessage={errors.discountRate.message}
                                />
                              )}
                            </div>
                          </div>
                          <div className="space-y-2">
                            <p className="font-bold text-[#4F4F4F] text-base">
                              연관상품
                            </p>
                            <Autocomplete
                              disablePortal
                              options={relations}
                              autoComplete
                              sx={{ padding: 0 }}
                              onChange={(detail, value) => {
                                makeRelation(value);
                              }}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  sx={{ width: "100%" }}
                                  placeholder="등록할 연관상품을 적어주세요"
                                />
                              )}
                            />
                            <p className="pt-4 space-x-4 space-y-2">
                              {relatedGoods &&
                                relatedGoods.length > 0 &&
                                relatedGoods.map((el) => (
                                  <span
                                    key={`relateion-width-${el.value}`}
                                    className="text-sm text-darkFont bg-gray-200 rounded-full py-1 px-2  inlie-flex flex-wrap content-center"
                                  >
                                    {el.label}
                                    <button
                                      className="px-2"
                                      onClick={() => {
                                        deleteRelation(el);
                                      }}
                                    >
                                      <img
                                        src={del}
                                        alt="remove this relations"
                                        className="inline invert w-4"
                                      />
                                    </button>
                                  </span>
                                ))}
                            </p>
                          </div>
                          <div className="space-y-2">
                            <p className="font-bold text-[#4F4F4F] text-base">
                              태그
                            </p>

                            <div className="pt-4 flex flex-wrap content-center gap-x-4">
                              {TagList.map((tag) => (
                                <label
                                  key={`tags-${tag.name}`}
                                  className="text-lg"
                                >
                                  <input
                                    checked={tags.includes(tag.value)}
                                    onChange={() => {
                                      if (tags.includes(tag.value)) {
                                        removeTagHandler(tag.value);
                                      } else {
                                        addTagHandler(tag.value);
                                      }
                                    }}
                                    type="checkbox"
                                    value={tag.value}
                                  />
                                  <span className="pl-2">{tag.name}</span>
                                </label>
                              ))}
                            </div>
                          </div>
                          <div className="flex flex-wrap justify-center pt-6">
                            <Button
                              className={`test w-[240px] h-[40px] bg-[#3D3E49] text-white rounded-lg`}
                              canClick={true}
                              actionText={`등록`}
                              loading={loading}
                              color={"text-white"}
                            />
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
};
