import moment from "moment";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDelComment } from "../hooks/useDelComment";
import { IEditCommentFormProps, useEditComment } from "../hooks/useEditComment";
import { useMe } from "../hooks/useMe";
import { Button } from "./button";
import { ArrElement } from "../sub-routes/sub-admin-up-product-detail";
import {
  DelCommentInput,
  DelCommentMutation,
  EditCommentInput,
  EditCommentMutation,
  ListCommentQuery,
} from "../generated/graphql";

export type TComment = ArrElement<ListCommentQuery["listComment"]["comments"]>;

interface IComponentEditCommentForm {
  comment: TComment;
  listRefetch: () => void;
}

export const ComponentEditComment: React.FC<IComponentEditCommentForm> = ({
  comment,
  listRefetch,
}) => {
  const [edit, setEdit] = useState<boolean>(false);
  const setEditHandler = () => {
    setEdit(!edit);
    if (edit) {
      setFocus("note");
    }
  };

  const { data: me } = useMe();

  const onDelete = async (data: DelCommentMutation) => {
    const {
      delComment: { ok, err },
    } = data;
    if (ok) {
      alert("문의 정보를 삭제하였습니다");
      listRefetch();
    }
    if (!ok) {
      alert(err);
    }
  };

  const [delComment, { loading: delCommentLoading }] = useDelComment(onDelete);
  const delCommentHandler = (commentId: number) => {
    if (!delCommentLoading) {
      const input: DelCommentInput = {
        commentId,
      };
      delComment({ variables: { input } });
    }
  };
  const {
    register,
    getValues,
    setValue,
    handleSubmit,
    setFocus,
    formState: { errors, isValid },
  } = useForm<IEditCommentFormProps>({ mode: "all" });

  const onEditCompleted = async (data: EditCommentMutation) => {
    const {
      editComment: { ok, err },
    } = data;
    if (ok) {
      alert("답변 정보를 수정하였습니다");
      setEditHandler();
      listRefetch();
    }
    if (!ok) {
      alert(err);
    }
  };
  const [editComment, { loading: editCommentLoading }] = useEditComment(
    onEditCompleted
  );
  const onSubmit = () => {
    if (!editCommentLoading) {
      const { note } = getValues();
      const input: EditCommentInput = {
        commentId: comment.id,
        note,
      };
      editComment({ variables: { input } });
    }
  };

  useEffect(() => {
    setValue("note", comment.note);
  });
  return (
    <div className="w-full block py-4 mt-1 border-b border-[#e2e2e2]">
      <form onSubmit={handleSubmit(onSubmit)}>
        <p>
          {`${comment.author.name}님, ${moment(comment.createdAt).format(
            "YYYY-M-D, HH:mm"
          )}`}
          {comment.author.id === me?.me.id && !edit && (
            <span className="float-right">
              <span
                className="cursor-pointer"
                onClick={() => delCommentHandler(comment.id)}
              >
                삭제
              </span>
              <span>{` / `}</span>
              <span className="cursor-pointer" onClick={setEditHandler}>
                수정
              </span>
            </span>
          )}
          {comment.author.id === me?.me.id && edit && (
            <span
              className="float-right cursor-pointer"
              onClick={setEditHandler}
            >
              취소
            </span>
          )}
        </p>
        <div className="grid grid-cols-7">
          <textarea
            {...register(`note`, { required: "필수 정보입니다" })}
            disabled={!edit}
            placeholder="답변을 입력해주세요"
            className={`w-full py-1 px-4 bg-white resize-none rounded-sm border-[#F0F0F4] border h-40 ${
              edit ? `col-span-6` : `col-span-7`
            }`}
          ></textarea>

          {edit && (
            <Button
              className={`inline-block rounded-lg text-center w-[200px] py-2 bg-[#3D3E49] text-white`}
              canClick={true}
              actionText={`저장`}
              loading={editCommentLoading}
              color={"text-white"}
            />
          )}
        </div>
      </form>
    </div>
  );
};
