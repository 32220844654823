import { gql, useLazyQuery } from "@apollo/client";
import {
  ListCommentQuery,
  ListCommentQueryVariables,
} from "../generated/graphql";

const LIST_COMMENT_QUERY = gql`
  query listComment($input: ListCommentInput!) {
    listComment(input: $input) {
      ok
      err
      comments {
        id
        createdAt
        note
        author {
          id
          name
        }
        inquiry {
          id
        }
      }
    }
  }
`;

export const useListComment = (
  onCompleted?: (data: ListCommentQuery) => void
) => {
  return useLazyQuery<ListCommentQuery, ListCommentQueryVariables>(
    LIST_COMMENT_QUERY,
    {
      onCompleted,
      fetchPolicy: "network-only",
      refetchWritePolicy: "merge",
    }
  );
};
