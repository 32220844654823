import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router";
import { pageIndicator } from "../apollo";
import {
  ComponentTabLink,
  ITabLink,
  ITabTitle,
  PageType,
} from "../components/component-tab";
import { upload } from "../functions/uploads";
import {
  IEditBannerMainFormProps,
  useEditBannerMain,
} from "../hooks/useEditBannerMain";
import img from "../assets/icon/coolicon.png";
import { useGetBannerMain } from "../hooks/useGetBannerMain";
import { AsideAdminComponent } from "../layout/aside-admin";
import del from "../assets/icon/exit.png";
import React from "react";
import {
  EditBannerMainInput,
  EditBannerMainMutation,
  GetBannerMainInput,
  GetBannerMainQuery,
  ListLinkServiceSelectQuery,
} from "../generated/graphql";
import { Autocomplete, TextField } from "@mui/material";
import { useListLinkServiceSelect } from "../hooks/useListLinkServiceSelect";
export interface serviceProps {
  label: string;
  value: number;
}
export const AdminUpBannerMain = () => {
  const tabList: ITabLink[] = [];
  const stitle: ITabTitle = { text: "메인배너 편집" };
  const [viewId, setViewId] = useState<String | undefined>("");
  const [eventViewId, setEventViewId] = useState<String | undefined>("");
  const [services, setServices] = useState<serviceProps[]>([]);

  const [relations, setRelations] = useState<serviceProps[]>([
    { label: "불러올 서비스가 없습니다.", value: 0 },
  ]);
  const onlistService = (data: ListLinkServiceSelectQuery) => {
    const {
      listLinkServiceSelect: { ok, err, linkservice },
    } = data;
    var arrays: serviceProps[] = [...relations];
    arrays.splice(0);
    if (linkservice && linkservice.length > 0)
      for (const service of linkservice) {
        var obj = {
          label: service.name,
          value: service.id,
        };
        arrays.push(obj);
      }
    setRelations(arrays);
  };

  const [listLinkServiceSelect, {}] = useListLinkServiceSelect(onlistService);

  const navigate = useNavigate();
  useEffect(() => {
    pageIndicator("events");
  });
  const params = useParams();

  const [imgBase64, setImgBase64] = React.useState(""); // 파일 base64
  const [eventImgBase64, setEventImgBase64] = React.useState(""); //
  const [imgFile, setImgFile] = useState(null); //파일
  const [eventImgFile, setEventImgFile] = useState(null); //파일
  const [uploading, setUploading] = useState<boolean>(false);
  const [mainEvent, setMainEvent] = useState<boolean>(false);
  const [eventActive, setEventActive] = useState<boolean>(false);
  const [mainFree, setMainFree] = useState<boolean>(false);
  const [mainRes, setMainRes] = useState<boolean>(false);
  const [mainOnePlusOne, setMainOnePlusOne] = useState<boolean>(false);

  const {
    getValues,
    setValue,
    handleSubmit,
    register,
    formState: { errors, isValid },
  } = useForm<IEditBannerMainFormProps>({ mode: "all" });

  const onGetComplete = (data: GetBannerMainQuery) => {
    const {
      getBannerMain: { ok, err },
    } = data;
    if (!ok) {
      alert("잘못된 접근입니다");
      navigate(-1);
      return;
    }
    setValue("isEvent", data.getBannerMain.bannerMain?.isEvent);
    setValue("alt", data.getBannerMain.bannerMain?.alt);
    setValue("isFree", data.getBannerMain.bannerMain?.isFree);
    setValue("isOnePlusOne", data.getBannerMain.bannerMain?.isOnePlusOne);
    setValue("isRes", data.getBannerMain.bannerMain?.isRes);
    setValue("link", data.getBannerMain.bannerMain?.link);
    setValue("note", data.getBannerMain.bannerMain?.note);
    setValue("title", data.getBannerMain.bannerMain?.title);
    setValue("name", data.getBannerMain.bannerMain?.name);
    setValue("eventTitle", data.getBannerMain.bannerMain?.event?.title);
    setValue("eventLink", data.getBannerMain.bannerMain?.event?.link);
    let _array: serviceProps[] = [];
    if (
      data.getBannerMain.bannerMain?.linkServices &&
      data.getBannerMain.bannerMain.linkServices.length > 0
    ) {
      for (let service of data.getBannerMain.bannerMain.linkServices) {
        var object: serviceProps = {
          label: service.name,
          value: Number(service.id),
        };
        _array.push(object);
      }

      setServices(_array);
      // data.getBannerMain.bannerMain.linkServices.forEach((service) => {
      //   let sId = service.id;
      //   serviceId.push(sId);
      // });
    }
    setMainEvent(Boolean(data.getBannerMain.bannerMain?.isEvent));
    setEventActive(Boolean(data.getBannerMain.bannerMain?.event?.isActive));
    setMainFree(Boolean(data.getBannerMain.bannerMain?.isFree));
    setMainOnePlusOne(Boolean(data.getBannerMain.bannerMain?.isOnePlusOne));
    setMainRes(Boolean(data.getBannerMain.bannerMain?.isRes));

    setImgBase64(String(data.getBannerMain.bannerMain?.upload?.url));
    setEventImgBase64(
      String(data.getBannerMain.bannerMain?.event?.upload?.url)
    );
  };

  const [getBannerMain, { data, loading }] = useGetBannerMain(onGetComplete);
  const getBannerMainHandler = (bannerMainId: number) => {
    const getBannerMainInput: GetBannerMainInput = {
      bannerMainId,
    };
    getBannerMain({ variables: { input: getBannerMainInput } });
  };

  const onEditCompleted = async (data: EditBannerMainMutation) => {
    const {
      editBannerMain: { ok, err },
    } = data;
    if (ok) {
      alert("저장되었습니다.");
      navigate(-1);
    } else {
      alert(err);
    }

    // setUploading(false);
  };
  const onSubmit = () => {
    if (!params.bannerMainId) return;

    if (!editBannerMainLoading) {
      const {
        link,
        title,
        name,
        note,
        alt,
        eventTitle,
        eventLink,
      } = getValues();
      const editBannerMainInput: EditBannerMainInput = {
        bannerMainId: +params.bannerMainId,
        link,
        title,
        name,
        note,
        alt,
        eventTitle,
        eventLink,
        eventActive: eventActive,
        isFree: mainFree,
        isRes: mainRes,
        isEvent: mainEvent,
        isOnePlusOne: mainOnePlusOne,
        viewUuid: String(viewId),
        eventUuid: String(eventViewId),
        serviceIds: makeServiceInputData(services),
      };
      editBannerMain({ variables: { input: editBannerMainInput } });
    }
  };
  const makeServiceInputData = (data: serviceProps[]) => {
    var serviceData: number[] = [];
    for (let item of data) {
      serviceData.push(item.value);
    }
    return serviceData;
  };
  const [
    editBannerMain,
    { loading: editBannerMainLoading },
  ] = useEditBannerMain(onEditCompleted);

  const editBannerMainHandler = (bannerMainId: number, viewUuid: string) => {
    const editBannerOptionInput: EditBannerMainInput = {
      bannerMainId,
      viewUuid,
    };
    editBannerMain({ variables: { input: editBannerOptionInput } });
  };

  const makeService = (data: serviceProps | null) => {
    if (data) {
      if (
        services.length > 0 &&
        services.filter((el) => el.value === data.value).length > 0
      ) {
        return;
      }
      setServices((service) => [...service, data]);
    }
  };
  const deleteService = (data: serviceProps | null) => {
    if (data) {
      var newList = services.filter((val) => val.value !== data.value);
      setServices(newList);
    }
  };

  useEffect(() => {
    if (!params.bannerMainId) return;
    const bannerMainId = +params.bannerMainId;
    getBannerMainHandler(bannerMainId);
    listLinkServiceSelect();
  }, []);

  const uploadBannerMainHandler = async (event: any) => {
    if (uploading) {
      alert("파일을 업로드 중입니다.");
      return;
    }
    if (!editBannerMainLoading) {
      setUploading(true);
      const result = await upload(event);

      if (!params.bannerMainId) {
        alert("배너 정보가 잘못되었습니다.");
      }
      if (result.data.ok) {
        setViewId(result.data.upload.uuid);
        setUploading(false);
        // editBannerMainHandler(
        //   Number(params.bannerMainId),
        //   result.data.upload.uuid
        // );
        alert("저장되었습니다.");
      } else {
        alert(`잘못된 파일입니다.`);
      }
    }
  };

  const handleChangeFile = async (e: any) => {
    if (uploading) {
      alert("파일을 업로드 중입니다.");
      return;
    }
    if (!editBannerMainLoading) {
      setUploading(true);
      const result = await upload(e);

      if (!params.bannerMainId) {
        alert("배너 정보가 잘못되었습니다.");
      }
      if (result.data.ok) {
        setViewId(result.data.upload.uuid);
        // setUrl(result.data.upload);
        setUploading(false);
        // editBannerMainHandler(
        //   Number(params.bannerMainId),
        //   result.data.upload.uuid
        // );
      } else {
        alert(`잘못된 파일입니다.`);
      }
    }

    let reader = new FileReader();
    reader.onloadend = () => {
      const base64 = reader.result;
      if (base64) {
        setImgBase64(base64.toString());
      }
    };
    if (e.target.files[0]) {
      reader.readAsDataURL(e.target.files[0]);
      setImgFile(e.target.files[0]);
    }
  };

  const handleChangeEventFile = async (e: any) => {
    if (uploading) {
      alert("파일을 업로드 중입니다.");
      return;
    }
    if (!editBannerMainLoading) {
      setUploading(true);
      const result = await upload(e);

      if (!params.bannerMainId) {
        alert("배너 정보가 잘못되었습니다.");
      }
      if (result.data.ok) {
        setEventViewId(result.data.upload.uuid);
        setUploading(false);
      } else {
        alert(`잘못된 파일입니다.`);
      }
    }

    let reader = new FileReader();
    reader.onloadend = () => {
      const base64 = reader.result;
      if (base64) {
        setEventImgBase64(base64.toString());
      }
    };
    if (e.target.files[0]) {
      reader.readAsDataURL(e.target.files[0]);
      setEventImgFile(e.target.files[0]);
    }
  };

  return (
    <div className="bg-main bg-opacity-50 w-full h-full min-h-screen">
      <div className="w-full mx-auto h-full min-h-screen bg-white">
        <main id="main">
          <div className="grid grid-cols-[200px_1fr]">
            <AsideAdminComponent />
            <div className="mypages bg-gray-100">
              <div className="orderWrap">
                <div className="bg-gray-100 h-fix-content relative">
                  <div className={`w-full h-full px-10 py-8`}>
                    <ComponentTabLink tabs={tabList} stitle={stitle} />
                    <div className="shadow-lg w-full  bg-white">
                      <form onSubmit={handleSubmit(onSubmit)} id="create">
                        <div className="pl-9 pr-11 py-8 space-y-4">
                          <div className="space-y-2">
                            <p className="p-1">1. 배너</p>
                            <div className="w-full flex flex-wrap space-y-4 ">
                              <p className=" flex flex-wrap content-center gap-x-2 gap-y-2 w-full">
                                <span className="flex flex-wrap content-center">
                                  Name:
                                </span>
                                <input
                                  {...register("name", {})}
                                  type="text"
                                  className="rounded-lg border flex-1 py-1 px-2"
                                />
                              </p>
                              <p className=" flex flex-wrap content-center gap-x-2 gap-y-2 w-full">
                                <span className="flex flex-wrap content-center">
                                  URL:
                                </span>
                                <input
                                  {...register("link", {})}
                                  type="text"
                                  className="rounded-lg border flex-1 py-1 px-2"
                                />
                              </p>
                              <p className=" flex flex-wrap content-center gap-x-2 gap-y-2 w-full">
                                <span className="flex flex-wrap content-center">
                                  Title:{" "}
                                </span>
                                <input
                                  {...register("title", {})}
                                  type="text"
                                  maxLength={14}
                                  className="rounded-lg border flex-1 py-1 px-2"
                                />
                              </p>
                              <p className=" flex flex-wrap content-center gap-x-2 gap-y-2 w-full">
                                <span className="flex flex-wrap content-center">
                                  서비스
                                </span>
                                <p className="w-full">
                                  <Autocomplete
                                    disablePortal
                                    options={relations}
                                    autoComplete
                                    sx={{ padding: 0 }}
                                    onChange={(detail, value) => {
                                      makeService(value);
                                    }}
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        sx={{ width: "100%" }}
                                        placeholder="등록할 서비스를 적어주세요"
                                      />
                                    )}
                                  />
                                </p>
                              </p>
                              <p>
                                {services &&
                                  services.length > 0 &&
                                  services.map((el, index) => (
                                    <span
                                      key={`relateion-width-${el.value}-${index}`}
                                      className="text-sm text-darkFont bg-gray-200 rounded-full py-1 px-2  inlie-flex flex-wrap content-center"
                                    >
                                      {el.label}
                                      <button
                                        className="px-2"
                                        onClick={() => {
                                          deleteService(el);
                                        }}
                                      >
                                        <img
                                          src={del}
                                          alt="remove this relations"
                                          className="inline invert w-4"
                                        />
                                      </button>
                                    </span>
                                  ))}
                              </p>
                              <p className=" flex flex-wrap content-center gap-x-2 w-full">
                                <span className="flex flex-wrap content-center">
                                  Note:{" "}
                                </span>
                                <input
                                  {...register("note", {})}
                                  type="text"
                                  maxLength={60}
                                  className="rounded-lg border flex-1 py-1 px-2"
                                />
                              </p>
                              <p className=" flex flex-wrap content-center gap-x-2 w-full">
                                <span className="flex flex-wrap content-center">
                                  Alt:{" "}
                                </span>
                                <input
                                  {...register("alt", {})}
                                  type="text"
                                  className="rounded-lg border flex-1 py-1 px-2"
                                />
                              </p>
                              <div className="grid grid-cols-2 lg:grid-cols-4 content-center gap-x-4 lg:gap-x-4 gap-y-2  w-full">
                                <div className="flex flex-wrap gap-x-2 content-center">
                                  <input
                                    type="checkbox"
                                    name="event"
                                    checked={mainFree}
                                    onChange={() => setMainFree(!mainFree)}
                                  />
                                  <div
                                    className={`py-[2px] px-[6px] font-semibold leading-[14px] rounded-[20px] bg-[#00B0EB] text-white text-[10px]`}
                                  >
                                    무료배송
                                  </div>
                                </div>
                                <div className="flex flex-wrap gap-x-2 content-center">
                                  <input
                                    type="checkbox"
                                    name="event"
                                    checked={mainRes}
                                    onChange={() => setMainRes(!mainRes)}
                                  />
                                  <div
                                    className={`py-[2px] px-[6px] font-semibold leading-[14px] rounded-[20px] bg-[#FFE400] text-[#363A3D] text-[10px]`}
                                  >
                                    예약배송
                                  </div>
                                </div>
                                <div className="flex flex-wrap gap-x-2 content-center">
                                  <input
                                    type="checkbox"
                                    name="event"
                                    checked={mainEvent}
                                    onChange={() => setMainEvent(!mainEvent)}
                                  />
                                  <div
                                    className={`py-[2px] px-[6px] font-semibold leading-[14px] rounded-[20px] bg-[#B42713] text-[#fff] text-[10px]`}
                                  >
                                    이벤트
                                  </div>
                                </div>
                                <div className="flex flex-wrap gap-x-2 content-center">
                                  <input
                                    type="checkbox"
                                    name="event"
                                    checked={mainOnePlusOne}
                                    onChange={() =>
                                      setMainOnePlusOne(!mainOnePlusOne)
                                    }
                                  />
                                  <div
                                    className={`py-[2px] px-[6px] font-semibold leading-[14px] rounded-[20px] bg-[#00B0EB] text-white text-[10px]`}
                                  >
                                    1+1
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="w-full grid grid-cols-1 gap-3">
                              <div className="w-[240px] aspect-square border rounded-lg border-[#000000] relative">
                                <label
                                  htmlFor={`banner${data?.getBannerMain.bannerMain?.id}`}
                                  className="flex flex-wrap w-full h-full content-center justify-center cursor-pointer"
                                >
                                  <img src={img}></img>
                                  <img
                                    // src={`${
                                    //   data?.getBannerMain.bannerMain?.upload
                                    //     ?.url !== null
                                    //     ? data?.getBannerMain.bannerMain?.upload
                                    //         ?.url
                                    //     : ""
                                    // }`}
                                    src={imgBase64}
                                    alt="배너이미지"
                                    className={`absolute left-0 top-0 w-full h-full rounded-lg ${
                                      imgBase64 === "undefined"
                                        ? // !Boolean(
                                          //   data?.getBannerMain.bannerMain?.upload
                                          // )
                                          "hidden"
                                        : ""
                                    } `}
                                  />
                                </label>
                                <input
                                  type="file"
                                  className="hidden"
                                  id={`banner${data?.getBannerMain.bannerMain?.id}`}
                                  accept="image/png, image/jpeg, image/jpg"
                                  onChange={handleChangeFile}
                                  // onChange={(event: any) =>
                                  //   uploadBannerMainHandler(event)
                                  // }
                                ></input>
                              </div>
                            </div>
                            <div className="space-y-2">
                              <div className="flex flex-wrap gap-x-4">
                                <p className="p-1">2. 이벤트</p>
                                <input
                                  type="checkbox"
                                  name="event"
                                  checked={eventActive}
                                  onChange={() => setEventActive(!eventActive)}
                                />
                                <div className="flex flex-wrap content-center">
                                  <span
                                    className={`py-[2px] px-[6px] font-semibold rounded-full bg-[#B42713] text-[#fff] text-[10px]`}
                                  >
                                    활성화
                                  </span>
                                </div>
                              </div>

                              <div className="w-full flex flex-wrap space-y-4 ">
                                <p className=" flex flex-wrap content-center gap-x-2 gap-y-2 w-full">
                                  <span className="flex flex-wrap content-center">
                                    Title:
                                  </span>
                                  <input
                                    {...register("eventTitle", {})}
                                    type="text"
                                    className="rounded-lg border flex-1 py-1 px-2"
                                  />
                                </p>
                                <p className=" flex flex-wrap content-center gap-x-2 gap-y-2 w-full">
                                  <span className="flex flex-wrap content-center">
                                    URL:
                                  </span>
                                  <input
                                    {...register("eventLink", {})}
                                    type="text"
                                    className="rounded-lg border flex-1 py-1 px-2"
                                  />
                                </p>
                                <div className="w-full grid grid-cols-1 gap-3">
                                  <div className="w-[240px] aspect-square border rounded-lg border-[#000000] relative">
                                    <label
                                      htmlFor={`bannerEvent${data?.getBannerMain.bannerMain?.id}`}
                                      className="flex flex-wrap w-full h-full content-center justify-center cursor-pointer"
                                    >
                                      <img src={img}></img>
                                      <img
                                        // src={`${
                                        //   data?.getBannerMain.bannerMain?.upload
                                        //     ?.url !== null
                                        //     ? data?.getBannerMain.bannerMain?.upload
                                        //         ?.url
                                        //     : ""
                                        // }`}
                                        src={eventImgBase64}
                                        alt="배너이미지"
                                        className={` object-contain absolute left-0 top-0 w-full h-full rounded-lg ${
                                          eventImgBase64 === "undefined"
                                            ? // !Boolean(
                                              //   data?.getBannerMain.bannerMain?.upload
                                              // )
                                              "hidden"
                                            : ""
                                        } `}
                                      />
                                    </label>
                                    <input
                                      type="file"
                                      className="hidden"
                                      id={`bannerEvent${data?.getBannerMain.bannerMain?.id}`}
                                      accept="image/png, image/jpeg, image/jpg"
                                      onChange={handleChangeEventFile}
                                      // onChange={(event: any) =>
                                      //   uploadBannerMainHandler(event)
                                      // }
                                    ></input>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="text-center py-4">
                            <button className="hover:opacity-90 w-[240px] h-[40px] bg-[#3D3E49] text-white rounded-lg">
                              등록하기
                            </button>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
};
