import React from "react";

interface IButtonProps {
  canClick: boolean;
  loading: boolean;
  color?: string;
  actionText: string;
  className?: string;
  id?: string;
  onClick?: () => void;
  type?: "submit" | "button" | "reset";
}
interface ITextButtonProps {
  canClick: boolean;
  actionText: string;
  className?: string;
  onClick?: any;
  ref?: any;
}
interface IButtonProps2 {
  canClick: boolean;
  loading: boolean;
  orange: boolean;
  ref?: any;
  actionText: string;
  className?: string;
  onClick?: any;
}
export const Button: React.FC<IButtonProps> = ({
  canClick,
  loading,
  actionText,
  className,
  onClick,
  color,
  type,
}) => {
  return (
    <button
      type={type ? type : `submit`}
      className={`${canClick ? "hover:opacity-90" : "pointer-events-none"} ${
        className ? className : ``
      } ${color ? color : ``}`}
      onClick={onClick}
    >
      {loading ? "Loading..." : actionText}
    </button>
  );
};

export const ReplyButton: React.FC<IButtonProps> = ({
  canClick,
  loading,
  actionText,
  className,
  onClick,
  color,
}) => {
  return (
    <button
      className={`inline-block hover:opacity-60  border-main font-bold rounded-md ${
        canClick ? "hover:opacity-90" : "pointer-events-none"
      } ${className} ${color}`}
      onClick={onClick}
    >
      {loading ? "Loading..." : actionText}
    </button>
  );
};

export const LoginButton: React.FC<IButtonProps> = ({
  canClick,
  loading,
  actionText,
  className,
  onClick,
  color,
}) => {
  return (
    <button
      className={` text-bold flex flex-wrap content-center justify-center w-[280px] py-2 mx-auto ${
        canClick ? "hover:opacity-80" : "pointer-events-none opacity-50"
      } ${className} ${color}`}
      onClick={onClick}
    >
      {loading ? "Loading..." : actionText}
    </button>
  );
};

export const TextButton: React.FC<ITextButtonProps> = ({
  canClick,
  actionText,
  onClick,
  className,
}) => {
  return (
    <button
      className={`${
        canClick
          ? `hover:opacity-100 hover:underline`
          : `pointer-events-none opacity-40`
      } ${className}`}
      onClick={onClick}
    >
      {actionText}
    </button>
  );
};
