import { Modal } from "@mui/material";
import moment from "moment";
import React, { useState } from "react";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router";
import { pageIndicator } from "../apollo";
import { Button } from "../components/button";
import exit from "../assets/icon/exit.png";
import {
  ComponentTabLink,
  ITabLink,
  ITabTitle,
  PageType,
} from "../components/component-tab";
import { FormError2 } from "../components/form-error";
import { useEditFlight } from "../hooks/useEditFlight";
import { useListProjectSelect } from "../hooks/useListProjectSelect";
import { useDaumPostcodePopup } from "react-daum-postcode";
import { useListUserSelect } from "../hooks/useListUserSelect";
import { AsideAdminComponent } from "../layout/aside-admin";

import { useListPackEdit } from "../hooks/useListPackEdit";
import { IEditPortFormProps, useEditPort } from "../hooks/useEditPort";
import { useGetPort } from "../hooks/useGetPort";
import { PortStatusOption, PortStatusOptionType } from "../constants/enum";
import {
  EditPortInput,
  EditPortMutation,
  GetPortInput,
  GetPortQuery,
} from "../generated/graphql";

export const AdminUpPort = () => {
  const navigate = useNavigate();
  const tabList: ITabLink[] = [];
  const [pickUpState, setPickUpState] = useState<boolean>();
  const [deliveryState, setDeliveryState] = useState<boolean>();
  const stitle: ITabTitle = { text: "포트기록 편집" };

  const [listProjectSelect, { data: projects }] = useListProjectSelect();

  const params = useParams();
  const makeStringFromKeyworkd = (keyWords?: string[] | null) => {
    var strings = "";
    if (!keyWords) return;
    for (const keyword of keyWords) {
      strings = strings + keyword + " ";
    }
    return strings;
  };
  const {
    register,
    handleSubmit,
    getValues,
    setValue,
    setFocus,
    watch,
    formState: { errors, isValid },
  } = useForm<IEditPortFormProps>({ mode: "all" });

  const handlePostCode = (data: any) => {
    let fullAddress = data.address;
    let extraAddress = "";

    if (data.addressType === "R") {
      if (data.bname !== "") {
        extraAddress += data.bname;
      }
      if (data.buildingName !== "") {
        extraAddress +=
          extraAddress !== "" ? `, ${data.buildingName}` : data.buildingName;
      }
      fullAddress += extraAddress !== "" ? ` (${extraAddress})` : "";
    }
    setValue(`address`, fullAddress);
    setFocus(`address`);
  };
  const scriptUrl =
    "https://t1.daumcdn.net/mapjsapi/bundle/postcode/prod/postcode.v2.js";
  const open = useDaumPostcodePopup(scriptUrl);
  const handlerClick = () => {
    open({ onComplete: handlePostCode });
  };

  const onGetComplete = (data: GetPortQuery) => {
    const {
      getPort: { ok, err, port },
    } = data;
    if (ok && port) {
      setValue("name", port.name);
      setValue("portId", port.id);
      setValue("projectId", Number(port.project?.id));
      setValue("address", port.address);
      setValue("phone", port.phone);
      setValue("usageTime", port.usageTime);
      setValue("status", port.status);
      setValue("pickUp", port.pickUp);
      setPickUpState(port.pickUp);
      setValue("deliveryRegion", port.deliveryRegion);
      setDeliveryState(port.deliveryRegion);
      setValue(`regionKeyWord`, port.regionKeyWord);
      makeStringFromKeyworkd(port.regionKeyWord);
    } else {
      alert(err);
    }
  };
  const [getPort, { data, loading }] = useGetPort(onGetComplete);
  const getFlightHandler = (portId: number) => {
    const getFlightInput: GetPortInput = {
      portId,
    };
    getPort({ variables: { input: getFlightInput } });
  };

  const onUpComplete = (data: EditPortMutation) => {
    const {
      editPort: { ok, err },
    } = data;
    if (ok) {
      alert("저장되었습니다");
      window.location.href = "/port";
    } else {
      alert(err);
    }
  };
  const [editPort, { loading: editFlightLoading }] = useEditPort(onUpComplete);

  const onSubmit = () => {
    if (!editFlightLoading) {
      const {
        name,
        status,
        pickUp,
        address,
        phone,
        deliveryRegion,
        usageTime,
        portId,
        projectId,
        regionKeyWord,
      } = getValues();
      const editPortInput: EditPortInput = {
        name,
        status,
        pickUp,
        address,
        phone,
        deliveryRegion,
        usageTime,
        portId,
        projectId: Number(projectId),
        regionKeyWord,
      };
      editPort({ variables: { input: editPortInput } });
    }
  };
  useEffect(() => {
    if (params.portId) {
      const flightId = +params.portId;
      getFlightHandler(flightId);
    } else {
      alert("잘못된 경로 입니다.");
      navigate(-1);
    }
    listProjectSelect();
  }, []);
  return (
    <div className="bg-main bg-opacity-50 w-full h-full min-h-screen">
      <div className="w-full mx-auto h-full min-h-screen bg-white">
        <main id="main">
          <div className="grid grid-cols-[200px_1fr]">
            <AsideAdminComponent />
            <div className="mypages bg-gray-100">
              <div className="orderWrap">
                <div className="bg-gray-100 h-fix-content relative">
                  <div className={`w-full h-full px-10 py-8`}>
                    <ComponentTabLink
                      tabs={tabList}
                      stitle={stitle}
                      type={PageType.Create}
                    />
                    <div className="shadow-lg min-w-[1160px] max-w-[1160px]  2xl:max-w-[1623px]  bg-white">
                      <form onSubmit={handleSubmit(onSubmit)} id="create">
                        <div className="pl-9 pr-11 py-8 space-y-4">
                          <div className="space-y-2">
                            <p className="font-bold text-[#4F4F4F] text-base">
                              포트 이름
                            </p>
                            <input
                              {...register("name", {})}
                              type="text"
                              className="border w-full h-10
                            border-[#F0F0F4] text-base rounded-lg py-1 px-4"
                            />
                          </div>
                          <div className="space-y-2">
                            <p className="font-bold text-[#4F4F4F] text-base">
                              프로젝트
                            </p>
                            <select
                              {...register(`projectId`, {
                                required: "프로젝트를 선택해주세요",
                              })}
                              className="border w-full border-[#F0F0F4] rounded-lg py-1 px-2"
                            >
                              {projects?.listProjectSelect.projects?.map(
                                (project) => (
                                  <option
                                    key={`project-${project.id}`}
                                    value={project.id}
                                  >
                                    {project.name}
                                  </option>
                                )
                              )}
                            </select>

                            {errors.projectId?.message && (
                              <FormError2
                                errorMessage={errors.projectId.message}
                              />
                            )}
                          </div>
                          <div className="space-y-2">
                            <p className="font-bold text-[#4F4F4F] text-base">
                              <span className="pr-10">주소</span>
                              <button
                                className="py-2 px-4 border border-btnDarkLine rounded-lg text-btn text-grayFont"
                                type="button"
                                onClick={handlerClick}
                              >
                                우편번호찾기
                              </button>
                            </p>
                            <div className="py-1">
                              <input
                                type="text"
                                {...register("address", {})}
                                className=" w-full text-standard break:text-large text-darkFont  rounded-lg h-10 py-1 px-4  border border-btnDarkLine focus:outline-mainIcon"
                              ></input>
                            </div>
                          </div>

                          <div className="space-y-2">
                            <p className="font-bold text-[#4F4F4F] text-base">
                              연락처
                            </p>
                            <input
                              {...register("phone", {})}
                              type="text"
                              className="border w-full h-10 border-[#F0F0F4] text-base rounded-lg py-1 px-4"
                            />
                          </div>
                          <div className="space-y-2">
                            <p className="font-bold text-[#4F4F4F] text-base">
                              이용시간
                            </p>
                            <input
                              {...register("usageTime", {})}
                              type="text"
                              placeholder="이용시간 안내를 텍스트로 입력해주세요"
                              className="border w-full h-10 border-[#F0F0F4] text-base rounded-lg py-1 px-4"
                            />
                          </div>
                          <div className="space-y-2">
                            <p className="font-bold text-[#4F4F4F] text-base">
                              포트 상태
                            </p>
                            <select
                              {...register(`status`, {
                                required: "상태를 선택해주세요",
                              })}
                              className="w-full border border-[#F0F0F4] rounded-lg py-1 px-2"
                            >
                              {Object(PortStatusOption).map(
                                (
                                  model: PortStatusOptionType,
                                  index: number
                                ) => (
                                  <option
                                    key={`drone-model-option-${index}`}
                                    value={model.value}
                                    disabled={index === 0}
                                  >
                                    {model.label}
                                  </option>
                                )
                              )}
                            </select>
                          </div>
                          <div className="grid grid-cols-2 gap-x-6">
                            <div className="space-y-2">
                              <p className="font-bold text-[#4F4F4F] text-base">
                                픽업 지역
                              </p>
                              <div className="grid grid-cols-2">
                                <label className="block">
                                  <input
                                    className="border border-[#F0F0F4] rounded-lg py-1 px-2 w-full text-base"
                                    type="radio"
                                    name="pickUp"
                                    checked={pickUpState === true}
                                    onChange={() => {
                                      setValue(`pickUp`, true);
                                      setPickUpState(true);
                                    }}
                                  ></input>
                                  <span className="px-2">픽업 가능</span>
                                </label>
                                <label className="block">
                                  <input
                                    className="border border-[#F0F0F4] rounded-lg py-1 px-2 w-full text-base"
                                    type="radio"
                                    checked={pickUpState === false}
                                    name="pickUp"
                                    onChange={() => {
                                      setValue(`pickUp`, false);
                                      setPickUpState(false);
                                    }}
                                  ></input>
                                  <span className="px-2">이용 불가</span>
                                </label>
                              </div>
                            </div>
                            <div className="space-y-2">
                              <p className="font-bold text-[#4F4F4F] text-base">
                                배송 지역
                              </p>
                              <div className="grid grid-cols-2">
                                <label className="block">
                                  <input
                                    className="border border-[#F0F0F4] rounded-lg py-1 px-2 w-full text-base"
                                    type="radio"
                                    name="deliveryRegion"
                                    checked={deliveryState === true}
                                    onChange={() => {
                                      setValue(`deliveryRegion`, true);
                                      setDeliveryState(true);
                                    }}
                                  ></input>
                                  <span className="px-2">배송 가능</span>
                                </label>
                                <label className="block">
                                  <input
                                    className="border border-[#F0F0F4] rounded-lg py-1 px-2 w-full text-base"
                                    type="radio"
                                    checked={deliveryState === false}
                                    name="deliveryRegion"
                                    onChange={() => {
                                      setValue(`deliveryRegion`, false);
                                      setDeliveryState(false);
                                    }}
                                  ></input>
                                  <span className="px-2">배송 불가</span>
                                </label>
                              </div>
                            </div>
                          </div>
                          <div className="space-y-2">
                            <p className="font-bold text-[#4F4F4F] text-base">
                              배송 가능 지역
                            </p>
                            <input
                              disabled={watch(`deliveryRegion`) === false}
                              type="text"
                              defaultValue={makeStringFromKeyworkd(
                                data?.getPort.port?.regionKeyWord
                              )}
                              onChange={(e) => {
                                var regionKeyWord = String(
                                  e.target.value
                                ).split(" ");
                                setValue("regionKeyWord", regionKeyWord);
                              }}
                              placeholder="동,리 단위로 작성 ( 예시 : 율곡동 송곡리 )"
                              className="border w-full h-10 border-[#F0F0F4] text-base rounded-lg py-1 px-4"
                            />
                            <p className="text-sm text-blue-400">
                              여러 개 작성시 띄워쓰기로 구분됩니다.
                            </p>
                          </div>
                          <div className="flex flex-wrap justify-center pt-6">
                            <Button
                              className={`w-[240px] h-[40px] bg-[#3D3E49] text-white rounded-lg`}
                              canClick={true}
                              actionText={`등록`}
                              loading={loading}
                              color={"text-white"}
                            />
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
};
