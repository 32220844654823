import { gql, useLazyQuery } from "@apollo/client";
import {
  ListPackEditQuery,
  ListPackEditQueryVariables,
} from "../generated/graphql";

const LIST_PACK_EDIT_QUERY = gql`
  query listPackEdit($input: ListPackEditInput!) {
    listPackEdit(input: $input) {
      ok
      err
      packs {
        id
        orders {
          orderNum
          id
          goodOptionName
        }
      }
    }
  }
`;

export const useListPackEdit = (
  onCompleted?: (data: ListPackEditQuery) => void
) => {
  return useLazyQuery<ListPackEditQuery, ListPackEditQueryVariables>(
    LIST_PACK_EDIT_QUERY,
    {
      onCompleted,
      fetchPolicy: "network-only",
    }
  );
};
