import { gql, useLazyQuery } from "@apollo/client";
import {
  ListGoodOptionQuery,
  ListGoodOptionQueryVariables,
} from "../generated/graphql";

const LIST_GOOD_OPTION_QUERY = gql`
  query listGoodOption($input: ListGoodOptionInput!) {
    listGoodOption(input: $input) {
      ok
      err
      goodOptions {
        id
        createdAt
        name
        stock
        price
        weight
        count
        goodOrder
        expirydate
        deliveryDate
        goodOptionUuid
        dateActive
        isEvent
        discountRate
        goodStocks {
          price
          stock
          expirydate
        }
        corporation {
          id
          name
        }
        bounds {
          status
        }
        good {
          title
          id
          category
          status
          goodType
        }
      }
    }
  }
`;

export const useListGoodOption = (
  onCompleted?: (data: ListGoodOptionQuery) => void
) => {
  return useLazyQuery<ListGoodOptionQuery, ListGoodOptionQueryVariables>(
    LIST_GOOD_OPTION_QUERY,
    {
      onCompleted,
      fetchPolicy: "network-only",
    }
  );
};
