import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { pageIndicator } from "../apollo";
import {
  ComponentTabLink,
  ITabLink,
  ITabTitle,
  PageType,
} from "../components/component-tab";
import { AsideAdminComponent } from "../layout/aside-admin";
import { FormError2 } from "../components/form-error";
import { Button } from "../components/button";
import {
  ICrCorporationFormProps,
  useCrCorporation,
} from "../hooks/useCrCorporation";
import { usePrompt } from "../constants/function";
import { ICrBannerFormProps, useCrBanner } from "../hooks/useCrBanner";
import { CrBannerInput, CrBannerMutation } from "../generated/graphql";
export const AdminCrBanner = () => {
  const [out, setOut] = useState<boolean>(true);
  const { confirmNavigation, showPrompt, cancelNavigation } = usePrompt(out);
  useEffect(() => {
    if (showPrompt) {
      if (
        window.confirm("변경사항이 저장 되지 않았습니다. 이동하시겠습니까?")
      ) {
        confirmNavigation();
      } else {
        cancelNavigation();
      }
    }
    pageIndicator("events");
    if (!out) {
      window.location.href = `/events`;
    }
  }, [showPrompt, out]);

  const {
    getValues,
    register,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm<ICrBannerFormProps>({ mode: "all" });

  const onComplete = async (data: CrBannerMutation) => {
    const {
      crBanner: { ok, err },
    } = data;
    if (ok) {
      // alert("저장되었습니다");
      setOut(false);
    } else {
      alert(err);
    }
  };
  const [crBanner, { loading }] = useCrBanner(onComplete);
  const onSubmit = () => {
    if (!loading) {
      const { title } = getValues();
      const crBannerInput: CrBannerInput = {
        title,
      };
      crBanner({ variables: { input: crBannerInput } });
    }
  };
  const tabList: ITabLink[] = [];

  const stitle: ITabTitle = { text: "배너 등록" };

  return (
    <div className="bg-main bg-opacity-50 w-full h-full min-h-screen">
      <div className="w-full mx-auto h-full min-h-screen bg-gray-100">
        <main id="main">
          <div className="grid grid-cols-[200px_1fr] max-w-full">
            <AsideAdminComponent />

            <div className="mypages bg-gray-100 w-full min-w-[1200px] overflow-hidden">
              <div className="orderWrap">
                <div className="min-h-screen relative">
                  <div className={`w-full h-full px-10 py-8`}>
                    <ComponentTabLink
                      tabs={tabList}
                      stitle={stitle}
                      type={PageType.Create}
                    />
                    <div className="shadow-lg w-full h-fix-content bg-white">
                      <form onSubmit={handleSubmit(onSubmit)} id="create">
                        <div className="pl-9 pr-11 py-8 space-y-4">
                          <div className="space-y-2">
                            <p className="font-bold text-[#4F4F4F] text-base">
                              배너 제목
                            </p>
                            <input
                              {...register("title", {
                                required: `배너 제목을 입력해주세요`,
                              })}
                              type="text"
                              className="border w-full h-10 border-[#F0F0F4] text-base rounded-lg py-1 px-4"
                            />
                            {errors.title?.message && (
                              <FormError2 errorMessage={errors.title.message} />
                            )}
                          </div>

                          <div className="flex flex-wrap justify-center pt-6">
                            <Button
                              className={`w-[240px] h-[40px] bg-[#3D3E49] text-white rounded-lg`}
                              canClick={true}
                              actionText={`등록`}
                              loading={loading}
                              color={"text-white"}
                            />
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
};
