import { useEffect, useState } from "react";
import { useParams } from "react-router";
import { ProductAttrTypes, saveJson } from "../constants/enum";
import { useGetGood } from "../hooks/useGetGood";
import { useEditGoodAttr } from "../hooks/useEditGoodAttr";
import { ProductAttrTypeLists } from "../constants/datas";
import { useForm } from "react-hook-form";
import {
  EditGoodAttrInput,
  EditGoodAttrMutation,
  GetGoodInput,
  GetGoodQuery,
} from "../generated/graphql";
export const SubAdminEditProductAttr: React.FC = () => {
  const params = useParams();
  const [attrSet, setAttrSet] = useState<saveJson>(ProductAttrTypeLists[0]);
  const [pushAttr, setPushAttr] = useState<string>();
  const [attrType, setAttrType] = useState<ProductAttrTypes[]>(attrSet.attrs);

  const { register, getValues, setValue } = useForm({ mode: "all" });

  const selectChangeHandelr = (productAttr: saveJson) => {
    let name = productAttr.typeName;
    let _index = ProductAttrTypeLists.findIndex((el) => el.typeName === name);
    setAttrSet(ProductAttrTypeLists[_index]);
  };

  const valueChangeHandler = (key: string, value: string) => {
    let arrays = { ...attrSet }.attrs;
    let _index = arrays.findIndex((el) => el.name === key);
    arrays[_index].value = value;
    setAttrSet((prev) => ({
      ...prev,
      attrs: arrays,
    }));
  };

  const onGetComplete = async (datas: GetGoodQuery) => {
    const {
      getGood: { ok, good },
    } = datas;
    if (!ok) {
      alert("잘못된 접근입니다");
      window.location.href = "/product";
      return;
    } else {
      if (good?.attributeSet) {
        const ParseDate = JSON.parse(String(good?.attributeSet));
        setAttrSet(ParseDate);
        setAttrType(ParseDate.attrs);
        setValue("options", ParseDate.typeName);
      }
    }
  };
  const [getGood, { data, loading }] = useGetGood(onGetComplete);
  const getGoodHandler = (goodId: number) => {
    const getGoodInput: GetGoodInput = {
      goodId,
    };
    getGood({ variables: { input: getGoodInput } });
  };

  //  editup

  const onEditComleted = (data: EditGoodAttrMutation) => {
    const {
      editGoodAttr: { ok, err },
    } = data;
    if (ok) {
      alert("업데이트 되었습니다.");
      document.location.reload();
    } else {
      alert(err);
    }
  };
  const [editGoodAttr] = useEditGoodAttr(onEditComleted);
  const editGoodAttrHandler = () => {
    const editGoodAttrInput: EditGoodAttrInput = {
      goodId: Number(params.goodId),
      attributeSet: pushAttr,
    };
    editGoodAttr({ variables: { input: editGoodAttrInput } });
  };
  useEffect(() => {
    if (!params.goodId) return;
    const goodId = +params.goodId;
    getGoodHandler(goodId);
  }, []);
  useEffect(() => {
    var pushJson = JSON.stringify(attrSet);
    setPushAttr(pushJson);
    setValue("options", attrSet.typeName);
    setAttrType(attrSet.attrs);
  }, [attrSet]);
  return (
    <div className="shadow-lg min-w-[1160px] min-h-[800px] h-fix-content max-w-[1160px]  2xl:max-w-[1623px]  bg-white">
      <div className="pl-9 pr-11 py-8">
        <p className="font-bold text-[#4F4F4F] text-base pb-12">
          제품정보고시
          <select
            {...register(`options`)}
            onChange={(e) => {
              setAttrSet(() => ({
                ...attrSet,
                ...ProductAttrTypeLists.filter(
                  (val) => val.typeName === e.target.value
                )[0],
              }));
            }}
            className="ml-8 w-[130px] h-[30px] border pl-4 rounded-lg"
          >
            {ProductAttrTypeLists &&
              ProductAttrTypeLists.map((el) => (
                <option
                  key={`goodsAttrTypeList-${el.typeName}`}
                  value={el.typeName}
                >
                  {el.typeName}
                </option>
              ))}
          </select>
        </p>
        <div className="grid grid-cols-2 gap-x-16 gap-y-8">
          {!loading &&
            attrType &&
            attrType.map((value) => (
              <div key={`goodAttrsLists-${value.name}`} className="space-y-2">
                <p className="font-bold text-[#4F4F4F] text-base">
                  {value.name}
                </p>
                <input
                  type="text"
                  defaultValue={value.value}
                  onKeyUp={(e) => {
                    var Value = e.currentTarget.value;
                    valueChangeHandler(value.name, Value);
                  }}
                  className="border w-full h-10 border-[#F0F0F4] text-base rounded-lg py-1 px-4"
                />
              </div>
            ))}
        </div>
      </div>
      <div className="flex flex-wrap justify-center py-6">
        <button
          onClick={editGoodAttrHandler}
          className="w-[240px] h-[40px] bg-[#3D3E49] text-white rounded-lg"
        >
          등록
        </button>
      </div>
    </div>
  );
};
