import { gql, useLazyQuery } from "@apollo/client";
import { ListDroneSelectQuery } from "../generated/graphql";

const LIST_DRONE_SELECT = gql`
  query listDroneSelect {
    listDroneSelect {
      ok
      err
      drones {
        id
        name
        model
      }
    }
  }
`;

export const useListDroneSelect = (
  onCompleted?: (data: ListDroneSelectQuery) => void
) => {
  return useLazyQuery<ListDroneSelectQuery>(LIST_DRONE_SELECT, {
    onCompleted,
  });
};
