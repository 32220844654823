import { ApolloError, gql, useMutation } from "@apollo/client";
import {
  CrCorporationInput,
  CrCorporationMutation,
  CrCorporationMutationVariables,
} from "../generated/graphql";

export interface ICrCorporationFormProps extends CrCorporationInput {}
const CR_CORPORATION_MUTATION = gql`
  mutation crCorporation($input: CrCorporationInput!) {
    crCorporation(input: $input) {
      ok
      err
    }
  }
`;

export const useCrCorporation = (
  onCompleted?: (data: CrCorporationMutation) => void,
  onError?: (error: ApolloError) => void
) => {
  return useMutation<CrCorporationMutation, CrCorporationMutationVariables>(
    CR_CORPORATION_MUTATION,
    {
      onCompleted,
      onError,
    }
  );
};
