import { gql, useLazyQuery, useQuery } from "@apollo/client";
import { ListProjectSelectQuery } from "../generated/graphql";

const LIST_PROJECT_SELECT = gql`
  query listProjectSelect {
    listProjectSelect {
      ok
      err
      projects {
        id
        name
        status
      }
    }
  }
`;

export const useListProjectSelect = (
  onCompleted?: (data: ListProjectSelectQuery) => void
) => {
  return useLazyQuery<ListProjectSelectQuery>(LIST_PROJECT_SELECT, {
    onCompleted,
  });
};
