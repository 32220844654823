import { useDelGoodDetail } from "../hooks/useDelGoodDetail";
import { useEffect, useState } from "react";
import { ListGoodDetail } from "./detail-video";
import {
  GoodDetailType,
  DelGoodDetailInput,
  DelGoodDetailMutation,
} from "../generated/graphql";

interface DetailTextProps {
  goodDetail: ListGoodDetail;
  refetch: () => void;
  detailHandler: (
    id: number,
    index: number,
    note: string | null,
    url: string | null,
    detailType: GoodDetailType,
    file?: any
  ) => void;
}

export const DetailText: React.FC<DetailTextProps> = ({
  goodDetail,
  refetch,
  detailHandler,
}) => {
  // edit
  const [text, setText] = useState<string>(goodDetail.note || "");
  const [detailurl, setDetailUrl] = useState<string>(goodDetail.url || "");

  //   delete
  const onDelCompleted = async (data: DelGoodDetailMutation) => {
    const {
      delGoodDetail: { ok, err },
    } = data;
    if (ok) {
      await refetch();
    } else {
      alert(err);
    }
  };
  const [delGoodDetail, { loading: delGoodDetailLoading }] = useDelGoodDetail(
    onDelCompleted
  );
  const delGoodDetailHandler = (goodDetailId: number) => {
    if (!delGoodDetailLoading && goodDetailId) {
      const delGoodDetailInput: DelGoodDetailInput = {
        goodDetailId,
      };
      delGoodDetail({ variables: { input: delGoodDetailInput } });
    }
  };

  useEffect(() => {
    detailHandler(
      goodDetail.id,
      goodDetail.index,
      text,
      detailurl,
      GoodDetailType.TextArea
    );
  }, [text, detailurl]);

  return (
    <div className="pb-8">
      <p className="font-bold">{goodDetail.index}.</p>
      <p className="pt-2 pb-4 flex flex-wrap content-center gap-x-2">
        <span className="flex flex-wrap content-center">URL: </span>
        <input
          type="text"
          value={detailurl}
          className="rounded-lg border flex-1 py-1 px-2"
          onChange={(e) => setDetailUrl(e.target.value)}
        />
      </p>
      <div className="w-full flex flex-wrap ">
        <textarea
          // {...register(`note`, {})}
          value={text}
          placeholder="내용을 입력해주세요"
          className="rounded-lg w-full border border-black py-1 px-2 whitespace-pre-wrap"
          rows={8}
          onChange={(e) => setText(e.target.value)}
        ></textarea>
      </div>
      <div className="py-2 text-right space-x-4">
        <button
          className={`py-1 px-2 rounded-lg bg-red-400 text-white`}
          onClick={() => delGoodDetailHandler(goodDetail.id)}
        >
          삭제하기
        </button>
      </div>
    </div>
  );
};
