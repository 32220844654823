import { gql, useLazyQuery } from "@apollo/client";
import { ListPortSelectQuery } from "../generated/graphql";

const LIST_PORT_SELECT = gql`
  query listPortSelect {
    listPortSelect {
      ok
      err
      ports {
        name
        id
      }
    }
  }
`;

export const useListPortSelect = (
  onCompleted?: (data: ListPortSelectQuery) => void
) => {
  return useLazyQuery<ListPortSelectQuery>(LIST_PORT_SELECT, {
    onCompleted,
  });
};
