import { ApolloError, gql, useMutation } from "@apollo/client";
import {
  DelDroneMutation,
  DelDroneMutationVariables,
} from "../generated/graphql";

const DEL_DRONE_MUTATION = gql`
  mutation delDrone($input: DelDroneInput!) {
    delDrone(input: $input) {
      ok
      err
    }
  }
`;

export const useDelDrone = (
  onCompleted?: (data: DelDroneMutation) => void,
  onError?: (Error: ApolloError) => void
) => {
  return useMutation<DelDroneMutation, DelDroneMutationVariables>(
    DEL_DRONE_MUTATION,
    {
      onCompleted,
      onError,
    }
  );
};
