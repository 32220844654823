import { ApolloError, gql, useMutation } from "@apollo/client";
import {
  CrPortInput,
  CrPortMutation,
  CrPortMutationVariables,
} from "../generated/graphql";

export interface ICrPortFormProps extends CrPortInput {}
const CR_PORT_MUTAION = gql`
  mutation crPort($input: CrPortInput!) {
    crPort(input: $input) {
      ok
      err
    }
  }
`;

export const useCrPort = (
  onCompleted?: (data: CrPortMutation) => void,
  onError?: (error: ApolloError) => void
) => {
  return useMutation<CrPortMutation, CrPortMutationVariables>(CR_PORT_MUTAION, {
    onCompleted,
    onError,
  });
};
