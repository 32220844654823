import { gql, useLazyQuery } from "@apollo/client";
import { ListBannerMainQuery } from "../generated/graphql";

const LIST_BANNER_MAIN_QUERY = gql`
  query listBannerMain {
    listBannerMain {
      ok
      err
      bannerMain {
        id
        index
        link
        mainType
        title
        name
        note
        alt
        isRes
        isEvent
        isFree
        isOnePlusOne
        upload {
          id
          uuid
          url
          filename
        }
        linkServices {
          id
          name
        }
      }
    }
  }
`;

export const useListBannerMain = (
  onCompleted?: (data: ListBannerMainQuery) => void
) => {
  return useLazyQuery<ListBannerMainQuery>(LIST_BANNER_MAIN_QUERY, {
    onCompleted,
  });
};
