import { ApolloError, gql, useMutation } from "@apollo/client";
import {
  CrBannerInput,
  CrBannerMutation,
  CrBannerMutationVariables,
} from "../generated/graphql";

export interface ICrBannerFormProps extends CrBannerInput {}
const CR_BANNER_MUTATION = gql`
  mutation crBanner($input: CrBannerInput!) {
    crBanner(input: $input) {
      ok
      err
    }
  }
`;

export const useCrBanner = (
  onCompleted?: (data: CrBannerMutation) => void,
  onError?: (error: ApolloError) => void
) => {
  return useMutation<CrBannerMutation, CrBannerMutationVariables>(
    CR_BANNER_MUTATION,
    {
      onCompleted,
      onError,
    }
  );
};
