import { ApolloError, gql, useMutation } from "@apollo/client";
import {
  EditOrdersStatusMutation,
  EditOrdersStatusMutationVariables,
} from "../generated/graphql";

const EDIT_ORDERS_STATUS_MUTATION = gql`
  mutation editOrdersStatus($input: EditOrdersStatusInput!) {
    editOrdersStatus(input: $input) {
      ok
      err
    }
  }
`;

export const useEditOrdersStatus = (
  onCompleted?: (data: EditOrdersStatusMutation) => void,
  onError?: (error: ApolloError) => void
) => {
  return useMutation<
    EditOrdersStatusMutation,
    EditOrdersStatusMutationVariables
  >(EDIT_ORDERS_STATUS_MUTATION, {
    onCompleted,
    onError,
  });
};
