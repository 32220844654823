import { ApolloError, gql, useMutation } from "@apollo/client";
import {
  EditFlightStatusMutation,
  EditFlightStatusMutationVariables,
} from "../generated/graphql";

const EDIT_FLIGHT_STATUS_MUTATION = gql`
  mutation editFlightStatus($input: EditFlightStatusInput!) {
    editFlightStatus(input: $input) {
      ok
      err
    }
  }
`;

export const useEditFlightStatus = (
  onCompleted?: (data: EditFlightStatusMutation) => void,
  onError?: (error: ApolloError) => void
) => {
  return useMutation<
    EditFlightStatusMutation,
    EditFlightStatusMutationVariables
  >(EDIT_FLIGHT_STATUS_MUTATION, {
    onCompleted,
    onError,
  });
};
