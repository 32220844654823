import type { Identifier, XYCoord } from 'dnd-core'
import type { FC } from 'react'
import { useRef } from 'react'
import { useDrag, useDrop } from 'react-dnd'
import { Link } from 'react-router-dom'
import { useDelBannerMain } from '../hooks/useDelBannerMain'
import { ItemTypes } from './ItemTypes'
import { DelBannerMainInput, DelBannerMainMutation } from '../generated/graphql'

const style = {
  border: '1px solid gray',
  padding: '0.5rem 1rem',
  marginBottom: '.5rem',
  backgroundColor: 'white',
  cursor: 'move',
  borderRadius: '0.5rem'
}

export interface CardProps {
  id: any
 name: any
  index: number
  // moveCard: (dragIndex: number, hoverIndex: number) => void
//   editGoodDetailIndex : (hoverIndex:number) => void
  editBannerMainIndex: (dragId: number, hoverIndex: number) => void
}

interface DragItem {
  index: number
  id: string
  type: string
}

export const CardBanner: FC<CardProps> = ({ id, name, index, editBannerMainIndex }) => {

  const ref = useRef<HTMLDivElement>(null)
  const [{ handlerId }, drop] = useDrop<
    DragItem,
    void,
    { handlerId: Identifier | null }
  >({
    accept: ItemTypes.CARD,
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      }
    },
    hover(item: DragItem, monitor) {
      if (!ref.current) {
        return
      }
      const dragId = item.id
      const dragIndex = item.index
      const hoverIndex = index

      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return
      }
      
      // Determine rectangle on screen
      const hoverBoundingRect = ref.current?.getBoundingClientRect()

      // Get vertical middle
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2

      // Determine mouse position
      const clientOffset = monitor.getClientOffset()

      // Get pixels to the top
      const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top

      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%

      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return
      }

      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return
      }
      editBannerMainIndex(Number(dragId), hoverIndex)
      // Time to actually perform the action
      // moveCard(dragIndex, hoverIndex)
    
      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex
    },
  })

  const [{ isDragging }, drag] = useDrag({
    type: ItemTypes.CARD,
    item: () => {
      return { id, name, index }
    },
    collect: (monitor: any) => ({
      isDragging: monitor.isDragging(),
    }),
  })

  const opacity = isDragging ? 0.3 : 1
  const borderColor = isDragging ? 'orange' : 'gray'
  drag(drop(ref))

  const onDelCompleted = async (data: DelBannerMainMutation) => {
    const {
      delBannerMain: { ok, err },
    } = data;
    if (ok) {
     window.location.reload();
    } else {
      alert(err);
    }
  };
  const [delBannerMain, { loading: delBannerMainLoading }] = useDelBannerMain(
    onDelCompleted
  );
  const delBannerMainHandler = (bannerMainId: number) => {
    if (
      window.confirm(
        "삭제한 배너는 복구할 수 없습니다. 정말로 삭제하시겠습니까?"
      )
    ){  if (!delBannerMainLoading && bannerMainId) {
      const delBannerMainInput: DelBannerMainInput = {
        bannerMainId,
      };
      delBannerMain({ variables: { input: delBannerMainInput } });
    }}
  
  };

  return (
    <div className='flex flex-wrap gap-x-4 '>
    <div ref={ref} className="flex-1 border border-gray-200 py-[6px] px-[3px] text-xs" data-handler-id={handlerId}>
      {index +1} <span className='pl-8'>{name}</span>

    </div>
    <a
      href={`/events/update/bannerMain/${id}`}
      className="inline-block border text-center border-[#8B8C8C]
        rounded-lg py-[6px] px-[3px] w-[48px] h-7 text-xs text-[#8B8C8C]"
    >
      편집
    </a>
    <button
      className={`py-[6px] px-[3px] rounded-lg text-xs bg-red-400 text-white`}
      onClick={() => delBannerMainHandler(id)}
    >
      삭제하기
    </button>
    </div>
  )
}
