import { ApolloError, gql, useMutation } from "@apollo/client";
import {
  EditBannerOptionInput,
  EditBannerOptionMutation,
  EditBannerOptionMutationVariables,
} from "../generated/graphql";

export interface IEditBannerOptionFormProps extends EditBannerOptionInput {}
const EDIT_BANNER_OPTION_QUERY = gql`
  mutation editBannerOption($input: EditBannerOptionInput!) {
    editBannerOption(input: $input) {
      ok
      err
    }
  }
`;

export const useEditBannerOption = (
  onCompleted?: (data: EditBannerOptionMutation) => void,
  onError?: (error: ApolloError) => void
) => {
  return useMutation<
    EditBannerOptionMutation,
    EditBannerOptionMutationVariables
  >(EDIT_BANNER_OPTION_QUERY, {
    onCompleted,
    onError,
  });
};
