import { ApolloError, gql, useMutation } from "@apollo/client";
import {
  EditGoodAttrInfoMutation,
  EditGoodAttrInfoMutationVariables,
} from "../generated/graphql";

const EDIT_GOOD_ATTR_INFO_MUTATION = gql`
  mutation editGoodAttrInfo($input: EditGoodAttrInfoInput!) {
    editGoodAttrInfo(input: $input) {
      ok
      err
    }
  }
`;

export const useEditGoodAttrInfo = (
  onCompleted?: (data: EditGoodAttrInfoMutation) => void,
  onError?: (error: ApolloError) => void
) => {
  return useMutation<
    EditGoodAttrInfoMutation,
    EditGoodAttrInfoMutationVariables
  >(EDIT_GOOD_ATTR_INFO_MUTATION, {
    onCompleted,
    onError,
  });
};
