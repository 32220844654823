import { ApolloError, gql, useMutation } from "@apollo/client";
import {
  DelPackMutation,
  DelPackMutationVariables,
} from "../generated/graphql";

const DEL_PACK_MUTATION = gql`
  mutation delPack($input: DelPackInput!) {
    delPack(input: $input) {
      ok
      err
    }
  }
`;

export const useDelPack = (
  onCompleted?: (data: DelPackMutation) => void,
  onError?: (Error: ApolloError) => void
) => {
  return useMutation<DelPackMutation, DelPackMutationVariables>(
    DEL_PACK_MUTATION,
    {
      onCompleted,
      onError,
    }
  );
};
