import { useEffect, useState } from "react";
import {
  DelLinkServiceInput,
  DelLinkServiceMutation,
  EditLinkServiceInput,
  EditLinkServiceMutation,
  ListLinkServiceInput,
  ListLinkServiceQuery,
} from "../generated/graphql";
import { ArrElement } from "../sub-routes/sub-admin-up-product-detail";
import { useLocation, useNavigate } from "react-router-dom";
import {
  ComponentTabLink,
  ITabLink,
  ITabTitle,
  PageType,
} from "../components/component-tab";
import exit from "../assets/icon/exit.png";
import { useListLinkService } from "../hooks/useListLinkService";
import { pageIndicator } from "../apollo";
import { TableError } from "../components/table-error";
import { PaginationComponent } from "../components/pagination";
import { AsideAdminComponent } from "../layout/aside-admin";
import { useDelLinkService } from "../hooks/useDelLinkService";
import { useForm } from "react-hook-form";
import {
  IEditServiceFormProps,
  useEditLinkService,
} from "../hooks/useEditLinkService";
import { Modal } from "@mui/material";
import { FormError2 } from "../components/form-error";

export type ListLinkService = ArrElement<
  ListLinkServiceQuery["listLinkService"]["linkservice"]
>;

export const AdminListLinkService = () => {
  const [page, setPage] = useState<number>(1);
  const [search, setSearch] = useState<string>("");
  const [edit, setEdit] = useState(false);
  const [checkedLists, setCheckedLists] = useState<ListLinkService[]>([]);
  const [allChecked, setAllChecked] = useState<boolean>(false);

  const transModalDataHandler = (service: ListLinkService) => {
    setValue("name", service.name);
    setValue("minAmount", service.minAmount);
    setValue("linkServiceId", service.id);
  };
  // --- 서비스 편집 ---
  const {
    register,
    handleSubmit,
    getValues,
    setValue,
    formState: { errors, isValid },
  } = useForm<IEditServiceFormProps>({ mode: "all" });

  const onEditComplete = (data: EditLinkServiceMutation) => {
    const {
      editLinkService: { ok, err },
    } = data;
    if (ok) {
      // alert("저장되었습니다");
      window.location.href = "/service";
    } else {
      alert(err);
    }
  };

  const [
    editLinkService,
    { loading: editLinkServiceLoading },
  ] = useEditLinkService(onEditComplete);

  const onSubmit = () => {
    if (!editLinkServiceLoading) {
      const { name, minAmount, linkServiceId } = getValues();
      const editLinkSerivceInput: EditLinkServiceInput = {
        name,
        minAmount: minAmount ? +minAmount : 0,
        linkServiceId: linkServiceId ? +linkServiceId : 1,
      };
      editLinkService({ variables: { input: editLinkSerivceInput } });
      setEdit(false);
    }
  };

  const location = useLocation();
  const tabList: ITabLink[] = [
    { text: "거래처", to: "/corporation" },
    { text: "서비스", to: "/service", selected: true },
  ];

  const stitle: ITabTitle = { text: "서비스" };
  const word = "서비스";
  const [listLinkService, { data, loading, refetch }] = useListLinkService();
  const listLinkServiceHandler = (
    page: number | null,
    serach: string | null
  ) => {
    const input: ListLinkServiceInput = {
      ...(page && { page: +page }),
      ...(search && { search: decodeURI(search) }),
    };
    listLinkService({ variables: { input } });
  };

  const onDelCompleted = async (data: DelLinkServiceMutation) => {
    const {
      delLinkService: { ok, err },
    } = data;
    if (ok) {
      alert("서비스를 삭제하였습니다.");
      await refetch();
    } else {
      alert(err);
    }
  };
  const [
    delLinkService,
    { loading: delLinkServiceLoading },
  ] = useDelLinkService(onDelCompleted);
  const delLinkServiceHandler = (linkServiceId: number) => {
    if (delLinkServiceLoading) return;
    if (
      window.confirm(
        "삭제한 서비스는 복구할 수 없습니다. 정말로 삭제하시겠습니까?"
      )
    ) {
      const input: DelLinkServiceInput = {
        linkServiceId,
      };
      delLinkService({ variables: { input } });
    }
  };
  const navigate = useNavigate();

  // --- Page ---
  const pageHandler = (page: number) => {
    const search =
      location.search.split("search=").length > 1
        ? location.search.split("search=")[1].split("&")[0]
        : null;
    setPage(page);
    navigate({
      pathname: "/service",
      search: `?page=${page}${search ? `&search=${search}` : ""}`,
    });
    listLinkServiceHandler(page, search);
  };

  useEffect(() => {
    // pageIndicator("service");
    setPage(
      location.search.split("page=").length > 1
        ? +location.search.split("page=")[1].split("&")[0]
        : 1
    );
    setSearch(
      location.search.split("search=").length > 1
        ? location.search.split("search=")[1].split("&")[0]
        : ""
    );
    listLinkServiceHandler(page, search);
  }, [search, page]);

  return (
    <div className="bg-main bg-opacity-50 w-full h-full min-h-screen">
      <div className="w-full mx-auto h-full min-h-screen bg-gray-100">
        <main id="main">
          <div className="grid grid-cols-[200px_1fr] max-w-full">
            <AsideAdminComponent />
            <div className="mypages bg-gray-100 w-full min-w-[1200px] overflow-hidden">
              <div className="orderWrap">
                <div className="h-fix-content relative">
                  <div className={`w-full h-full px-10 py-8`}>
                    <ComponentTabLink
                      tabs={tabList}
                      stitle={stitle}
                      type={PageType.Service}
                    />
                    {/* 목록 */}
                    <div className=" shadow-lg h-[800px] h-fix-content w-full overflow-auto bg-white">
                      <table
                        className="align-center w-[1623px]  text-sm  border-[#F9F8F9] "
                        id="table"
                      >
                        <colgroup>
                          <col width="36" />
                          <col width="64" />
                          <col width="64" />
                          <col />
                        </colgroup>
                        <thead>
                          <tr className="border-b border-[#F9F8F9] h-8">
                            <th className="text-[#8B8C8C] leading-3 ">
                              <input
                                type="checkbox"
                                name="allChk"
                                className="h-[20px] w-[20px]"
                                // checked={allChecked}
                                // onChange={setAllCheckedHandler}
                              />
                            </th>
                            <th className="text-[#8B8C8C] ">no</th>
                            <th className="text-[#8B8C8C] ">이름</th>
                            <th className="text-[#8B8C8C] ">객단가</th>
                            <th className="text-[#8B8C8C] ">비고</th>
                          </tr>
                        </thead>
                        <tbody>
                          {!loading &&
                            data?.listLinkService.linkservice &&
                            data.listLinkService.linkservice.map(
                              (service, index) => (
                                <tr
                                  className="border-b border-[#F9F8F9] h-[68px]"
                                  key={`service-list-${service.id}`}
                                >
                                  <td className="table-text">
                                    <input
                                      type="checkbox"
                                      className="h-[20px] w-[20px]"
                                      // checked={
                                      //   checkedLists.find(
                                      //     (checkedId) =>
                                      //       checkedId.id === service.id
                                      //   ) !== undefined
                                      // }
                                      //   onChange={(e) => {
                                      //     setCheckedListHandler(
                                      //       corporation.id,
                                      //       corporation
                                      //     );
                                      //   }}
                                    />
                                  </td>
                                  <td className="table-text">{index + 1}</td>
                                  <td className="table-text">{service.name}</td>
                                  <td className="table-text">
                                    {service.minAmount &&
                                      service.minAmount + "원"}
                                  </td>
                                  <td className="table-text">
                                    <button
                                      className="bg-[#CCCCCC] rounded-lg py-[6px] px-[3px] w-[48px] h-7 text-white"
                                      onClick={() => {
                                        setEdit(true);
                                        transModalDataHandler(service);
                                      }}
                                    >
                                      편집
                                    </button>
                                    <button
                                      onClick={() =>
                                        delLinkServiceHandler(service.id)
                                      }
                                      className="bg-[#CCCCCC] rounded-lg py-[6px]  w-[48px] h-7 text-white ml-4"
                                    >
                                      삭제
                                    </button>
                                  </td>
                                </tr>
                              )
                            )}
                        </tbody>
                      </table>
                      {loading && (
                        <TableError errorMessage={`정보를 불러오는중입니다`} />
                      )}
                      {!loading &&
                        data?.listLinkService.linkservice &&
                        data.listLinkService.linkservice.length < 1 && (
                          <TableError
                            errorMessage={`${word} 정보가 없습니다`}
                          />
                        )}

                      {!loading &&
                        data?.listLinkService.linkservice &&
                        data.listLinkService.linkservice.length > 0 && (
                          <PaginationComponent
                            page={page}
                            totalPages={Number(data.listLinkService.totalPages)}
                            totalResults={Number(
                              data.listLinkService.totalResults
                            )}
                            pageHandler={pageHandler}
                          />
                        )}
                    </div>
                  </div>
                </div>
                <Modal
                  open={edit}
                  onClose={() => {
                    setEdit(false);
                  }}
                >
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="max-w-[360px] w-full absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-[#F9F8F9] rounded-lg">
                      <div className="text-center py-2 h-10 text-white font-bold bg-[#3D3E49] rounded-t-lg  ">
                        <div className="flex flex-wrap justify-between">
                          <p className="w-[240px] ml-[40px] font-bold text-base">
                            서비스 생성
                          </p>
                          <img
                            src={exit}
                            alt="닫기"
                            className="w-6 mr-2 cursor-pointer"
                            onClick={() => setEdit(false)}
                          ></img>
                        </div>
                      </div>
                      <div className="px-10 py-10">
                        <div className="space-y-5">
                          <div className="space-y-3">
                            <p className="font-bold text-[#8B8C8C] text-sm">
                              서비스명
                            </p>
                            <input
                              {...register("name", {
                                required: `서비스이름을 입력해주세요`,
                              })}
                              type="text"
                              className="w-full border-[#F0F0F4] rounded-lg py-1 px-4 h-10"
                            ></input>
                            {errors.name?.message && (
                              <FormError2 errorMessage={errors.name.message} />
                            )}
                          </div>
                          <div className="space-y-3">
                            <p className="font-bold text-[#8B8C8C] text-sm">
                              객단가
                            </p>
                            <input
                              {...register("minAmount", {})}
                              type="text"
                              className="w-full border-[#F0F0F4] rounded-lg py-1 px-4 h-10"
                            ></input>
                            {errors.minAmount?.message && (
                              <FormError2
                                errorMessage={errors.minAmount.message}
                              />
                            )}
                          </div>
                        </div>
                      </div>
                      <button
                        type="submit"
                        className="w-full h-14 bg-[#F0F0F4] rounded-b-lg"
                      >
                        {loading ? "Loading..." : "확인"}
                      </button>
                    </div>
                  </form>
                </Modal>
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
};
