import { ApolloError, gql, useMutation } from "@apollo/client";
import {
  DelInquiryMutation,
  DelInquiryMutationVariables,
} from "../generated/graphql";

const DEL_INQUIRY_MUTATION = gql`
  mutation delInquiry($input: DelInquiryInput!) {
    delInquiry(input: $input) {
      ok
      err
    }
  }
`;

export const useDelInquiry = (
  onCompleted?: (data: DelInquiryMutation) => void,
  onError?: (Error: ApolloError) => void
) => {
  return useMutation<DelInquiryMutation, DelInquiryMutationVariables>(
    DEL_INQUIRY_MUTATION,
    {
      onCompleted,
      onError,
    }
  );
};
