import { gql, useLazyQuery } from "@apollo/client";
import {
  GetInquiryQuery,
  GetInquiryQueryVariables,
} from "../generated/graphql";

const GET_INQUIRY_QUERY = gql`
  query getInquiry($input: GetInquiryInput!) {
    getInquiry(input: $input) {
      ok
      err
      inquiry {
        id
        createdAt
        inquiryPosters {
          id
          index
          upload {
            id
            url
          }
        }
        title
        note
        error
        status
        type
        author {
          id
          name
          phone
        }
        manager {
          id
          name
        }
        comments {
          id
          note
        }
        receipt {
          id
          uuid
        }
      }
    }
  }
`;

export const useGetInquiry = (
  onCompleted?: (data: GetInquiryQuery) => void
) => {
  return useLazyQuery<GetInquiryQuery, GetInquiryQueryVariables>(
    GET_INQUIRY_QUERY,
    {
      onCompleted,
      fetchPolicy: "network-only",
    }
  );
};
