import { useRef, useState } from "react";
import { eDate, sDate } from "../../apollo";
import refresh from "../../assets/icon/refresh.png";
import { ko } from "date-fns/esm/locale";
import searchdate from "../../assets/icon/searchdate.png";
import { CSVLink } from "react-csv";
import { droneheaders } from "../../constants/datas";
import excel from "../../assets/icon/excel.png";
import { Modal } from "@mui/material";
import { useForm } from "react-hook-form";
import { DroneModelOption, DroneModelOptionType } from "../../constants/enum";
import { ICrDroneFormProps, useCrDrone } from "../../hooks/useCrDrone";
import { FormError2 } from "../form-error";
import exit from "../../assets/icon/exit.png";
import moment from "moment";
import {
  DroneStatus,
  CrDroneInput,
  CrDroneMutation,
} from "../../generated/graphql";

export interface ComponentTabDrone {
  dataList?: any[];
  DataCallback?: () => void;
}

export const ComponentTabDrone: React.FC<ComponentTabDrone> = ({
  dataList = [],
  DataCallback,
}) => {
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [dopen, setDroneOpen] = useState(false);
  const datalist = dataList.map((row) => ({
    ...row,
    createdAt: moment(row.createdAt).format("YYYY-MM-DD HH:mm"),
    status:
      row.status === DroneStatus.Damage
        ? "파손"
        : row.status === DroneStatus.Operating
        ? "운용"
        : row.status === DroneStatus.Pending
        ? "대기"
        : row.status === DroneStatus.Repair
        ? "수리"
        : "테스트",
  }));
  const dateHandler = (date: Date) => {
    sDate(date);
  };
  const edateHandler = (date: Date) => {
    eDate(date);
  };
  const refetchHandler = () => {
    window.location.href = "/drone";
  };
  const {
    getValues,
    register,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm<ICrDroneFormProps>({ mode: "all" });

  const onComplete = (data: CrDroneMutation) => {
    const {
      crDrone: { ok, err },
    } = data;
    if (ok) {
      // alert("드론을 생성했습니다.");
      window.location.href = `/drone`;
    } else {
      alert(err);
    }
  };

  const [crDrone, { loading }] = useCrDrone(onComplete);
  const onSubmit = () => {
    if (!loading) {
      const { name, model, serial } = getValues();
      const crDroneInput: CrDroneInput = {
        name,
        model,
        serial,
      };
      crDrone({ variables: { input: crDroneInput } });
      setDroneOpen(false);
    }
  };
  return (
    <div className="flex flex-wrap justify-between">
      <div className="flex flex-wrap">
        <button
          className="py-3 px-4 relative"
          onClick={() => {
            refetchHandler();
          }}
        >
          <img src={refresh} alt="새로고침" className="w-[30px] h-[30px]" />
        </button>
      </div>
      <div className="flex flex-wrap space-x-2"></div>
      <div className="flex flex-wrap justify-end pr-8">
        <div className="flex flex-wrap gap-x-3">
          <CSVLink
            data={datalist}
            headers={droneheaders}
            filename={`드론`}
            className={`flex flex-wrap gap-x-1  w-[72px] h-[30px] my-[13px] bg-white text-center items-center rounded-lg`}
          >
            <p className="pl-[15px] text-xs text-[#8B8C8C]">csv</p>
            <img src={excel} className="w-[10px] h-3" />
          </CSVLink>
          <button
            className="bg-transparent bg-[#8b8c8c] w-[104px] h-[30px] py-[7px] text-xs font-bold text-center rounded-lg my-[13px] text-white"
            onClick={() => setDroneOpen(true)}
          >
            드론 생성
          </button>
        </div>
      </div>
      <Modal
        open={dopen}
        onClose={() => {
          setDroneOpen(false);
        }}
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="max-w-[360px] w-full absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-[#F9F8F9] rounded-lg">
            <div className="text-center py-2 h-10 text-white font-bold bg-[#3D3E49] rounded-t-lg  ">
              <div className="flex flex-wrap justify-between">
                <p className="w-[240px] ml-[40px] font-bold text-base">
                  드론 생성
                </p>
                <img
                  src={exit}
                  alt="닫기"
                  className="w-6 mr-2 cursor-pointer"
                  onClick={() => setDroneOpen(false)}
                ></img>
              </div>
            </div>
            <div className="px-10 py-10">
              <div className="space-y-5">
                <div className="space-y-3">
                  <p className="font-bold text-[#8B8C8C] text-sm">드론명</p>
                  <input
                    {...register("name", {
                      required: `드론이름을 입력해주세요`,
                    })}
                    type="text"
                    className="w-full border-[#F0F0F4] rounded-lg py-1 px-4 h-10"
                  ></input>
                  {errors.name?.message && (
                    <FormError2 errorMessage={errors.name.message} />
                  )}
                </div>
                <div className="space-y-3">
                  <p className="font-bold text-[#8B8C8C] text-sm">드론 모델</p>
                  <div>
                    <select
                      {...register(`model`, {
                        required: "드론모델을 선택해주세요",
                      })}
                      className="w-full border-[#F0F0F4] rounded-lg py-1 px-4 "
                    >
                      {Object(DroneModelOption).map(
                        (model: DroneModelOptionType, index: number) => (
                          <option
                            key={`drone-model-option-${index}`}
                            value={model.value}
                          >
                            {model.label}
                          </option>
                        )
                      )}
                    </select>
                    {errors.model?.message && (
                      <FormError2 errorMessage={errors.model.message} />
                    )}
                  </div>
                </div>
                <div className="space-y-3">
                  <p className="font-bold text-[#8B8C8C] text-sm">serial</p>
                  <input
                    {...register("serial", {
                      required: `serial을 입력해주세요`,
                    })}
                    type="text"
                    className="w-full border-[#F0F0F4] rounded-lg py-1 px-4 h-10"
                  ></input>
                  {errors.serial?.message && (
                    <FormError2 errorMessage={errors.serial.message} />
                  )}
                </div>
              </div>
            </div>
            <button
              type="submit"
              className="w-full h-14 bg-[#F0F0F4] rounded-b-lg"
            >
              {loading ? "Loading..." : "확인"}
            </button>
          </div>
        </form>
      </Modal>
    </div>
  );
};
