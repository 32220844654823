import moment from "moment";
import React from "react";
import { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { pageIndicator } from "../apollo";
import {
  ComponentTabLink,
  ITabLink,
  ITabTitle,
  PageType,
} from "../components/component-tab";
import { PaginationComponent } from "../components/pagination";
import { TableError } from "../components/table-error";
import { useDelProject } from "../hooks/useDelProject";
import { useListProject } from "../hooks/useListProject";
import { AsideAdminComponent } from "../layout/aside-admin";
import { ArrElement } from "../sub-routes/sub-admin-up-product-detail";
import {
  DelProjectInput,
  DelProjectMutation,
  ListProjectInput,
  ListProjectQuery,
} from "../generated/graphql";

export type ListProject = ArrElement<
  ListProjectQuery["listProject"]["projects"]
>;

export const AdminListProject = () => {
  const [page, setPage] = useState<number>(1);
  const [search, setSearch] = useState<string>("");
  const [checkedLists, setCheckedLists] = useState<ListProject[]>([]);
  const [allChecked, setAllChecked] = useState<boolean>(false);
  const navigate = useNavigate();
  const location = useLocation();
  const tabList: ITabLink[] = [
    { text: "비행기록", to: "/flight" },
    { text: "드론관리", to: "/drone" },
    { text: "프로젝트관리", to: "/project", selected: true },
    { text: "포트관리", to: "/port" },
    { text: "Pack관리", to: "/pack" },
  ];
  const stitle: ITabTitle = { text: "비행/드론 관리" };
  const word = "프로젝트";

  // --- List ---
  const [listProject, { data, loading, refetch }] = useListProject();
  const listProjectHandler = (page: number | null, search: string | null) => {
    const input: ListProjectInput = {
      ...(page && { page: +page }),
      ...(search && { search: decodeURI(search) }),
    };
    listProject({ variables: { input } });
  };

  // --- Delete ---
  const onDelCompleted = async (data: DelProjectMutation) => {
    const {
      delProject: { ok, err },
    } = data;
    if (ok) {
      alert("프로젝트 삭제");
      await refetch();
    } else {
      alert(err);
    }
  };
  const [delProject, { loading: delProjectLoading }] = useDelProject(
    onDelCompleted
  );
  const delProjectHandler = (projectId: number) => {
    if (delProjectLoading) return;
    if (
      window.confirm(
        "삭제한 프로젝트는 복구할 수 없습니다. 정말로 삭제하시겠습니까?"
      )
    ) {
      const input: DelProjectInput = {
        projectId,
      };
      delProject({ variables: { input } });
    }
  };

  // --- Search ---
  const setSearchHandler = (search: string) => {
    navigate({
      pathname: "/project",
      search: `${search ? `?search=${search}` : ""}`,
    });
    listProjectHandler(1, search);
  };

  // --- CheckBox ---
  const setAllCheckedHandler = () => {
    setAllChecked(!allChecked);
    if (allChecked) setCheckedLists([]);

    if (!allChecked && data?.listProject.projects) {
      setCheckedLists([]);
      for (const project of data.listProject.projects) {
        setCheckedLists((checkedList) => [...checkedList, project]);
      }
    }
  };

  const setCheckedListHandler = (projectId: number, data: any) => {
    if (Boolean(checkedLists.find((checkedId) => checkedId.id === projectId))) {
      setCheckedLists(
        checkedLists.filter((checkedId) => checkedId.id !== projectId)
      );
    } else {
      setCheckedLists((originalList) => [...originalList, data]);
    }
  };
  useEffect(() => {
    if (checkedLists.length === data?.listProject.projects?.length)
      setAllChecked(true);
    else setAllChecked(false);
  }, [checkedLists]);

  // --- 페이지 ---
  const pageHandler = (page: number) => {
    const search =
      location.search.split("search=").length > 1
        ? location.search.split("search=")[1].split("&")[0]
        : null;
    setPage(page);
    navigate({
      pathname: "/project",
      search: `?page=${page}${search ? `&search=${search}` : ""}`,
    });
    listProjectHandler(page, search);
  };

  useEffect(() => {
    pageIndicator("flight");
    setPage(
      location.search.split("page=").length > 1
        ? +location.search.split("page=")[1].split("&")[0]
        : 1
    );
    setSearch(
      location.search.split("search=").length > 1
        ? location.search.split("search=")[1].split("&")[0]
        : ""
    );
    listProjectHandler(page, search);
  }, [search, page]);

  return (
    <div className="bg-main bg-opacity-50 w-full h-full min-h-screen">
      <div className="w-full mx-auto h-full min-h-screen bg-gray-100">
        <main id="main">
          <div className="grid grid-cols-[200px_1fr] max-w-full">
            <AsideAdminComponent />
            <div className="mypages bg-gray-100 w-full min-w-[1200px] overflow-hidden">
              <div className="orderWrap">
                <div className="h-fix-content relative">
                  <div className={`w-full h-full px-10 py-8`}>
                    <ComponentTabLink
                      tabs={tabList}
                      stitle={stitle}
                      type={PageType.Project}
                      setSearchs={setSearchHandler}
                    />
                    {/* 목록 */}
                    <div className=" shadow-lg h-[800px] h-fix-content w-full overflow-auto bg-white">
                      <table
                        className="align-center w-[1623px]  text-sm  border-[#F9F8F9] "
                        id="table"
                      >
                        <colgroup>
                          <col width="36" />
                          <col width="40" />
                          <col width="64" />
                          <col width="128" />
                          <col />
                          <col width="88" />
                          <col width="88" />
                          <col width="88" />
                          <col width="120" />
                        </colgroup>
                        <thead>
                          <tr className="border-b border-[#F9F8F9] h-8">
                            <th className="text-[#8B8C8C] leading-3 ">
                              <input
                                type="checkbox"
                                name="allChk"
                                className="h-[20px] w-[20px]"
                                checked={allChecked}
                                onChange={setAllCheckedHandler}
                              />
                            </th>
                            <th className="text-[#8B8C8C] ">no</th>
                            <th className="text-[#8B8C8C] ">상태</th>
                            <th className="text-[#8B8C8C] ">이름</th>
                            <th className="text-[#8B8C8C] ">설명</th>
                            <th className="text-[#8B8C8C] ">생성날짜</th>
                            <th className="text-[#8B8C8C] ">시작일</th>
                            <th className="text-[#8B8C8C] ">종료일</th>

                            <th className="text-[#8B8C8C] ">기능</th>
                          </tr>
                        </thead>
                        <tbody>
                          {!loading &&
                            data?.listProject.projects &&
                            data.listProject.projects.map((project, index) => (
                              <tr
                                className="border-b border-[#F9F8F9] h-[68px]"
                                key={`project-${project.id}`}
                              >
                                <td className="table-text">
                                  <input
                                    type="checkbox"
                                    className="h-[20px] w-[20px]"
                                    checked={
                                      checkedLists.find(
                                        (checkedId) =>
                                          checkedId.id === project.id
                                      ) !== undefined
                                    }
                                    onChange={(e) => {
                                      setCheckedListHandler(
                                        project.id,
                                        project
                                      );
                                    }}
                                  />
                                </td>
                                <td className="table-text">
                                  {(page - 1) * 20 + index + 1}
                                </td>
                                <td className="table-text">
                                  <p
                                    className={`rounded-lg mx-auto py-[6px] px-[3px]  w-[48px] h-7 text-white text-xs ${
                                      project.status === "Pending"
                                        ? "bg-[#555B94]"
                                        : project.status === "On"
                                        ? "bg-[#CCCCCC]"
                                        : "text-[#B42713] border border-[#B42713]"
                                    }  `}
                                  >
                                    {project.status === "Pending"
                                      ? "준비"
                                      : project.status === "On"
                                      ? "진행"
                                      : "완료"}
                                  </p>
                                </td>
                                <td className="table-text font-bold">
                                  {project.name}
                                </td>
                                <td className="table-text">{project.note}</td>
                                <td className="table-text">
                                  {project.createdAt &&
                                    moment(project.createdAt).format(
                                      "YY-MM-DD"
                                    )}
                                </td>
                                <td className="table-text">
                                  {project.startAt &&
                                    moment(project.startAt).format("YY-MM-DD")}
                                </td>
                                <td className="table-text">
                                  {project.endAt &&
                                    moment(project.endAt).format("YY-MM-DD")}
                                </td>

                                <td className="table-text">
                                  <Link
                                    to={`/project/update/${project.id}`}
                                    className="inline-block bg-[#CCCCCC] 
                                      rounded-lg py-[6px] px-[3px] w-[48px] text-xs h-7 text-white"
                                  >
                                    편집
                                  </Link>
                                  <button
                                    className="bg-[#CCCCCC] rounded-lg py-[6px]  w-[48px] h-7 text-white ml-4"
                                    onClick={() =>
                                      delProjectHandler(project.id)
                                    }
                                  >
                                    삭제
                                  </button>
                                </td>
                              </tr>
                            ))}
                        </tbody>
                      </table>
                      {loading && (
                        <TableError errorMessage={`정보를 불러오는중입니다`} />
                      )}
                      {!loading &&
                        data?.listProject.projects &&
                        data.listProject.projects.length < 1 && (
                          <TableError
                            errorMessage={`${word} 정보가 없습니다`}
                          />
                        )}

                      {!loading &&
                        data?.listProject.projects &&
                        data.listProject.projects.length > 0 && (
                          <PaginationComponent
                            page={page}
                            totalPages={Number(data.listProject.totalPages)}
                            totalResults={Number(data.listProject.totalResults)}
                            pageHandler={pageHandler}
                          />
                        )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
};
