import { ApolloError, gql, useMutation } from "@apollo/client";
import {
  EditOrdersStatusReleaseMutation,
  EditOrdersStatusReleaseMutationVariables,
} from "../generated/graphql";

const EDIT_ORDER_STATUS_RELEASE_MUTATION = gql`
  mutation editOrdersStatusRelease($input: EditOrdersStatusInput!) {
    editOrdersStatusRelease(input: $input) {
      ok
      err
    }
  }
`;

export const useEditOrdersStatusRelease = (
  onCompleted?: (data: EditOrdersStatusReleaseMutation) => void,
  onError?: (error: ApolloError) => void
) => {
  return useMutation<
    EditOrdersStatusReleaseMutation,
    EditOrdersStatusReleaseMutationVariables
  >(EDIT_ORDER_STATUS_RELEASE_MUTATION, {
    onCompleted,
    onError,
  });
};
