import { useEffect } from "react";

import { useParams } from "react-router";
import { pageIndicator } from "../apollo";
import { EditBannerProps } from "../constants/enum";

import { listBannerOption } from "../__generated__/listBannerOption";
import { useListBannerOption } from "../hooks/useListBannerOption";
import { crBannerOption } from "../__generated__/crBannerOption";
import { BannerOptionForm } from "../components/bannerOption";
import { useCrBannerOption } from "../hooks/useCrBannerOption";

export const SubAdminEditBannerOption: React.FC<EditBannerProps> = ({
  tabs,
  setTabHandler,
}) => {
  const params = useParams();

  const onCrCompleted = async (data: crBannerOption) => {
    const {
      crBannerOption: { ok, err },
    } = data;
    if (ok) {
      await refetch();
    } else {
      alert(err);
    }
  };
  const [
    crBannerOption,
    { loading: crBannerOptionLoading },
  ] = useCrBannerOption(onCrCompleted);
  const crBannerOptionHandler = () => {
    if (!crBannerOptionLoading) {
      crBannerOption();
    }
  };

  // --- 토스트 배너 리스트 ---
  const onListCompleted = async (data: listBannerOption) => {};
  const [
    listBannerOption,
    { data: listBannerOptionData, loading, refetch },
  ] = useListBannerOption(onListCompleted);

  useEffect(() => {
    pageIndicator("events");
  }, []);

  useEffect(() => {
    listBannerOption();
  }, []);

  return (
    <div className="shadow-lg min-w-[1160px] h-fix-content max-w-[1160px]  2xl:max-w-[1623px]  bg-white">
      <div className="pl-9 pr-11 py-8 space-y-4 min-h-[800px]">
        <p className="font-bold text-[#4F4F4F] text-base">
          토스트배너
          <button
            className="ml-4 min-w-[50px] px-2 h-[30px] bg-[#8B8C8C] text-white rounded-lg"
            onClick={() => crBannerOptionHandler()}
          >
            이미지
          </button>
        </p>

        {loading && <div>정보를 불러오는 중입니다</div>}
        {!loading &&
          listBannerOptionData?.listBannerOption.bannerOptions &&
          listBannerOptionData.listBannerOption.bannerOptions.map((banner) => (
            <>
              <BannerOptionForm
                bannerOption={banner}
                refetch={refetch}
                index={banner.index}
              />
            </>
          ))}
      </div>
    </div>
  );
};
