import { gql, useLazyQuery } from "@apollo/client";
import { ListPackSelectQuery } from "../generated/graphql";

const LIST_PACK_SELECT = gql`
  query listPackSelect {
    listPackSelect {
      ok
      err
      packs {
        id
        orders {
          orderNum
          id
          goodOptionName
        }
      }
    }
  }
`;

export const useListPackSelect = (
  onCompleted?: (data: ListPackSelectQuery) => void
) => {
  return useLazyQuery<ListPackSelectQuery>(LIST_PACK_SELECT, {
    onCompleted,
  });
};
