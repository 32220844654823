import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { AdminLogin } from "../routes/login/admin-login";
import { DOMRoutes } from "../constants/props";
import { FindId } from "../routes/login/find-id";
import { FindPwd } from "../routes/login/find-pwd";

const CommonRoutes: DOMRoutes[] = [
  {
    path: "/login",
    key: "/login",
    component: <AdminLogin />,
  },
  {
    path: "/findId",
    key: "/findId",
    component: <FindId />,
  },
  {
    path: "/findPwd",
    key: "/findPwd",
    component: <FindPwd />,
  },
];

export const LoggedOutRouter = () => {
  return (
    <Router>
      <Routes>
        {CommonRoutes.map((route) => (
          <Route key={route.key} path={route.path} element={route.component} />
        ))}
        <Route path={`/*`} element={<AdminLogin />} />
      </Routes>
    </Router>
  );
};
