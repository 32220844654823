import { ApolloError, gql, useMutation } from "@apollo/client";
import {
  EditGoodsStatusMutation,
  EditGoodsStatusMutationVariables,
} from "../generated/graphql";

const EDIT_GOODS_STATUS_MUTATION = gql`
  mutation editGoodsStatus($input: EditGoodsStatusInput!) {
    editGoodsStatus(input: $input) {
      ok
      err
    }
  }
`;

export const useEditGoodsStatus = (
  onCompleted?: (data: EditGoodsStatusMutation) => void,
  onError?: (error: ApolloError) => void
) => {
  return useMutation<EditGoodsStatusMutation, EditGoodsStatusMutationVariables>(
    EDIT_GOODS_STATUS_MUTATION,
    {
      onCompleted,
      onError,
    }
  );
};
