import { ApolloError, gql, useMutation } from "@apollo/client";
import {
  DelGoodPosterMutation,
  DelGoodPosterMutationVariables,
} from "../generated/graphql";

const DEL_GOOD_POSTER_MUTATION = gql`
  mutation delGoodPoster($input: DelGoodPosterInput!) {
    delGoodPoster(input: $input) {
      ok
      err
    }
  }
`;

export const useDelGoodPoster = (
  onCompleted?: (data: DelGoodPosterMutation) => void,
  onError?: (Error: ApolloError) => void
) => {
  return useMutation<DelGoodPosterMutation, DelGoodPosterMutationVariables>(
    DEL_GOOD_POSTER_MUTATION,
    {
      onCompleted,
      onError,
    }
  );
};
