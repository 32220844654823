import { ApolloError, gql, useMutation } from "@apollo/client";
import {
  CrGoodOptionInput,
  CrGoodOptionMutation,
  CrGoodOptionMutationVariables,
} from "../generated/graphql";

export interface ICrGoodOptionFormProps extends CrGoodOptionInput {
  related: number;
}

const CR_GOOD_OPTION_MUTATION = gql`
  mutation crGoodOption($input: CrGoodOptionInput!) {
    crGoodOption(input: $input) {
      ok
      err
      # createdId
    }
  }
`;

export const useCrGoodOption = (
  onCompleted?: (data: CrGoodOptionMutation) => void,
  onError?: (error: ApolloError) => void
) => {
  return useMutation<CrGoodOptionMutation, CrGoodOptionMutationVariables>(
    CR_GOOD_OPTION_MUTATION,
    {
      onCompleted,
      onError,
    }
  );
};
