import moment from "moment";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useParams } from "react-router";
import { pageIndicator } from "../apollo";
import { Button } from "../components/button";
import {
  ComponentTabLink,
  ITabLink,
  ITabTitle,
  PageType,
} from "../components/component-tab";
import { FormError2 } from "../components/form-error";
import {
  ProjectStatusOption,
  ProjectStatusOptionType,
} from "../constants/enum";
import { IEditProjectFormProps, useEditProject } from "../hooks/useEditProject";
import { useGetProject } from "../hooks/useGetProject";
import { AsideAdminComponent } from "../layout/aside-admin";
import {
  EditProjectInput,
  EditProjectMutation,
  GetProjectInput,
  GetProjectQuery,
  ProjectStatus,
} from "../generated/graphql";

export const AdminUpProject = () => {
  const tabList: ITabLink[] = [];

  const stitle: ITabTitle = { text: "프로젝트 편집" };
  const params = useParams();

  useEffect(() => {
    pageIndicator("flight");
  });
  const {
    register,
    handleSubmit,
    getValues,
    setValue,
    setFocus,
    formState: { errors, isValid },
  } = useForm<IEditProjectFormProps>({ mode: "all" });

  const onGetComplete = (data: GetProjectQuery) => {
    const {
      getProject: { ok, err },
    } = data;
    if (!ok) {
      alert("잘못된 접근입니다.");
      window.location.href = "/project";
      return;
    }
    setValue("name", `${data.getProject.project?.name}`);
    setValue("note", `${data.getProject.project?.note}`);
    setValue(
      "startAt",
      moment(data.getProject.project?.startAt).format("YYYY-MM-DD")
    );
    setValue(
      "endAt",
      moment(data.getProject.project?.endAt).format("YYYY-MM-DD")
    );
    setValue(
      "status",
      data.getProject.project?.status
        ? data.getProject.project.status
        : ProjectStatus.Pending
    );
  };
  const [getProject, { data, loading }] = useGetProject(onGetComplete);
  const getProjectHandler = (projectId: number) => {
    const getProjectInput: GetProjectInput = {
      projectId,
    };
    getProject({ variables: { input: getProjectInput } });
  };

  const onUpComplete = (data: EditProjectMutation) => {
    const {
      editProject: { ok, err },
    } = data;
    if (ok) {
      alert("저장되었습니다");
      window.location.href = "/project";
    } else {
      alert(err);
    }
  };
  const [editProject, { loading: editProjectLoading }] = useEditProject(
    onUpComplete
  );

  const onSubmit = () => {
    if (!params.projectId) return;
    if (!editProjectLoading) {
      const { name, note, startAt, endAt, status } = getValues();
      const editProjectInput: EditProjectInput = {
        name,
        note,
        startAt,
        endAt,
        status,
        projectId: +params.projectId,
      };
      editProject({ variables: { input: editProjectInput } });
    }
  };
  useEffect(() => {
    if (!params.projectId) return;
    const projectId = +params.projectId;
    getProjectHandler(projectId);
  }, []);

  return (
    <div className="bg-main bg-opacity-50 w-full h-full min-h-screen">
      <div className="w-full mx-auto h-full min-h-screen bg-white">
        <main id="main">
          <div className="grid grid-cols-[200px_1fr] max-w-full">
            <AsideAdminComponent />

            <div className="mypages bg-gray-100 w-full min-w-[1200px] overflow-hidden">
              <div className="orderWrap">
                <div className="bg-gray-100 h-fix-content relative">
                  <div className={`w-full h-full px-10 py-8`}>
                    <ComponentTabLink
                      tabs={tabList}
                      stitle={stitle}
                      type={PageType.Create}
                    />
                    <div className="shadow-lg h-[800px] h-fix-content w-full overflow-auto bg-white">
                      <form onSubmit={handleSubmit(onSubmit)} id="create">
                        <div className="pl-9 pr-11 py-8 space-y-4">
                          <div className="space-y-2">
                            <p className="font-bold text-[#4F4F4F] text-base">
                              이름
                            </p>
                            <input
                              {...register("name", {
                                required: `이름을 입력해주세요`,
                              })}
                              type="text"
                              className="border w-full h-10 border-[#F0F0F4] text-base rounded-lg py-1 px-4"
                            />
                            {errors.name?.message && (
                              <FormError2 errorMessage={errors.name.message} />
                            )}
                          </div>
                          <div className="space-y-2">
                            <p className="font-bold text-[#4F4F4F] text-base">
                              설명
                            </p>
                            <input
                              {...register("note", {
                                required: `설명을 입력해주세요`,
                              })}
                              type="text"
                              className="border w-full h-10 border-[#F0F0F4] text-base rounded-lg py-1 px-4"
                            />
                            {errors.note?.message && (
                              <FormError2 errorMessage={errors.note.message} />
                            )}
                          </div>
                          <div className="space-y-2">
                            <div className="font-bold text-[#4F4F4F] text-base flex flex-wrap justify-between">
                              <p className="font-bold text-[#4F4F4F] text-base">
                                프로젝트 상태
                              </p>
                            </div>
                            <div>
                              <select
                                {...register(`status`, {
                                  required: "프로젝트 상태를 선택해주세요",
                                })}
                                defaultValue="type"
                                className="border border-[#F0F0F4] rounded-lg py-1 px-2 w-full text-base"
                              >
                                {Object(ProjectStatusOption).map(
                                  (
                                    status: ProjectStatusOptionType,
                                    index: number
                                  ) => (
                                    <option
                                      key={`status-option-${index}`}
                                      value={status.value}
                                    >
                                      {status.label}
                                    </option>
                                  )
                                )}
                              </select>
                              {errors.status?.message && (
                                <FormError2
                                  errorMessage={errors.status.message}
                                />
                              )}
                            </div>
                          </div>
                          <div className="space-y-2">
                            <p className="font-bold text-[#4F4F4F] text-base">
                              시작일
                            </p>
                            <input
                              {...register("startAt", {
                                required: `시작일을 입력해주세요`,
                              })}
                              type="date"
                              className="border w-full h-10 border-[#F0F0F4] text-base rounded-lg py-1 px-4"
                            />
                            {errors.startAt?.message && (
                              <FormError2
                                errorMessage={errors.startAt.message}
                              />
                            )}
                          </div>
                          <div className="space-y-2">
                            <p className="font-bold text-[#4F4F4F] text-base">
                              종료일
                            </p>
                            <input
                              {...register("endAt", {
                                required: `종료일을 입력해주세요`,
                              })}
                              type="date"
                              className="border w-full h-10 border-[#F0F0F4] text-base rounded-lg py-1 px-4"
                            />
                            {errors.endAt?.message && (
                              <FormError2 errorMessage={errors.endAt.message} />
                            )}
                          </div>

                          <div className="flex flex-wrap justify-center pt-6">
                            <Button
                              className={`w-[240px] h-[40px] bg-[#3D3E49] text-white rounded-lg`}
                              canClick={true}
                              actionText={`등록`}
                              loading={loading}
                              color={"text-white"}
                            />
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
};
