import { ApolloError, gql, useMutation } from "@apollo/client";
import {
  LoginInput,
  LoginMutation,
  LoginMutationVariables,
} from "../generated/graphql";

export interface ILoginFormProps extends LoginInput {}

const LOGIN_MUTATION = gql`
  mutation login($input: LoginInput!) {
    login(input: $input) {
      ok
      token
      err
    }
  }
`;

export const useLogin = (
  onCompleted?: (data: LoginMutation) => void,
  onError?: (error: ApolloError) => void
) => {
  return useMutation<LoginMutation, LoginMutationVariables>(LOGIN_MUTATION, {
    onCompleted,
    onError,
  });
};
