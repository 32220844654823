import { ApolloError, gql, useMutation } from "@apollo/client";
import {
  EditLinkServiceInput,
  EditLinkServiceMutation,
  EditLinkServiceMutationVariables,
} from "../generated/graphql";

export interface IEditServiceFormProps extends EditLinkServiceInput {}

const EDIT_LINKSERVICE_MUTATION = gql`
  mutation editLinkService($input: EditLinkServiceInput!) {
    editLinkService(input: $input) {
      ok
      err
    }
  }
`;

export const useEditLinkService = (
  onCompleted?: (data: EditLinkServiceMutation) => void,
  onError?: (error: ApolloError) => void
) => {
  return useMutation<EditLinkServiceMutation, EditLinkServiceMutationVariables>(
    EDIT_LINKSERVICE_MUTATION,
    {
      onCompleted,
      onError,
    }
  );
};
