import { useDelGoodDetail } from "../hooks/useDelGoodDetail";
import { useListGoodDetail } from "../hooks/useListGoodDetail";
import img from "../assets/icon/coolicon.png";
import { useEditGoodDetail } from "../hooks/useEditGoodDetail";
import { useEffect, useState } from "react";
import { uploadDetailImage } from "../functions/uploads";
import { useForm } from "react-hook-form";
import { ListGoodDetail } from "./detail-video";
import {
  GoodDetailType,
  EditGoodDetailInput,
  DelGoodDetailInput,
  EditGoodDetailMutation,
  DelGoodDetailMutation,
} from "../generated/graphql";

interface DetailImageProps {
  goodDetail: ListGoodDetail;
  refetch: () => void;
  detailHandler: (
    id: number,
    index: number,
    note: string | null,
    url: string | null,
    detailType: GoodDetailType,
    file?: any
  ) => void;
}

export const DetailImage: React.FC<DetailImageProps> = ({
  goodDetail,
  refetch,
  detailHandler,
}) => {
  const [detailurl, setDetailUrl] = useState<string>(goodDetail.url || "");
  const [uploading, setUploading] = useState<boolean>(false);
  const [file, setFile] = useState<any>();
  const onEditCompleted = async (data: EditGoodDetailMutation) => {
    const {
      editGoodDetail: { ok, err },
    } = data;
    if (ok) {
      // alert("저장되었습니다.");
      await refetch();
    } else {
      alert(err);
      return;
    }
    setUploading(false);
  };
  const [
    editGoodDetail,
    { loading: editGoodDetailLoading },
  ] = useEditGoodDetail(onEditCompleted);

  const editGoodDetailImageHandler = (
    index: number,
    note: string | null,
    url: string | null,
    goodDetailId: number,
    imageUuid: string | null | undefined
  ) => {
    const editGoodDetailInput: EditGoodDetailInput = {
      index,
      note,
      url,
      goodDetailId,
      imageUuid,
    };
    editGoodDetail({ variables: { input: editGoodDetailInput } });
  };
  const uploadGoodDetailImageHandler = async (
    index: number,
    goodDetailId: number,
    note: string | null,
    file?: any,
    url?: string | null,
    uuid?: string | null | undefined
  ) => {
    if (uploading) {
      alert("파일을 업로드 중입니다.");
      return;
    }
    if (!editGoodDetailLoading) {
      if (!goodDetailId) {
        alert("상세 정보가 잘못되었습니다");
        return;
      }
      setUploading(true);
      if (file) {
        const result = await uploadDetailImage(file);
        if (result.data.ok) {
          setFile(result.data.upload.uuid);
          editGoodDetailImageHandler(
            index,
            note,
            url ? url : detailurl,
            goodDetailId,
            result.data.upload.uuid
          );
        } else {
          alert("잘못된 파일");
          return;
        }
      } else {
        editGoodDetailImageHandler(
          index,
          note,
          url ? url : null,
          goodDetailId,
          uuid
        );
      }
    }
  };
  // del
  const onDelCompleted = async (data: DelGoodDetailMutation) => {
    const {
      delGoodDetail: { ok, err },
    } = data;
    if (ok) {
      await refetch();
    } else {
      alert(err);
    }
  };
  const [delGoodDetail, { loading: delGoodDetailLoading }] = useDelGoodDetail(
    onDelCompleted
  );
  const delGoodDetailHandler = (goodDetailId: number) => {
    if (!delGoodDetailLoading && goodDetailId) {
      const delGoodDetailInput: DelGoodDetailInput = {
        goodDetailId,
      };
      delGoodDetail({ variables: { input: delGoodDetailInput } });
    }
  };
  useEffect(() => {
    detailHandler(
      goodDetail.id,
      goodDetail.index,
      null,
      detailurl,
      GoodDetailType.Image
    );
  }, [detailurl]);

  return (
    <div className="pb-8">
      <p className="font-bold flex fle-wrap  justify-between">
        {goodDetail.index}.
      </p>
      <p className="pt-2 pb-4 flex flex-wrap content-center gap-x-2">
        <span className="flex flex-wrap content-center">URL: </span>
        <input
          value={detailurl}
          type="text"
          className="rounded-lg border flex-1 py-1 px-2"
          onChange={(e) => setDetailUrl(e.target.value)}
        />
      </p>
      <div className="min-h-[360px] border rounded-lg  border-[#000000] relative">
        <label
          htmlFor={`detail${goodDetail.id}`}
          className="flex flex-wrap absolute left-0 top-0 w-full h-full content-center justify-center cursor-pointer"
        >
          <img src={img}></img>
          <img
            src={`${
              goodDetail.upload?.url !== null ? goodDetail.upload?.url : img
            }`}
            alt={`상세페이지 이미지 - ${goodDetail.createdAt}`}
            className={`absolute left-0 top-0 w-full h-full rounded-lg object-contain  ${
              !Boolean(goodDetail.upload?.url) ? "hidden" : ""
            } `}
          />
        </label>
        <input
          type="file"
          className="hidden"
          id={`detail${goodDetail.id}`}
          accept="image/png, image/jpeg, image/jpg, image/gif"
          onChange={(event: any) => {
            uploadGoodDetailImageHandler(
              goodDetail.index,
              goodDetail.id,
              "상세페이지 이미지",
              event.target.files[0]
            );
          }}
        ></input>
      </div>
      <div className="py-2 text-right space-x-4">
        <button
          className={`py-1 px-2 rounded-lg bg-red-400 text-white`}
          onClick={() => delGoodDetailHandler(goodDetail.id)}
        >
          삭제하기
        </button>
      </div>
    </div>
  );
};
